<template>
  <v-container fluid >
    <v-row v-if="!variables" no-gutters>
      <v-col cols="12" v-for="i in numberComponents" :key="i">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else no-gutters>
      <template v-for="(vardata, index) in variables">
        <v-col cols="12" :key="index">
          <VariableActionComponent :vardto="vardata" :show-diff="componentData.config.showDiff" />
        </v-col>
      </template>
    </v-row>
  </v-container>

</template>

<script>
import {mapGetters} from "vuex";
import VariableActionComponent from "./VariableActionComponent";
import ResultsService from "../../../services/ResultsService";
//import AuthService from "@/services/AuthService";

export default {
  name: 'VariableActionBlockComponent',

  components: {
    VariableActionComponent
  },

  data () {
    return {
      variables: null
    }
  },

  props: {
    componentData: Object,
    vardto: Object
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isUserRoot']),

    numberComponents() {
      return 6
      // if(this.componentData.config.expressions && this.componentData.config.expressions.length>0) {
      //   return this.componentData.config.expressions.length
      // }
      //
      // return this.componentData.config.variables.length
    }


  },

  methods: {
    loadChartData() {
      ResultsService.fetchVariableBlockData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
        this.variables = response.data.variables
      })
    },
  }

}
</script>
<style scoped>
</style>
