<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app id="mainouter">

    <v-system-bar app :color="systemBarBackgroundColor" v-if="showSystemBar" height="50">
      <div class="systemBar" :style="{'color': systemBarTextColor}">
        <v-icon :color="systemBarTextColor" size="1.5rem">{{systemBarIcon}}</v-icon>
        {{lozalizedSystemMessageText}}
        <a v-if="systemBarLink" :href="systemBarLink" class="systemBarLink" :style="{'color': systemBarTextColor}"  >{{systemBarLinkText}}</a>
      </div>
    </v-system-bar>

    <!--    https://codepen.io/kematzy/pen/NzQqOR-->

    <!-- expand-on-hover -->

    <v-navigation-drawer v-if="isLoggedIn && enableMainSidebar" app clipped overlay v-model="drawer" :width="100" >
      <MainSidebar :mini-variant="mini"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="enableFilterSidebar" app clipped overlay right v-model="drawerRight">
      <FiltersSidebar/>
    </v-navigation-drawer>


    <v-app-bar color="#f5f5f5" v-if="isLoggedIn && enableMainSidebar" app fixed clipped-left clipped-right>

      <v-toolbar-items>
        <div class="preButtonSpacer"></div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="onHomeClicked" v-on="on"><v-icon size="28">mdi-home-outline</v-icon></v-btn>
          </template>
          <span>{{'HOMEPAGE' | i18n}}</span>
        </v-tooltip>

        <div class="postButtonSpacer"></div>

      </v-toolbar-items>

      <SurveyMenuComponent v-if="activeSurvey!==null" />

      <ViewTabsComponent v-if="showTabsComponent"/>

      <v-spacer/>

      <v-toolbar-items>

        <AlertNotifierComponent/>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="onAccountClicked" v-on="on"><v-icon size="26">mdi-account-outline</v-icon></v-btn>
          </template>
          <span>{{'USER' | i18n}}</span>
        </v-tooltip>

        <v-tooltip bottom v-if="isUserAdmin">
          <template v-slot:activator="{ on }">
            <v-btn icon @click="onUsersAdminClicked" v-on="on"><v-icon size="26">mdi-account-multiple-plus</v-icon></v-btn>
          </template>
          <span>{{'USER_ADMIN' | i18n}}</span>
        </v-tooltip>

        <v-tooltip bottom v-if="isUserRoot">
          <template v-slot:activator="{ on }">
            <v-btn icon @click="onBackendClicked" v-on="on"><v-icon size="26">mdi-wrench-outline</v-icon></v-btn>
          </template>
          <span>Backend</span>
        </v-tooltip>

        <BugsNotifierComponent v-if="isUserRoot"/>

        <v-tooltip bottom v-if="canChangeUniverse">
          <template v-slot:activator="{ on }">
            <v-btn icon @click="onChangeUniverse" v-on="on"><v-icon size="26">mdi-swap-horizontal</v-icon></v-btn>
          </template>
          <span>{{'CHANGE_UNIVERSE' | i18n}}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="onLogoutClicked" v-on="on"><v-icon size="26">mdi-logout</v-icon></v-btn>
          </template>
          <span>{{'LOGOUT' | i18n}}</span>
        </v-tooltip>
      </v-toolbar-items>

      <v-toolbar-items class="logoItems">
        <LogoComponent/>
      </v-toolbar-items>

    </v-app-bar>

    <v-main class="contentDiv">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <SnackWatcher/>
    <Snackbar />
  </v-app>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from "vuex";
import FiltersSidebar from "./components/FiltersSidebar";
import SurveyMenuComponent from "./components/SurveyMenuComponent";
import ViewTabsComponent from "./components/ViewTabsComponent";
import Snackbar from "./components/Snackbar";
import MainSidebar from "./components/MainSidebar";
import SnackWatcher from "./components/SnackWatcher";
import LogoComponent from "./components/LogoComponent";
import PageNavigator from "./pagenavigator";
import AlertNotifierComponent from "./components/AlertNotifierComponent";
import BugsNotifierComponent from "@/components/BugsNotifierComponent";

export default {
  name: 'App',
  components: {
    BugsNotifierComponent,
    AlertNotifierComponent,
    LogoComponent, MainSidebar, ViewTabsComponent, SurveyMenuComponent, FiltersSidebar, SnackWatcher, Snackbar},

  data () {
    return {
      on: true,
      drawer: true,
      drawerRight: false,
      mini: true,
    }
  },

  computed: {
    ...mapState(['activeSurvey', 'activeView', 'filterSideBar', 'userIdentity']),
    ...mapGetters(['isLoggedIn', 'getSurveys', 'getViews', 'getFilteredDrillPath', 'isUserAdmin', 'isUserRoot', 'getSystemMessage', 'getLocale']),

    lozalizedSystemMessageText() {
      const textMap = this.getSystemMessage.texts

      if(textMap && textMap[this.getLocale]) {
        return textMap[this.getLocale]
      }

      return this.getSystemMessage.text
    },

    showSystemBar() {
      return this.isLoggedIn && this.getSystemMessage && this.lozalizedSystemMessageText
    },

    systemBarBackgroundColor() {
      return (this.getSystemMessage && this.getSystemMessage.backgroundColor) ? this.getSystemMessage.backgroundColor : "red"
    },

    systemBarTextColor() {
      return (this.getSystemMessage && this.getSystemMessage.textColor) ? this.getSystemMessage.textColor : "black"
    },

    systemBarLink() {
      return (this.getSystemMessage && this.getSystemMessage.link) ? this.getSystemMessage.link : null
    },

    systemBarLinkText() {
      if(this.systemBarLink) {
        if(this.systemBarLink.startsWith("https://")) {
          return this.systemBarLink.slice(8)
        }
      }

      return null;
    },

    systemBarIcon() {
      return (this.getSystemMessage && this.getSystemMessage.icon) ? this.getSystemMessage.icon : "mdi-message"
    },


    showTabsComponent() {
      return !this.$route.meta.hideViewTabs
    },

    enableMainSidebar() {
      return !this.$route.meta.hideMainSidebar
    },

    enableFilterSidebar() {
      return !this.$route.meta.hideFilterSidebar
    },

    canChangeUniverse() {
      return this.userIdentity && _.size(this.userIdentity.universes)>1
    },


  },

  methods: {
    rightSidebarButtonClicked() {
      if(this.drawer) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }

      this.drawerRight = !this.drawerRight
    },

    onHomeClicked() {
      this.$store.commit('resetFilters')
      PageNavigator.navigateToDefaultPage(this, this.activeSurvey, this.getFilteredDrillPath)
    },

    onAccountClicked() {
      this.$router.push({
        name: 'userdetails',
        params: {surveyExtName: this.activeSurvey.extName, userId: this.userIdentity.userId}
      })
    },

    onUsersAdminClicked() {
      this.$router.push({
        name: 'users',
        params: {surveyExtName: this.activeSurvey.extName}
      })
    },

    onBackendClicked() {
      this.$router.push({
        name: 'surveylist'
      })

    },

    onChangeUniverse() {
      window.location.href = window.location.protocol + '//' + window.location.host + "?r="+Math.random()+"/#login"
    },

    onLogoutClicked() {
      this.$cookies.remove('sessionId')
      this.$store.commit('logout')

      if(this.$keycloak && this.$keycloak.authenticated) {
         const redirectURL = window.location.protocol + '//' + window.location.host

        var logoutOptions = { redirectUri : redirectURL}
        this.$keycloak.logoutFn( logoutOptions )
      } else {
        PageNavigator.navigateToLoginPage(this)
      }


    },

  },

  watch: {
    filterSideBar(newVal) {
      this.drawerRight = newVal
    }
  }

}
</script>

<style scoped>

.systemBar {
  font-size: 1.5rem;
  display: flex;
}

.systemBarLink {
  margin-left: 0.5em;
}



.preButtonSpacer {
  width: 12px;
}

.postButtonSpacer {
  width: 37px;
}

.contentDiv {
  background-color: #F1F3F6;
}

.logoItems {
  align-items: center;
  margin-left: 1em;
}

.viewTab {
  text-transform: capitalize;
}

</style>
