<template>
    <v-container fluid pa-0>
        <v-row>
            <v-col>
                <v-switch v-model="channelData.enabled" :label="channelData.title" @change="onChannelChange"/>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'NotificationChannelComponent',

  components: {
  },

  props: {
      channelData: Object
  },

    data: () => ({}),

    computed: {
        ...mapState(['activeSurvey']),
        ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),
    },

    methods: {
        onChannelChange() {
            this.$emit('update-channeldata', this.channelData)
        }
    },


}
</script>
