<template>
  <v-container fluid>
    <v-row>
      <v-col>

        <v-card class="overviewCard" outlined v-if="overviewDTO">

          <v-row><v-col><span class="title">{{'TEXTANALYTICS_TITLE' | i18n}}</span></v-col></v-row>
          <v-row>
            <v-col>
              <div><span class="interviewsLabel">{{'INTERVIEWS' | i18n}}:</span> <span class="interviewsCount">{{overviewDTO.nInterviews}} ({{overviewDTO.nCommentInterviews}} {{'WITH_COMMENTS' | i18n}})  </span></div>
            </v-col>
          </v-row>

          <!-- boxes -->
          <v-row>
            <v-col cols="2">
              <CircleComponent color="#00C896" :title="$t('POSITIVE_FEEDBACK')" :value="overviewDTO.positiveFeedback.feedbackPercentage" :formatted-value="overviewDTO.positiveFeedback.feedbackPercentageTitle"/>
            </v-col>

            <v-col cols="2" class=".col-md-8">
              <TextAnalyticsCategoryComponent :positive="true" :categories="overviewDTO.positiveFeedback.topCategories" :page-name="componentData.config.textAnalyticsPage"/>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="2">
              <CircleComponent color="#BE0000" :title="$t('NEGATIVE_FEEDBACK')" :value="overviewDTO.negativeFeedback.feedbackPercentage" :formatted-value="overviewDTO.negativeFeedback.feedbackPercentageTitle"/>
            </v-col>
            <v-col cols="2">
              <TextAnalyticsCategoryComponent :positive="false" :categories="overviewDTO.negativeFeedback.topCategories" :page-name="componentData.config.textAnalyticsPage"/>
            </v-col>

            <v-spacer></v-spacer>


            <v-col cols="2">
              <CircleComponent color="3e3e3e" :title="$t('NEUTRAL_FEEDBACK')" :value="overviewDTO.neutralFeedback.feedbackPercentage" :formatted-value="overviewDTO.neutralFeedback.feedbackPercentageTitle"/>
            </v-col>
          </v-row>


        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from "vuex";
  import ResultsService from "../../../../services/ResultsService";
  import TextAnalyticsCategoryComponent from "./TextAnalyticsCategoryComponent";
  import CircleComponent from "./CircleComponent";

  export default {
    name: 'TextAnalyticsComponent',

    components: {
      CircleComponent, TextAnalyticsCategoryComponent
    },

    data () {
      return {
        overviewDTO: null
      }
    },

    props: {
      componentData: Object
    },

    watch: {
      $route: {
        handler() {
          this.loadServerData()
        },
        immediate: true
      }
    },

    computed: {
      ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
    },

    methods: {
      loadServerData() {
        ResultsService.fetchTextAnalyticsData(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath).then((response) => {
          this.overviewDTO = response.data.overviewDTO
        })
      },
    }

  }
</script>

<style scoped>

  .overviewCard {
    padding: 30px;
  }

  span.title {
    /*font-size: 20px;*/
    font-size: calc((20/16)*1rem);
    font-weight: 500;
  }

  span.interviewsLabel {
    /*font-size: 14px;*/
    font-size: calc((14/16)*1rem);
    font-weight: 500;
  }

  span.interviewsCount {
    /*font-size: 14px;*/
    font-size: calc((14/16)*1rem);
  }


</style>
