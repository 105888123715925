<template>
  <v-container  class="wrapper pa-4" fluid>
    <v-row class="bottomBorder">
      <v-col cols="12">
        <v-icon class="actionIcon">{{actionIcon}}</v-icon>
        <span class="actiontitle">{{compoundAction.title}}</span>
      </v-col>
    </v-row>

    <v-row v-for="(item, idx) in compoundAction.items" :key="idx" :class="{ bottomBorder : idx<(compoundAction.items.length-1)}" >
      <v-col cols="12">
        <v-icon size="1em" :color="targetColor(item.target)">mdi-circle</v-icon>
        <span class="scoreSpan">{{item.value}}</span>
        <span class="questionSpan">{{item.text}}</span>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  // import {mapGetters} from "vuex";
  // import ResultsService from "../../../services/ResultsService";

  export default {
    name: 'ActionSectionComponent',

    data () {
      return {
      }
    },

    props: {
      compoundAction: Object
    },

    computed: {
      actionIcon() {
        return (this.compoundAction.type==="maintain") ? "mdi-emoticon-outline" : "mdi-alert-circle-outline"
      },


    },

    methods: {
      targetColor(target) {
        if(target==='TARGET3') {
          return "#00c095" // green
        } else if(target==='TARGET2') {
          return "#ffef35" // yellow
        } else if(target==='TARGET1') {
          return "#ff6f2b" // orange
        } else if(target==='TARGET0') {
          return "#bd063b" // red
        }

        return "grey"
      }

    }


  }
</script>

<style scoped>
  .wrapper {
    margin-top: 1em;
  }

  .scoreSpan {
    margin-left: 1em;
    /*font-size: 24px;*/
    font-size: calc((24/16)*1rem);
  }

  .questionSpan {
    /*font-size: 14px;*/
    font-size: calc((14/16)*1rem);
    margin-left: 1em;
  }

  .bottomBorder {
    border-bottom: 1px rgba(0,0,0,0.14) solid;
  }

  span.actiontitle {
    margin-left: 0.5em;
    color: rgba(0,0,0,0.87);
    /*font-size: 16px;*/
    font-size: calc((16/16)*1rem);

    line-height: 20px;
  }

  i.actionIcon {
    color: rgba(0,0,0,0.70);
  }

</style>
