import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0000c8', //colors.red.darken1, // #E53935
        secondary: '#424242',
        accent: '#0000c8',
        error: "#be0000",
        info: "#2196F3",
        success: "#00c896",
        warning: "#FFC107"
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});


