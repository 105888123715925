<template>
  <v-form>
    <v-container fluid>

      <v-chip-group class="chipscontainer">

        <template v-for="(item) of filterItems">

          <v-tooltip v-if="item.filter.multipleSelections" bottom :key="item.filter.extName+item.option.extName">
            <template v-slot:activator="{ on }">

              <v-chip
                  v-on="on"
                  color="#EBEBEB"
                  text-color="black"
                  class="singleChip"
                  :close="item.filter.multipleSelections"
                  v-model="chips[0]"
                  @click:close="onCloseClicked(item)">{{buildChipName(item, item.option)}}
              </v-chip>

            </template>
            <span>{{buildChipTooltipText(item, item.option)}}</span>
          </v-tooltip>

        </template>


      </v-chip-group>



<!--      <v-checkbox v-for="filter in campaignDetails.campaignFilters" :key="filter.title" :label="filter.title" v-model="filter.selected">-->
<!--      </v-checkbox>-->


    </v-container>
  </v-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'FiltersCard',

  components: {},

  props: {
    campaignDetails: Object
  },

  data: () => ({
    filterItems: [],
    chips: [true],
  }),

  computed: {

    ...mapState(['activeSurvey', 'combinedFilters']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getStandardFilters', 'getCampaignName']),
  },

  watch: {
    combinedFilters: {
      handler() {
        let items = []

        const filterSet = this.getStandardFilters
        if (filterSet) {
          items.push(...this.combinedFilters.getFilterItems(filterSet))
        }

        this.filterItems = items
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    buildChipName(item, option) {
      if(option.title.endsWith("~")) {
        return option.title.startsWith("_") ?
            this.$t("FILTER_NOT_CONTAINS") + " " + option.title.slice(1, -1) :
            this.$t("FILTER_CONTAINS") + " " + option.title.slice(0, -1)
      } else if(option.title.startsWith("_")) {
        return this.$t("FILTER_NO_MATCH") + " " + option.title.substring(1)
      } else if(item.filter.extName==='cf') {
        const campaignId = parseInt(option.extName.substring(2))
        const campaignName = this.getCampaignName(campaignId)
        if(!campaignName) {
          this.$store.dispatch('fetchCampaignName', { surveyName: this.activeSurvey.extName, campaignId: campaignId})
        }
        return campaignName ? campaignName : "?"

      } else {
        if(option.extName.startsWith("_")) {
          return this.$t("FILTER_NO_MATCH") + " " + option.title
        } else {
          return option.title
        }
      }

    },

    buildChipTooltipText(item, option) {
      if(item.filter.extName==='cf') {
        return option.title
      } else {
        return item.filter.title
      }

    },

    onCloseClicked(item) {

      if(item.filter.extName==='cf') {
        this.$store.commit('clearFilter', {filterName: item.option.extName, filterValue: null})
      } else {
        this.$store.commit('clearFilter', {filterName: item.filter.extName, filterValue: item.option.extName})
      }

    },

  }


  }
</script>

<style scoped>

</style>
