<template>
  <div>

    <div v-if="showIncludeExclude">
      <v-select v-model="includeExcludeMode" :items="[{value: 'included', text: $t('FILTER_INCLUDED')}, {value: 'excluded', text: $t('FILTER_EXCLUDED')}]"></v-select>
    </div>

    <v-autocomplete v-if="serverSideFilter"
                    @change="onAutoComplete"
                    item-text="title"
                    item-value="extName"
                    v-model="autoModel"
                    :loading="loading"
                    :items="serverSideItems"
                    :search-input.sync="searchInput"
                    cache-items>

      <template v-slot:prepend-item v-if="filter.submatch">
        <v-list-item  @click="onPlusMinusClicked(searchInput)">
          <v-list-item-content>
            <v-list-item-title>
              {{ searchInput }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <div style="display: flex">
              <v-icon >mdi-plus</v-icon>
            </div>
          </v-list-item-action>
        </v-list-item>
      </template>

      <template v-slot:no-data v-if="searchMatch && filter.submatch">
        <v-list-item  @click="onPlusMinusClicked(searchInput)">
          <v-list-item-content>
            <v-list-item-title>
              {{ searchInput }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <div style="display: flex">
              <v-icon >mdi-plus</v-icon>
            </div>
          </v-list-item-action>

        </v-list-item>
      </template>

    </v-autocomplete>



    <v-autocomplete v-else-if="showTypeahead && !serverSideFilter"
        @change="onAutoComplete"
        :items="uncheckedOptionObjects"
        item-text="title"
        item-value="extName"
        v-model="autoModel"
        :search-input.sync="searchInput"
    >

      <template v-slot:prepend-item v-if="filter.submatch">
        <v-list-item  @click="onPlusMinusClicked(searchInput)">
          <v-list-item-content>
            <v-list-item-title>
              {{ searchInput }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <div style="display: flex">
              <v-icon >mdi-plus</v-icon>
            </div>
          </v-list-item-action>
        </v-list-item>
      </template>

      <template v-slot:no-data v-if="searchMatch && filter.submatch">
        <v-list-item  @click="onPlusMinusClicked(searchInput)">
          <v-list-item-content>
            <v-list-item-title>
              {{ searchInput }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <div style="display: flex">
              <v-icon >mdi-plus</v-icon>
            </div>
          </v-list-item-action>

        </v-list-item>
      </template>

    </v-autocomplete>

    <v-list dense>
      <v-list-item dense v-for="option in listedOptions" :key="option.extName">
        <v-list-item-content>
          <v-checkbox dense hide-details class="filterCheckBox ma-0 pa-0" :label="option.title" v-model="checkedOptions"
                      :value="option.extName" @change="checkClicked"></v-checkbox>
        </v-list-item-content>
      </v-list-item>
    </v-list>




  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import _ from 'lodash'
import FiltersService from "@/services/FiltersService";

export default {
  name: 'FilterComponentChecks',

  data() {
    return {
      includeExcludeMode: null,
      searchInput: "",
      autoModel: null,
      checkedOptions: [],
      checkedSubstringOptions: [],
      serverSideItems: [],
      searchServerSide: null,
      loading: false
    }
  },

  props: {
    filter: Object
  },

  computed: {
    ...mapState(['combinedFilters', 'activeSurvey']),
    ...mapGetters(['getAuthToken']),

    serverSideFilter() {
      return this.filter.serverSide
    },

    includeMode() {
       return this.includeExcludeMode==='included'
    },


    showIncludeExclude() {
      return this.showTypeahead && this.filter.exclude
    },

    showTypeahead() {
      if(this.filter.serverSide) {
        return true
      }

      if (this.filter.dropdown !== null) {
        return this.filter.dropdown
      }

      return this.filter.options.length >= 10
    },

    listedOptions() {
      if (this.showTypeahead) {
        return this.checkedOptionObjects
      }

      return this.filter.options
    },

    checkedOptionObjects() {
      return this.filter.options.filter(o => this.checkedOptions.includes(o.extName)).concat( this.checkedSubstringOptions )
    },

    searchMatch() {
      if(this.searchInput) {
        const lower = this.searchInput.toLowerCase()
        return this.uncheckedOptionObjects.filter( option => option.title.toLowerCase()===lower).length>0
      }

      return false
    },

    uncheckedOptionObjects() {
      let unchecked = this.filter.options.filter(o => !this.checkedOptions.includes(o.extName))

      // if(this.searchInput!==null && this.searchInput.length>0) {
      //   const lower = this.searchInput.toLowerCase()
      //   unchecked = unchecked.filter( option => option.title.toLowerCase()===lower )
      // }

      return unchecked.slice(0, 50000)        // hack!
    },

  },

  created() {
    this.checkedSubstringOptions = []
    this.includeExcludeMode = 'included'
    this.computedCheckedOptions()

    if (this.filter.serverSide) {
      this.serverSideItems = this.filter.options
      if (this.serverSideItems.length === 0) {
        this.queryServerSide("")
      }
    }

  },

  methods: {

    isSelected(extname) {
      return this.checkedOptions.filter(o => o===extname).length>0
    },

    onPlusMinusClicked(o) {
      const extname = this.includeMode ? o + "~" : "_" + o + "~"
      const title = this.includeMode ? this.$t('FILTER_PLUS') + " " + o : this.$t('FILTER_MINUS') + " " +o

      if(this.isSelected(extname)) {
        // console.log("plus Was selected")
        return
      }

      this.checkedOptions.push( extname )
      this.checkedSubstringOptions.push({extName: extname, title: title })

      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: this.checkedOptions})

      this.$nextTick(function () { this.autoModel = "" })
    },

    onAutoComplete(o) {
      if(o===null) {
        return;
      }

      const extname = this.includeMode ? o : "_"+o
      this.checkedOptions.push(extname)

      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: this.checkedOptions})
      this.$nextTick(function () { this.autoModel = "" })
    },

    checkClicked() {
      // console.log("checkClicked")
      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: this.checkedOptions})
    },

    computedCheckedOptions() {
      let filterValues = this.combinedFilters.getFilterValuesForFilter(this.filter.extName);

      if (!_.isEqual(filterValues, this.checkedOptions)) {
        this.checkedOptions = [...filterValues]
      }
    },


    queryServerSide(v) {
      this.loading = true

      // console.log("querying serverside " + this.filter.extName)

      FiltersService.fetchFilterQuery(this.getAuthToken, this.activeSurvey.extName, this.filter.extName, v).then((response) => {
        this.serverSideItems = response.data
        this.loading = false

        // console.log("serverside results er in " + this.filter.extName)

        this.$store.commit('appendFilterOptions', { surveyExtName: this.activeSurvey.extName, filterName: this.filter.extName, filterOptions: response.data } )
      })

    },

  },


  watch: {
    combinedFilters: {
      handler() {
        this.computedCheckedOptions()
      },
      deep: true,
      immediate: true
    },

    searchInput (val) {
      if(this.serverSideFilter) {
        val && val !== this.select && this.queryServerSide(val)
      }
    },


  },


}
</script>

<style>
.filterCheckBox {
}

div.filterCheckBox div.v-input__control div.v-input__slot {
  margin: 0 !important;
  font-size: calc((13.5 / 16) * 1rem) !important;
}

div.filterCheckBox label {
  left: 6px !important;
}

div.filterCheckBox label {
  font-size: calc((13.5 / 16) * 1rem) !important;

}

.v-select-list .v-list .v-input__slot {
  margin-bottom: 0 !important;
}

</style>
