<template>
  <div class="nodatacontainer">
    <v-icon size="55px" class="nodataicon">mdi-alert-circle-outline</v-icon>
    <div class="nodatatext"> {{title}}</div>
  </div>
</template>


<script>
export default {
  name: "NoDataComponent",

  props: {
    title: String
  }

}
</script>

<style scoped>
.nodatacontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nodataicon {
  color: rgba(0,0,0,.7);
  margin-bottom: 30px;
}

.nodatatext {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
  color: #000000;
}
</style>