<template>
  <div v-bind:style="{ visibility: visibilityState }">
    <div v-bind:style="{ width: segment0Width + '%', backgroundColor: segment0Color }" class="segment0"></div>
    <div v-bind:style="{ width: segment1Width + '%', backgroundColor: segment1Color }" class="segment1"></div>
    <div v-bind:style="{ width: segment2Width + '%', backgroundColor: segment2Color }" class="segment2"></div>
    <div v-bind:style="{ width: segment3Width + '%', backgroundColor: segment3Color }" class="segment3"></div>
  </div>
</template>

<script>
export default {
  name: 'ScoreGoalComponent',

  props: {
    vardto: {},
    hide: Boolean,
    forceGrey: Boolean
  },

  created () {
    var minMaxSpan = this.vardto.maxValue - this.vardto.minValue
    var fracValue = (this.vardto.value == null) ? null : this.vardto.value / minMaxSpan

    if(fracValue && fracValue>1.0) {
      fracValue = 1.0;
    }

    var fracGoal = (this.vardto.goalValue == null) ? null : this.vardto.goalValue / minMaxSpan
    this.configureSegments(this.vardto.value, fracValue, fracGoal, this.vardto.inverse)
  },

  watch: {
    vardto: function () {
      var minMaxSpan = this.vardto.maxValue - this.vardto.minValue
      var fracValue = (this.vardto.value == null) ? null : this.vardto.value / minMaxSpan

      if(fracValue && fracValue>1.0) {
        fracValue = 1.0;
      }

      var fracGoal = (this.vardto.goalValue == null) ? null : this.vardto.goalValue / minMaxSpan
      this.configureSegments(this.vardto.value, fracValue, fracGoal, this.vardto.inverse)
    }
  },

  computed: {
    visibilityState () {
      return (this.hide === true) ? 'hidden' : 'visible'
    }
  },

  methods: {

    styleSegments: function (width0, color0, width1, color1, width2, color2, width3, color3) {
      this.segment0Width = width0
      this.segment1Width = width1
      this.segment2Width = width2
      this.segment3Width = width3

      // if(this.forceGrey) {
      //   color0 = 'grey'
      //   color1 = 'grey'
      //   color2 = 'grey'
      //   color3 = 'grey'
      // }

      this.segment0Color = color0
      this.segment1Color = color1
      this.segment2Color = color2
      this.segment3Color = color3
    },

    configureSegments: function (value, fracValue, fracGoal, inverse) {
      var goalPct = 1
      var noneGoalPct = 100 - goalPct

      var goalBarColor = '#909ba7'
      var goalColor = '#ffffff'

      var belowColor = inverse ? '#00c896' : '#b9133e'
      var aboveColor = inverse ? '#b9133e' : '#00c896'

      if (fracGoal == null || this.forceGrey) {
        aboveColor = 'grey'
        belowColor = 'grey'
      }

      if (fracValue == null && fracGoal == null) {
        this.styleSegments(50, goalBarColor, 0, null, 0, null, 50, goalBarColor)
      } else if (fracValue == null) {
        var startWidth = Math.round(noneGoalPct * fracGoal)
        var endWidth = noneGoalPct - startWidth
        this.styleSegments(startWidth, goalBarColor, goalPct, goalColor, 0, null, endWidth, goalBarColor)
      } else if (fracGoal == null) {
        startWidth = Math.round(100 * fracValue)
        endWidth = 100 - startWidth
        this.styleSegments(startWidth, belowColor, 0, null, 0, null, endWidth, goalBarColor)
      } else if (fracValue < fracGoal) {
        startWidth = Math.round(noneGoalPct * fracGoal)
        endWidth = noneGoalPct - startWidth
        var valueWidth = Math.round(noneGoalPct * fracValue)
        var restWidth = startWidth - valueWidth
        this.styleSegments(valueWidth, belowColor, restWidth, goalBarColor, goalPct, goalColor, endWidth, goalBarColor)
      } else {
        startWidth = Math.round(noneGoalPct * fracGoal)
        valueWidth = Math.round(noneGoalPct * fracValue) - startWidth
        restWidth = noneGoalPct - startWidth - valueWidth
        this.styleSegments(startWidth, aboveColor, goalPct, goalColor, valueWidth, aboveColor, restWidth, goalBarColor)
      }
    }

  },

  data: function () {
    return {
      segment0Width: 30,
      segment1Width: 30,
      segment2Width: 30,
      segment3Width: 30,
      segment0Color: '',
      segment1Color: '',
      segment2Color: '',
      segment3Color: ''
    }
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

div.segment0 {
  height:8px;
  float:left;
: #5bb75b;
}

div.segment1 {
  height:8px;
  float:left;
  background-color: white;
}

div.segment2 {
  height:8px;
  float:left;
  background-color: #5bb75b;
}

div.segment3 {
  height:8px;
  float:left;
  background-color: grey;
}

</style>
