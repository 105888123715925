<template>
  <div v-if="show">
    <v-form ref="form">

    <v-select v-if="questionData.type==='SingleChoice'"
              ref="inputField"
              :disabled="isDisabled"
              :items="questionData.choices"
              item-value="extname"
              item-text="title"
              prepend-icon="mdi-form-dropdown"
              :label="questionData.title"
              v-model="questionData.value"
              :error-messages="validationMessages"
              :rules="selectRules"
              @change="$emit('updated', questionData)"/>

      <v-select v-else-if="questionData.type==='MultiChoice2'"
                ref="inputField"
                :disabled="isDisabled"
                :items="questionData.choices"
                item-value="extname"
                item-text="title"
                prepend-icon="mdi-form-dropdown"
                :label="questionData.title"
                v-model="questionData.values"
                :error-messages="validationMessages"
                :rules="selectRules"
                multiple
                @change="$emit('updated', questionData)"/>

    <v-menu v-else-if="questionData.type==='Date' || questionData.type==='EventDate'"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
            ref="inputField"
            v-model="questionData.value"
            :label="questionData.title"
            prepend-icon="mdi-calendar-clock"
            :rules="fieldRules"
            readonly
            clearable
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker  v-model="questionData.value" @input="menu = false" ></v-date-picker>
    </v-menu>


    <v-text-field v-else
                  ref="inputField"
                  :disabled="isDisabled"
                  :label="questionData.title"
                  prepend-icon="mdi-form-textbox"
                  v-model="questionData.value"
                  :error-messages="validationMessages"
                  :rules="fieldRules"
                  @change="$emit('updated', questionData)"/>
    </v-form>
  </div>

</template>

<script>

// import _ from 'lodash'

export default {
  name: 'QuestionComponent',

  components: {},

  data: () => ({
    nameRules: [
      v => !!v || 'Name is required'
    ],

    errorMessages: [],
    menu: null,

  }),

  props: {
    questionData: Object,
    validationMessageMap: Object,
    canEditState: Boolean,
    isCancelled: Boolean
  },

  computed: {
    show() {
//          return true
      return !this.questionData.cancelledComment || this.isCancelled
    },

    isDisabled() {
      return this.questionData.disabled || !this.canEditState
    },

    fieldRules() {
      return  [
        value => this.questionData.optional || !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
        value =>  {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return this.questionData.optional || this.questionData.type!=='Email' || pattern.test(value) || this.$t('VALIDATE_ERROR_EMAIL');
        }
      ]
    },

    selectRules() {
      return  [ value => this.questionData.optional || !!value || this.$t('VALIDATE_ERROR_MANDATORY') ]
    },

    validationMessages() {
      const msg = this.validationMessageMap[ this.questionData.questionId ]
      return (msg && msg.length>0) ? [msg] : []
    }
  },

  methods: {
    validate() {
      this.$refs['form'].validate()
      return !this.$refs['inputField'].hasError
    }
  },

}
</script>
<style scoped>
.alerttitle {
  background-color: #37c796;
}
</style>