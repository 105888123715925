<template>
  <v-card outlined>
    <v-card-title>Roles</v-card-title>

    <v-simple-table>
      <thead>
      <tr>
        <th class="text-left">Name</th>
        <th class="text-left">Users</th>
        <th v-for="(surveyName) in surveyNames" class="text-left" :key="surveyName" >{{ surveyName }}</th>
      </tr>

      </thead>
      <tbody>
      <tr v-for="row in rows" :key="row.id" @click="onRoleClick(row.id)">
        <td>{{row.name}}</td>
        <td>{{row.usersWithRole}}</td>
        <td v-for="(enabled,idx) in row.surveysEnabled" :key="idx">{{enabled}}</td>
      </tr>
      </tbody>
    </v-simple-table>

    <div class="d-flex">
      <v-btn @click="onRoleClick(0)" icon>
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </div>


  </v-card>
</template>

<script>

import {mapGetters} from "vuex";
import PageNavigator from "@/pagenavigator";
import RolesService from "@/services/RolesService";

export default {
  name: 'RolesList',

  components: {},

  data: () => ({
    surveyNames: [],
    rows: []
  }),

  props: {
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),
  },

  created() {
    RolesService.fetchRolesListData(this.getAuthToken, this.getActiveSurvey.extName).then((response) => {
      this.surveyNames = response.data.surveyNames
      this.rows = response.data.rows;
    })
  },

  methods: {
    onRoleClick(roleId) {
      PageNavigator.navigateToRoleDetailsPage(this, this.getActiveSurvey.extName, roleId);
    }
  }

}
</script>

<style scoped>
</style>