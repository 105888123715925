<template>
  <v-container fluid selectionBlock>

    <v-row>
      <v-col class="menuContainer">

        <div class="prelabel">{{'SELECTED_DATA' | i18n}}:</div>

        <div v-if="subMenu!=null">
          <v-icon class="preicon" size="20px">mdi-chart-bar</v-icon>
          <SelectionMenuComponent  :menu-items="subMenu" :selectedName="subMenu[0].name" :simple="true" @item-selected="subMenuItemSelected($event)"/>
        </div>

        <div v-if="selectionBlockBus.menus.length>0">
          <v-icon class="preicon" size="20px">mdi-calendar</v-icon>
          <SelectionMenuComponent v-for="menu in selectionBlockBus.menus"  :menu-items="menu.options" :selectedName="menu.options[0].name" :simple="true" :key="menu.title" @item-selected="subComponentMenuItemSelected(menu, $event)"/>
        </div>

      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <page-component :selection-block-bus="selectionBlockBus" v-if="selectedComponent != null" :component-data="selectedComponent"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import SelectionMenuComponent from "../../../components/SelectionMenuComponent";

export default {
  name: 'SelectionBlockComponent',
  components: {
    SelectionMenuComponent,
    'page-component': () => import('../PageComponent.vue')
  },

  data () {
    return {
      subMenu: null,

      model: 0,
      selectedComponent: null,
      selectionBlockBus: {
        menus: [],

        addMenu(menuName, options, callback) {
          const menu = { title: menuName, options: options, currentSelection: options[0].name, selectionCallback: callback }
          this.menus = [menu]
          return menu
        }
      }

    }
  },

  props: {
    componentData: Object,
  },

  created () {
    this.selectionBlockBus.menus = []

    if(this.componentData.component != null) {
      this.selectedComponent = this.componentData.component
    } else if(this.componentData.items.length>0) {
      var options = []

      var index = 0
      for(const item of this.componentData.items) {
        options.push( { name: "" + (index++), title: item.title[this.getLocale]}  )
      }

      this.subMenu = options

      this.subMenuItemSelected(0)
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),



  },

  methods: {
    menuTitle(item) {
      return item.title[this.getLocale]
    },

    subComponentMenuItemSelected(menu, name) {
      menu.selectionCallback(name)
    },

    subMenuItemSelected(name) {
      this.selectionBlockBus.menus = []
      this.selectedComponent = this.componentData.items[name].component
    }

  }

}
</script>

<style scoped>
div.selectionBlock {
  background-color: white;
}

.menuContainer {
  display: flex;
  justify-content: flex-end;
}

div.prelabel {
  margin-right: 2em;
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  letter-spacing: 0.8px;
}

.preicon {
  padding-bottom: 4px;
}

</style>
