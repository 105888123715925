<template>
<div>
  <v-expansion-panels class="groupExpansionPanel" multiple v-if="!filterGroup.ungrouped">
    <v-expansion-panel  active-class="activegrouppanel">
        <v-expansion-panel-header class="grouppanelheader">{{filterGroup.title}}</v-expansion-panel-header>
        <v-expansion-panel-content class="panelContent">
          <template v-for="filter in filterGroup.filters">
            <FilterComponent :filter="filter" :flatten="filterGroup.flatten" :key="filter.key"/>
          </template>
      </v-expansion-panel-content>
    </v-expansion-panel>

  </v-expansion-panels>

  <template v-else>
    <FilterComponent v-for="filter in filterGroup.filters" :flatten="filterGroup.flatten" :filter="filter" :key="filter.key"/>
  </template>


</div>
</template>

<script>
import FilterComponent from "./FilterComponent";

export default {
  name: 'FilterGroup',
  components: {FilterComponent},

  data () {
    return {
      isOpen: null
    }
  },

  created() {
    this.isOpen = this.filterGroup.expanded ? 0 : null
  },

  props: {
    filterGroup: Object
  }

}
</script>

<style >
  .groupExpansionPanel {
    border-bottom:1px solid #ccc;
    border-radius: 0px !important;
  }

  .panelContent {
    border-radius: 0px !important;
  }

  .activegrouppanel {
    color: #0000C8 !important;
    border-radius: 0px !important;
  }

  .grouppanelheader {
    /*font-size: 14px !important;*/
    font-size: calc((14/16)*1rem);

    font-weight: 500 !important;
    padding-bottom: 0 !important;
  }

</style>
