<template>
  <v-container fluid pa-3>
    <ConfirmDialog ref="confirm" />

    <v-alert type="error" v-if="errorMessage">
      {{errorMessage}}
    </v-alert>

    <v-form v-if="templateDetails!==null"  ref="form" v-model="formValidity">
      <v-row class="pa-3">
        <v-col>
          <h4>{{headlineLabel}}</h4>
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col cols="4">
          <v-text-field  v-model="templateDetails.name" :label="$t('NAME')" :rules="nameRules" mandatory :disabled="isReadonlyMode"/>
        </v-col>
        <v-col  cols="1" v-if="isUserRoot">
          <v-checkbox v-model="templateDetails.system" label="system"/>
        </v-col>

        <v-col cols="1">
          <v-select
              v-model="templateDetails.locale"
              :items="availableLocales"
              label="locale"
              :disabled="isReadonlyMode"
          ></v-select>
        </v-col>

        <v-col cols="1">
          <v-combobox
              v-model="templateDetails.tags"
              :items="tags"
              label="tags"
              multiple
              :disabled="isReadonlyMode"
          ></v-combobox>
        </v-col>

        <v-col v-if="isUserRoot">
          <v-text-field v-model="templateDetails.extname" label="extname"/>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          <v-file-input :label="$t('DATA_UPLOADER_FILE_INPUT')"  @change="selectFile" accept=".fodt" :rules="nameRules" mandatory v-model="currentFile">
            <template v-if="!currentFile" #prepend-inner>
              {{templateDetails.design}}
            </template>
          </v-file-input>
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col>
          <div class="mt-4">
            <v-btn class="mr-4" @click="onSubmitClicked" v-if="!isReadonlyMode" :disabled="!canSubmit">{{ buttonLabel }}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode && !isNew && !isReadonlyMode" @click="onDeleteClicked">{{'DELETE' | i18n}}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode && !isNew && this.templateDetails.contents" @click="onDuplicateClicked">{{'DUPLICATE' | i18n}}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode" @click="onCancelClicked">{{'CANCEL' | i18n}}</v-btn>

          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <PDFViewer v-if="renderedPDFBytes" :source="renderedPDFBytes" style="height: 100vh; width: 100vw" :controls="[]" />
          <div v-if="isReadonlyMode" class="overlay"></div>
        </v-col>
      </v-row>

    </v-form>

  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import PageNavigator from "../../pagenavigator";
import MailsService from "@/services/MailsService";
import ConfirmDialog from "@/components/ConfirmDialog";
import UploadService from "@/services/UploadService";
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'

export default {
  name: 'LetterTemplateDetails',

  components: {
    ConfirmDialog,
    PDFViewer
  },

  props: {
    embedded: Boolean,
    templateId: null
  },

  data: () => ({
    formValidity: false,

    isNewFileSelected: false,

    fileInput: null,

    currentFile: undefined,
    progress: 0,
    renderedPDFBytes: null,

    templateDetails: null,
    repeatPassword: '',

    rolesList: [],

    tags: [],

    availableLocales: [],

    isNew: false,

    nameRules: [
      v => !!v || 'Field is required'
    ],

    dialog: false,

    errorMessage: null,
  }),

  created: function () {
    if (this.calculateTemplateId !== 0) {
      this.isNew = false;

      MailsService.fetchLetterTemplateData(this.getAuthToken, this.activeSurvey.extName, this.calculateTemplateId).then((response) => {
        this.templateDetails = response.data
        this.currentFile = new File(["foo"], this.templateDetails.design, {type: "text/plain"})

        MailsService.fetchRenderedLetterTemplateData(this.getAuthToken, this.activeSurvey.extName, this.templateDetails.id).then((response) => {
          this.renderedPDFBytes = "data:application/octet-stream;base64," + response.data;
        })

      })

    } else {
      this.templateDetails = { name: "", extname: "", labels: "", contents: "", design: "", locale: this.getLocale}
      this.isNew = true
    }

    MailsService.fetchTemplateTags(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      this.tags = response.data
    })

    MailsService.fetchAvailableLocales(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      this.availableLocales = response.data
    })

  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot', 'getLocale']),


    canSubmit() {
      return this.formValidity
    },

    isReadonlyMode() {
      return !this.isUserRoot && this.templateDetails.system
    },

    calculateTemplateId() {
      return this.templateId ? this.templateId : this.$route.params.templateId
    },

    requiredForEmailRules() {
      return [v => (!this.isEmailMode || (!!v && this.isEmailMode)) || 'Name is required']
    },

    label() {
      return this.$route.query.label;
    },

    buttonLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    headlineLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    embeddedMode() {
      return this.templateId
    }

  },


  methods: {
    selectFile(file) {
      this.currentFile = file;
      this.templateDetails.design = file.name
      this.isNewFileSelected = true

      UploadService.uploadLetterTemplate(this.getAuthToken, this.activeSurvey.extName, this.currentFile, null).then((response) => {
        this.renderedPDFBytes = "data:application/octet-stream;base64," + response.data.renderedPDFBytes;
      })
    },

    goToList() {
      const fromPage = this.$route.query.fromPage

      if(fromPage) {
        PageNavigator.navigateToPage(this, fromPage, this.activeSurvey.extName,  this.getFilteredDrillPath)
      } else {
        PageNavigator.navigateToDefaultPage(this, this.activeSurvey)
      }
    },

    onCancelClicked() {
      this.goToList()
    },

    onSubmitClicked() {
        if (this.isNew) {
          this.templateDetails.label = this.$route.query.label


          MailsService.createLetterTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then((response) => {
            this.templateDetails = response.data

              UploadService.uploadLetterTemplate(this.getAuthToken, this.activeSurvey.extName, this.currentFile, this.templateDetails.id).then((response) => {
                this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
                this.renderedPDFBytes = "data:application/octet-stream;base64," + response.data.renderedPDFBytes;
                if (!this.embedded) {
                  this.goToList()
                }
              })

            } ).catch((/*error*/) => {
            this.errorMessage = "Failed creating letter template - name already in use"
          })

        } else {
          MailsService.updateLetterTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then((response) => {

            if(this.isNewFileSelected) {
              UploadService.uploadLetterTemplate(this.getAuthToken, this.activeSurvey.extName, this.currentFile, this.templateDetails.id).then((response) => {
                if(!this.embedded) {
                  this.goToList()
                }
              })

            } else {
              if(!this.embedded) {
                this.goToList()
              }

            }

          }).catch((/*error*/) => {
            this.errorMessage = "Failed creating letter template - name already in use"
          })

        }

    },

    async onDuplicateClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Duplicate template?")) {
        this.isNew = true
        this.templateDetails.system=false
        this.templateDetails.name = ""
      }
    },

    async onDeleteClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Delete template?")) {
        MailsService.deleteSMSTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails.id).then(() => {
          this.goToList()
        })
      }
    },

  },


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128,128,128,0.2);
}
</style>
