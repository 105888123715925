<template>
  <div>
    <template v-if="componentData.expressions && componentData.expressions.length>0">
      <BenchmarkChartComponent :component-data="componentData"/>
    </template>
    <template v-else>
      <BenchmarkChartComponentLegacy :component-data="componentData"/>
    </template>
  </div>
</template>

<script>
import BenchmarkChartComponentLegacy from "@/views/pages/BenchmarkChartComponentLegacy.vue";
import BenchmarkChartComponent from "@/views/pages/BenchmarkChartComponent.vue";

export default {
  name: 'BenchmarkChartComponentDiscriminator',
  components: {BenchmarkChartComponent, BenchmarkChartComponentLegacy},

  props: {
    componentData: Object
  },

}
</script>
