<template>
  <div class="wrapper" :style="{color: compomentColor}" @click="onClick">
    <v-icon :color="compomentColor" size="24px" v-if="icon">{{icon}}</v-icon>
    <span v-if="circularProgress">
      <v-progress-circular
          size="24"
          indeterminate
          :color="compomentColor"
      />
      <span
          v-if="progressMessage"
          class="pl-1"
          :title="progressMessageTooltip"
      >
        &nbsp;{{ progressMessage }}
      </span>
    </span>
    <div class="label">
      {{label}}
      <v-icon v-if="secondIcon"
              :color="compomentColor"
              size="12"
      >
        {{secondIcon}}
      </v-icon>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'IconTextButtonComponent',

  props: {
    icon: String, // Icon if specified
    label: String, // Label to place below
    color: String, // Color
    secondIcon: String, // Icon to place right to the label (used to indicate a drop down menu)
    circularProgress: String,  // Indicate we should use this a a progress
    progressMessage: [String, Number], // String or number to place right of progress.
    progressMessageTooltip: String
  },

    computed: {
    ...mapState(['numberOpenAlerts']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isAlertFilterDefined', 'getAlertFilteredDrillPath']),

    compomentColor() {
      return this.color ? this.color : '#757575';
    }

  },

  methods: {
    onClick() {
      this.$emit('click')
    }
  }

}
</script>

<style scoped>

div.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*font-size: 13px;*/
  font-size: calc((13/16)*1rem);

  cursor: pointer;
  /*width: 6em;*/
  margin: 0 1.2em;
}

div.label {
  white-space: nowrap;
}

div.filterButton span {
}

</style>
