<template>
  <div class="circle_container">
    <div class="circle_main" :style="{backgroundColor: color}">
      <div class="circle_text_container">
        <div class = "circle_text">
          {{labelCleaned}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LabelBallComponent',

  data: () => ({}),

  props: {
    color: String,
    value: String
  },

  computed: {
    labelCleaned() {
      return this.value.indexOf('.')!==-1 ? this.value.substr(0, this.value.indexOf('.')) : this.value;
    },

    // iconNumberColor() {
    //   if(this.qaData.icon==='good') {
    //     return '#00C697'
    //   } else if(this.qaData.icon==='bad') {
    //     return "#C00000"
    //   }
    //   return "#aaaaaa"
    // },

  }


}
</script>
<style scoped>
/* Main Container -  this controls the size of the circle */
.circle_container
{
  width : 32px;
  height : 32px;
  margin : 0;
  padding : 0;
  margin-right: 5px;
  display: inline-block;
  /*	border : 1px solid red; */
}

/* Circle Main draws the actual circle */
.circle_main
{
  width : 100%;
  height : 100%;
  border-radius : 50%;
  border : 0px solid black;	/* can alter thickness and colour of circle on this line */
  /*margin : 0;*/
  /*padding : 0;*/
  /*background-color: red;*/
}

/* Circle Text Container - constrains text area to within the circle */
.circle_text_container
{
  /* area constraints */
  width : 70%;
  height : 70%;
  max-width : 70%;
  max-height : 70%;
  margin : 0;
  padding : 0;

  /* some position nudging to center the text area */
  position : relative;
  left : 15%;
  top : 15%;

  /* preserve 3d prevents blurring sometimes caused by the text centering in the next class */
  transform-style : preserve-3d;

  /*border : 1px solid green;*/
}

/* Circle Text - the appearance of the text within the circle plus vertical centering */
.circle_text
{
  /* change font/size/etc here */
  /*font-size: 11px;*/
  font-size: calc((11/16)*1rem);

  color: white;
  font-weight: bold;
  text-align : center;

  /* vertical centering technique */
  position : relative;
  top : 50%;
  transform : translateY(-50%);
}
</style>