<template>
  <v-menu class="surveyMenu">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on" >
        <span class="surveyTitleText">{{activeSurvey.title}}</span>
        <v-icon>mdi-chevron-down</v-icon></v-btn>
    </template>

    <v-list>
      <v-list-item v-for="(item,i) in getSurveys" :key="i" @click="surveySelected(item.extName)">
        <v-list-item-title> {{item.title}}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import {mapGetters, mapState} from "vuex";
  import PageNavigator from "../pagenavigator";

  export default {
    name: 'SurveyMenuComponent',
    data () {
      return {
        items: []
      }
    },

    props: {
      filterItem: Object,
      filterSets: Array
    },

    computed: {
      ...mapState(['activeSurvey']),
      ...mapGetters(['getSurveys','getFilteredDrillPath'])
    },

    methods: {
      surveySelected(surveyExtName) {
        this.$store.dispatch('changeSurvey', { srcComponent: this, surveyExtName: surveyExtName})
        // this.$store.commit('selectSurvey', surveyExtName)
        PageNavigator.navigateToDefaultPage(this, this.activeSurvey, this.getFilteredDrillPath)
      },

    },

  }
</script>
<style scoped>
  .surveyMenu {
    padding-left: 2em;
  }

  .surveyTitleText {
    text-transform: none;
  }

  .v-btn {
    background: #0000c8;
  }

  .v-btn__content {
    color: white !important;
  }
</style>
