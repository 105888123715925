<template>
  <v-container
    fluid
    pa-0
  >
    <v-row class="pa-3">
      <v-col>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="rows"
          :loading="loading"
          :items-per-page="50"
          item-key="id"
          class="elevation-2"
          :multi-sort="false"
        >
          <template #top>
            <v-text-field
              v-model="search"
              label="Filter"
              class="mx-4"
            />
          </template>


          <template #item.viewTemplate="{ item }">
            <v-dialog width="auto ">
              <template #activator="{ on, attrs }">
                <v-link
                  class="viewButton"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.templateTitle }}
                </v-link>
              </template>
              <MessageCard :rendered-mail="item.templateContents" />
            </v-dialog>
          </template>

          <template #item.viewFilters="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <div v-on="on">
                  <span>{{ filtersAbrev(item.filters) }}</span>
                </div>
              </template>
              <div
                v-for="(text, index) in item.filters"
                :key="index"
              >
                {{ text }}
              </div>
            </v-tooltip>
          </template>

          <template #item.rateOpened="{ item }">
            {{ item.rateOpened.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1} ) }}%
          </template>

          <template #item.rateClicked="{ item }">
            {{ item.rateClicked.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1} ) }}%
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";
import MessageCard from "@/views/mailcampaigns/MessageCard.vue";

export default {
  name: 'GlobalCampaignsListComponent',

  components: {
    MessageCard,
  },

  props: {
    componentData: Object
  },

  data () {
    return {
      search: '',

      headers: [
        { text: this.$t('NAME'), align: 'left', value: 'title' },
        { text: this.$t('UNIVERSE'), align: 'left', value: 'universeName' },
        { text: this.$t('SAMPLE_STATE'), align: 'left', value: 'state' },
        { text: this.$t('MAIL_CAMPAIGN_METHOD'), align: 'left', value: 'sendingType' },
        { text: this.$t('MAIL_TEMPLATE'), align: 'left', value: 'viewTemplate' },

        { text: this.$t('MAILS_TOTAL'), align: 'right', value: 'nTotalMessages' },
        { text: this.$t('MAIL_TRACKING_SEND_PENDING'), align: 'right', value: 'totalPending' },
        { text: this.$t('MAIL_TRACKING_SENT'), align: 'right', value: 'totalSent' },
        { text: this.$t('EXPR_COUNT_FAILED'), align: 'right', value: 'totalFailed' },

        { text: this.$t('EXPR_RATE_OPENED'), align: 'left', value: 'rateOpened' },
        { text: this.$t('EXPR_RATE_CLICKED'), align: 'left', value: 'rateClicked' },

        { text: this.$t('SELECTIONS'), align: 'left', value: 'viewFilters' },
      ],

      loading: false,

      rows: [],

      sortBy: 'title',

    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getMailCampaignIds']),
  },

  created: function () {
    this.initialFetchData();
  },

  methods: {
    initialFetchData() {
       this.loading = true
      MailsService.fetchGlobalCampaignsListData(this.getAuthToken, this.activeSurvey.extName,  this.componentData.config.id).then((response) => {
        this.rows = response.data
        this.loading = false
      })
    },

    filtersAbrev(filterList) {
      if(filterList.length===0) {
        return ''
      } else if(filterList.length===1) {
        return filterList[0]
      } else {
        return filterList[0] + '...'
      }
    }


  },


}
</script>

<style scoped>

.backDiv {
  display: flex;
  align-items: center;
  padding: 1em;
}

div.campaignNameDiv {
  font-size: 140%;
  font-weight: bold;
  margin-right: 1em;
}

</style>

