<template>
    <div class="outerPanel">

      <div class="innerPanel">

        <div class="leftInnerPanel">
          <div>
            <v-row>
              <v-col :cols="6" v-for="(highlight, idx) in componentData.highlightedData" :key="idx">
                <div class="fieldlabel">{{ highlight.label }}</div>
                <div class="fieldValue">{{ highlight.value }}</div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="rightInnerPanel">
          <div class="mainFieldPanel">
            <div class="fieldLabel">{{componentData.mainData.label}}</div>
            <div class="mainFieldValue">{{componentData.mainData.value}}</div>
          </div>
        </div>

      </div>

    </div>
</template>

<script>

// import SamplesService from "@/services/SamplesService";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'HeaderPanelComponent',

  components: {},

  data: () => ({}),

  props: {
    componentData: Object,
    sampleId: Number
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'rankingList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),
  },

  created() {
  },

}
</script>
<style scoped>
div.outerPanel {
  color: white;
  background-color: #0000c8;
}

div.innerPanel {
  display: flex;
  flex-direction: row;
  padding: 2em;
}

div.leftInnerPanel {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}


div.buttonsPanel {
  display: flex;
  justify-content: flex-end;
}

div.rightInnerPanel {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

div.mainFieldPanel {
  display: flex;
  flex-direction: column;
}

div.mainFieldValue {
  /*font-size: 80px;*/
  font-size: calc((80/16)*1rem);

  line-height: 80px;
  color: #FFFFFF;
  text-transform: uppercase;
}


div.fieldLabel {
  /*font-size: 16px;*/
  font-size: calc((16/16)*1rem);

}

div.fieldValue {
  /*font-size: 22px;*/
  font-size: calc((22/16)*1rem);

  font-weight: bold;
}

</style>