<template>
  <v-container class="wrapper" fluid >
    <v-row no-gutters>
      <v-col cols="12">
        <v-tabs v-model="model"  class="viewTabs">
          <v-tab class="singletab" active-class="activetab" v-for="(item, index) in menuItems" :key="index" @click="menuItemSelected(item)">{{ menuTitle(item) }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <page-component v-if="selectedMenuItem != null" :component-data="selectedMenuItem.component" :key="selectedMenuItem.component.id"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'MenuBlockComponent',
  components: {
    'page-component': () => import('../PageComponent.vue')
  },

  data () {
    return {
      model: 0,
      selectedMenuItem: null
    }
  },

  props: {
    componentData: {
      type: Object,
      required: true
    }
  },

  computed: {
    // ...mapState(['activeView']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    menuItems() {
//      return []
//      return this.componentData.config.items.filter((item) => item.rootOnly)

      return this.componentData.config.items;
    }


  },

  created: function () {
    if(this.componentData.config.items.length > 0) {
      this.selectedMenuItem = this.componentData.config.items[0]
    }
  },

  methods: {
    menuTitle(item) {
      return item.title[this.getLocale]
    },

    menuItemSelected(item) {
      this.selectedMenuItem = item
    }
  }

}
</script>

<style scoped>
.wrapper {
  border-radius: 5px;
  color: #2E4F5C
}

.singletab {
  text-transform: capitalize;
  color: #000 !important;
}

.activetab {
  color: #0000c8 !important;
}



</style>
