import axios from 'axios'
// import store from '@/store'

let backend = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/rest' : '/rest'
  // baseURL: process.env.NODE_ENV === 'development' ? 'http://172.17.112.122:8080/rest' : '/rest'
})

// api.interceptors.request.use(config => {
//   // NProgress.start()
//   // console.log('call going out!')
//   store.commit('setLoading', 1)
//   return config
// })
//
// // before a response is returned stop nprogress
// api.interceptors.response.use(response => {
//   // NProgress.done()
//   // console.log('response coming back!')
//   store.commit('setLoading', 0)
//   return response
// })
export default backend
