<template>
  <v-container fluid pa-0>

    <v-row>
      <v-col>
        <b>{{surveyName}}</b>
        <template v-for="(filter,idx) in value">
          <UserQuestionFilterComponent @update-filters="updateFilters(filter, $event)" :uqfmodel="filter" :key="idx"/>
        </template>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import UserQuestionFilterComponent from "./UserQuestionFilterComponent";

export default {
  name: 'UserSurveyComponent',

  components: {
    UserQuestionFilterComponent
  },

  props: {
    surveyName: String,
    value: Array,
    showGlobals: Boolean
  },

  data: () => ({
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    restricted() {
      return this.userDetails.restricted
    },

  },

  methods: {
    updateFilters(filter, newValue) {
      filter.value = newValue
      this.$emit('update-surveydata', this.value)
    }
  },


}
</script>
