<template>
  <v-container class="pa-2" fluid>
    <v-row>
      <v-col>
        <div class="headline">{{questionGroupData.title}}</div>
      </v-col>
    </v-row>

    <v-divider/>

    <v-row v-for="(row, idx) in fieldRows" v-bind:key="idx">
      <v-col cols="6">
        <QuestionComponent :is-cancelled="isCancelled" :can-edit-state="canEditState" :question-data="row[0]" @update="row[0]=$event.target.value" :validation-message-map="validationMessageMap"></QuestionComponent>
      </v-col>
      <v-col cols="6">
        <QuestionComponent v-if="row.length===2" :is-cancelled="isCancelled" :can-edit-state="canEditState" :question-data="row[1]" @update="row[1]=$event.target.value"  :validation-message-map="validationMessageMap"></QuestionComponent>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import _ from 'lodash'
import QuestionComponent from "./QuestionComponent";

export default {
  name: 'QuestionGroupComponent',

  components: {
    QuestionComponent
  },

  data: () => ({
    errorMessages: []
  }),

  props: {
    questionGroupData: Object,
    validationMessageMap: Object,
    canEditState: Boolean,
    isCancelled: Boolean
  },

  computed: {
    fieldRows() {
      return _.chunk(this.questionGroupData.fields, 2)
    }
  },

  methods: {
  },

}
</script>
<style scoped>
.alerttitle {
  background-color: #37c796;
}
</style>