<template>
    <div style="height: 25px">
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {Chart} from 'highcharts-vue'
    // import ColorManager from "colormanager";


    export default {
        name: 'SentimentBarChart',

        components: {
            highcharts: Chart
        },

        data () {
            return {
                chartOptions: {
                    chart: {
                        type: 'bar',
                        height: 25,
                        marginTop: 0,
                        marginBottom: 0,
                        paddingTop: 0,

                        animation: false
                    },
                    title: null,

                    credits: {
                        enabled: false
                    },

                    legend: {
                        enabled: false,
                    },

                    tooltip: {
                        enabled: false
                    },

                    xAxis: {
                        visible: false,
                    },

                    yAxis: {
                        visible: false
                    },

                    plotOptions: {
                        series: {
                            stacking: 'percentage',
                            stackingOrder: 'normal',

                            dataLabels: {
                                enabled: true,
                                inside: true,
                                formatter: function() {
                                    return (this.series.index===1) ? this.y.toFixed(2) +'%' : '';
                                }

                            }

                        },

                        bar: {
                            groupPadding: 0,
                            pointPadding: 0,
                            dataLabels: {
                                enabled: true,
                            }
                        }


                    },


                    series: [{
                        color: "red",
                        index: 1,
                        data: []
                    }, {
                        color: "#BFBFBF",
                        index: 0,
                        data: []
                    }]

                }
            }
        },

        props: {
            categoryData: Object,
            positives: Boolean
        },

        created: function () {
            this.chartOptions.series[0].color = this.color
            this.chartOptions.series[0].data = [this.percentageValue]
            this.chartOptions.series[1].data = [this.percentageRest]
        },

        computed: {
            ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

            percentageValue() {
                return this.positives ? this.categoryData.percentagePositives : this.categoryData.percentageNegatives
            },

            percentageRest() {
                return 100.0 - this.percentageValue
            },

            color() {
                return this.positives ? '#00C896' : '#BE0000'
            }

        },
    }
</script>
<style scoped>
</style>
