<template>

  <v-container fluid v-if="scriptBlock!==null">
    <ConfirmDialog ref="confirm" />

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-container fluid>

              <v-row>
                <v-col cols="2">
                  <v-text-field v-model="scriptBlock.metaInterviewExtName" label="metainterview/tag" :disabled="isUniverseScript"/>
                </v-col>
                <v-col cols="2" >
                  <v-select label="type" :items="scriptTypes"   v-model="scriptBlock.scriptType" :disabled="isUniverseScript"></v-select>
                </v-col>

                <v-col cols="2">
                  <v-text-field v-model="scriptBlock.extname" label="extname"/>
                </v-col>

                <v-col>
                  <v-text-field v-model="scriptBlock.description" label="description"/>
                </v-col>

                <v-col cols="1" >
                  <v-checkbox label="disabled" v-model="scriptBlock.disabled"></v-checkbox>
                </v-col>

                <v-col cols="1" align-self="center">
                  <v-btn @click="onSave" icon>
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn @click="onDelete" icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>

              </v-row>

              <v-row>
                {{scriptBlock.matchingMetaInterviews}}
              </v-row>


            </v-container>
          </v-card-text>

        </v-card>


      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ScriptEventList :script-block-id="scriptBlock.id" :script-event-list="scriptBlock.events"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>

            <AceEditor
                v-model="scriptBlock.script"
                @init="editorInit"
                :lang="scriptLang"
                theme="eclipse"
                width="100%"
                height="600px"
                :options="{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        fontSize: 14,
        highlightActiveLine: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        showPrintMargin: false,
        showGutter: true,
    }"
                :commands="[
        {
            name: 'save',
            bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
            exec: onSave,
            readOnly: true,
        },
        {
            name: 'dryrun',
            bindKey: { win: 'Ctrl-d', mac: 'Command-d' },
            exec: onDryrun,
            readOnly: true,
        },
        {
            name: 'search',
            bindKey: { win: 'Ctrl-f', mac: 'Command-f'},
            exec: onSearch,
            readOnly: true,
        },
    ]"
            />



<!--            <v-textarea ref="scriptarea" autofocus rows="20" v-model="scriptBlock.script" style="font-family: monospace"></v-textarea>-->
          </v-card-text>

          <v-container fluid v-if="showInterviewParams">
            <v-row>
              <v-col cols="2">
                <v-text-field type="number" label="interview-id" v-model="runParams.interviewId"></v-text-field>
              </v-col>
            </v-row>
          </v-container>



          <v-card-actions>
            <v-btn @click="onSave()">Save</v-btn>
            <v-btn @click="onRun(true)">Dryrun</v-btn>
            <v-btn @click="onRun(false)">Run</v-btn>
          </v-card-actions>

          <v-card-text>
            <v-textarea auto-grow readonly :value="runResult" style="font-family: monospace"></v-textarea>
          </v-card-text>

        </v-card>
      </v-col>

    </v-row>

  </v-container>

</template>

<script>

import {mapGetters} from "vuex";
import ScriptsService from "@/services/ScriptsService";
import PageNavigator from "@/pagenavigator";
import ConfirmDialog from "@/components/ConfirmDialog";
import AceEditor from 'vuejs-ace-editor';
import ScriptEventDetails from "@/views/backend/ScriptEventDetails.vue";
import ScriptBlockList from "@/views/backend/ScriptBlockList.vue";
import ScriptEventList from "@/views/backend/ScriptEventList.vue";

export default {
  name: 'ScriptBlockDetails',

  components: {
    ScriptEventList,
    ConfirmDialog, AceEditor
  },

  data: () => ({
    interviewId: null,

    runParams: {
      interviewId: null
    },

    scriptBlock: null,
    runResult: ""
  }),

  props: {
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey']),

    showInterviewParams() {
      return this.scriptBlock.scriptType==="HANDLE_SAMPLE_UPDATED"
    },

    scriptLang() {
      return this.scriptBlock.scriptType==="CONFIGURATION" ? "yaml" : "javascript"
    },

    isGlobal() {
      return this.scriptBlock.global
    },

    isUniverseScript() {
      return this.scriptBlock.universeId!==null
    },


    scriptTypes() {
      return [
        "CONFIGURATION",
        "GENERIC",
        "JAVASCRIPT",
        "GET_AVAILABLE_TIMESPANS",
        "GET_DEFAULT_TIMESPAN",
        "GET_ALERT_TIMESPAN",
        "GET_SAMPLES_OPEN_TIMESPAN",
        "GET_RANKING_TREND_TIMESPAN",
        "GET_DRIVERS_TREND_TIMESPAN",
        "GET_NOTIFICATION_EMAILS",
        "GET_ALERT_EMAILS",
        "GET_ACTION_EMAILS",
        "GENERATE_ALERT",
        "GET_EMAIL_CAMPAIGN",
        "GET_TIME_CONFIGURATION",
        "HANDLE_SAMPLE_UPDATED",
        "HANDLE_NODESTATS",
        "HANDLE_MSG_EVENT",
        "VALIDATE_SAMPLE"
        ]
    }
  },

  mounted() {
    if(this.$route.params.scriptId) {
      ScriptsService.fetchScriptBlock(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.scriptId).then((response) => {
        this.scriptBlock = response.data;
      })
    } else {
      ScriptsService.fetchUniverseScriptBlock(this.getAuthToken, this.getActiveSurvey.extName).then((response) => {
        this.scriptBlock = response.data;
      })
    }

  },


  methods: {

    editorInit: function () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/javascript')    //language
      require('brace/mode/yaml')
      require('brace/theme/eclipse')
      require('brace/ext/searchbox')
      // require('brace/snippets/javascript') //snippet
    },

    navigateBack() {
      if(this.isGlobal || this.isUniverseScript) {
        PageNavigator.navigateToSurveyListPage(this, this.getActiveSurvey.extName)
      } else {
        PageNavigator.navigateToSurveyDetailsPage(this, this.getActiveSurvey.extName, this.$route.params.surveyId)
      }
    },

    onSave() {
      ScriptsService.saveScriptBlock(this.getAuthToken, this.getActiveSurvey.extName, this.scriptBlock).then(() => {
        this.navigateBack()
      })
    },

    async onDelete() {
      if ( await this.$refs.confirm.open("Confirm", "Delete script?")) {
        ScriptsService.deleteScriptBlock(this.getAuthToken, this.getActiveSurvey.extName, this.scriptBlock.id).then(() => {
          this.navigateBack()
        })
      }
    },

    onDryrun() {
      this.onRun(true)
    },

    onRun(dryrun) {
      ScriptsService.saveScriptBlock(this.getAuthToken, this.getActiveSurvey.extName, this.scriptBlock).then(() => {

        ScriptsService.runScriptBlock(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId, this.scriptBlock.id, dryrun, this.runParams).then((response) => {
          const scriptResult = response.data
          this.runResult = scriptResult.returnCode + ":" + scriptResult.message
        })
      })
    },

    onSearch(editor) {
      editor.execCommand('find')
    },
  },

}


</script>

<style scoped>
</style>