<template>
  <v-tabs background-color="#f5f5f5" v-model="model" class="viewTabs">
    <v-tab v-for="(view) in getViews" :href="'#' + view.extName" :key="view.extName" @click="viewSelected(view.extName)" ref="viewTabs">
      <span class="tabText">{{ view.title }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
import {mapGetters, mapState} from "vuex";
// import _ from 'lodash'

export default {
  name: 'ViewTabsComponent',
  data () {
    return {
      model: 0,
      selectedView: null
    }
  },

  watch: {
    activeView: function () {
      if(this.activeView.extName===this.selectedView) {
        this.selectedView = null
      } else {
        this.model = this.activeView.extName
      }
    },
  },

  computed: {
    ...mapState(['activeView']),
    ...mapGetters(['getViews'])
  },

  methods: {
    viewSelected(viewExtName) {
      this.selectedView = viewExtName
      this.$store.commit('selectView', viewExtName)
    }
  },

}
</script>
<style scoped>
.viewTabs {
  margin-left: 1em;
}

.tabText {
  text-transform: none;
}
</style>
