<template>
  <VariableFieldComponent v-if="componentData.componentDef.type==='VariableFieldComponent'" :component-data="componentData" ref="childComponent"/>
  <GroupComponent v-else-if="componentData.componentDef.type==='GroupComponent'" :component-data="componentData" :sample-id="sampleId" :state="state" ref="childComponent"/>
  <CommentsPanelComponent v-else-if="componentData.componentDef.type==='CommentsPanelComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>
  <MailsPanelComponent v-else-if="componentData.componentDef.type==='MailsPanelComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>

  <HeaderPanelComponent v-else-if="componentData.componentDef.type==='HeaderPanelComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>

  <MailActionButtonComponent v-else-if="componentData.componentDef.type==='MailActionButtonComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>
  <PushCampaignButtonComponent v-else-if="componentData.componentDef.type==='PushCampaignButtonComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>

  <EditButtonComponent v-else-if="componentData.componentDef.type==='EditButtonComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>
  <UpdateButtonComponent v-else-if="componentData.componentDef.type==='UpdateButtonComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>
  <CancelButtonComponent v-else-if="componentData.componentDef.type==='CancelButtonComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>
  <ResendButtonComponent v-else-if="componentData.componentDef.type==='ResendButtonComponent'" :component-data="componentData" :sample-id="sampleId" ref="childComponent"/>
  <StateButtonComponent v-else-if="componentData.componentDef.type==='StateButtonComponent'" :component-data="componentData" :sample-id="sampleId" :state="state" ref="childComponent"/>

  <div v-else>*Unknown component: {{componentData.type}}</div>
</template>

<script>

import VariableFieldComponent from "@/components/sampleditor/VariableFieldComponent";
import GroupComponent from "@/components/sampleditor/GroupComponent";
import MailActionButtonComponent from "@/components/sampleditor/MailActionButtonComponent";
import CommentsPanelComponent from "@/components/sampleditor/CommentsPanel";
import HeaderPanelComponent from "@/components/sampleditor/HeaderPanel";
import UpdateButtonComponent from "@/components/sampleditor/UpdateButtonComponent";
import CancelButtonComponent from "@/components/sampleditor/CancelButtonComponent";
import EditButtonComponent from "@/components/sampleditor/EditButtonComponent";
import ResendButtonComponent from "@/components/sampleditor/ResendButtonComponent";
import StateButtonComponent from "@/components/sampleditor/StateButtonComponent";
import MailsPanelComponent from "@/components/sampleditor/MailsPanel.vue";
import PushCampaignButtonComponent from "@/components/sampleditor/PushCampaignButtonComponent.vue";

export default {
  name: 'EditorComponent',
  components: {
    PushCampaignButtonComponent,
    MailsPanelComponent,
    StateButtonComponent,
    ResendButtonComponent,
    EditButtonComponent,
    CancelButtonComponent,
    UpdateButtonComponent,
    HeaderPanelComponent,
    CommentsPanelComponent,
    MailActionButtonComponent,
    GroupComponent,
    VariableFieldComponent,
  },

  data () {
    return {}
  },

  props: {
    componentData: Object,
    sampleId: Number,
    state: Number
  },

  computed: {

    classObject: function () {
      return {
        xs2:  this.componentData.config.size===2,
        xs4:  this.componentData.config.size===4,
        xs6:  this.componentData.config.size===6,
        xs8:  this.componentData.config.size===8,
        xs12: this.componentData.config.size===12
      }
    },

  },

  methods: {
    requestSave(validateOnly) {
      return this.$refs.childComponent.requestSave(validateOnly);
    }
  }

  }
</script>
<style scoped>
</style>
