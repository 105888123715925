<template>
  <v-container fluid>
    <v-row>
      <v-col>

        <v-card outlined class="sentimentCard" v-if="textAnalyticsData">

          <v-container fluid>
            <v-row><v-col><span class="title">{{title}}</span></v-col></v-row>
            <v-row><v-col><span class="title">{{'CATEGORIES' | i18n}}</span></v-col></v-row>

            <v-row>
              <v-col>

                <v-expansion-panels>

                  <v-expansion-panel v-for="(cat,idx) in categories" :key="idx">
                    <v-expansion-panel-header v-slot="{  }" hide-actions  >
                      <v-row no-gutters>
                        <v-col cols="2">{{cat.title}}</v-col>
                        <v-col cols="10" class="text--secondary">
                          <SentimentBarChart :positives="isPositive" :indicator-data="cat"/>
                        </v-col>

                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row no-gutters v-for="(question, idx) in cat.questionResults" :key="idx">
                        <v-col cols="2"></v-col>
                        <v-col cols="3"><v-icon :color="sentimentColor">mdi-circle-medium</v-icon>{{question.title}}:</v-col>
                        <v-col cols="1"><span class="numberSpan">{{question.percentageText}}</span></v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>


                </v-expansion-panels>



              </v-col>
            </v-row>

          </v-container>

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters, mapState} from "vuex";
  import SentimentBarChart from "./SentimentBarChart";

  export default {
    name: 'FeedbackSentimentComponent',

    components: {
      SentimentBarChart
    },

    data () {
      return {
      }
    },

    props: {
      componentData: Object,
    },

    computed: {
      ...mapState(['textAnalyticsData']),
      ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

      categories() {
        return this.isPositive ? this.textAnalyticsData.positiveCategories : this.textAnalyticsData.negativeCategories
      },

      title() {
        return this.isPositive ? this.$t('POSITIVE_FEEDBACK') : this.$t('NEGATIVE_FEEDBACK')
      },

      isPositive() {
        return this.componentData.config.positives
      },

      sentimentColor() {
        return this.isPositive ? "#00C896" :"#BE0000"
      },

    },

  }
</script>

<style scoped>
  .sentimentCard {
    padding: 30px;
  }

  .numberSpan {
    font-weight: 500;
  }

  td.categoriesTitleCell {
    /*font-size: 20px;*/
    font-size: calc((20/16)*1rem);
  }

  td.categoryTitleCell {
    text-align: right;
    width: 20%;
  }

  td.categoryBarCell {
    width: 80%;
  }

  span.title {
    /*font-size: 20px;*/
    font-size: calc((20/16)*1rem);
  }

</style>
