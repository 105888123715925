<template>
    <img width="64" height="64" :src="logoFilename"  alt="logo"/>
</template>

<script>

export default {
  name: 'TinyLogoComponent',

  props: {
    universe: String
  },


  computed: {

    logoFilename() {
      return "https://dashboard.aganalytics.dk/files/dashboard_logos/" + this.universe.toLowerCase() + "_square.png"
    }

  },

}
</script>