<template>
  <v-container fluid>
    <v-row v-if="!uploadResponse || uploadResponse.error">
      <v-col>
        <v-file-input :label="$t('DATA_UPLOADER_FILE_INPUT')" outlined dense @change="selectFile" accept=".xlsx"></v-file-input>
      </v-col>
    </v-row>
    <v-row v-if="!uploadResponse || uploadResponse.error">
      <v-col>
        <v-btn :disabled="!currentFile" @click="onUpdateClicked">{{'DATA_UPLOADER_UPLOAD' | i18n}}</v-btn>
      </v-col>
    </v-row>
    <v-row  v-if="uploadResponse && !uploadResponse.error">
      <v-col>
        <DataMapperComponent @importedfile="fileImported()" :filename="uploadResponse.filename" :mapping-structure="uploadResponse.mapping" :spreadsheet-structure="uploadResponse.spreadsheet" :config-id="componentData.config.id"/>
      </v-col>
    </v-row>
    <v-row  v-else-if="uploadResponse && uploadResponse.error">
      <v-col>
        {{ uploadResponse.error}}
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ImportSetListComponent :component-data="componentData" ref="importSetList"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import {mapGetters} from "vuex";
import UploadService from "@/services/UploadService";
import DataMapperComponent from "@/views/pages/pagecomponents/datauploader/DataMapperComponent";
import ImportSetListComponent from "@/views/pages/pagecomponents/datauploader/ImportSetListComponent";
// import NoDataComponent from "../subcomponents/NoDataComponent";

export default {
  name: 'DataUploaderComponent',
  components: {
    ImportSetListComponent,
    DataMapperComponent,
  },

  data () {
    return {
      importdata: null,
      currentFile: undefined,
      progress: 0,
      message: "",
      fileInfos: [],
      uploadResponse: null
    }
  },

  props: {
    componentData: Object,
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale'])
  },

  methods: {
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },

    onUpdateClicked() {
      // if (!this.currentFile) {
      //   this.message = "Please select a file!";
      //   return;
      // }
      this.message = "";
      UploadService.upload(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.currentFile,  (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
          .then((response) => {
            this.message = response.data.message;

            this.uploadResponse = response.data


            return UploadService.getFiles();
          })
          .then((files) => {
            this.fileInfos = files.data;
          })
          .catch(() => {
            this.progress = 0;
            // this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });
    },

    fileImported() {
      this.$refs.importSetList.initialFetchData()
      this.$store.dispatch('fetchFilterData', { surveyName: this.getActiveSurvey.extName, filtersType:  'standard'})
    }

  }


}
</script>

<style scoped>

.nodatacontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nodataicon {
  color: rgba(0,0,0,.7);
  margin-bottom: 30px;
}

.nodatatext {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem) !important;
  color: #000000;
}

/*span.title {*/
/*  !*font-size: 24px;*!*/
/*  font-size: calc((24/16)*1rem) !important;*/
/*}*/
</style>
