<template>

  <v-container class="wrapper elevation-2" fill-height fluid>
    <v-row no-gutters align="center" justify="center">
      <v-col>
        <div class="title text-center">
          {{title}}
        </div>
        <highcharts :options="chartOptions"></highcharts>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {Chart} from 'highcharts-vue'

export default {
  name: 'CircleComponent',

  components: {
    highcharts: Chart
  },

  data () {

    return {
      chartOptions: {
        chart: {
          type: 'pie',
          alignTicks: false,
          height: 150,
          style: {
            fontFamily: 'Roboto, sans-serif',
          },
        },

        plotOptions: {
          pie: {
            animation: false,
            size: 110,
            innerSize: 90,
            enableMouseTracking: false,
            dataLabels: {
              enabled: false
            },
            shadows: false

          }
        },

        series: [{
          data: [
            {name: '', color: this.color, y: this.value},
            {name: '', color: '#c9c9c9', y: 100-this.value}
          ]
        }
        ],

        title: {
          text: this.formattedValue,
          verticalAlign: "middle",
          style: {
            fontSize: '24px'
          }
        },

        credits: {
          enabled: false
        },

        legend: {
          enabled: false
        }

      },




    }
    },


  props: {
    color: String,
    title: String,
    value: Number,
    formattedValue: String,
  },

  computed: {
    // arrowIcon: function () {
    //   if (this.vardto.diffArrow === 1) {
    //     return 'mdi-menu-up'
    //   } else if (this.vardto.diffArrow === 2) {
    //     return 'mdi-menu-down'
    //   }
    //
    //   return 'mdi-trending-neutral'
    // }
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  height: 100%;
  background-color: white;
  border-radius: 5px;
}


div.title {
  font-size: 24px !important;
  height: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  letter-spacing: 0;
  color: #000;
}

div.score {
  font-size: 3em;
  font-weight: 500;
}

i.bottomicon {
  vertical-align: text-top;
}
</style>
