<template>

    <v-col cols="4">
        <v-card>

            <v-form v-model="isValid" ref="form">
                <v-card-title>{{universeName}}</v-card-title>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-select ref="roleSelect" :value="selectedrole" :items="roles" :label="$t('ROLE')" :disabled="!isUserEditable" @change="$emit('update:selectedrole', $event)" required :rules="roleRules"/>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-select :value="selectedlocale" :items="locales" label="locale" :disabled="!isUserEditable" @change="$emit('update:selectedlocale', $event)"/>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-checkbox v-model="disabled" :disabled="!isUserEditable" label="disabled" @change="$emit('update:disabled', $event)"/>
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="notification" :disabled="!isUserEditable" label="notification" @change="$emit('update:notification', $event)"/>
                        </v-col>
                        <v-col v-if="fullAccess">
                            <v-checkbox v-model="admin" :disabled="!isUserEditable" label="admin" @change="$emit('update:admin', $event)"/>
                        </v-col>
                    </v-row>

                  <v-row>
                    <v-col>
                      <b>Notifications</b>
                      <template v-for="(ncc,idx) in notificationChannels">
                        <template v-for="(channel,channelName) in ncc">
                          <NotificationChannelComponent @update-channeldata="$emit('update-channeldata', $event, idx, channelName)" :key="channel.title" :channel-data="channel"/>
                        </template>
                      </template>
                    </v-col>
                  </v-row>

                  <v-row v-if="findGlobalSurveys">
                        <v-col cols="12">
                            <NewUserSurveyComponent :is-user-editable="isUserEditable"
                                                    :show-globals="true"
                                                    :survey-name="$t('GLOBAL_UQF_TITLE')"
                                                    :value="findGlobalSurveys.userVariables"
                                                    @updateuqf="$emit('updateglobaluqf', $event)"/>
                        </v-col>
                    </v-row>

                    <v-row v-if="fullAccess || !findGlobalSurveys">
                        <v-col cols="4" v-for="(survey) in surveysMinusGlobal" :key="survey.extname">
                            <NewUserSurveyComponent :is-user-editable="isUserEditable" :show-globals="false" :survey-name="survey.surveyName" :value="survey.userVariables" @updateuqf="$emit('updateuqf', $event)"/>
                        </v-col>
                    </v-row>

                </v-container>


            </v-form>
        </v-card>

    </v-col>


</template>


<script>
import {mapGetters, mapState} from 'vuex'
import NewUserSurveyComponent from "@/views/users/NewUserSurveyComponent.vue";
import NotificationChannelComponent from "@/views/users/NotificationChannelComponent.vue";
// import NotificationChannelComponent from "@/views/users/NotificationChannelComponent.vue";

export default {
    name: 'UniverseUserComponent',

    components: {
      NotificationChannelComponent,
      // NotificationChannelComponent,
        NewUserSurveyComponent,
    },

    props: {
        fullAccess: Boolean,
        universeName: String,
        userId: Number,
        createuser: Boolean,
        disabled: Boolean,
        admin: Boolean,
        notification: Boolean,
        locales: Array,
        selectedlocale: String,
        selectedrole: String,
        roles: Array,
        surveys: Array,
        notificationChannels: Object
    },

    // roleRule:   [v => Boolean(Object.keys(v || {})[0]) || "Field is required"],


    data: () => ({
        isValid: false,

        roleRules: [
            v => !!v || 'Role is required'
        ],

    }),

    computed: {
        ...mapState(['activeSurvey']),
        ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

        isUserExisting() {
            return this.userId!==0
        },

        isUserEditable() {
            return this.isUserExisting || this.createuser
        },

        findGlobalSurveys() {
            return this.surveys.find(s => s.surveyName==='global')
        },

        surveysMinusGlobal() {
            return this.surveys.filter(s => s.surveyName!=='global')
        },

    },

    methods: {
        validateMandatory() {
            if( this.isUserExisting || this.createuser ) {
                this.$refs.form.validate();

              // console.log("--")
              // console.log(this.$refs.roleSelect.errorBucket)
              //
              return this.isValid ? [] : this.$refs.roleSelect.errorBucket;

            }

            return [];
        },

    },


}
</script>
