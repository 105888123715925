<template>
  <v-container fluid pa-0>

    <v-row class="pa-3">
      <v-col>
        <div>
          <v-btn v-if="compoundTemplatesData.createEmailTemplates" class="mr-4" @click="onAddEmailClicked()">Add email template</v-btn>
          <v-btn v-if="compoundTemplatesData.createSMSTemplates"   class="mr-4" @click="onAddSMSClicked()">Add sms template</v-btn>
          <v-btn v-if="compoundTemplatesData.createLetterTemplates" @click="onAddLetterClicked()">Add letter template</v-btn>
        </div>

        <v-data-table
            :search = "search"
            :headers="headers"
            :items="compoundTemplatesData.rows"
            :loading="loading"
            item-key="id + name"
            class="elevation-2"
            must-sort
            :sort-by.sync="sortBy"
            :options="{'itemsPerPage': 30}"
            :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
            @click:row="onRowClicked">

          <template v-slot:top>
            <v-text-field v-model="search" label="Filter" class="mx-4"></v-text-field>
          </template>

        </v-data-table>

      </v-col>
    </v-row>


  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";
import pagenavigator from "@/pagenavigator";

export default {
  name: 'MailTemplatesListComponent',

  components: {
  },

  props: {
    componentData: {
      type: Object,
      required: true
    }
  },


  data () {
    return {
      search: '',
      headers: [
        { text: this.$t('NAME'), align: 'left', value: 'name' },
        { text: "Subject", align: 'left', value: 'subject' },
        { text: "Type", align: 'left', value: 'sendingType' },
        { text: "Labels", align: 'left', value: 'labels' },
      ],

      loading: false,

      compoundTemplatesData: { rows: [], createEmailTemplates: false, createSMSTemplates: false },

      sortBy: 'name'
    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),
  },

  created: function () {
    this.initialFetchData();
  },

  methods: {

    initialFetchData() {
      this.loading = true
      MailsService.fetchCompoundMailTemplatesListData(this.getAuthToken, this.activeSurvey.extName,  this.componentData.config.id).then((response) => {
        this.compoundTemplatesData = response.data
        this.loading = false
      })
    },

    onRowClicked(row) {
      let pageId = this.$route.params.pageId

      if(row.sendingType==='EMAIL') {
        pagenavigator.navigateToMailTemplateDetailsPage(this, this.activeSurvey.extName, row.id, pageId)
      } else if(row.sendingType==='SMS') {
        pagenavigator.navigateToSMSTemplateDetailsPage(this, this.activeSurvey.extName, row.id, pageId)
      } else if(row.sendingType==='LETTER') {
        pagenavigator.navigateToLetterTemplateDetailsPage(this, this.activeSurvey.extName, row.id, pageId)
      }

    },

    onAddEmailClicked() {
      let pageId = this.$route.params.pageId
      pagenavigator.navigateToMailTemplateDetailsPage(this, this.activeSurvey.extName, 0, pageId)
    },

    onAddSMSClicked() {
      let pageId = this.$route.params.pageId
      pagenavigator.navigateToSMSTemplateDetailsPage(this, this.activeSurvey.extName, 0, pageId)
    },

    onAddLetterClicked() {
      let pageId = this.$route.params.pageId
      pagenavigator.navigateToLetterTemplateDetailsPage(this, this.activeSurvey.extName, 0, pageId)
    },


  },


}
</script>
