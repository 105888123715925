<template>
  <v-container fluid pa-3>
    <ConfirmDialog ref="confirm" />

    <v-alert type="error" v-if="errorMessage">
      {{errorMessage}}
    </v-alert>

    <v-form v-if="templateDetails!==null"  ref="form" >
      <v-row class="pa-3">
        <v-col>
          <h4>{{headlineLabel}}</h4>
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col cols="4">
          <v-text-field  v-model="templateDetails.name" :label="$t('NAME')" :rules="nameRules" mandatory :disabled="isReadonlyMode"/>
        </v-col>
        <v-col  cols="1" v-if="isUserRoot">
          <v-checkbox v-model="templateDetails.system" label="system"/>
        </v-col>

        <v-col cols="1">
          <v-select
              v-model="templateDetails.locale"
              :items="availableLocales"
              label="locale"
              :disabled="isReadonlyMode"
          ></v-select>
        </v-col>

        <v-col cols="1">
          <v-combobox
              v-model="templateDetails.tags"
              :items="tags"
              label="tags"
              multiple
              :disabled="isReadonlyMode"
          ></v-combobox>
        </v-col>

        <v-col v-if="isUserRoot">
          <v-text-field v-model="templateDetails.extname" label="extname"/>
        </v-col>

      </v-row>



      <v-row class="pa-3">
        <v-col>
          <div class="mt-4">
            <v-btn class="mr-4" @click="onSubmitClicked" v-if="!isReadonlyMode">{{ buttonLabel }}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode && !isNew && !isReadonlyMode" @click="onDeleteClicked">{{'DELETE' | i18n}}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode && !isNew && this.templateDetails.contents" @click="onDuplicateClicked">{{'DUPLICATE' | i18n}}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode" @click="onCancelClicked">{{'CANCEL' | i18n}}</v-btn>

            <v-dialog
                v-model="dialog"
                width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">Test SMS</v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Send test SMS
                </v-card-title>

                <v-card-text>
                  <v-text-field v-model.lazy.trim="testsms" label="name of sender or phone number" type="text" required/>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="onSendTestSMS();dialog = false" :disabled="!testsms">{{'SEND' | i18n }}</v-btn>
                  <v-btn color="primary" text @click="dialog = false">{{'CANCEL' | i18n}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div style="height: 100%; position: relative" >
            <SMSEditor @change-content="setSMSContents" editorId="myEditor" :content="templateDetails.contents" :merge-tags="mergeTags"/>
          </div>
          <div v-if="isReadonlyMode" class="overlay"></div>
        </v-col>
      </v-row>

    </v-form>

  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import PageNavigator from "../../pagenavigator";
import MailsService from "@/services/MailsService";
import ConfirmDialog from "@/components/ConfirmDialog";
import SMSEditor from "@/views/mailtemplates/SMSEditor";
export default {
  name: 'SMSTemplateDetails',

  components: {
    SMSEditor,
    ConfirmDialog
  },

  props: {
    embedded: Boolean,
    templateId: null
  },

  data: () => ({
    templateDetails: null,
    repeatPassword: '',

    rolesList: [],

    tags: [],

    availableLocales: [],

    isNew: false,

    nameRules: [
      v => !!v || 'Name is required'
    ],


    emailRules: [ v => /.+@.+/.test(v) || 'Invalid Email address' ],

    mergeTags: [],

    dialog: false,
    testsms: "test sender",

    errorMessage: null,
  }),

  created: function () {
    if (this.calculateTemplateId !== 0) {
      this.isNew = false;

      MailsService.fetchSMSTemplateData(this.getAuthToken, this.activeSurvey.extName, this.calculateTemplateId).then((response) => {
        this.templateDetails = response.data
      })

    } else {
      this.isNew = true;
      this.templateDetails = { name: "", labels: "", contents: "", locale: this.getLocale}
      this.isNew = true
    }

    MailsService.fetchUnlayerMergeTags(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      this.mergeTags = response.data
    })

    MailsService.fetchTemplateTags(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      this.tags = response.data
    })

    MailsService.fetchAvailableLocales(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      this.availableLocales = response.data
    })

  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot', 'getLocale']),

    isReadonlyMode() {
      return !this.isUserRoot && this.templateDetails.system
    },

    calculateTemplateId() {
      return this.templateId ? this.templateId : this.$route.params.templateId
    },

    requiredForEmailRules() {
      return [v => (!this.isEmailMode || (!!v && this.isEmailMode)) || 'Name is required']
    },

    label() {
      return this.$route.query.label;
    },

    buttonLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    headlineLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    embeddedMode() {
      return this.templateId
    }

  },


  methods: {

    setSMSContents(smsContents) {
      this.templateDetails.contents = smsContents
    },

    onSendTestSMS() {
      MailsService.sendTestSMS(this.getAuthToken, this.activeSurvey.extName, this.testsms, this.templateDetails.contents).then((/*response*/) => {
      })
    },

    goToList() {
      const fromPage = this.$route.query.fromPage

      if(fromPage) {
        PageNavigator.navigateToPage(this, fromPage, this.activeSurvey.extName,  this.getFilteredDrillPath)
      } else {
        PageNavigator.navigateToDefaultPage(this, this.activeSurvey)
      }
    },

    onCancelClicked() {
      this.goToList()
    },

    onSubmitClicked() {
      if (this.$refs.form.validate() && this.templateDetails.contents) {

        if (this.isNew) {
          this.templateDetails.label = this.$route.query.label

          MailsService.createSMSTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then(() => {
            this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
            if(!this.embedded) {
              this.goToList()
            }
          }).catch((/*error*/) => {
            this.errorMessage = "Failed creating SMS template - name already in use"
          })

        } else {
          MailsService.updateSMSTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then(() => {
            this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
            if(!this.embedded) {
              this.goToList()
            }
          }).catch((/*error*/) => {
            this.errorMessage = "Failed creating SMS template - name already in use"
          })
        }

      }
    },

    async onDuplicateClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Duplicate template?")) {
        this.isNew = true
        this.templateDetails.system=false
        this.templateDetails.name = ""
      }
    },

    async onDeleteClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Delete template?")) {
        MailsService.deleteSMSTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails.id).then(() => {
          this.goToList()
        })
      }
    },

  },


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.unlayer {
  height: 100%;
}

div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128,128,128,0.2);
}


</style>
