<template>
  <v-dialog @click:outside="cancel" v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <SampleEditor @update="agree"  @cancel="cancel" :config-id="configId" :sample-id="sampleId" :key="editorKey"/>
  </v-dialog>
</template>

<script>

import SampleEditor from "@/components/sampleditor/SampleEditor";
export default {
  name: "SampleEditorDialog",
  components: { SampleEditor },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      options: {
        color: "grey lighten-3",
        width: '80%',
        zIndex: 200,
        noconfirm: false,
      },
      configId: null,
      sampleId: null,
      editorKey: 0
    };
  },

  methods: {
    open(configId, sampleId) {
      this.dialog = true;
      this.configId = configId
      this.sampleId = sampleId;
      this.editorKey++

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
