<template>
  <v-simple-table class="pa-0 ma-0">

    <template v-slot:default>
      <tbody>
        <tr v-for="(answer, idx) in answers" :key="'answer_' + idx">
          <td class="iconColumn">
            <InterviewAnswerIconComponent :iconValue="answer.icon" :use-number-icons="useNumberIcons"/>
          </td>
          <td class="answerColumn">
            <VariableComponent :label="answer.label" :value="answer.value"/>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import InterviewAnswerIconComponent from "./InterviewAnswerIconComponent";
import VariableComponent from "./VariableComponent";

export default {
  name: "InterviewAnswersComponent",
  components: {
    VariableComponent,
    InterviewAnswerIconComponent
  },
  props: {
    answers: Array,
    useNumberIcons: Boolean
  }
}
</script>

<style scoped>
  td.answerColumn {
    width: 100%;
  }
</style>
