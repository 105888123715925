<template>
  <v-card>
    <div class="renderDiv" v-html="renderedMail"> </div>
  </v-card>

</template>


<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'MessageCard',

  components: {},

  props: {
    renderedMail: String,
  },

  data: () => ({
    loading: false,
  }),


  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),
  },

}
</script>

<style scoped>

.waitingDiv {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.renderDiv {
  padding: 1em;
  background-color: white;
}
</style>
