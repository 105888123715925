<template>
  <div>

    <div style="display: flex; flex-direction: column">
      <v-subheader>{{'ADD_COMMENTS' | i18n}}</v-subheader>
      <v-textarea solo v-model="newComments" rows="3"> </v-textarea>
      <div class="newCommentButtons">
        <v-btn color="primary" :disabled="newComments==null || newComments.length===0" text @click="onSaveComment" >{{'SAVE' | i18n}}</v-btn>
        <v-btn color="primary" :disabled="newComments==null || newComments.length===0" text @click="newComments=''">{{'CANCEL' | i18n}}</v-btn>
      </div>
    </div>

    <v-simple-table v-if="oldComments.length>0">
      <thead>
      <tr>
        <th style="width: 10%;" class="text-left">{{'DATE' | i18n}}</th>
        <th style="width: 20%;" class="text-left">{{'USER' | i18n}}</th>
        <th class="text-left">{{'COMMENTS' | i18n}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(commentsData, idx) in oldComments" :key="idx">
        <td>{{commentsData.updated}}</td>
        <td>{{commentsData.user}}</td>
        <td>{{commentsData.comments}}</td>
      </tr>
      </tbody>
    </v-simple-table>
  </div>

</template>

<script>

import SamplesService from "@/services/SamplesService";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'CommentsPanelComponent',

  components: {},

  data: () => ({
    newComments: "",
    oldComments: []
  }),

  props: {
    componentData: Object,
    sampleId: Number
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'rankingList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),
  },

  created () {
    this.fetchComments();
  },

  methods: {

    requestSave(validateOnly) {
      if( validateOnly && this.newComments.trim().length>0 ) {
        SamplesService.createComments(this.getAuthToken, this.getActiveSurvey.extName, this.sampleId, this.newComments).then(() => {})
      }

      return true;
    },

    fetchComments() {
      SamplesService.fetchComments(this.getAuthToken, this.getActiveSurvey.extName, this.sampleId).then((response) => {
        this.oldComments = response.data
      })
    },

    onSaveComment() {
      SamplesService.createComments(this.getAuthToken, this.getActiveSurvey.extName, this.sampleId, this.newComments).then((/*response*/) => {
        this.newComments = ""
        this.fetchComments();
      })
    }
  },

}
</script>
<style scoped>

/*div.label {*/
/*  font-size: 12px;*/
/*  color: rgba(0, 0, 0, 0.6);*/
/*}*/

div.newCommentButtons {
  align-self: flex-end;
}
</style>