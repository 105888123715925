<template>
  <v-container class="wrapper" fluid>
    <v-row no-gutters>
      <v-col v-if="anchorable">
        <ActionsPartComponent :open-actions="openActions" :closed-actions="closedActions" :suggested-actions="suggestedActions" :writeable="anchorable" @actionchanged="loadChartData"/>
      </v-col>
      <v-col v-else-if="anchorable!==null">
        <NoDataComponent :title="$t('ACTION_PLAN_NO_DATA')"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import ResultsService from "../../../services/ResultsService";
// import ActionPlanPartComponent from "./ActionPlanPartComponent";
import ActionsPartComponent from "./ActionsPartComponent";
// import MainVariableComponent from "./MainVariableComponent";
import NoDataComponent from "./subcomponents/NoDataComponent";

export default {
  name: 'ActionManagerComponent',

  components: {
    ActionsPartComponent, NoDataComponent
  },

  data () {
    return {
      anchorable: null,
      actionPlanData: null,
      // mainVarData: null,
      openActions: [],
      closedActions: [],
      suggestedActions: []
    }
  },

  props: {
    componentData: Object,
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale'])
  },

  methods: {
    loadChartData() {
      // console.log("load chart data")

      ResultsService.fetchActionManagerData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
        this.anchorable = response.data.anchorable
        this.actionPlanData = response.data.actionPlanData;
        // this.mainVarData = response.data.mainVariable
        this.openActions = response.data.openActions
        this.closedActions = response.data.closedActions
        this.suggestedActions = response.data.suggestedActions
      })
    },
  }


}
</script>

<style scoped>
.wrapper {
  background-color: #F1F3F6;
  border-radius: 5px;
}

.mainVar {
  height: 30%
}

div.mainVarContainer div {
  height: 10% !important;
}

</style>
