<template>

  <v-form v-model="valid">

    <v-container fluid>
      <v-row style="background-color: rgb(70 165 212)">
        <v-col cols="2">
          <v-text-field disabled v-model="qgroupdata.type" label="grouptype"/>
        </v-col>

        <v-col cols="3">
          <v-text-field v-model="qgroupdata.extName" label="extname"/>
        </v-col>


        <v-col cols="5">
          <v-text-field v-model="qgroupdata.features" label="features"/>
        </v-col>

        <v-col cols="1">
          <v-text-field v-model="qgroupdata.sorder" label="sort order" type="number"/>
        </v-col>

        <v-col cols="1" align-self="center">
          <v-btn @click="onSave()" icon>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <MetaTextComponent :text-data="qgroupdata.name"/>
      </v-row>
      <v-row>
        <MetaTextComponent :text-data="qgroupdata.format"/>
      </v-row>

      <v-row v-for="(q,idx) in filteredQuestions" :key="idx">
        <MetaQuestionComponent :qdata="q"/>
      </v-row>


    </v-container>
  </v-form>

</template>

<script>

import {mapGetters, mapState} from "vuex";
import MetaTextComponent from "@/views/backend/MetaTextComponent";
import MetadataService from "@/services/MetadataService";
import MetaQuestionComponent from "@/views/backend/MetaQuestionComponent";

export default {
  name: 'MetaQuestionGroupComponent',

  components: {MetaQuestionComponent, MetaTextComponent},

  data () {
    return {
      valid: true
    }
  },

  props: {
    qgroupdata: Object,
    filterText: String
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    filteredQuestions() {

      return this.qgroupdata.questions.filter(question => this.filterText==="" || Object.values(question).includes(this.filterText))
    }

  },

  methods: {

    onSave() {
      MetadataService.saveQuestionGroup(this.getAuthToken, this.activeSurvey.extName, this.qgroupdata).then(() => {
        // console.log(response.data)
      })

    }


  }

}
</script>

<style scoped>
</style>