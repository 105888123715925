<template>
  <v-container fluid>
    <v-row>
      <v-col>

        <v-card class="sentimentCard" outlined v-if="textAnalyticsData">

          <v-container fluid>
            <v-row><v-col><span class="title">{{'SEARCH_WORDS' | i18n}}</span></v-col></v-row>

            <v-row><v-col cols="3">

              <!-- https://github.com/vuetifyjs/vuetify/issues/867 -->
              <v-combobox @change="onSearchClicked" :search-input.sync="searchValue" class="combobox" outlined>
                <template v-slot:append>
                  <v-btn @click="onSearchClicked" class="searchBtn" height="auto" text>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-combobox>

            </v-col></v-row>

            <v-row><v-col>
              <v-data-table :headers="headers" :items="rows" :items-per-page="25" class="elevation-2">

                <template v-slot:item.comment="{ item }">
                  <span v-html="item.comment"></span>
                </template>

                <template v-slot:item.posneg="{ item }">
                  <v-icon color="#00C896" v-if="item.positive">mdi-emoticon-outline</v-icon>
                  <v-icon color="#BE0000" v-if="item.negative">mdi-emoticon-sad</v-icon>
                </template>


              </v-data-table>
            </v-col></v-row>

          </v-container>

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ResultsService from "../../../../services/ResultsService";

export default {
  name: 'FeedbackSearchComponent',

  components: {},

  props: {
    componentData: Object,
    vardto: Object
  },

  created: function () {
    this.headers[0].text = this.$t('COMMENT')
    this.headers[1].text = this.$t('POSITIVE_NEGATIVE')
    this.headers[2].text = this.$t('CATEGORY')
    this.headers[3].text = this.$t('NAME')
  },


  data () {
    return {
      analyticsData: null,
      searchValue: "",

      headers: [
        { text: 'Kommentar' , value: 'comment' },
        { text: 'Positiv/Negativ', value: 'posneg' },
        { text: 'Kategori', value: 'indicator' },
        { text: 'Kundeinfo', value: 'var0' },
      ],

      rows: [],
    }
  },

  computed: {
    ...mapState(['textAnalyticsData']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
  },

  methods: {
    onSearchClicked() {
      ResultsService.searchTextAnalyticsAnswers(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, this.searchValue).then((response) => {
        this.rows = response.data.rows;
      })

    }

  },

}
</script>

<style>
.sentimentCard {
  padding: 30px;
}

span.title {
  /*font-size: 20px;*/
  font-size: calc((20/16)*1rem);
}

.searchBtn {
  background-color: blue;
  border-radius: 0 4px 4px 0;
  width:100%;
}
.v-input__append-inner{
  margin:0 !important;
  padding:0 !important;
  width:5%;
  height:100%;
}
.v-input__slot {
  padding-right:0 !important;
}
.theme--light.v-btn.searchBtn {
  color: white;
}

</style>
