<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on }">
                <v-btn :color="color" dark v-on="on">{{title | i18n}}</v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{ headline | i18n }}
                </v-card-title>

                <v-card-text>
                    <QuestionComponent :question-data="questionField" />
                </v-card-text>


                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="onOkClicked">{{title | i18n}}</v-btn>
                    <v-btn color="primary" @click="dialog = false">{{'CANCEL' | i18n}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    // import _ from 'lodash'

    import QuestionComponent from "./QuestionComponent";
    export default {
        name: 'EvaluationPeriodDialog',

        components: {QuestionComponent},

        data: () => ({
            dialog: false,

            questionField: null,
        }),

        props: {
            color: String,
            headline: String,
            title: String,
            value: Object
        },

        created: function () {
            this.questionField = this.value
        },

        computed: {},

        methods: {

            onOkClicked() {
                this.$emit("do-action", { evalPeriod: this.questionField.value})
                this.dialog = false;
            }

        },

    }
</script>
<style scoped>
</style>