<template>
  <v-container fluid pa-0>

    <v-row class="pa-3" v-if="!showCampaignDetails">
      <v-col>
        <v-data-table
            :search = "search"
            :headers="headers"
            :items="rows"
            :loading="loading"
            :items-per-page="50"
            item-key="id"
            class="elevation-2"
            @click:row="onRowClicked"
            :multi-sort="false"
        >

          <template v-slot:top>
            <v-text-field v-model="search" label="Filter" class="mx-4"></v-text-field>
          </template>

          <template v-slot:item.state="{ item }">
            <v-chip v-if="item.state==='CREATED'" class="ma-2" color="grey" text-color="white">{{'MAIL_CAMPAIGN_PREPARE' | i18n}}</v-chip>
            <v-chip v-else-if="item.state==='STARTED'" class="ma-2" color="#00C896" text-color="white">{{'MAIL_CAMPAIGN_ACTIVE' | i18n}}</v-chip>
            <v-chip v-else-if="item.state==='PAUSED'" class="ma-2" color="yellow" text-color="black">{{'MAIL_CAMPAIGN_PAUSED' | i18n}}</v-chip>
            <v-chip v-else-if="item.state==='STOPPED'" class="ma-2" color="grey" text-color="white">{{'MAIL_CAMPAIGN_STOPPED' | i18n}}</v-chip>
          </template>

          <template v-slot:item.scheduled="{ item }">
            {{item.scheduled ? new Date(item.scheduled).toLocaleDateString() +' '+ new Date(item.scheduled).toLocaleTimeString() : ''}}
          </template>

          <template v-slot:item.rateOpened="{ item }">
            {{item.rateOpened.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}  ) }}%
          </template>

          <template v-slot:item.rateClicked="{ item }">
            {{item.rateClicked.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}  ) }}%
          </template>


          <template v-slot:item.viewFilters="{ item }">

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <span>{{filtersAbrev(item.filters)}}</span>
                </div>
              </template>
              <div v-for="(text, index) in item.filters" :key="index">{{text}}</div>
            </v-tooltip>
          </template>


        </v-data-table>

      </v-col>
    </v-row>

    <template v-else>
      <div class="pl-3">
        <v-btn color="grey" dark @click="onReturnToListClicked">
          <v-icon>mdi-chevron-left</v-icon>
          {{'BACK' | i18n }}
        </v-btn>
      </div>

      <v-row>
        <v-col>
          <MailCampaignDetails :component-config-id="componentData.config.id" @exit-details="onReturnToListClicked"  :campaign-id-param="getSelectedCampaignId" :show-info-dialog="showInfoDialog"/>
        </v-col>
      </v-row>
    </template>


  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";
import MailCampaignDetails from "@/views/mailcampaigns/MailCampaignDetails";

export default {
  name: 'MailCampaignsListComponent',

  components: {
    MailCampaignDetails
  },

  props: {
    componentData: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      campaignId: null,

      showInfoDialog: false,

      search: '',
      headers: [
        { text: this.$t('NAME'), align: 'left', value: 'title' },
        { text: this.$t('MAIL_CAMPAIGN_METHOD'), align: 'left', value: 'sendingType' },
        { text: this.$t('MAIL_CAMPAIGN_TYPE'), align: 'left', value: 'autoAddTitle' },
        { text: this.$t('STATE_FILTER'), align: 'left', value: 'state' },
        // { text: this.$t('CREATED'), align: 'left', value: 'created' },
        { text: this.$t('SCHEDULED'), align: 'left', value: 'scheduled' },

        { text: this.$t('EXPR_RATE_OPENED'), align: 'left', value: 'rateOpened' },
        { text: this.$t('EXPR_RATE_CLICKED'), align: 'left', value: 'rateClicked' },

        { text: this.$t('MAIL_TRACKING_SEND_PENDING'), align: 'right', value: 'nPending' },
        { text: this.$t('MAIL_TRACKING_SENT'), align: 'right', value: 'nSent' },
        { text: this.$t('EXPR_COUNT_FAILED'), align: 'right', value: 'nFailed' },
        { text: this.$t('FILTERS'), align: 'left', value: 'viewFilters' },
      ],

      loading: false,

      rows: [],

      sortBy: 'title',

      selectedCampaignName: null,

      infoDialog: false
    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getMailCampaignIds']),

    campaignName() {
      if(this.getSelectedCampaignId && this.rows && this.rows.length>0) {
        return this.rows.filter(row => row.id===this.getSelectedCampaignId)[0].title
      }

      return ""
    },

    showCampaignDetails() {
      return this.getMailCampaignIds.length>0
    },

    getSelectedCampaignId() {
      const ids = this.getMailCampaignIds
      return (ids.length===1) ? Number(ids[0]) : null
    },


  },

  created: function () {
    this.initialFetchData();
  },

  methods: {
    initialFetchData() {
      this.loading = true
      MailsService.fetchMailCampaignsListData(this.getAuthToken, this.activeSurvey.extName,  this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
        this.rows = response.data.rows
        this.loading = false
      })
    },

    onRowClicked(row) {
      this.$store.commit('setFilter', {filterName: 'mcids', filterValue: [row.id]})
    },

    onReturnToListClicked() {
      this.$store.commit('setFilter', {filterName: 'mcids', filterValue: []})
    },

    onInformationClicked() {
      this.showInfoDialog = !this.showInfoDialog
    },

    filtersAbrev(filterList) {
      if(filterList.length===0) {
        return ''
      } else if(filterList.length===1) {
        return filterList[0]
      } else {
        return filterList[0] + '...'
      }
    }


  },


}
</script>

<style scoped>

.backDiv {
  display: flex;
  align-items: center;
  padding: 1em;
}

div.campaignNameDiv {
  font-size: 140%;
  font-weight: bold;
  margin-right: 1em;
}

</style>

