import backend from '@/services/backend'

export default {

  fetchMetadata (authToken, surveyName) {
    return backend.get('vuejs/metadata/' + surveyName, { headers: { Authorization: authToken } })
  },

  saveQuestionGroup(authToken, surveyName, qgroupdata) {
    return backend({ method: 'post', url: 'vuejs/metadata/questiongroup/' + surveyName, data: qgroupdata,  headers: { Authorization: authToken } })
  },

  saveQuestion(authToken, surveyName, qdata) {
    return backend({ method: 'post', url: 'vuejs/metadata/question/' + surveyName, data: qdata,  headers: { Authorization: authToken } })
  },

  saveChoice(authToken, surveyName, cdata) {
    return backend({ method: 'post', url: 'vuejs/metadata/choice/' + surveyName, data: cdata,  headers: { Authorization: authToken } })
  }

}
