<template>
  <div>
    <template v-if="dataLoaded">
      <highcharts :options="chartOptions"></highcharts>
    </template>
    <template v-else>
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'
import ResultsService from "../../services/ResultsService";
import ColorManager from "../../colormanager";
import Highcharts from "highcharts"
import ChartUtil from "../../chartutil";

export default {
  name: 'BenchmarkChartComponent',

  components: {
    highcharts: Chart
  },

  data() {
    return {
      dataLoaded: false,
      chartOptions: null


      // chartOptions: {
      //   lang: ChartUtil.getLangTranslations(this),
      //   exporting: ChartUtil.getExportingSettings(),
      //
      //   chart: {
      //     type: 'line',
      //     alignTicks: false,
      //   },
      //
      //   plotOptions: {},
      //
      //   series: [],
      //
      //   tooltip: {
      //     enabled: true,
      //     shared: false
      //   },
      //
      //   title: {
      //     text: null
      //   },
      //
      //   legend: {
      //     layout: this.componentData.verticalLegend ? 'vertical' : 'horizontal',
      //   },
      //
      //   credits: {
      //     enabled: false
      //   },
      //
      // }
    }
  },

  props: {
    componentData: Object
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData('years')
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),
  },

  methods: {

    loadChartData(period) {
      this.dataloaded = false
      ResultsService.fetchChartData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.id, period, this.getFilteredDrillPath).then((response) => {

        this.chartOptions = response.data
        this.dataLoaded = true;
        //
        //
        // this.updateChart(response.data)
      })

    },

    updateChart(chartdata) {
      this.dataloaded = true
      const config = this.componentData

      let colorManager = new ColorManager()

      const dataLabels = {
        enabled: true,
        color: '#000',
        format: '{point.y:,.1f}'
      }

      const percentage = !(chartdata.descriptors.length === 0) && (chartdata.descriptors[0].renderType === 'RENDER_PERCENTAGE')

      if (config.horizontal === true) {
        this.chartOptions.chart.type = "bar"
        this.chartOptions.plotOptions.bar = {
          dataLabels: dataLabels,
          stacking: percentage ? 'percent' : 'normal',
        }

      } else {
        this.chartOptions.chart.type = "column"

        this.chartOptions.plotOptions.column = {
          dataLabels: dataLabels
        }
      }

      this.chartOptions.tooltip = {
        useHTML: true,
        shared: false,
        shadow: false,
        formatter: function () {
          return this.series.name + ": " + Highcharts.numberFormat(this.y, 1, ',', '.');
        }
      }


      this.chartOptions.xAxis = {
        categories: chartdata.series.map(serie => serie.name),
      }

      this.chartOptions.yAxis = [{
        tickInterval: 10,
        title: {
          text: this.$t('CHART_BENCHMARK_YAXIS')
        },
        min: config.lowerY ? config.lowerY : null,
        max: config.upperY ? config.upperY : null
      }]

      let allSeries = []

      for (let i = 0; i < chartdata.descriptors.length; ++i) {
        let desc = chartdata.descriptors[i]

        let values = []
        for (let serie of chartdata.series) {
          values.push(serie.data[i])
        }

        const hcSerie = {
          data: values,
          name: desc.title,
          index: i,
          color: colorManager.getColor(desc.variable),


        }

        allSeries.push(hcSerie)
      }

      this.chartOptions.series = allSeries
    }

  },

}
</script>
<style scoped>
</style>
