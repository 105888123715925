<template>
  <div>
    <div style="display: flex; flex-direction: column">

      <div class="mb2">

        <v-text-field v-model="fromDays" :label="$t('MAIL_CAMPAIGN_RELATIVE_FROM_TODAY')" type="number" persistent-hint
                      :hint="fromDaysHint"
                      :rules="[mandatoryNumberRules, validateFirstTextField]" mandatory></v-text-field>
      </div>

      <v-text-field v-model="toDays" :label="$t('MAIL_CAMPAIGN_RELATIVE_FROM_TODAY')" type="number" persistent-hint
                    :hint="toDaysHint"
                    :rules="[mandatoryNumberRules, validateSecondTextField]" mandatory></v-text-field>
    </div>
    <v-btn @click="onClickAdd">{{ 'ADD' | i18n }}</v-btn>
  </div>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'FilterComponentDateInterval',

  data() {
    return {
      fromDays: -30,
      toDays: 0
    }
  },

  props: {
    filterSet: Object,
    filter: Object
  },

  created() {
    this.filterDirection = "e"
    this.deltaValue = 0
    this.deltaUnit = "d"
    this.deltaDirection = 'b'
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getFilterSelectedOptions', 'getActiveCombinedFilters']),

    validateFirstTextField() {
      return v => isNaN(v) || Number(v) < Number(this.toDays) || this.$t('MAIL_CAMPAIGN_MUST_BE_LESS_THAN') + ' ' + this.toDays
    },

    validateSecondTextField() {
      return v => isNaN(v) || Number(v) > Number(this.fromDays) || this.$t('MAIL_CAMPAIGN_MUST_BE_LARGER_THAN') + ' ' + this.fromDays
    },

    fromDaysHint() {
      if (this.fromDays) {
        const days = this.fromDays > 0 ? '+' + this.fromDays : this.fromDays;
        return this.$t('TODAY') + ' ' + days + ' ' + this.$t('DAYS')
      }
      return ''
    },

    toDaysHint() {
      if (this.toDays) {
        const days = this.toDays > 0 ? '+' + this.toDays : this.toDays;
        return this.$t('TODAY') + ' ' + days + ' ' + this.$t('DAYS')
      }
      return ''
    },

    mandatoryRules() {
      return value => !!value || this.$t('VALIDATE_ERROR_MANDATORY')
    },

    mandatoryNumberRules() {
      return v => (v !== null && v !== '' && !isNaN(v)) ? true : this.$t('VALIDATE_ERROR_MANDATORY')
    },


  },

  methods: {
    onClickAdd() {
      const extname = "rdays" + this.fromDays + "," + this.toDays

      const filterValues = this.getActiveCombinedFilters.getFilterValuesForFilter(this.filter.extName);

      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: [extname, ...filterValues]})

      this.checkedOptions = this.getActiveCombinedFilters.getFilterItems(this.activeSurvey.standardFilterSet)
          .filter(e => e.filter.extName === this.filter.extName)
          .map(e => e.option);
    },
  },


}
</script>

<style>
.filterCheckBox {
  /*    color: red; */
}

div.filterCheckBox div.v-input__control div.v-input__slot {
  margin: 0 !important;
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5 / 16) * 1rem) !important;

}

div.filterCheckBox label {
  left: 6px !important;
}

div.filterCheckBox label {
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5 / 16) * 1rem) !important;

}

.v-select-list .v-list .v-input__slot {
  margin-bottom: 0 !important;
}

/*.v-autocomplete-content .v-select-list .v-subheader {*/
/*  color: blue;*/
/*  font-size: 13.5px !important;*/
/*}*/

</style>
