<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2>{{surveyAccessData.surveyExtName}}</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.access" label="enable access"/>
      </v-col>
      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canViewSamples" label="view samples"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canViewAnon" label="view anonymous"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canEditOpenPeriods" label="edit data open periods"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canEditClosedPeriods" label="edit data closed periods"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canChangeStateOpenPeriods" label="change state open periods"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canChangeStateClosedPeriods" label="change state closed periods"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canCancelSamples" label="cancel samples"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canPostponeSamples" label="postpone samples"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canAccessStatusPage" label="status page"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canHandleAlerts" label="handle alerts"/>
      </v-col>

      <v-col cols="2">
        <v-checkbox v-model="surveyAccessData.canEditActions" label="edit actions"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-text-field label="start page" v-model="surveyAccessData.startPage"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="display order" v-model="surveyAccessData.viewOrder"></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field label="modify user filters" v-model="surveyAccessData.modifyUserFilters"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Views</h4>
      </v-col>
    </v-row>

    <v-row v-for="viewAccess in surveyAccessData.viewAccessList" :key="viewAccess.drilldownId" no-gutters>
      <v-col cols="2">
        <v-checkbox :label="viewAccess.viewName + '[' + viewAccess.viewExtName + ']' " v-model="viewAccess.access">  </v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-text-field label="default drill" v-model="viewAccess.defaultDrill"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Anchors</h4>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col v-for="anchorAccess in surveyAccessData.anchorAccessList" :key="anchorAccess.id" cols="2">
        <v-checkbox :label="anchorAccess.anchorName" v-model="anchorAccess.access"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Notifications</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-checkbox label="enabled" v-model="surveyAccessData.notifications"/>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col v-for="questionNotification in surveyAccessData.questionNotificationList" :key="questionNotification.id" cols="2">
        <v-checkbox :label="questionNotification.title" v-model="questionNotification.notify"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Notification channels</h4>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col v-for="notificationChannelDTO in surveyAccessData.notificationChannelList" :key="notificationChannelDTO.id" cols="2">
        <v-checkbox :label="notificationChannelDTO.title" v-model="notificationChannelDTO.enabled"/>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>

import {mapGetters, mapState} from "vuex";
import SurveysService from "@/services/SurveysService";

export default {
  name: 'SurveyAccessComponent',

  components: {},

  data () {
    return {
    }
  },

  props: {
    surveyAccessData: Object
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),
  },

  methods: {

    onSave() {
      SurveysService.saveMetaProperty(this.getAuthToken, this.activeSurvey.extName, this.mdpLive).then((response) => {
        this.mdpLive = response.data
      })

    }


  }

}
</script>

<style scoped>

</style>