<template>
  <div>
    <template v-if="dataLoaded">
      <highcharts :options="chartOptions"></highcharts>
    </template>
    <template v-else>
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'
import ResultsService from "../../services/ResultsService";
import ColorManager from "../../colormanager";
import ChartUtil from "../../chartutil";

export default {
  name: 'PriorityChartComponent',

  components: {
    highcharts: Chart
  },

  data () {
    return {
      dataLoaded: false,
      chartOptions: null
    }
  },

  props: {
    componentData: Object
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
  },

  methods: {
    loadChartData() {
      this.dataLoaded = false;
      ResultsService.fetchPriorityChartData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.id, this.getFilteredDrillPath).then((response) => {
        this.chartOptions = response.data
        this.dataLoaded = true;
      })
    }

  },

}
</script>
<style scoped>
</style>


<!--<template>-->
<!--<div>-->
<!--  <template v-if="dataLoaded">-->
<!--    <highcharts :options="chartOptions"></highcharts>-->
<!--  </template>-->
<!--  <template v-else>-->
<!--    <v-skeleton-loader type="image"></v-skeleton-loader>-->
<!--  </template>-->
<!--</div>-->
<!--</template>-->

<!--<script>-->
<!--  import {mapGetters} from "vuex";-->
<!--  import {Chart} from 'highcharts-vue'-->
<!--  import ResultsService from "../../services/ResultsService";-->
<!--  import ColorManager from "../../colormanager";-->
<!--  import Highcharts from "highcharts"-->
<!--  import ChartUtil from "../../chartutil";-->

<!--  export default {-->
<!--    name: 'PriorityChartComponent',-->

<!--    components: {-->
<!--        highcharts: Chart-->
<!--    },-->

<!--  data () {-->
<!--      return {-->
<!--        dataLoaded: false,-->

<!--        chartOptions: {-->
<!--          lang: ChartUtil.getLangTranslations(this),-->
<!--          exporting: ChartUtil.getExportingSettings(),-->

<!--          chart: {-->
<!--              type: 'scatter',-->
<!--              zoomType: 'xy'-->
<!--            },-->
<!--            title: null,-->

<!--            credits: {-->
<!--              enabled: false-->
<!--            },-->

<!--              legend: {-->
<!--                  reversed: true-->
<!--              },-->

<!--            tooltip: {-->
<!--              formatter:function(){-->
<!--                return this.point.series.name + ': ' + Highcharts.numberFormat(this.point.y,2,',','.') + ' ' + Highcharts.numberFormat(this.point.x,2,',','.');-->
<!--              }-->
<!--            },-->

<!--            xAxis: {-->
<!--              title: {-->
<!--                enabled: true,-->
<!--                text: this.$t('EFFECT')-->
<!--              },-->

<!--              labels: {-->
<!--                enabled: false-->
<!--              },-->

<!--              startOnTick: true,-->
<!--              endOnTick: true,-->
<!--              showLastLabel: true,-->
<!--              plotLines: []-->
<!--            },-->
<!--            yAxis: {-->
<!--              title: {-->
<!--                text: this.$t('SATISFACTION')-->
<!--              },-->
<!--              plotLines: []-->
<!--            },-->

<!--            plotOptions: {-->
<!--              scatter: {-->
<!--                marker: {-->
<!--                  radius: 5,-->
<!--                  states: {-->
<!--                    hover: {-->
<!--                      enabled: true,-->
<!--                      lineColor: 'rgb(100,100,100)'-->
<!--                    }-->
<!--                  }-->
<!--                },-->
<!--                states: {-->
<!--                  hover: {-->
<!--                    marker: {-->
<!--                      enabled: false-->
<!--                    }-->
<!--                  }-->
<!--                },-->
<!--                tooltip: {-->
<!--                  headerFormat: '<b>{series.name}</b><br>',-->
<!--                  pointFormat: '{point.x} cm, {point.y} kg'-->
<!--                }-->
<!--              }-->
<!--            },-->
<!--            series: []-->
<!--          }-->

<!--    }-->
<!--  },-->

<!--  props: {-->
<!--    componentData: Object-->
<!--  },-->

<!--    watch: {-->
<!--        $route: {-->
<!--            handler() {-->
<!--                this.loadChartData()-->
<!--          },-->
<!--            immediate: true-->
<!--        }-->
<!--    },-->




<!--  computed: {-->
<!--    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])-->
<!--  },-->

<!--  methods: {-->
<!--      loadChartData() {-->
<!--          this.dataLoaded = false-->
<!--          ResultsService.fetchPriorityChartData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.id, this.getFilteredDrillPath).then((response) => {-->
<!--          this.updateChart(response.data)-->
<!--        })-->

<!--      },-->

<!--      updateChart(chartdata) {-->
<!--        this.dataLoaded = true-->
<!--        this.chartOptions.series = chartdata.driverSeries-->

<!--        let colorManager = new ColorManager()-->
<!--        for(let s of this.chartOptions.series) {-->
<!--            s.color = colorManager.getColor(s.name)-->
<!--        }-->


<!--        this.chartOptions.xAxis.min = chartdata.xmin;-->
<!--        this.chartOptions.xAxis.max = chartdata.xmax;-->
<!--        this.chartOptions.yAxis.min = chartdata.ymin;-->
<!--        this.chartOptions.yAxis.max = chartdata.ymax;-->

<!--        if(chartdata.avgSatisfaction!==null) {-->
<!--          this.chartOptions.yAxis.plotLines = [ { color: '#AAAAAA', value: chartdata.avgSatisfaction, width: 2 }]-->
<!--          this.chartOptions.xAxis.plotLines = [ { color: '#AAAAAA', value: chartdata.avgEffect, width: 2 }]-->
<!--        }-->


<!--      }-->

<!--  },-->

<!--}-->
<!--</script>-->
<!--<style scoped>-->
<!--</style>-->
