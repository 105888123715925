<template>
  <LabelBallComponent v-if="showLabelBall" :color="color" :value="iconPart"/>
  <v-icon v-else :color="color">{{icon}}</v-icon>
</template>

<script>

import LabelBallComponent from "@/views/interview/LabelBallComponent";

export default {

  name: "InterviewAnswerIconComponent",

  components: {LabelBallComponent},

  props: {
    iconValue: String,
    useNumberIcons: Boolean
  },

  computed: {
    iconPart() {
      const parts = this.iconValue.split("-")
      return parts[1]
    },

    showLabelBall() {
      const parts = this.iconValue.split("-")

      if (this.useNumberIcons && parts && parts.length > 1) {
        return this.isNumeric(parts[1])
      } else {
        return false
      }

    },

    color() {
      const parts = this.iconValue.split("-")
      let result = parts.length > 0 ? parts[0] : "black";
      switch (result) {
        case 'red':
          return 'rgb(185, 19, 62)'
        case 'green':
          return 'rgb(0, 200, 150)'
        case 'black':
          return 'rgba(0,0,0,0.54)'
      }
      return result
    },


    icon() {
      const parts = this.iconValue.split("-")
      if (parts && parts.length > 1) {
        const sIcon = parts[1]
        switch (sIcon) {
          case '0':
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
          case '7':
          case '8':
          case '9':
          case '10':
            return 'mdi-numeric-' + sIcon + '-circle'
          case 'textAnswered':
            return 'mdi-comment-text-outline'
          case 'textMissing':
            return 'mdi-comment-outline'
          case 'checked':
            return 'mdi-checkbox-marked-circle'
          case 'missing':
            return 'mdi-checkbox-blank-circle-outline'
          case 'x':
            return 'mdi-close-circle'
          case 'number':
            return 'mdi-dots-horizontal-circle-outline'
          default:
              return 'mdi-help-circle'
        }
      } else {
        return 'mdi-help-circle'
      }
    }
  },


  methods: {
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
  }
}
</script>

<style scoped>
</style>