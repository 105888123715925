<template>
  <div>
        <v-text-field :label="$t('FROM')" @change="onChange" v-model="rangeFrom" type="number" />
        <v-text-field :label="$t('TO')"  @change="onChange" v-model="rangeTo" type="number" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'FilterComponentRange',

  data () {
    return {
      rangeFrom: null,
      rangeFromItems: [""],

      rangeTo: null,
      rangeToItems: [""],
   }
  },

  props: {
    filter: Object
  },

  computed: {
    ...mapGetters(['getActiveCombinedFilters'])
  },

  created() {
    this.checkedOptions = []
  },

  methods: {
    onChange() {
      if(this.rangeFrom && this.rangeFrom.length>0 && this.rangeTo && this.rangeTo.length) {
        const val = this.rangeFrom+"_"+this.rangeTo
        // console.log(val)
        this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: [val]})
      } else {
        this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: []})
      }
    },

    onClearedFrom() {
      this.rangeFrom = ""
      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: []})
    },

    onClearedTo() {
      this.rangeTo = ""
      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: []})
    }


  },


  watch: {
    combinedFilters: {
      handler() {

        let filterValues = this.getActiveCombinedFilters.getFilterValuesForFilter(this.filter.extName);

        // console.log("WATCH: " + this.filter.extName + " = " + filterValues)

        const value = filterValues[0]

        if(value) {
          const found = value.match("(-?[0-9]\\d*(\\.\\d+)?)_(-?[0-9]\\d*(\\.\\d+)?)");

          // console.log("Found: " + found)
          // console.log(found)
          //
          if(found) {
            this.rangeFrom = found[1]
            this.rangeTo = found[3]

            // console.log("from:"+this.rangeFrom)
            // console.log("to:"+this.rangeTo)


          }

        }

      },
      deep: true,
      immediate: true
    },

  },


}
</script>

<style>
.filterCheckBox {
  /*    color: red; */
}

div.filterCheckBox div.v-input__control div.v-input__slot {
  margin: 0 !important;
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

}

div.filterCheckBox label {
  left: 6px !important;
}

div.filterCheckBox label {
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

}

.v-select-list .v-list .v-input__slot { margin-bottom: 0 !important; }

/*.v-autocomplete-content .v-select-list .v-subheader {*/
/*  color: blue;*/
/*  font-size: 13.5px !important;*/
/*}*/

</style>
