<template>
  <v-container fluid pa-0>

    <v-row>
      <v-col>
        <b>{{surveyName}}</b>
        <template v-for="(filter,idx) in value">
          <NewUserQuestionFilterComponent :is-user-editable="isUserEditable" @update-filters="updateFilters(filter, $event)" :uqfmodel="filter" :key="idx"/>
        </template>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import NewUserQuestionFilterComponent from "@/views/users/NewUserQuestionFilterComponent.vue";

export default {
  name: 'NewUserSurveyComponent',

  components: {
    NewUserQuestionFilterComponent
  },

  props: {
    surveyName: String,
    value: Array,
    showGlobals: Boolean,
    isUserEditable: Boolean
  },

  data: () => ({
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    restricted() {
      return this.userDetails.restricted
    },

  },

  methods: {
    updateFilters(filter, newValue) {
      let updatedFilter = { ...filter } // shallow copy
      updatedFilter.value = newValue
      this.$emit('updateuqf', updatedFilter)
    }
  },

}
</script>
