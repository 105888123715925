<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent v-if="sampleData" @path-selected="onDrillToNamedPath" :leaf="true" :show-download="true" :sample-id="sampleData.sampleId"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathBackComponent/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <InterviewHeader :interviewData="interviewData" v-if="interviewData" />
      </v-col>
    </v-row>

    <v-row  class="pa-3">
      <v-col cols="3">
        <SideCardComponent v-if="interviewData!=null" :interview-data="interviewData"/>
      </v-col>

      <v-col cols="9">
        <SampleComponent v-if="sampleData" v-model="sampleData"/>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import SamplesService from "../../services/SamplesService";
import SampleComponent from "./SampleComponent";
import DrillPathComponent from "../../components/DrillPathComponent";
import SideCardComponent from "../interview/SideCardComponent";
import InterviewsService from "../../services/InterviewsService";
import DrillPathBackComponent from "../../components/DrillPathBackComponent";
import InterviewHeader from "@/views/interview/InterviewHeader";

export default {
  name: 'SampleDetails',

  components: {
    InterviewHeader,
    SampleComponent, DrillPathComponent, DrillPathBackComponent, SideCardComponent
  },

  data: () => ({
    sampleData: null,
    interviewData: null,
    showSuccessAlert: false
  }),

  methods: {
    onDrillToNamedPath: function (namedPath) {
      if(!namedPath.entityId) {
        this.$store.commit('setDrillPath', namedPath.path)
        this.$router.push({ name: 'samples', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
      }
    },

    loadSampleData() {
      let surveyExtName = this.$route.params.surveyExtName
      let sampleId = this.$route.params.sampleId

      SamplesService.fetchSampleData(this.getAuthToken, surveyExtName, sampleId).then((response) => {
        this.sampleData = response.data
      })

      InterviewsService.fetchInterviewData(this.getAuthToken, surveyExtName, sampleId).then((response) => {
        this.interviewData = response.data
      })

    },


  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
  },

  watch: {
    $route: {
      handler(/*$route*/) {
        let surveyExtName = this.$route.params.surveyExtName
        if(surveyExtName !== this.activeSurvey.extName) {
          this.$store.commit('selectSurvey', surveyExtName)
        }
        this.loadSampleData()
      },
      immediate: true
    },
  },

}
</script>
<style scoped>
</style>