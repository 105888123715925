<template>
  <v-container fluid pa-3>
    <v-row>
    </v-row>

    <v-row>
      <v-col cols="6">
        <div :id="editorId" class="editor"></div>
        Chars: {{currentValue.length}} Parts: {{calcNumberParts}}
      </v-col>
      <v-col>
        <sub-menu name='Insert variable' :menu-items='mergeTagMenuItems' @sub-menu-click='onInsertVariable'/>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import * as ace from 'brace';
import 'brace/mode/yaml';
import 'brace/theme/monokai';
import 'brace/theme/eclipse';
import SubMenu from "@/views/mailtemplates/SubMenu";

export default {
  name: 'SMSEditor',
  components: {SubMenu},

  props: {
    editorId: String,
    content: String,
    mergeTags: Object,
    showStats: Boolean,
    // maxLines: {
    //   default: 1,
    //   type: Number
    // }
  },


  data () {
    return {
      editor: Object,
      beforeContent: '',
      currentValue: ""
    }
  },

  computed: {

    mergeTagMenuItems() {
      let result = []

      if(this.mergeTags) {

        for (const [key, value] of Object.entries(this.mergeTags)) {
          let submenu = []

          for (const [, v] of Object.entries(value.mergeTags)) {
            let tag = v.value.match("\\*\\|(.*?)\\|\\*");         // *|varname|* => {{varname}}
            const insertString = tag ? '{{'+tag[1]+'}}' : v.value
            submenu.push( { name:  v.name, value: insertString} )
          }

          result.push({name: key, menu: submenu})
        }

      }

      return result
    },

    // This means that you can use up to 160 characters in your first SMS, and for each additional SMS after that,
    // the maximum limit is 153 characters if the messages are to be sent as a single SMS.
    calcNumberParts() {
      const nchars = this.currentValue.length;
      return (nchars<=160) ? 1 : 1 + Math.floor((nchars-160 + 153 -1) / 153)
    }

  },


  watch: {
    'content' (value) {
      if (this.beforeContent !== value) {
        this.editor.setValue(value, 1)
      }
    }
  },

  mounted () {
    this.editor = ace.edit(this.editorId)
    this.editor.setValue(this.content, 1)
    this.editor.setOption("wrap", true)
    this.editor.setOption("maxLines", this.maxLines)
    this.editor.setOption("printMargin", (this.maxLines==1) ? false : true)
    this.editor.setOption("showGutter", (this.maxLines==1) ? false : true)
    this.editor.getSession().setMode("ace/mode/text")
    this.editor.setTheme("ace/theme/eclipse")

    this.currentValue = ""+this.content

    this.editor.on('change', () => {
      this.beforeContent = this.editor.getValue()
      this.$emit('change-content', this.editor.getValue())
      this.currentValue = this.editor.getValue()
    })

  },

  methods: {
    onInsertVariable(v) {
        this.editor.session.insert(this.editor.getCursorPosition(), v.value)
    }
  }

}
</script>

<style>
.editor {
  width: 100%;
  height: 100px;
}
</style>
