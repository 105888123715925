<template>
  <v-container fluid v-if="gamificationdata">
    <v-row v-if="gamificationdata.awards.length===0">
      <v-col>
        <NoDataComponent :title="$t('GAMIFICATION_NO_BADGES')"/>
      </v-col>
    </v-row>

    <v-row v-else>
      <template v-for="(vardata, index) in gamificationdata.awards">
        <v-col cols="2" :key="index">
          <TopMarksComponent    v-if="vardata.type==='TopMarksAward'" :topdto="vardata"/>
          <StreakMarksComponent v-else-if="vardata.type==='StreakAward'" :streakdto="vardata"/>
        </v-col>
      </template>
    </v-row>

    <v-row>
      <v-col>
        <PerformanceChart :chart-data="gamificationdata.chartData"/>
      </v-col>
    </v-row>

  </v-container>



</template>

<script>
import PerformanceChart from "./PerformanceChart";
import ResultsService from "../../../../services/ResultsService";
import StreakMarksComponent from "./awards/StreakMarksComponent";
import TopMarksComponent from "./awards/TopMarksComponent";

import {mapGetters} from "vuex";
import NoDataComponent from "../subcomponents/NoDataComponent";

export default {
  name: 'GamificationOverviewComponent',
  components: {PerformanceChart, StreakMarksComponent, TopMarksComponent, NoDataComponent},

  data () {
    return {
      gamificationdata: null
    }
  },

  props: {
    componentData: Object,
  },

  watch: {
    $route: {
      handler() {
        this.loadData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale'])
  },

  methods: {
    loadData() {
      if(this.componentData.config) {
        ResultsService.fetchGamificationData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
          this.gamificationdata = response.data;
        })
      }
    },
  }


}
</script>

<style scoped>

.nodatacontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nodataicon {
  color: rgba(0,0,0,.7);
  margin-bottom: 30px;
}

.nodatatext {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem) !important;
  color: #000000;
}

span.title {
  /*font-size: 24px;*/
  font-size: calc((24/16)*1rem) !important;
}
</style>
