<template>
  <v-card outlined>
    <v-card-title>Events</v-card-title>

    <v-container fluid>
      <v-row>
        <v-col>

          <v-simple-table>
            <thead>
            <tr>
              <th class="text-left">Type</th>
              <th class="text-left">Date</th>
              <th class="text-left">Message</th>
              <th class="text-left">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(scriptEvent, idx) in scriptEventList" :key="scriptEvent.id">
              <td>{{scriptEvent.eventType}}</td>
              <td>{{scriptEvent.updated}}</td>
              <td>{{scriptEvent.message}}</td>
              <td>
                <v-btn icon @click.stop="onDeleteItem(scriptEvent, idx)">  <v-icon small>mdi-delete</v-icon></v-btn>
              </td>
            </tr>

            <tr v-if="scriptEventList.length===0">
              <td colspan="4"></td>
            </tr>
            </tbody>
          </v-simple-table>

          <v-btn v-if="scriptEventList.length>0" @click="onDeleteAll">delete all</v-btn>
        </v-col>
      </v-row>


    </v-container>

  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import SurveysService from "@/services/SurveysService";
import ScriptsService from "@/services/ScriptsService";

export default {
  name: 'ScriptEventList',

  components: {},

  data: () => ({
    taskList: [],
    taskState: "",
    taskType: "",
    assignState: ""
  }),



  props: {
    scriptBlockId: Number,
    scriptEventList: Array
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey']),
  },


  methods: {
    onDeleteItem(scriptEvent, idx) {
      ScriptsService.deleteScriptEvent(this.getAuthToken, this.getActiveSurvey.extName, scriptEvent.id).then(() => {
        this.scriptEventList.splice(idx, 1)
      })
    },

    onDeleteAll() {
      ScriptsService.deleteAllScriptEvents(this.getAuthToken, this.getActiveSurvey.extName, this.scriptBlockId).then(() => {
        this.scriptEventList=[]
      })

    },

  }


}
</script>

<style scoped>
</style>