// import store from '@/store'


export default {

    /**
     * Track navigation with google analytics.
     *
     * @param source The source VueComponent. Used to get $ga (google analytics).
     * @param pageExtname The pageName.
     */
    trackNavigation(source, pageExtname) {
        // console.log("Tracking src=", source, " pageExtname=", pageExtname)
        if(this.isTrackingEnabled()) {
            source.$ga.page(pageExtname)
        }
    },

    /**
     * Tracks the event of changing survey / metainterview.
     *
     * @param source The source VueComponent.
     * @param survey The survey to change to.
     */
    trackChangeSurveyEvent(source, survey) {
        // console.log(source, survey)
        if (this.isTrackingEnabled()) {
            source.$ga.event({
                eventCategory: 'survey',
                eventAction: 'change',
                eventLabel: survey.extName,
                eventValue: 1
            })
        }
    },

    /**
     * Is tracking enabled
     *
     * @returns {boolean} true if tracking is enabled; false otherwise.
     */
    isTrackingEnabled() {
        let trackingEnabled = false
        switch (window.location.hostname) {
            case 'dashboard.aganalytics.dk':
            case 'vgs.aganalytics.se':
            case 'stagingvgs.aganalytics.dk':
            case 'stagingdb.aganalytics.dk':
            case 'stagingftz.aganalytics.dk':
            case 'ftz.auto-flows.com':
            case 'dashboard.auto-flows.com':
                trackingEnabled = true
                break
            default:
                trackingEnabled = false
        }
        // console.log(trackingEnabled)
        return trackingEnabled
    },

    /**
     * Gets Google Analytics tracking ID.
     * @returns {string} The tracking ID to use.
     */
    getTrackingId() {
        let trackingId = null
        switch (window.location.hostname) {
            case 'dashboard.aganalytics.dk':
                trackingId = 'UA-51143741-1'
                break
            case 'vgs.aganalytics.se':
                trackingId = 'UA-51143741-2'
                break
            case 'ftz.auto-flows.com':
                trackingId = 'UA-51143741-3'
                break
            case 'dashboard.auto-flows.com':
                trackingId = 'UA-51143741-7'
                break
            case 'stagingvgs.aganalytics.dk':
                trackingId = 'UA-51143741-5'
                break
            case 'stagingdb.aganalytics.dk':
                trackingId = 'UA-51143741-4'
                break
            case 'stagingftz.aganalytics.dk':
                trackingId = 'UA-51143741-6'
                break
            default:
                trackingId = 'UA-51143741-1'
        }
        // console.log(trackingId)
        return trackingId
    }

}