import backend from '@/services/backend'

export default {

  fetchCompoundMailTemplatesListData (authToken, surveyName, configId) {
    return backend.get('vuejs/mails/compoundtemplates/' + surveyName + '/' + configId, { headers: { Authorization: authToken } })
  },

  fetchLocalMailTemplatesList (authToken, surveyName, sendingType, mailActionName) {
    return backend.get('vuejs/mails/templates/' + surveyName + '?sendingType='+sendingType + "&mailAction="+mailActionName, { headers: { Authorization: authToken } })
  },


  fetchMailTemplateData(authToken, surveyName, templateName, mailAction) {
    return backend.get('vuejs/mails/template/' + surveyName + '/' + templateName + '?mailAction='+mailAction, { headers: { Authorization: authToken } })
  },

  fetchLetterTemplateData(authToken, surveyName, templateName, mailAction) {
    return backend.get('vuejs/mails/template/' + surveyName + '/' + templateName + '?mailAction='+mailAction, { headers: { Authorization: authToken } })
  },

  fetchAvailableLocales(authToken, surveyName) {
    return backend.get('vuejs/mails/locales/' + surveyName, { headers: { Authorization: authToken } })
  },

  fetchTemplateTags(authToken, surveyName) {
    return backend.get('vuejs/mails/templatetags/' + surveyName, { headers: { Authorization: authToken } })
  },

  fetchSMSTemplateData(authToken, surveyName, id) {
    return backend.get('vuejs/sms/template/' + surveyName + '/' + id, { headers: { Authorization: authToken } })
  },

  deleteSMSTemplate(authToken, surveyName, id) {
    return backend.delete('vuejs/sms/template/' + surveyName + '/' + id, { headers: { Authorization: authToken } })
  },

  sendTestSMS(authToken, surveyName, phoneNumber, contents) {
    return backend({ method: 'post', url: 'vuejs/sms/template/testsms/' + surveyName, data: { phoneNumber: phoneNumber, contents: contents }, headers: { Authorization: authToken } });
  },


  createMailTemplate(authToken, surveyName, templateDetails, testmail) {
    const mailparam = testmail ? "?testmail="+testmail : ""
    return backend({ method: 'post', url: 'vuejs/mails/template/create/' + surveyName + mailparam, data: templateDetails,  headers: { Authorization: authToken } })
  },

  updateMailTemplate(authToken, surveyName, templateDetails, testmail) {
    const mailparam = testmail ? "?testmail="+testmail : ""
    return backend({ method: 'put', url: 'vuejs/mails/template/update/' + surveyName + mailparam, data: templateDetails,  headers: { Authorization: authToken } })
  },

  createSMSTemplate(authToken, surveyName, templateDetails, testsms) {
    const smsparam = testsms ? "?testsms="+testsms : ""
    return backend({ method: 'post', url: 'vuejs/sms/template/create/' + surveyName + smsparam, data: templateDetails,  headers: { Authorization: authToken } })
  },

  createLetterTemplate(authToken, surveyName, templateDetails) {
    return backend({ method: 'post', url: 'vuejs/mails/template/createletter/' + surveyName, data: templateDetails,  headers: { Authorization: authToken } })
  },

  updateLetterTemplate(authToken, surveyName, templateDetails) {
    return backend({ method: 'put', url: 'vuejs/mails/template/updateletter/' + surveyName, data: templateDetails,  headers: { Authorization: authToken } })
  },

  updateSMSTemplate(authToken, surveyName, templateDetails, testsms) {
    const smsparam = testsms ? "?testsms="+testsms : ""
    return backend({ method: 'put', url: 'vuejs/sms/template/update/' + surveyName + smsparam, data: templateDetails,  headers: { Authorization: authToken } })
  },

  deleteMailTemplate(authToken, surveyName, templateName) {
    return backend.get('vuejs/mails/template/delete/' + surveyName + '/' + templateName, { headers: { Authorization: authToken } })
  },

  fetchMailCampaignsListData (authToken, surveyName, configId, drillPath) {
    return backend.get('vuejs/mailcampaign/list/' + surveyName + '/' + configId + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchMailCampaignData(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  updateMailCampaignData(authToken, surveyName, campaignId, campaignData) {
    return backend({ method: 'put', url: 'vuejs/mailcampaign/' + surveyName + '/' + campaignId, data: campaignData,  headers: { Authorization: authToken } })
  },

  fetchMailTrackingRows(authToken, surveyName, campaignId, configId, from, to, sortBy, sortDesc, filter) {
    return backend.get('vuejs/mailcampaign/trackings/' + surveyName + '/' + campaignId + '/' + configId
        + "?from="+from + "&to="+to + "&sortBy="+sortBy + "&sortDesc="+sortDesc + "&filter="+filter,  { headers: { Authorization: authToken } })
  },

  deleteDuplicatesFromMailCampaign(authToken, surveyName, campaignId) {
    return backend.delete('vuejs/mailcampaign/deleteduplicates/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  deleteMailTracking(authToken, surveyName, campaignId, trackingIds) {
    return backend.delete('vuejs/mailcampaign/trackings/' + surveyName + '/' + campaignId + "?ids=" + trackingIds.join(","), { headers: { Authorization: authToken } })
  },

  fetchRenderedMailData(authToken, surveyName, campaignId, trackingId, templateRef, mailAction) {
    return backend.get('vuejs/mailcampaign/rendermail/' + surveyName + '/' + campaignId + '/' + templateRef
        + '?mailAction='+mailAction + (trackingId ? '&trackingId='+trackingId : ''), { headers: { Authorization: authToken } })
  },

  fetchRenderedLetterTemplateData(authToken, surveyName, templateId) {
    return backend.get('vuejs/letteruploader/rendertemplate/' + surveyName + '/' + templateId, { headers: { Authorization: authToken } })
  },

  fetchRenderedLetterData(authToken, surveyName, campaignId, trackingId, templateRef, mailAction) {
    return backend.get('vuejs/letteruploader/renderletter3/' + surveyName + '/' + campaignId + '/' + templateRef
        + '?mailAction='+mailAction + (trackingId ? '&trackingId='+trackingId : ''), { headers: { Authorization: authToken } })
  },


  startMailCampaign(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/start/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  stopMailCampaign(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/stop/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  deleteMailCampaign(authToken, surveyName, campaignId) {
    return backend.delete('vuejs/mailcampaign/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },


  sendMails(authToken, surveyName, campaignId, trackingIds) {
    return backend.get('vuejs/mailcampaign/sendmail/' + surveyName + '/' + campaignId + "?ids=" + trackingIds.join(","), { headers: { Authorization: authToken } })

  },

  fetchUnlayerTemplates(authToken, surveyName, configId) {
    return backend.get('vuejs/unlayer/templates/' + surveyName + '/' + configId, { headers: { Authorization: authToken } })
  },

  fetchUnlayerTemplate(authToken, surveyName, templateId) {
    return backend.get('vuejs/unlayer/template/' + surveyName + '/' + templateId, { headers: { Authorization: authToken } })
  },

  uploadUnlayerFile(authToken, surveyName, file) {
    let formData = new FormData();

    for (var i = 0; i < file.attachments.length; i++) {
      formData.append('files', file.attachments[i]);
    }

    return backend({ method: 'post', url: 'vuejs/unlayer/uploadfile/' + surveyName, data: formData,
      headers: { Authorization: authToken, 'Content-Type': 'multipart/form-data' } })
  },

  fetchUnlayerImages(authToken, surveyName) {
    return backend.get('vuejs/unlayer/images/' + surveyName, { headers: { Authorization: authToken } })
  },

  deleteUnlayerFile(authToken, surveyName, imageId) {
    return backend.delete('vuejs/unlayer/images/' + surveyName + '/' + imageId, { headers: { Authorization: authToken } })
  },

  fetchUnlayerMergeTags(authToken, surveyName) {
    return backend.get('vuejs/unlayer/mergetags/' + surveyName, { headers: { Authorization: authToken } })
  },

  fetchUnlayerEditorOptions(authToken, surveyName) {
    return backend.get('vuejs/unlayer/editoroptions/' + surveyName, { headers: { Authorization: authToken } })
  },


  // new campaign creation wizard
  fetchCreateCampaignData(authToken, surveyName, mailActionName, filteredDrillPath, selectedFilters, deletedRecipients, sendingType, initial) {
    return backend.get('vuejs/mailcampaign/createcampaigndata/' + surveyName + '/' + mailActionName + '/' + filteredDrillPath +
        "?filters="+selectedFilters.join()
        + "&deletedRecipients="+deletedRecipients.join()
        + "&sendingType="+sendingType
        + "&initial="+initial,
        { headers: { Authorization: authToken } })
  },

  fetchCampaignConfiguration(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/campaignconfiguration/'+ surveyName + '/' + campaignId, { headers: { Authorization: authToken }});
  },

  fetchNewMailTrackingRows(authToken, surveyName, mailActionName, filteredDrillPath, sendingType, from, to, sortBy, sortDesc, textFilter, selectedFilters, deletedRecipients, fixedRecipient) {

    return backend.get('vuejs/mailcampaign/newtrackings/' + surveyName + '/' + mailActionName + '/' + filteredDrillPath +
        "?sendingtype="+sendingType+ "&from="+from + "&to="+to + "&sortBy="+sortBy + "&sortDesc="+sortDesc + "&textfilter="+textFilter
        + "&filters="+selectedFilters.join()
        + "&deletedRecipients="+deletedRecipients.join()
        + "&fixedrecipient="+fixedRecipient,
        { headers: { Authorization: authToken } })
  },

  startMailCampaignNow(authToken, surveyName, mailActionName, filteredDrillPath, campaignName, campaignExtName, sendingType, templateId, fromName, fromEmail, selectedFilters, deletedRecipients, scheduledStartDateTime, scheduledPeriod, pushPullType, bcc, replyTo, fixedRecipient, scheduleParams) {
    return backend({ method: 'post', url: 'vuejs/mailcampaign/startcampaignnow/' + surveyName + '/' + mailActionName + '/' + filteredDrillPath
          + "?filters="+selectedFilters.join()
          + "&deletedRecipients="+deletedRecipients.join(),
      data: { campaignName: campaignName, campaignExtName: campaignExtName, sendingType: sendingType, templateId : templateId, fromName: fromName, fromEmail: fromEmail, scheduledStartDateTime: scheduledStartDateTime, scheduledPeriod: scheduledPeriod, campaignType: pushPullType, bcc: bcc, replyTo: replyTo, fixedRecipient: fixedRecipient, scheduleParams: scheduleParams },
      headers: { Authorization: authToken } });
  },


  fetchMailCampaignName(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/name/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  pauseCampaign(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/pause/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  resumeCampaign(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/resume/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  fetchGlobalCampaignsListData(authToken, surveyName, configId) {
    return backend.get('vuejs/mailcampaign/globalcampaignslist/' + surveyName + '/' + configId, { headers: { Authorization: authToken } })
  },

  fetchLetterTemplateHelpData(authToken, surveyName, filteredDrillPath) {
    return backend.get('vuejs/mailcampaign/lettertemplatehelpdata/' + surveyName + '/' + filteredDrillPath, { headers: { Authorization: authToken } })
  },

}

