<template>

  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-text-field disabled :value="textData.key" label="localizedtext"/>
      </v-col>

      <v-col cols="10" v-for="(ltext, idx) in textData.localeTextList" :key="idx">
        <v-text-field v-model="ltext.text" :label="ltext.locale"/>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import {mapGetters, mapState} from "vuex";
import SurveysService from "@/services/SurveysService";

export default {
  name: 'MetaTextComponent',

  components: {},

  props: {
    textData: Object
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),
  },

  methods: {

    onSave() {
      SurveysService.saveMetaProperty(this.getAuthToken, this.activeSurvey.extName, this.mdpLive).then((response) => {
        this.mdpLive = response.data
      })

    }


  }

}
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 25%;
}

</style>