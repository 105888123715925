<template>

    <v-container fluid> <!-- new layout -->
      <v-card flat tile>
        <v-row
            no-gutters
            align="start"
            justify="start"
            dense
        >
          <v-col
              sm="4"
              md="3"
              lg="2"
              class="vMenuColumn"
          >
            <v-card flat tile>
              <div>
                <div v-for="(resultBlock, idx) in resultBlocks" :key="resultBlock.menuTitle" :class="getMenuClass(idx)"
                     @click="changeSelectedIdx(idx)">
                  <div class="menuText pa-2">
                    {{ resultBlock.menuTitle }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col
              sm="8"
              md="9"
              lg="10"
          >
            <div v-for="(resultBlock, idx) in resultBlocks" :key="resultBlock.menuTitle">
              <v-card flat tile v-if="isMenuSelected(idx)" class="pa-2">
                <table class="result-block-table" >
                  <thead>
                  <tr class="mainHeader">
                    <th colspan="2">{{'ACTION_QUESTION' | i18n}}</th>
                    <th>{{summeryData.selectionTime}}</th><!-- TODO translate headers and get periods from somewhere -->
                    <th>{{summeryData.trendTime }}</th>
                    <th>{{'CSI_DEVELOPMENT' | i18n}}</th>
                    <th>{{'GOAL_TOOLTIP' | i18n}}</th>
                    <th>{{'STATUS' | i18n}}</th>
                  </tr>
                  <tr class="subHeader">
                    <th colspan="2"></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="row in rows"
                      v-show="row.visible"
                      :key="row.extName"
                      @click="toggleRow(row)"
                      :class="{
                        'groupHeader' : !!row.group,
                        'showBorderBottom': !!row.showBorderBottom
                      }">
                    <td>
                      <v-icon v-if="row.icon">{{row.icon}}</v-icon>
                    </td>
                    <td style="width: 50%">
                      <span class="qa-space" v-if="row.parentGroup">&nbsp;</span>
                      {{row.title }}
                    </td>
                    <td>{{ row.valueSelection }}</td>
                    <td>{{ row.valueTrend }}</td>
                    <td>{{ row.development }}</td>
                    <td> <v-icon v-if="row.target" size="16px">mdi-target</v-icon>   {{ row.target }}</td>
                    <td> <v-icon v-if="row.target" size="16px" :color="statusColor(row.status)">{{statusIcon(row.status)}}</v-icon> </td>
                  </tr>
                  </tbody>
                </table>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import ResultsService from "../../../services/ResultsService";
// import SummaryQuestionGroupComponent from "./subcomponents/SummaryQuestionGroupComponent";
// import SummaryQuestionAnswerComponent from "@/views/pages/pagecomponents/subcomponents/SummaryQuestionAnswerComponent";

export default {
  name: 'ResultsBlockComponent',
  components: {
    // SummaryQuestionGroupComponent, SummaryQuestionAnswerComponent
  },

  data () {
    return {
      groups: [],
      model: 0,
      selectedMenuItem: null,
      summeryData: null,
      selectedIdx: 0,
      resultBlocks: [],
      rows: [],
      periodSelection: 'July 2020 - May - 2020',
      periodTrend: 'Apr 2020 - Feb - 2020'
    }
  },

  props: {
    componentData: {
      type: Object,
      required: true
    }
  },

  watch: {
    $route: {
      handler() {
        this.loadData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    showBusinessDrivers() {
      return this.summeryData && this.summeryData.driverGroups.length>0
    },

    showSatisfactionLoyalty() {
      return this.summeryData && this.summeryData.loyaltySatisfactionGroups.length>0
    },

  },

  methods: {
    loadData() {
      ResultsService.fetchSummaryResultsData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
        this.summeryData = response.data;
      }).finally(() => {
        let resultBlocks = []
        if (this.showBusinessDrivers) {
          resultBlocks.push({
            menuTitle: this.$t('BUSINESS_DRIVERS'),
            type: 'BUSINESS_DRIVERS',
            groups: this.summeryData.driverGroups,
            numericQuestionAnswers: []
          })
        }
        if (this.showSatisfactionLoyalty) {
          resultBlocks.push({
            menuTitle: this.$t('SATISFACTION_AND_LOYALTY'),
            type: 'SATISFACTION_AND_LOYALTY',
            groups: this.summeryData.loyaltySatisfactionGroups,
            numericQuestionAnswers: []
          })
        }
        for (let i = 0; i < this.summeryData.resultGroups.length; i++) {
          const resultGroup = this.summeryData.resultGroups[i]
          resultBlocks.push({
            menuTitle: resultGroup.title,
            type: 'RESULT_GROUP',
            resultGroup: resultGroup,
            groups: resultGroup.questionAnswers,
            numericQuestionAnswers: resultGroup.numericQuestionAnswers
          })
        }
        this.resultBlocks = resultBlocks
        this.changeSelectedIdx(0)
      })
    },
    getMenuClass(idx) {
      return this.isMenuSelected(idx) ? 'vMenuActiveItem' : 'vMenuItem'
    },
    isMenuSelected(idx) {
      return this.selectedIdx === idx
    },

    changeSelectedIdx(idx) {
      this.selectedIdx = idx
      const newRows = []
      const resultBlock = this.resultBlocks [idx];

      if(resultBlock) {
        for (const group of resultBlock.groups) {
          newRows.push({
            extName: group.extName,
            parentGroup: null,
            group: group,
            questionAnswer: null,
            visible: true,
            childsVisible: false,
            icon: 'mdi-chevron-down',
            title: group.title,
            valueSelection: group.value,
            valueTrend: group.valueTrend,
            development: group.diff,
            target: group.goal,
            status: group.goalStatus
          })
          for (const qa of group.questionAnswers) {
            newRows.push({
              extName: qa.extName,
              parentGroup: group,
              group: null,
              questionAnswer: qa,
              visible: false,
              icon: null,
              title: qa.title,
              valueSelection: qa.value,
              valueTrend: qa.valueTrend,
              development: qa.diff,
              target: qa.goal,
              status: qa.goalStatus
            })
          } // endfor questionAnswers
        } // endfor groups

        for (const na of resultBlock.numericQuestionAnswers) { // TODO [tho] - Do we have any example data?
          newRows.push({
            extName: na.extName,
            parentGroup: null,
            group: null,
            numericAnswer: na,
            visible: true,
            icon: null,
            title: na.title,
            valueSelection: na.value,
            valueTrend: na.valueTrend,
            development: na.diff,
            target: na.goal,
            status: na.goalStatus
          })
        }

        // if last row is hidden child group we need a way to set a border to the group if it's not folded.
        let lastRowWithGroup = null
        for (const row of newRows) {
          if (row.group) {
            lastRowWithGroup = row
          } else {
            if (lastRowWithGroup && lastRowWithGroup.group !== row.parentGroup) {
              lastRowWithGroup = null;
            }
          }
        }
        if (lastRowWithGroup) {
          lastRowWithGroup['lastGroupRow'] = true
          lastRowWithGroup['showBorderBottom'] = true
        }
        this.rows = newRows

      }

    },

    toggleRow(row) {
      if (row.group) {
        row.childsVisible = ! row.childsVisible
        if (row.lastGroupRow) {
          row.showBorderBottom = !row.childsVisible
        }
        row.icon = row.childsVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'
        for (const curRow of this.rows) {
          if (curRow.parentGroup === row.group) {
            curRow.visible = row.childsVisible
          }
        }
      }
    },

    statusIcon(status) {
      return status ? "mdi-check" : "mdi-close"
    },

    statusColor(status) {
      return status ? "#6bc2a2" : "#a73a3e"
    }

  }
}
</script>

<style scoped>
.singletab {
  text-transform: capitalize;
}


div.vMenuItem {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-left: #ffffff 4px solid;
}

div.vMenuActiveItem {
  color: #0000c8 !important;
  background-color: #e1e1f9;
  border-left: #0d47a1 4px solid !important;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-left: #ffffff 4px solid;
}

div.menuText {
  margin-top: 2px;
  /*font-size: 15px;*/
  font-size: calc((15/16)*1rem);
}

.vMenuColumn {
  border-right: 1px solid #ccc !important;
}

.qa-space {
  margin-left: 0.5em;
}

table.result-block-table {
  width: 100%;
  text-align: left;
  font-family: Roboto,serif;
  border-collapse: collapse;
  margin: 10px 10px 10px 10px;
}
table.result-block-table thead th {
  /*text-align: left;*/
  /*font-family: Roboto,serif;*/
  /*font-size: 13px;*/
  font-size: calc((13/16)*1rem);
  color: rgba(0,0,0,.54)
}
table.result-block-table thead tr.subHeader th {
  /*font-size: 10px;*/
  font-size: calc((10/16)*1rem);
}

table.result-block-table tr.hiddenRow {
  display: none;
}

table.result-block-table tbody td {
  padding-top: 10px;
  padding-bottom: 10px;
}

tr.groupHeader {
  cursor: pointer;
  border-top: 1px solid #ccc;
}

table.result-block-table tr:last-child, table.result-block-table tr.showBorderBottom {
  border-bottom: 1px solid #ccc;
}

</style>
