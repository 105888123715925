<template>

  <v-container fluid>
    <v-row style="background-color: #eeeeee">
      <v-col cols="2" >
        <v-text-field disabled label="choice"/>
      </v-col>

      <v-col cols="1">
        <v-text-field v-model="cdata.extname" label="extname"/>
      </v-col>

      <v-col cols="8">
        <v-text-field v-model="cdata.features" label="features"/>
      </v-col>

      <v-col cols="1" align-self="center">
        <v-btn @click="onSave()" icon>
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <MetaTextComponent :text-data="cdata.title"/>
    </v-row>
  </v-container>

</template>

<script>

import {mapGetters, mapState} from "vuex";
import MetadataService from "@/services/MetadataService";
import MetaTextComponent from "@/views/backend/MetaTextComponent";

export default {
  name: 'MetaChoiceComponent',

  components: { MetaTextComponent },

  props: {
    cdata: Object
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),
  },

  methods: {

    onSave() {
      MetadataService.saveChoice(this.getAuthToken, this.activeSurvey.extName, this.cdata).then(() => {
        // console.log(response.data)
      })

    }


  }

}
</script>

<style scoped>
</style>