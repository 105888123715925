<template>
  <v-card outlined>
    <v-card-title  :class="{ openTitle: isOpen, solvedTitle: isSolved, processingTitle: isProcessing }" >
      <v-container  class="pa-0" fluid>
        <v-row justify="space-between" align="center">
          <v-col class="pa-2">
            {{'ALERT' | i18n}}
          </v-col >
          <v-col class="pa-2 text-right">

            <div class="flex-body white--text">
              <div class="flex-row">
                <div><v-icon color="white">mdi-check-circle</v-icon></div>
              </div>
              <div class="flex-column">
                <div>{{alertData.stateText}}</div>
                <div>{{alertData.closedDate}}</div>
              </div>
            </div>

          </v-col>
        </v-row >
      </v-container>
    </v-card-title>

    <v-container class="pa-2" fluid>
      <v-row >
        <v-col cols="4">
          <v-switch  :disabled="!canModify"  v-model="alertBean.isClosed" :label="$t('CLOSED')"></v-switch>
          <div class="grey--text subtitle-1">{{'INFORMATION' | i18n}}</div>
          <div>{{alertData.alertInfo}}</div>
          <AlertQuestionComponent :can-modify="canModify" class="pt-5" v-for="q in alertData.questions" :key="q.questionId" :question-data="q" ref="questionComponents"/>
        </v-col>
        <v-col cols="8">

          <v-container fluid>
            <v-row >
              <v-col cols="6">
                {{'COMMENTS' | i18n}}
              </v-col>
              <v-col cols="6" class="text-right">
                <AlertHistoryDialog :alert-data="alertData"/>
              </v-col>
            </v-row >

            <v-row v-if="alertData.comments">
              <v-col>
                <div>{{'LATEST_COMMENT' | i18n}}</div>
                <v-textarea :disabled="true" solo v-model="alertData.comments"> </v-textarea>
              </v-col>
            </v-row >


            <v-row v-if="canModify">
              <v-col>
                <div>{{'ADD_NEW_COMMENT' | i18n}}</div>
                <v-textarea :disabled="!canModify" solo v-model="alertBean.newComment" :error-messages="errorMessage"> </v-textarea>
              </v-col>
            </v-row >



            <v-row >
              <v-col>
                <div class="text-right">
                  <v-btn text @click="onCancel">{{'CANCEL' | i18n}}</v-btn>
                  <v-btn v-if="canModify" text @click="onSave">{{'SAVE' | i18n}}</v-btn>
                  <v-btn v-if="showCloseAndSave" color="primary" @click="onCloseSave">{{'CLOSESAVE' | i18n}}</v-btn>
                </div>
              </v-col>
            </v-row >
          </v-container>

        </v-col>
      </v-row>

    </v-container>

  </v-card>
</template>

<script>

import AlertQuestionComponent from "./AlertQuestionComponent";
import AlertHistoryDialog from "./AlertHistoryDialog";
import {mapGetters} from "vuex";
import PageNavigator from "../../pagenavigator";

export default {
  name: 'AlertTopComponent',

  components: {
    AlertQuestionComponent, AlertHistoryDialog
  },

  data: () => ({
    alertBean: {
      newComment: "",
      isClosed: false,
      selections: []
    },

    errorMessage: []
  }),



  props: {
    alertData: Object
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    showCloseAndSave() {
      return this.alertData.enableCloseAndSave && this.alertData.canModify
    },

    isOpen() {
      return this.alertData.alertState===2
    },

    isSolved() {
      return this.alertData.alertState===3
    },

    isProcessing() {
      return this.alertData.alertState===6
    },

    canModify() {
      return this.alertData.canModify
    }

  },

  created() {
    this.alertBean.isClosed = this.alertData.alertState===3
  },

  methods: {
    onCancel() {
      PageNavigator.navigateToAlertsPage(this, this.getActiveSurvey.extName, this.getFilteredDrillPath)
    },

    onCloseSave() {
      this.alertBean.isClosed = true
      this.onSave()
    },

    onSave() {

      if(this.alertBean.isClosed) {
        let validated = true;

        if(!this.alertBean.newComment || !this.alertBean.newComment.trim()) {
          this.errorMessage = ["Mandatory"]
          validated = false;
        } else {
          this.errorMessage = []
        }

        if(this.$refs.questionComponents) {
          for(const qcomp of this.$refs.questionComponents) {
            validated &= qcomp.validateMandatory()
          }
        }

        if(!validated) {
          // console.log("Nope not submitting")
          return;
        }
      }


      this.alertBean.selections = []

      for(const q of this.alertData.questions) {
        this.alertBean.selections.push({ questionId: q.questionId, choiceId: q.selected, value: q.value})
      }

      // console.log(this.alertBean)

      this.$emit('save', this.alertBean)
    }
  }


}
</script>
<style scoped>
.solvedTitle {
  background-color: #37c796;
}

.openTitle {
  background-color: #C00000;
  color: white;
}

.processingTitle {
  background-color: #FFC107;
}

.flex-row {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.flex-body {
  justify-content: flex-end;
  display: flex;
}
</style>