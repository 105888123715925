<template>

  <v-container fluid class="wrapper elevation-2 d-flex flex-column align-center justify-space-between">
    <div class="topContainer">
      <div class="title text-center">{{'GAMIFICATION_TOP_MARKS_ROW' | i18n}}</div>
      <SlidingGamificationCounterComponent class="counterComponent" :end-value="topdto.obtained.toString()"/>
    </div>

    <div class="totalScore">{{'GAMIFICATION_ALL_TIME_BEST' | i18n}}: {{topdto.score}}</div>
  </v-container>

</template>

<script>
import SlidingGamificationCounterComponent from "./SlidingGamificationCounterComponent";

export default {
  name: 'TopMarksComponent',
  components: {SlidingGamificationCounterComponent},

  props: {
    topdto: Object
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  background-color: white;
  height: 100%;
  border-radius: 5px;
}

div.title {
  margin-top: 10px;
  /*font-size: 24px !important;*/
  /*font-size: 18px !important;*/
  font-size: calc((18/16)*1rem) !important;
  font-weight: 400;
  color: rgba(0,0,0,0.87);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.totalScore {
  /*font-size: 13px !important;*/
  font-size: calc((13/16)*1rem) !important;
  color: rgba(0,0,0,0.87);
  margin-bottom: 10px;
}

.topContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.counterComponent {
  margin-top: 36px;
}


</style>
