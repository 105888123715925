<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MetaInterviewComponent/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <MetadataComponent/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ScriptBlockList/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TaskList/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <EffectBlockList/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
// import ConfigBlockList from "@/views/backend/ConfigBlockList";
import MetadataComponent from "@/views/backend/MetadataComponent";
import EffectBlockList from "@/views/backend/EffectBlockList";
import ScriptBlockList from "@/views/backend/ScriptBlockList";
import TaskList from "@/views/backend/TaskList";
import MetaInterviewComponent from "@/views/backend/MetaInterviewComponent";
// import PageNavigator from "@/pagenavigator";

export default {
  name: 'SurveyDetails',

  data () {
    return {
      surveyDataList: [],
      configBlocks: [],
    }
  },

  components: {
    MetaInterviewComponent,
    TaskList,
    ScriptBlockList,
    EffectBlockList,
    MetadataComponent,
//    ConfigBlockList
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey']),
  },

  methods: {
    // initialFetchData() {
    //   SurveysService.fetchMetaData(this.getAuthToken, this.activeSurvey.extName, this.$route.params.surveyId).then((response) => {
    //     console.log(response.data)
    //   })
    // },

  },

}
</script>
<style scoped>
div.sectionTitle {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
}
</style>

