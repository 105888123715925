<template>
  <v-card outlined>
    <v-card-title class="primary white--text justify-center" >{{ title }}</v-card-title>
    <v-container class="py-2 px-4" fluid>
      <v-row>
        <v-col cols="2">

          <VariableComponent v-if="a1" :label="a1.label" :value="a1.value" class="pb-3" />
          <VariableComponent v-if="a3" :label="a3.label" :value="a3.value" class="pb-3"/>

        </v-col>
        <v-col cols="2">
          <VariableComponent v-if="a2" :label="a2.label" :value="a2.value" class="pb-3" align="right"/>
          <VariableComponent v-if="a4" :label="a4.label" :value="a4.value" class="pb-3" align="right"/>
        </v-col>
        <v-col cols="4">
          <v-container class="pa-0">

            <v-col cols="12" class="facecol pa-0">
              <v-icon size="5em">{{faceIcon}}</v-icon>
            </v-col>

            <v-col cols="12" class="facecol subTitle pa-0">
              {{ subTitle }}
            </v-col>

            <v-col cols="12" class="facecol pa-0">
              <span :class="ratingStyle">
                {{interviewData.sidebarData.rating}}
              </span>
            </v-col>

          </v-container>


        </v-col>
        <v-col cols="2">
          <VariableComponent v-if="a5" :label="a5.label" :value="a5.value" class="pb-3" />
          <VariableComponent v-if="a7" :label="a7.label" :value="a7.value" class="pb-3"/>
        </v-col>

        <v-col cols="2">
          <div class="text-right">
            <v-menu offset-y v-if="interviewData.enableSharing">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ "SHARE_ON_SOCIAL_MEDIA" | i18n }} <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list class="shareMenu">
                <v-list-item @click="onShareFacebookClicked">
                  <v-list-item-title>Facebook</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onShareLinkedInClicked">
                  <v-list-item-title>LinkedIn</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <VariableComponent v-if="a6" :label="a6.label" :value="a6.value" class="pb-3" />
          <VariableComponent v-if="a8" :label="a8.label" :value="a8.value" class="pb-3"/>
        </v-col>
      </v-row>

    </v-container>
  </v-card>
</template>

<script>
import VariableComponent from "./VariableComponent";
import InterviewsService from "@/services/InterviewsService";
import {mapGetters} from "vuex";
export default {
  name: "InterviewHeader",
  components: {VariableComponent},
  props: [ 'interviewData' ],
  data () {
    return {
      title: null,
      subTitle: null,
      faceIcon: null,
      rating: null,
      answers: []
    }
  },
  created() {
    // console.log('Created header', this.interviewData)
    this.title = this.interviewData.sidebarData.header
    this.subTitle = this.interviewData.headerVariableTitle //'Customer Satisfaction'
    this.faceIcon = this.interviewData.sidebarData.faceIcon
    this.rating = this.interviewData.sidebarData.rating
    this.answers = this.interviewData.sidebarData.bgAnswers
  },
  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    a1: function () {
      return this.answers.length > 0 ? this.answers[0] : null
    },
    a2: function () {
      return this.answers.length > 1 ? this.answers[1] : null
    },
    a3: function () {
      return this.answers.length > 2 ? this.answers[2] : null
    },
    a4: function () {
      return this.answers.length > 3 ? this.answers[3] : null
    },
    a5: function () {
      return this.answers.length > 4 ? this.answers[4] : null
    },
    a6: function () {
      return this.answers.length > 5 ? this.answers[5] : null
    },
    a7: function () {
      return this.answers.length > 6 ? this.answers[6] : null
    },
    a8: function () {
      return this.answers.length > 7 ? this.answers[7] : null
    },
    ratingStyle: function () {
      if(!this.isCompleted) {
        return 'rating-score'
      }

      return this.interviewData.sidebarData.rating && this.isNumberRating(this.interviewData.sidebarData.rating) ? 'rating-score' : 'rating-yellow'
    }
  },

  methods: {
    shareOnSocialMedia(shareType) {

      let surveyExtName = this.$route.params.surveyExtName
      let interviewId = this.$route.params.interviewId

      InterviewsService.shareInterview(this.getAuthToken, surveyExtName, interviewId, shareType).then((response) => {
        let sharingURL = response.data.sharingURL
        let publishUrl = response.data.publishUrl;
        let url = sharingURL.includes('localhost:') ? sharingURL : publishUrl
        window.open(url, '_blank').focus();
      })
    },

    onShareFacebookClicked() {
      this.shareOnSocialMedia('FACEBOOK')
    },

    onShareLinkedInClicked() {
      this.shareOnSocialMedia('LINKEDIN')
    },

    isNumberRating(rating) {
      var matches = rating && rating.match(/\d+/g);
      return matches
    },

    isCompleted() {
      return this.interviewData.isCompleted
    }

  }
}
</script>

<style scoped>
.facecol {
  text-align: center;
}
.rating-yellow {
  color: gold;
  font-size: 2em;
}
.rating-score {
  font-size: 2em;
}
</style>