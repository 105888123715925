import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import AnalyticsService from "./services/AnalyticsService";
import VueAnalytics from 'vue-analytics'
import './assets/css/main.css';
import ClientConfig from './clientconfig'


import VueCookies from 'vue-cookies'
import Highcharts from 'highcharts';
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

import VueSSE from 'vue-sse';
Vue.use(VueSSE);

import VueKeyCloak from '@dsb-norge/vue-keycloak-js'

import backend from '@/services/backend'


Highcharts.setOptions({
  exporting: {
    enabled: true,
    buttons: {
      contextBotton: {
        enabled: true,
        menuItems: ["printChart", "separator", "downloadPNG", "downloadPDF"]
      }
    }
  }
})
Vue.use(VueCookies)

Vue.use(VueAnalytics, {
  id: AnalyticsService.getTrackingId()
})

Vue.config.productionTip = false

Vue.filter('dateFilter', function (indate) {
  if (!indate) return 'N/A'
  return indate.day + '/' + indate.month + '-' + indate.year
})

Vue.filter('i18n', function (intext) {
  return store.getters.getTranslation(intext)
})

Vue.prototype.$t = function (key) {
  return store.getters.getTranslation(key)
}

Vue.config.devtools = true;

const domain = window.location.hostname

backend.get('/config/client?domain='+domain).then((response) => {
  let clientConfigDTO = response.data
  ClientConfig.setConfig(clientConfigDTO)

  if(clientConfigDTO.keyCloakMode) {
    startWithKeyCloak()
  } else {
    startLegacyMode()
  }

})



function startWithKeyCloak() {

// https://github.com/dsb-norge/vue-keycloak-js/issues/2

  const isLocal = window.location.origin.includes("localhost")

  Vue.use(VueKeyCloak, {
    init: {
      // Use 'login-required' to always require authentication
      // If using 'login-required', there is no need for the router guards in router.js
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + (isLocal ? "/silent-check-sso.html" : "/new/silent-check-sso.html")
    },
    config: {
          realm: ClientConfig.keyCloakRealm(),
          url: ClientConfig.keyCloakServer(),
          clientId: ClientConfig.keyCloakClientId()
    },

//     onReady: (keycloak) => {
//       // console.log(keycloak)
// //      store.commit('keycloak', this.$keycloak)
//     }


  })

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')

}

function startLegacyMode() {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}







