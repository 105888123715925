<template>
  <v-card outlined v-if="universeData">
    <v-card-title >Universe</v-card-title>

    <v-container fluid>

      <v-row>
        <v-col cols="2" >
          <v-text-field v-model="universeData.name" label="Name"/>
        </v-col>
        <v-col cols="2" >
          <v-text-field v-model="universeData.title" label="Title"/>
        </v-col>
        <v-col cols="2" >
          <v-text-field v-model="universeData.locales" label="Locales"/>
        </v-col>
        <v-col cols="2" >
          <v-text-field v-model="universeData.defaultLocale" label="Default locale"/>
        </v-col>
        <v-col cols="2" >
          <v-text-field v-model="universeData.sheetKey" label="Sheet"/>
        </v-col>
        <v-col cols="2" >
          <v-btn @click="onSaveClicked">{{'UPDATE' | i18n}}</v-btn>
          <v-btn @click="onScriptClicked">{{'SCRIPT' | i18n}}</v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-card>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import SurveysService from "@/services/SurveysService";
import Pagenavigator from "@/pagenavigator";

export default {
  name: 'UniverseComponent',
  components: {},

  data: () => ({
    universeData: null
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey']),
  },

  created() {
    SurveysService.fetchUniverseData(this.getAuthToken, this.getActiveSurvey.extName).then((response) => {
      this.universeData = response.data;
    })
  },

  methods: {
    onSaveClicked() {
      SurveysService.saveUniverseData(this.getAuthToken, this.getActiveSurvey.extName, this.universeData).then((/*response*/) => {
        this.$store.commit('showSnackbar', 'Universe saved')
      })
    },

    onScriptClicked() {
      Pagenavigator.navigateToUniverseScriptDetailsPage(this, this.getActiveSurvey.extName);
    }

  }

}
</script>

