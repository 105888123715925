<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col>
        <template v-if="!chartOptions">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </template>
        <template v-else>
          <highcharts :options="chartOptions" :ref="'chart'"></highcharts>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'

import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'

highchartsMore(Highcharts);

import ResultsService from "../../services/ResultsService";
export default {
  name: 'DeltaChartComponent',

  components: {
    highcharts: Chart
  },

  data () {
    return {
      chartOptions: null,
      percentages: [false, false, false, true, true]
    }
  },

  props: {
    componentData: Object
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    selectionMode() {
      return this.externalName!=null
    },

    externalName() {
      return (this.componentData.config) ? this.componentData.config.extname : this.componentData.extname
    }

  },

  methods: {
    loadChartData() {
      const id = (this.componentData.config) ? this.componentData.config.id : this.componentData.id

      ResultsService.fetchDeltaChartData(this.getAuthToken, this.getActiveSurvey.extName, id, this.getFilteredDrillPath).then((response) => {
        this.updateChart(response.data)
      })
    },

    updateChart(chartdata) {
      // if (! chartdata.lang) {
      //   chartdata.lang = ChartUtil.getLangTranslations(this) // TODO [tho] - move this to ChartsPort
      // }
      // if (! chartdata.exporting) {
      //   chartdata.exporting = ChartUtil.getExportingSettings() // TODO [tho] - move this to ChartsPort
      // }

      this.chartOptions = chartdata;

      this.chartOptions.percentages = [false, false, false, true, true]


      this.chartOptions.tooltip = {
        useHTML: true,
        shared: false,
        shadow: false,
        formatter: function () {
          const idx = this.point.index;

          if(this.series.index===1) { // values
            return this.series.name + ": " + this.series.chart.options.toolTipValues[idx]
          } else {
            return this.series.name + ": " + this.series.chart.options.toolTipGoalValues[idx]
          }
        }
      }



    },


  },

}
</script>
<style scoped>
</style>
