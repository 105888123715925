<template>
  <v-card>

    <div class="content">
      <div style="visibility: hidden">
        <CrossTimeSelector @change="(timespan) => $emit('change', timespan)" :timespans="globalOverviewData.timeFilters"/>
      </div>
      <div class="welcomeContainer">
        <div class="welcomeText">{{globalOverviewData.welcomeText}}</div>
        <div>{{globalOverviewData.clickText}}</div>
      </div>
      <div class="timeSelectorContainer">
        <CrossTimeSelector @change="(timespan) => $emit('change', timespan)" :timespans="globalOverviewData.timeFilters"/>
      </div>

    </div>

  </v-card>

</template>

<script>
import {mapGetters} from "vuex";
import CrossTimeSelector from "@/views/login/CrossTimeSelector";

export default {
  name: 'GlobalUserHeader',

  components: {
    CrossTimeSelector
  },

  props: {
    globalOverviewData: Object
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isUserRoot']),
  },

}
</script>
<style scoped>
div.content {
  display: flex;
  justify-content: space-between;
}

div.welcomeText {
  /*font-size: 24px;*/
  font-size: calc((24/16)*1rem);
  font-weight: bold;
}

div.welcomeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeSelectorContainer {
  display: flex;
  align-items: center;
}
</style>
