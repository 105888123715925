<template>
    <div class="drillpathbar">
        <v-btn class="bgbutton"  color="grey" dark @click="onBackClicked">
            <v-icon>mdi-chevron-left</v-icon>
            {{'BACK' | i18n }}
        </v-btn>
    </div>
</template>

<script>

    export default {
        name: 'DrillPathBackComponent',

        methods: {
            onBackClicked() {
                this.$router.go(-1)
            },
        },

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    div.drillpathbar {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
        background-color: white;
        border-bottom: 1px solid #cccccc;
    }
</style>
