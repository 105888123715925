import backend from '@/services/backend'

export default {

  fetchScriptBlocks(authToken, surveyName, surveyId) {
    return backend.get('vuejs/scripts/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  },

  fetchGlobalScriptBlocks(authToken, surveyName) {
    return backend.get('vuejs/scripts/globals/' + surveyName, { headers: { Authorization: authToken } })
  },

  fetchScriptBlock(authToken, surveyName, scriptId) {
    return backend.get('vuejs/scripts/details/'+ surveyName + '/' + scriptId, { headers: { Authorization: authToken } })
  },

  fetchUniverseScriptBlock(authToken, surveyName) {
    return backend.get('vuejs/scripts/universe/details/'+ surveyName, { headers: { Authorization: authToken } })
  },

  saveScriptBlock(authToken, surveyName, scriptBlock) {
    return backend({ method: 'post', url: 'vuejs/scripts/' + surveyName, data: scriptBlock,  headers: { Authorization: authToken } })
  },

  createScriptBlock(authToken, surveyName, surveyId) {
    return backend.get('vuejs/scripts/create/' + surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  },

  createGlobalScriptBlock(authToken, surveyName) {
    return backend.get('vuejs/scripts/create/' + surveyName, { headers: { Authorization: authToken } })
  },

  deleteScriptBlock(authToken, surveyName, scriptId) {
    return backend.delete('vuejs/scripts/delete/'+ surveyName + '/' + scriptId, { headers: { Authorization: authToken } })
  },

  runScriptBlock(authToken, surveyName, surveyId, scriptId, dryrun, runParams) {
    return backend({ method: 'post', url: 'vuejs/scripts/run/'+ surveyName + '/' + surveyId + '/' + scriptId + '?dryrun='+dryrun, data: runParams,  headers: { Authorization: authToken } })
  },

  deleteScriptEvent(authToken, surveyName, eventId) {
    return backend.delete('vuejs/scripts/deleteevent/'+ surveyName + '/' + eventId, { headers: { Authorization: authToken } })
  },

  deleteAllScriptEvents(authToken, surveyName, scriptBlockId) {
    return backend.delete('vuejs/scripts/deleteallevents/'+ surveyName + '/' + scriptBlockId, { headers: { Authorization: authToken } })
  }
}
