<template>
  <v-container class="wrapper" fluid v-if="!componentData.config.rootOnly || (componentData.config.rootOnly && isUserRoot)">
    <v-row no-gutters >
      <v-col v-if="isDataLoaded">
        <CompactActionsComponent :open-actions="openActions" :closed-actions="closedActions" :suggested-actions="suggestedActions" :writeable="anchorable" @actionchanged="loadChartData"/>
      </v-col>
      <v-col v-else>
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import ResultsService from "../../../services/ResultsService";
import CompactActionsComponent from "./CompactActionsComponent";

export default {
  name: 'ActionOverviewComponent',

  components: {
    CompactActionsComponent,
  },

  data () {
    return {
      anchorable: null,
      // mainVarData: null,
      openActions: [],
      closedActions: [],
      suggestedActions: [],
      isActionsConfigured: null,
      actionManagerDataLoaded: false
    }
  },

  props: {
    componentData: Object,
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale', 'isUserRoot']),

    isDataLoaded() {
      return this.actionManagerDataLoaded
    }


  },

  methods: {
    loadChartData() {
      if(this.componentData.config) {
          ResultsService.fetchActionManagerData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
            this.anchorable = response.data.anchorable
            // this.mainVarData = response.data.mainVariable
            this.openActions = response.data.openActions
            this.isActionsConfigured = response.data.isActionsConfigured
            this.closedActions = response.data.closedActions
            this.suggestedActions = response.data.suggestedActions
            this.actionManagerDataLoaded = true
          })
      }
    },
  }


}
</script>

<style scoped>
.wrapper {
  /*background-color: white;*/
  border-radius: 5px;
}


</style>
