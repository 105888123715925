<template>
  <tr>
    <td>
      <div>{{spreadsheetColumn.name}}</div>
    </td>

    <td>
      <div>{{spreadsheetColumn.firstRow}}</div>
    </td>

    <td>

      <v-select
          :items="mappingFields"
          item-text="title"
          item-value="variable"
          label="field"
          @change="$emit('fieldmapped', { idx: spreadsheetColumn.idx, variable: $event})"
      ></v-select>

    </td>
  </tr>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'ColumnComponent',
  components: {/*PerformanceChart, StreakMarksComponent, TopMarksComponent, NoDataComponent*/},

  data () {
    return {
    }
  },

  props: {
    spreadsheetColumn: Object,
    mappingStructure: Object
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    mappingFields() {
      const result = []

      const mandatoryFields = this.mappingStructure.columns.filter(e => e.mandatory)

      if(mandatoryFields.length>0) {
        result.push( { header: 'mandatory' } )
      }

      mandatoryFields.forEach( e => result.push( { title: e.title, variable: e.variable } ) )

      const otherFields = this.mappingStructure.columns.filter(e => !e.mandatory)

      if(mandatoryFields.length>0 && otherFields.length>0) {
        result.push( { divider: true } )
      }

      otherFields.forEach( e => result.push( { title: e.title, variable: e.variable } ) )

      return result
    }

  },

  methods: {}

}
</script>

<style scoped>

</style>
