<template>
  <v-card v-if="!isLetter">
    <div class="renderDiv" v-if="renderedMail" v-html="renderedMail"> </div>

    <div v-else class="waitingDiv">
      <div>
        Loading message
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </div>
  </v-card>

  <v-card v-else-if="isLetter && renderedPDFBytes">
    <PDFViewer :source="renderedPDFBytes" style="height: 100vh; width: 100vw" filename="ole"/>
  </v-card>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";

export default {
  name: 'RenderedMail',

  components: {PDFViewer},

  props: {
    campaignId: Number,
    template: String,
    trackingId: Number,
    mailAction: String,
    sendingType: String,
  },

  data: () => ({
    loading: false,
    renderedMail: null,
    renderedPDFBytes: null
  }),

  created: function () {
    if(this.sendingType!=='LETTER') {
      MailsService.fetchRenderedMailData(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.trackingId, this.template, "maillive").then((response) => {
        this.renderedMail = response.data
      })
    } else {
      this.renderedPDFBytes = null
      MailsService.fetchRenderedLetterData(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.trackingId, this.template, "maillive").then((response) => {
        this.selectedTemplate = response.data
        this.renderedPDFBytes = "data:application/octet-stream;base64," + response.data;
      })

    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    pdfURL() {
      const rootURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : ''
      return rootURL + '/rest/vuejs/letteruploader/renderletter/' + this.activeSurvey.extName + '/' + this.template + '?trackingid='+this.trackingId+'&auth='+this.getAuthToken
    },

    isLetter() {
      return this.sendingType==='LETTER'
    }

  },

  watch: {
    template: function () {
      MailsService.fetchRenderedMailData(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.trackingId, this.template, "maillive").then((response) => {
        this.renderedMail = response.data
      })
    }
  }


}
</script>

<style scoped>

.waitingDiv {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.renderDiv {
  padding: 1em;
  background-color: white;
}
</style>
