<template>
  <div>
    <v-select v-model="filterDirection" :items="filterDirections"></v-select>
    <div style="display: flex">
      <v-text-field  v-model="deltaValue" type="number" />
      <v-select v-model="deltaUnit" :items="deltaUnits"></v-select>
    </div>
    <v-select v-model="deltaDirection" :items="deltaDirections"></v-select>
    <v-btn @click="onClickAdd">{{'ADD' | i18n}}</v-btn>

    <v-list dense>
      <v-list-item dense v-for="(option,idx) in selectedOptions" :key="option.extName+idx">
        <v-list-item-content>
          <v-checkbox dense hide-details class="filterCheckBox ma-0 pa-0" :label="option.title"
                      v-model="checkedOption"  @change="onCheckClicked(option.extName)"></v-checkbox>
        </v-list-item-content>
      </v-list-item>
    </v-list>


  </div>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'FilterComponentDateRef',

  data () {
    return {
      filterDirection: null,
      deltaUnit: null,
      deltaValue: 0,
      deltaDirection: null,
      checkedOption: true
   }
  },

  props: {
    filterSet: Object,
    filter: Object
  },

  created() {
    this.filterDirection = "e"
    this.deltaValue = 0
    this.deltaUnit = "d"
    this.deltaDirection = 'b'
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getFilterSelectedOptions', 'getActiveCombinedFilters']),

    filterDirections() {
      return [{value: 'e', text: 'Er præcis'}, {value: 'b', text: 'Er før'}, {value: 'a', text: 'Er efter'} ]
    },

    deltaUnits() {
      return [{value: 'd', text: 'Days'}, {value: 'm', text: 'Months'}, {value: 'y', text: 'Years'} ]
    },

    deltaDirections() {
      return [{value: 'b', text: 'Back'}, {value: 'f', text: 'Forward'} ]
    },

    selectedOptions() {
      return this.getActiveCombinedFilters.getFilterItems(this.activeSurvey.standardFilterSet)
          .filter(e => e.filter.extName===this.filter.extName)
           .map(e => e.option);
    }


  },


  methods: {
    onClickAdd() {
      const extname = "rdate" + this.filterDirection + this.deltaValue + this.deltaUnit + this.deltaDirection

      const filterValues = this.getActiveCombinedFilters.getFilterValuesForFilter(this.filter.extName);

      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: [extname, ...filterValues]})

      this.checkedOptions = this.getActiveCombinedFilters.getFilterItems(this.activeSurvey.standardFilterSet)
          .filter(e => e.filter.extName===this.filter.extName)
          .map(e => e.option);
    },

    onCheckClicked(optionExtName) {
      this.$store.commit('clearFilter', {filterName: this.filter.extName, filterValue: optionExtName})
    }

  },


}
</script>

<style>
.filterCheckBox {
  /*    color: red; */
}

div.filterCheckBox div.v-input__control div.v-input__slot {
  margin: 0 !important;
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

}

div.filterCheckBox label {
  left: 6px !important;
}

div.filterCheckBox label {
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

}

.v-select-list .v-list .v-input__slot { margin-bottom: 0 !important; }

/*.v-autocomplete-content .v-select-list .v-subheader {*/
/*  color: blue;*/
/*  font-size: 13.5px !important;*/
/*}*/

</style>
