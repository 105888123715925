<template>
  <div>
    <template v-if="dataLoaded">
      <highcharts :options="chartOptions"/>
    </template>
    <template v-else>
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'
import ColorManager from "../../colormanager";
import ChartUtil from "../../chartutil";

export default {
  name: 'ActionDevChartComponent',

  components: {
    highcharts: Chart
  },

  data () {
    return {
      dataLoaded: false,
      selectedPeriod: null,

      chartOptions: {
        lang: ChartUtil.getLangTranslations(this),
        exporting: ChartUtil.getExportingSettings(),

        chart: {
          type: 'line',
          alignTicks: false,
        },

        plotOptions: {

        },

        xAxis: {
        },

        yAxis: [{
          lineWidth: 1,
          labels: {
            format: '{value:.2f}',
          },
          title: {
            text: this.$t('CHART_DEVELOPMENT_YAXIS1')
          }
        }
        ],

        series: [],

        // tooltip: {
        //   // enabled: true,
        //   // shared: false,
        //   // valueDecimals: 2
        //
        //   formatter: function() {
        //     return this.series.name +': ' + (this.series.type==='column' ? this.y.toFixed(0) : this.y.toFixed(2));
        //   }
        //
        // },

        title: {
          text: null
        },

        legend: {
          layout: /*this.componentData.verticalLegend ? 'vertical' :*/ 'horizontal',
        },

        credits: {
          enabled: false
        },

      }
    }

  },

  props: {
    chartData: Object,
    selectionBlockBus: Object
  },

  watch: {
    chartData: {
      handler() {
        this.updateChart(this.chartData)
      },
      immediate: true
    },

  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    numberDecimals() {
      const numberFormat = this.$t('FORMAT_STD_NUMBER')
      const dotIdx = numberFormat.indexOf('.');
      return dotIdx!==-1 ? numberFormat.length-dotIdx-1 : 0;
    },


  },

  methods: {

    updateChart(dto) {

      this.dataLoaded = true

      let colorManager = new ColorManager()

      // if(this.componentData.stacked) {
      //   this.chartOptions.chart.type = 'area'
      //
      //   this.chartOptions.plotOptions.area = {
      //     stacking: 'normal',
      //     lineColor: '#666666',
      //     lineWidth: 1,
      //   }
      //
      // }

      this.chartOptions.xAxis.categories = dto.series.map(s => s.name)


      // if(this.componentData.lowerY) {
      //   this.chartOptions.yAxis.min = this.componentData.lowerY
      // }
      // if(this.componentData.upperY) {
      //   this.chartOptions.yAxis.max = this.componentData.upperY
      // }

      // if(this.componentData.showInterviews) {

      this.chartOptions.yAxis[1] = {
        gridLineWidth: 0,
        opposite: true,
        title: {
          text: this.$t('CHART_DEVELOPMENT_YAXIS2')
        }
      }
      // }

      let allSeries = []

      for(let i=0; i<dto.descriptors.length; ++i) {
        let desc = dto.descriptors[i]

        let values = []

        for (let serie of dto.series) {
          values.push( serie.data[i] )
        }

        if( /*this.componentData.showInterviews &&*/ desc.variable==='interviews' ) {
          let series = {
            name: desc.title,
            type: 'column',
            data: values,
            color:  "#eeeeee",
            yAxis: 1,
            zIndex: -1,

            dataLabels: {
              enabled: true,
              format: "{point.y:.0f}"
            },

            tooltip: {
              pointFormat: "{point.y:.0f}"
            },

            interviews: true
          }


          allSeries[i] = series

        } else {

          let series = {
            data: values,
            name: desc.title,
            color: colorManager.getColor(desc.variable),
            type: /*this.componentData.stacked ? 'area' :*/ 'line',

            dataLabels: {
              enabled: true,
              format: "{point.y:." + this.numberDecimals + "f}"
            },

            tooltip: {
              pointFormat: "{point.y:." + this.numberDecimals + "f}"
            }

          }

          allSeries[i] = series
        }

      }

      this.chartOptions.series = allSeries
    }

  },

}
</script>
<style scoped>
</style>
