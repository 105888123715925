<template>
  <v-container fluid>
    <v-row :justify="componentData.justify" v-if="title">
      <v-col><h3>{{title}}</h3></v-col>
    </v-row>

    <v-row :justify="componentData.justify">
      <v-col :cols="cdata.componentDef.cols" v-for="(cdata, idx) in componentData.components" v-bind:key="idx">
        <editor-component :component-data="cdata" :sample-id="sampleId" :state="state" ref="childComponents"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'GroupComponent',

  components: {
    'editor-component': () => import('./EditorComponent.vue')
  },

  props: {
    componentData: Object,
    sampleId: Number,
    state: Number
  },

  computed: {
    ...mapGetters(['getLocale']),

    title() {
      return this.componentData.title
    }
  },

  methods: {

    requestSave(validateOnly) {
      let result = true;

      for(const cc of this.$refs.childComponents) {
        result = result && cc.requestSave(validateOnly)
      }

      return result
    },
  }


}
</script>
