<template>
  <v-container fluid>
    <v-row>
      <v-col>

        <v-card class="overviewCard" outlined v-if="analyticsData">

          <v-container fluid>
            <v-row><v-col><span class="title">{{'TOTAL_FEEDBACK' | i18n}}</span></v-col></v-row>

            <v-row>
              <v-col cols="2">
                <CircleComponent color="#00C896" :title="$t('POSITIVE_FEEDBACK')" :value="analyticsData.overviewDTO.positiveFeedback.feedbackPercentage" :formatted-value="analyticsData.overviewDTO.positiveFeedback.feedbackPercentageTitle"/>
              </v-col>

              <v-col cols="2">
                <CircleComponent color="#BE0000" :title="$t('NEGATIVE_FEEDBACK')" :value="analyticsData.overviewDTO.negativeFeedback.feedbackPercentage" :formatted-value="analyticsData.overviewDTO.negativeFeedback.feedbackPercentageTitle"/>
              </v-col>


              <v-col cols="8">

                <table width="100%">

                  <tr>
                    <td class="categoriesTitleCell">{{'CATEGORIES_TOP4' | i18n}}</td>
                    <td></td>
                  </tr>

                  <tr v-for="(cat,idx) in analyticsData.overviewDTO.categories" :key="idx">
                    <td class="categoryTitleCell">{{cat.title}}</td>
                    <td class="categoryBarCell"><CategoryFeedbackChartComponent :indicator-data="cat"/></td>
                  </tr>

                </table>

              </v-col>
            </v-row>

          </v-container>


        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from "vuex";
  import ResultsService from "../../../../services/ResultsService";
  import CategoryFeedbackChartComponent from "./CategoryFeedbackChartComponent";
  import CircleComponent from "./CircleComponent";

  export default {
    name: 'FeedbackOverviewComponent',

    components: {
      CategoryFeedbackChartComponent, CircleComponent
    },

    data () {
      return {
        analyticsData: null,
      }
    },

    props: {
      componentData: Object,
      vardto: Object
    },

    watch: {
      $route: {
        handler() {
          this.loadServerData()
        },
        immediate: true
      }
    },

    computed: {
      ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
    },

    methods: {
      loadServerData() {
        this.$store.commit('setTextAnalyticsData', null)

        ResultsService.fetchTextAnalyticsData(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath).then((response) => {
          this.$store.commit('setTextAnalyticsData', response.data)
          this.analyticsData = response.data
        })
      },
    }

  }
</script>

<style scoped>
  .overviewCard {
    padding: 30px;
  }

  td.categoriesTitleCell {
    /*font-size: 24px;*/
    font-size: calc((24/16)*1rem);
    font-weight: 500;
    letter-spacing: 0;
    color: #000
  }

  td.categoryTitleCell {
    text-align: right;
    width: 20%;
  }

  td.categoryBarCell {
    width: 80%;
  }

  span.title {
    /*font-size: 20px;*/
    font-size: calc((20/16)*1rem);
  }

</style>
