<template>
<div class="wrapper">
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">

      <div v-on="on">
        <v-icon>mdi-chart-timeline-variant</v-icon>
        <span class="trendTitleSpan">{{getTrendTitle}}</span>
      </div>
      <div class="fromto">{{getTrendDetails}}</div>

    </template>
    <span>{{'COMPARE_PERIOD' | i18n}}</span>
  </v-tooltip>
</div>

</template>

<script>
  import {mapGetters, mapState} from "vuex";
//import _ from 'lodash'

export default {
  name: 'TrendTimeSelector',
  data () {
    return {
      items: [],
      on: true
    }
  },

  props: {
    filterItem: Object,
    filterSets: Array
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getTrendTitle', 'getTrendDetails']),
  },

}
</script>
<style scoped>
  div.wrapper {
    display: inline-block;
    /*font-size: 14px;*/
    font-size: calc((14/16)*1rem);
    margin-right: 1.5em;
    margin-left: 1em;
  }

  div.fromto {
    color: rgba(0,0,0,0.54);
    white-space: nowrap;
  }

  span.trendTitleSpan {
    margin-left: 5px;
  }

</style>
