<template>

  <v-container fluid>
    <DataTableStaticComponent :component-data="componentData" v-if="isStaticTable"/>
    <DataTableServerComponent :component-data="componentData" v-else/>
  </v-container>

</template>

<script>

import DataTableServerComponent from "@/views/pages/pagecomponents/DataTableServerComponent.vue";
import DataTableStaticComponent from "@/views/pages/pagecomponents/DataTableStaticComponent.vue";

export default {
  name: 'DataTableComponent',

  components: {DataTableServerComponent, DataTableStaticComponent},

  props: {
    componentData: Object,
  },

  computed: {
    isStaticTable() {
      return this.componentData.config.rows.length>0
    },
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
