<template>
  <div class="progressWrapper" :style="cssVars">
    <div class="progress">
      <div class="progress-value"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnimatedProgressBarComponent",
  props: {
    target: Number,
    value: Number,
    color: String
  },
  computed: {
    cssVars () {
      return {
        '--color': this.color,
        '--value': this.value + '%',
        '--target': this.target + '%'
      }
    }
  }
}
</script>

<style scoped>


.progressWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;

}

.progress {
  background: #ddd;
  justify-content: flex-start;
  /*border: 1px solid #666;*/
  border-radius: 3px;
  align-items: center;
  position: relative;
  /*padding: 0 5px;*/
  display: flex;
  height: 12px;
  width: 100%;
}

.progress-value {
  animation: load 2s normal forwards;
  /*box-shadow: 0 10px 40px -10px #fff;*/
  border-radius: 3px;
  /*background: #00C896;*/
  background: var(--color);
  height: 10px;
  width: 0;
}

@keyframes load {
  0% { width: 0; }
  100% { width: var(--value); }
}

.progress::after {
  position: absolute;
  top: 0;
  height: 100%;
  background: #444;
  content: "";
  width: 2px;
  left: var(--target);
  display: block;
}


</style>