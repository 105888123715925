<template>

  <v-text-field v-if="isTextQuestion" :label="questionData.title" v-model="questionData.value" :error-messages="errorMessages" />
  <v-text-field v-else-if="isNumberQuestion" type="number" :label="questionData.title" v-model="questionData.value" :error-messages="errorMessages" />

  <div v-else>
    <v-select v-if="useDropDown" ref="selectionBox"  :disabled="!canModify" :items="questionData.reasons" v-model="questionData.selected" item-text="title" item-value="choiceId" :label="questionData.title" @change="onChange" :error-messages="errorMessages"></v-select>

    <v-radio-group v-else :disabled="!canModify" v-model="questionData.selected" :label="questionData.title">
      <v-radio v-for="r in realReasons"  :key="r.choiceId" :label="r.title" :value="r.choiceId" ></v-radio>
    </v-radio-group>
  </div>

</template>

<script>

import _ from 'lodash'

export default {
  name: 'AlertQuestionComponent',

  components: {
  },

  data: () => ({
    errorMessages: []
  }),

  props: {
    questionData: Object,
    canModify: Boolean
  },

  computed: {
    isTextQuestion() {
      return this.questionData.type==='Text'
    },

    isNumberQuestion() {
      return this.questionData.type==='Number'
    },

    useDropDown() {
      return this.questionData.reasons.length>=5
    },

    realReasons() {
      return _.filter(this.questionData.reasons, function(r) { return r.choiceId!==0; });
    }


  },

  methods: {

    validateMandatory() {

      if(this.isTextQuestion || this.isNumberQuestion) {
        if(!this.questionData.value) {
          this.errorMessages = ["field is mandatory"]
          return false
        }
      } else {
        if(this.questionData.selected===0) {
          this.errorMessages = ["selection is mandatory"]
          return false
        }
      }

      this.errorMessages= []
      return true;
    },

    onChange(choiceId) {
      this.questionData.selected = choiceId
    }
  },

}
</script>
<style scoped>
.alerttitle {
  background-color: #37c796;
}
</style>