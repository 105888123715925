<template>
  <div class="cellContainer">
    <v-icon small :color="colors[0]" :style="{ visibility: ballVisibility(0)}">mdi-checkbox-blank-circle</v-icon>
    <v-icon small :color="colors[1]" :style="{ visibility: ballVisibility(1)}">mdi-checkbox-blank-circle</v-icon>
    <v-icon small :color="colors[2]" :style="{ visibility: ballVisibility(2)}">mdi-checkbox-blank-circle</v-icon>
    <v-icon small :color="colors[3]" :style="{ visibility: ballVisibility(3)}">mdi-checkbox-blank-circle</v-icon>
    <v-icon small :color="colors[4]" :style="{ visibility: ballVisibility(4)}">mdi-checkbox-blank-circle</v-icon>
  </div>
</template>

<script>
export default {
  name: 'LITSCell',

  data: () => ({
    colors: [],
    visibilities: []
  }),

  props: {
    targets: String
  },

  methods: {
    ballVisibility: function (no) {
      return this.visibilities[no]
    }
  },

  /*
  4 Grøn: 009600
  3 Gul: ffde1f
  2 Orange: ff7d30
  1 Rød: ff0000
  0 Grå: d9d9d9
   */

  watch: {
    targets: {
      handler() {
        this.colors=[]
        this.visibilities=[]
        const targetArray = this.targets.split(",");
        for(let i=0; i<5; ++i) {
          if(i<targetArray.length) {
            const target = targetArray[i];

            this.visibilities[i] = 'visible'

            if(target==='0') {
              this.colors.push('#be0000')
            } else if(target==='1') { //
              this.colors.push('#be0000')
            } else if(target==='2') { //
              this.colors.push('#be0000')
            } else if(target==='3') { //
              this.colors.push('#FFDE1F')
            } else if(target==='4') { //
              this.colors.push('#00C896')
            } else {
              this.colors.push('#ffffff')
            }
          } else {
            this.colors.push('#ffffff')
            this.visibilities[i] = 'hidden'
          }

        }

      },
      immediate: true
    },

  },

}

</script>

<style scoped>
div.cellContainer {
  text-align: right;
  white-space: nowrap;
}

</style>
