<template>
  <div v-if="leafLevel" @click.stop="onClick">
    <div v-if="alertList">
      <v-chip v-if="alertstate" v-bind:class="{ open: isOpen, processing: isProcessing }" @click.stop="onClick">{{alertstate.svalue}}</v-chip>
    </div>
    <div class="icontext" v-else>
      <template v-if="isOpen">
        <v-icon small color="error">mdi-alert</v-icon>{{'OPEN' | i18n}}
      </template>
      <template v-else-if="isProcessing">
        <v-icon small color="warning">mdi-alert</v-icon>{{'PROCESSING' | i18n}}
      </template>
      <template v-else-if="isClosed">
        {{'CLOSED' | i18n}}
      </template>
    </div>
  </div>
  <v-chip v-else-if="alertstate" v-bind:class="{ open: isOpen }" @click.stop="onClick">{{alertstate.svalue}}</v-chip>
</template>

<script>
import {mapGetters} from "vuex";
import PageNavigator from "@/pagenavigator"


export default {
  name: 'AlertStateCell',

  props: {
    alertid: Number,
    alertstate: Object,
    leafLevel: Boolean,
    alertList: Boolean
  },

  computed: {
    ...mapGetters(['getActiveSurvey']),

    isOpen() {
      return this.alertstate.value === 2
    },

    isProcessing() {
      return this.alertstate.value === 6
    },

    isClosed() {
      return this.alertstate.value === 3
    },

  },

  methods: {
    onClick: function () {
      PageNavigator.navigateToAlertPage(this, this.getActiveSurvey.extName, this.alertid)
    }
  },

}

</script>


<style scoped>
div.icontext {
  white-space: nowrap;
  cursor: pointer;
}

span.open {
  background: #be0000 !important;
  color: white;
}

span.processing {
  background: #FFC107 !important;
  color: white;
}
</style>

