<template>
  <v-container class="ma-0 pa-0">

    <v-row v-for="(questionGroup, idx) in questionGroups" :key="questionGroup.label + idx"
           :class="getRowClass(questionGroup)"
           @click="questionGroupClicked(questionGroup)">
      <v-col cols="6" class="ma-0 px-2 py-2">
        {{ questionGroup.label }}
      </v-col>
      <v-col cols="6" class="answerIcons ma-0 px-2 py-2" >
        <template v-if="questionGroup.answers && questionGroup.answers.length > 0">

          <InterviewAnswerIconComponent  v-for="answer in questionGroup.answers"
                                         :key="answer.label"
                                         :icon-value="answer.icon"
                                         :use-number-icons="useNumberIcons"/>
        </template>
        <v-icon v-else
            color="rgba(0,0,0,0.54)">
          mdi-minus
        </v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InterviewAnswerIconComponent from "./InterviewAnswerIconComponent";

export default {
  name: "InterviewResultGroupComponent",

  components: {InterviewAnswerIconComponent},

  comments: [InterviewAnswerIconComponent],

  props: ["questionGroups", "selectedGroup", "useNumberIcons"],

  methods: {
    getRowClass (questionGroup) {
      return 'pa-0 ma-0 handOnHover' + (questionGroup === this.selectedGroup ? ' selectedGroup' : '')
    },

    questionGroupClicked(questionGroup) {
      this.$emit('questionGroupClicked', questionGroup)
    }
  }

}
</script>

<style scoped>
  .answerIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .selectedGroup {
    background-color: rgba(0,0,0,0.12);
  }
  .handOnHover:hover {
    cursor: pointer;
  }
</style>