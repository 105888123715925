<template>
  <div>
    <v-autocomplete
        :items="filter.options"
        item-text="title"
        item-value="extName"
        v-model="campaignId">
    </v-autocomplete>

    <v-text-field :disabled="!isValid" label="Days after send out" type="number" v-model="nDays"/>
    <v-select :disabled="!isValid" v-model="selectState" label="State" :items="stateOptions" item-text="title" item-value="extName"></v-select>
    <v-select :disabled="!isValid" v-model="selectOpen" label="Opened?" :items="openOptions" item-text="title" item-value="extName"></v-select>
    <v-select :disabled="!isValid" v-model="selectClick" label="Clicked?" :items="clickOptions" item-text="title" item-value="extName"></v-select>

    <v-btn :disabled="!isValid" @click="onApplyClicked">Apply</v-btn>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'FilterComponentCampaign',

  data() {
    return {
      campaignId: null,
      nDays: null,
      selectState: "any",
      selectOpen: "any",
      selectClick: "any",
    }
  },

  props: {
    filter: Object
  },

  computed: {
    ...mapState(['combinedFilters']),

    isValid() {
      return this.campaignId!==null
    },

    stateOptions() {
      return [
        { extName: 'any', title: '' },
        { extName: 'delivered', title: 'Delivered' },
        { extName: 'pending', title: 'Pending' },
        { extName: 'failed', title: 'Failed' },
        { extName: 'unsub', title: 'Unsubscribed' },
      ];
    },

    openOptions() {
      return [
        { extName: 'any', title: '' },
        { extName: 'yes', title: 'Yes' },
        { extName: 'no', title: 'No' },
      ];
    },

    clickOptions() {
      return [
        { extName: 'any', title: '' },
        { extName: 'yes', title: 'Yes' },
        { extName: 'no', title: 'No' },
      ];
    },

  },

  created() {
    this.nDays = null
    this.selectState = "any"
    this.selectOpen = "any"
    this.selectClick = "any"
  },


  methods: {
    onApplyClicked() {

      const values = [ this.nDays ? this.nDays : "any",  this.selectState, this.selectOpen, this.selectClick ]

      this.$store.commit('setFilter', {filterName: this.filter.extName+this.campaignId, filterValue: values})
      this.campaignId = null
      this.nDays = null
      this.selectState = "any"
      this.selectOpen = "any"
      this.selectClick = "any"
    }

  },

}
</script>

<style>

.daysbox {
  display: flex;
  align-items: center;
}

.daysbox label {
  margin-left: 1rem;
}


.filterCheckBox {
}

div.filterCheckBox div.v-input__control div.v-input__slot {
  margin: 0 !important;
  font-size: calc((13.5 / 16) * 1rem) !important;
}

div.filterCheckBox label {
  left: 6px !important;
}

div.filterCheckBox label {
  font-size: calc((13.5 / 16) * 1rem) !important;

}

.v-select-list .v-list .v-input__slot {
  margin-bottom: 0 !important;
}

</style>
