<template>
        <v-dialog v-model="dialog" width="60%">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on">
                    <v-icon left >mdi-history</v-icon>
                    {{'HISTORY' | i18n}}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline" primary-title>
                    {{'COMMENTS_HISTORY' | i18n}}
                </v-card-title>

                <v-simple-table>
                    <thead>
                    <tr>
                        <th class="text-left">{{'ALERT_STATE' | i18n}}</th>
                        <th class="text-left">{{'USER' | i18n}}</th>
                        <th class="text-left">{{'COMMENTS' | i18n}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(log, idx) in alertData.alertLogs" :key="idx">
                        <td>
                            <div v-bind:style="{ backgroundColor: bgColor(log.state) }">
                                <div>{{log.stateText}}</div>
                                <div>{{log.date}}</div>
                            </div>
                        </td>
                        <td>{{log.user}}</td>
                        <td>{{log.comment}}</td>
                    </tr>
                    </tbody>
                </v-simple-table>

                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="dialog = false">{{'CANCEL' | i18n}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>

// import {mapState} from "vuex";

export default {
  name: 'AlertHistoryDialog',

  components: {},

  data: () => ({
      dialog: false
  }),

  props: {
    alertData: Object
  },

  methods: {
      bgColor(state) {
          if(state===6) {
              return '#ffc43d'
          } else if(state===3) {
            return '#31c368'
          }

          return "#ff0000"
      }
  }


}

</script>

<style scoped>
    .stateCell {
        background-color: greenyellow;
    }
</style>