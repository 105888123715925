// const AMBASSADOR_COLOR = "#006F32"
// const DESERTER_COLOR = "#B9133E"
// const UNKNOWN_COLOR = "#bfbfbf"

const AMBASSADOR_COLOR = "#00C896"
const DESERTER_COLOR = "#BE0000"
const UNKNOWN_COLOR = "#BFBFBF"

const STANDARD_COLORS = [
  "#0000C8",
  "#282850",
  "#E6C8BE",
  "#646464",
  "#AFB9C3",
  "#50738C",
  "#BFBFBF",
]

// const STANDARD_COLORS = [
//   "#50bcff",
//   "#00325a",
//   "#909ba6",
//   "#465a69",
//   "#a6deff",
//   "#829baa",
//   "#c8cdd2",
//   "#232c34",
//   "#7c7c7c",
//   "#006cc3",
//   "#ff7d3c",
//   "#ffdc3c",
//   "#006f32",
//   "#00c373",
//   "#b91441"
// ]

function ColorManager () {
  this.colorCache = new Map()
  this.colorsUsed = 0
}

ColorManager.prototype.getColor = function (varname) {
  if(this.colorCache.has(varname)) {
    return this.colorCache.get(varname)
  }

  let color = this.mapVarToColor(varname)

  this.colorCache.set(varname, color)

  return color
}


ColorManager.prototype.mapVarToColor = function (varname) {
  if(varname === 'ambassador' || varname === 'ambassadors()') {
    return AMBASSADOR_COLOR
  } else if(varname === 'negatives' || varname === 'negatives()') {
    return DESERTER_COLOR
  }

  if(this.colorsUsed < STANDARD_COLORS.length) {
     return STANDARD_COLORS[this.colorsUsed++];
  }

  return UNKNOWN_COLOR
}


export default ColorManager
