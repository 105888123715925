<template>

  <v-container class="wrapper elevation-2" @click="onClick" v-bind:class="{'alert' : alertMode, 'clickableContainer' : isClickable}" fluid>
    <v-row class="justify-space-between align-center py-1">
      <div class="title text-center" :class="{ alertTitle: alertMode }">
        {{vardto.title}}
      </div>

      <div class="score text-center" :class="{ alertScore: alertMode }">

        <template v-if="vardto.type.startsWith('rank')">
          {{vardto.formattedValue}}
          <span class="slashnumber">/{{vardto.formattedDiff}}</span>
        </template>

        <template v-else-if="showSlidingCounter">
          <SlidingCounterComponent :end-value="vardto.formattedValue"/>
        </template>

        <template v-else>
          <div>{{valueString}}</div>
          <template v-if="showDiff">
            <v-icon large class="bottomicon" :color="arrowColor">{{ arrowIcon }}</v-icon>
            <div class="diffNumber">{{vardto.formattedDiff}}</div>
          </template>
        </template>

        <span :style="{ visibility: actionButtonVisibilty }">
          <v-btn icon @click="onClick"> <v-icon :color="iconColor">mdi-chevron-right</v-icon> </v-btn>
        </span>

      </div>
    </v-row>


  </v-container>

</template>

<script>

import PageNavigator from "../../../pagenavigator";
import {mapGetters} from "vuex";
import SlidingCounterComponent from "./subcomponents/SlidingCounterComponent";

export default {
  name: 'VariableActionComponent',

  components: {
    SlidingCounterComponent
  },

  data () {
    return { }
  },


  props: {
    vardto: {},
    showDiff: Boolean
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getGamificationPageId', 'getRankingPageId']),

    arrowIcon: function () {
      if (this.vardto.diffArrow === 1) {
        return 'mdi-menu-up'
      } else if (this.vardto.diffArrow === 2) {
        return 'mdi-menu-down'
      }

      return 'mdi-trending-neutral'
    },

    arrowColor: function () {
      if(this.vardto.inverse) {
        if (this.vardto.diffArrow === 1) {
          return '#b9133e'
        } else if (this.vardto.diffArrow === 2) {
          return '#00c896'
        }
      } else {
        return (this.vardto.diffArrow === 1) ? '#00c896' : '#b9133e';
      }

      return '#000000'
    },

    valueString() {
      if(this.vardto.type==='rank') {
        return this.vardto.formattedValue + ' / ' + this.vardto.formattedDiff
      } else {
        return this.vardto.formattedValue
      }

    },

    showSlidingCounter() {
      return this.vardto.type.startsWith('toplits')
    },

    isClickable() {
      return this.alertMode ||
          this.vardto.shortcutPage ||
          this.vardto.type.startsWith('rank') ||
          this.vardto.type.startsWith('responserate') ||
          this.vardto.type.startsWith('interviews') ||
          this.vardto.type.startsWith('pmnew') ||
          this.vardto.type.startsWith('noresponse') ||
          this.vardto.type.startsWith('pmunapproved') ||
          this.vardto.type.startsWith('pmnotapproved') ||
          (this.vardto.type.startsWith('toplits') && this.getGamificationPageId!==null);
    },

    alertMode() {
      return this.vardto.alertMode || this.vardto.type.includes('alert')
    },

    componentBGColor() {
      return this.alertMode ? '#C00000' : '#FFFFFF'
    },

    componentColor() {
      return this.alertMode ? '#FFFFFF' : '#000000'
    },

    iconColor() {
      return this.alertMode ? '#FFFFFF' : 'primary'
    },

    actionButtonVisibilty() {
      return this.isClickable ? 'visible' : 'hidden'
    }


  },

  methods: {
    onClick() {
      if(this.vardto.shortcutPage) {
        PageNavigator.navigateToPage(this, this.vardto.shortcutPage, this.getActiveSurvey.extName,
            this.vardto.shortcutDrillPath ? this.vardto.shortcutDrillPath : this.getFilteredDrillPath)
      } else if(this.alertMode) {
        PageNavigator.navigateToAlertsPage(this, this.getActiveSurvey.extName, this.getFilteredDrillPath)
      } else if(this.vardto.type.startsWith('rank')) {
        this.$store.commit('moveDrillPathUp')
        PageNavigator.navigateToRankingsPage(this, this.getActiveSurvey.extName, this.getFilteredDrillPath)
      } else if(this.vardto.type.startsWith('responserate')) {
        PageNavigator.navigateToRankingsPage(this, this.getActiveSurvey.extName, this.getFilteredDrillPath)
      } else if(this.vardto.type.startsWith('interviews')) {
        this.navigateToRankWithState("0")
      } else if(this.vardto.type.startsWith('pmnew')) {
        this.navigateToRankWithState("1")
      } else if(this.vardto.type.startsWith('noresponse')) {
        this.navigateToRankWithState("2")
      } else if(this.vardto.type.startsWith('pmunapproved')) {
        this.navigateToRankWithState("3")
      } else if(this.vardto.type.startsWith('pmnotapproved')) {
        this.navigateToRankWithState("4")
      } else if(this.vardto.type.startsWith('toplits')) {
        PageNavigator.navigateToPage(this, this.getGamificationPageId, this.getActiveSurvey.extName, this.getFilteredDrillPath);
      }

    },

    navigateToRankWithState(state) {
      this.$store.commit('setFilter', {filterName: "states", filterValue: [state] } )
      PageNavigator.navigateToRankingsPage(this, this.getActiveSurvey.extName, this.getFilteredDrillPath)
    }



  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.normal {

}

.clickableContainer {
  cursor: pointer;
}

span.slashnumber {
  color: rgba(0,0,0,0.7);
}

.alert {
  background-color: #C00000 !important;
}


div.title {
  font-size: calc((20/16)*1rem);

  /*font-size: 20px !important;*/
  font-weight: 500;
  color: rgba(0,0,0,0.87);
  margin-left: 1em
}


div.alertTitle {
  color: #fff !important;
}


div.score {
  font-size: calc((24/16)*1rem)  !important;
  /*font-size: 24px !important;*/
  font-weight: 400;
  color: rgba(0,0,0,0.87);
  display: flex;
  align-items: center
}

div.alertScore {
  color: #fff !important;
}

div.diffNumber {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
}

</style>
