<template>
  <v-simple-table class="pa-0 ma-0" dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">{{ "STARED_QUESTION_HEADER" | i18n }}</th>
        <th class="text-right">{{ "STARED_ANSWER_HEADER" | i18n }}</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="qa in starredQuestions" :key="qa.label">
          <td>{{qa.label}}</td>
          <td class="text-right">{{qa.value}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "StaredQuestionsComponent",
  props: [ 'starredQuestions']
}
</script>

<style scoped>
  /** TODO remove styles according to spec */
</style>