<template>
  <v-card outlined>

    <v-container class="pa-2" fluid>
      <v-row>
        <v-col align="center"><div class="headline">{{'INTERVIEW_SIDEBAR_HEADER' | i18n}}</div></v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <VerticalLineList :items="interviewData.sidebarData.interviewLinks" idFieldName="interviewId" :fnIsItemSelected="isLinkSelected" @itemSelected="itemSelected" :fnGetEventLineClass="getItemLineClass">
            <template v-slot:default="props">
              <v-container class="py-2 ma-0" fluid>
                <v-row>
                  <v-col cols="6" class="pa-0 ma-0">
                    <SideVariableComponent :link=true text-align="left"  :label="props.item.value" :value="props.item.label"></SideVariableComponent>
                  </v-col>
                  <v-col cols="6" class="pa-0 ma-0">
                    <SideInterviewLink :selected="true" :link-data="props.item"></SideInterviewLink>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </VerticalLineList>
        </v-col>
      </v-row>

      <v-divider class="mt-3"/>

      <v-row v-if="interviewData.sidebarData.showDevelopmentChart">
        <v-col cols="12">
          <SideDevChart :chart-data="interviewData.sidebarData.chartData"></SideDevChart>
        </v-col>
      </v-row>

<!--      <v-divider/>-->

<!--      <v-row v-for="(link, index) in interviewData.sidebarData.interviewLinks" v-bind:key="400+index" justify="center"  :class="{ selectedLink : link.isSelected }">-->
<!--        <v-col cols="6">-->
<!--          <SideVariableComponent :link=true text-align="left"  :label="link.value" :value="link.label"></SideVariableComponent>-->
<!--        </v-col>-->
<!--        <v-col cols="6"  class="linkcol">-->
<!--          <SideInterviewLink :selected="true" :link-data="link"></SideInterviewLink>-->
<!--        </v-col>-->
<!--      </v-row>-->


    </v-container>

  </v-card>
</template>

<script>

import SideVariableComponent from "./SideVariableComponent";
import SideInterviewLink from "./SideInterviewLink";
import SideDevChart from "./SideDevChart";
import VerticalLineList from '../../components/VerticalLineList';

import _ from 'lodash'
import PageNavigator from "../../pagenavigator";
import {mapState} from "vuex";

export default {
  name: 'SideCardComponent',

  components: {
    SideVariableComponent, SideInterviewLink, SideDevChart, VerticalLineList
  },

  data: () => ({}),

  props: {
    interviewData: Object
  },
  methods: {
    isLinkSelected(link) {
      return link.isSelected
    },
    getItemLineClass(link) {
      return link.isCompleted ? 'above' : 'unknown' // TODO what data on the link should indicate below
    },
    itemSelected(event) {
      // console.log('itemSelected', event)
      const link = event.item
      if(link.isCompleted) {
        PageNavigator.navigateToInterviewPage(this, this.activeSurvey.extName, link.interviewId)
      } else {
        PageNavigator.navigateToSamplePage(this, this.activeSurvey.extName, this.$route.params.fromPage, link.interviewId)
      }

    }
  },
  computed: {
    bgAnswerChunks(){
      return _.chunk(Object.values(this.interviewData.sidebarData.bgAnswers), 2);
    },
    ...mapState(['activeSurvey'])
  },

}
</script>
<style scoped>
.facecol {
  text-align: center;
}

.linkcol {
  display: flex;
  align-items: center;
  justify-content: flex-end
}

.selectedLink {
  background-color: rgba(0,0,0,0.12);
}


</style>