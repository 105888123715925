<template>
<div v-bind:style="{ textAlign: textAlign}">
    <div :class="{linkLabel: link, linkValue: !link}">
        {{ label }}
    </div>
    <div :class="{linkValue: link, linkLabel: !link}">
        {{ value }}
    </div>
</div>
</template>

<script>

export default {
  name: 'VariableComponent',

  data: () => ({}),

  props: {
    textAlign: String,
    label: String,
    value: String,
    link: Boolean
  },

}
</script>
<style scoped>
    .noneLinkLabel {
        /*font-size: 16px;*/
        font-size: calc((16/16)*1rem);
    }

    .noneLinkValue {
        /*font-size: 14px;*/
        font-size: calc((14/16)*1rem);
        color: rgba(0,0,0,0.54);
    }

    .linkLabel {
        /*font-size: 16px;*/
      font-size: calc((16/16)*1rem);
    }

    .linkValue {
      /*font-size: 14px;*/
      font-size: calc((14/16)*1rem);
      color: rgba(0,0,0,0.54);
    }

</style>