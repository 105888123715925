<template>
  <v-menu
      :close-on-content-click="false"
      :offset-x='isOffsetX'
      :offset-y='isOffsetY'
      :open-on-hover='isOpenOnHover'
      :transition='transition'
      :value="openMenu"
  >
    <template
        v-slot:activator="{ on }"
    >
      <v-btn
          :color='color'
          v-if='icon'
          v-on="on"
      >
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-btn>
      <v-list-item
          class='d-flex justify-space-between'
          v-else-if='isSubMenu'
          v-on="on"
      >
        {{ name }}
        <div class="flex-grow-1">
        </div>
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-list-item>
      <v-btn
          @click="openMenu=true"
          v-else
          v-on="on"
      >
        {{ name }}
      </v-btn>
    </template>
    <v-list>
      <template
          v-for="(item, index) in menuItems"
      >
        <v-divider
            :key='index'
            v-if='item.isDivider'
        />
        <sub-menu
            :is-offset-x=true
            :is-offset-y=false
            :is-open-on-hover=false
            :is-sub-menu=true
            :key='index'
            :menu-items='item.menu'
            :name='item.name'
            @sub-menu-click='emitClickEvent'
            v-else-if='item.menu'
        />
        <v-list-item
            :key='index'
            @click='emitClickEvent(item)'
            v-else
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'SubMenu',

  props: {
    name: String,
    icon: String,
    menuItems: Array,
    color: { type: String, default: "secondary" },
    isOffsetX: { type: Boolean, default: false },
    isOffsetY: { type: Boolean, default: true },
    isOpenOnHover: { type: Boolean, default: false },
    isSubMenu: { type: Boolean, default: false },
    transition: { type: String, default: "scale-transition" }
  },
  methods: {
    emitClickEvent(item) {
      this.$emit("sub-menu-click", item);
      this.openMenu = false;
    }
  },

  data () {
    return {
      openMenu: false
    }
  },

  computed: {

    // fileMenuItems() {
    //   return [
    //     {
    //       name: "Menu Item 1",
    //       action: () => {
    //         console.log("menu-item-1");
    //       }
    //     },
    //     {isDivider: true},
    //     {name: "Menu Item 2"},
    //     {
    //       name: "Sub 1",
    //       menu: [
    //         {name: "1.1"},
    //         {name: "1.2"},
    //         {
    //           name: "Sub-menu 2",
    //           menu: [
    //             {name: "2.1"},
    //             {name: "2.2"},
    //             {
    //               name: "Sub-menu 3",
    //               menu: [
    //                 {name: "3.1"},
    //                 {name: "3.2"},
    //                 {
    //                   name: "Sub-menu 4",
    //                   menu: [{name: "4.1"}, {name: "4.2"}, {name: "4.3"}]
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //
    //     {name: "Menu Item 3"},
    //     {isDivider: true},
    //     {
    //       name: "Menu Item 4",
    //       action: () => {
    //         console.log("menu-item-4");
    //       }
    //     },
    //     {
    //       name: "Menu Item 5",
    //       action: () => {
    //         console.log("menu-item-5");
    //       }
    //     }
    //   ]
    // }

  },



}
</script>

<style>
.editor {
  width: 100%;
  height: 100px;
}
</style>

