<template>

  <v-container fluid pa-0>
    <v-row class="pa-3">
      <v-col>
        <RankingTable v-if='alertList!==null'
                      v-on:request-data="onRequestData"
                      v-on:view-alert="onAlertSelected"
                      :alert-list="true"
                      :leaf-rows="true"
                      :tableheaders="alertList.headers"
                      :tabledata="alertList.rows"
                      :tablefooter="alertList.footer"
                      :table-options="tableOptions"
        />
      </v-col>
    </v-row>

  </v-container>


</template>


<script>
import {mapGetters, mapState} from 'vuex'
import _ from 'lodash'
import RankingTable from "../../../../views/ranking/RankingTable";
import AlertsService from "../../../../services/AlertsService";
import PageNavigator from "@/pagenavigator"

export default {
  name: 'AlertTable',

  components: {
    RankingTable
  },

  props: {
    componentData: Object
  },

  data: () => ({
    alertList: null,

    tableOptions: {
      page: 1,
      sortBy: [],
      sortDesc: []
    }

  }),

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'filterTerm', 'searchTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getFilterTerm']),

    page: function () {
      return _.find(this.activeSurvey.pages, { extname: 'alerts' })
    }

  },



  methods: {
    replaceCurrentRoute() {
      this.$router.replace( {
        name: 'alerts',
        params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath },
        query: { page: this.tableOptions.page, sortBy: this.tableOptions.sortBy, sortDesc: this.tableOptions.sortDesc }
      })
    },



    onDrillToNamedPath: function (namedPath) {
      if(namedPath.entityId) {
        this.replaceCurrentRoute()
        PageNavigator.navigateToAlertPage(this, this.activeSurvey.extName, namedPath.entityId)
      } else {
        this.$store.commit('setDrillPath', namedPath.path)
      }
    },

    onAlertSelected: function (alertId) {
      this.replaceCurrentRoute()
      PageNavigator.navigateToAlertPage(this, this.activeSurvey.extName, alertId)
    },



    initialFetchData() {
      AlertsService.fetchAlertListDataNew(this.getAuthToken, this.activeSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, this.componentData.config.sortBy, this.componentData.config.sortDesc, this.getFilterTerm ).then((response) => {
        this.alertList = response.data.rankingData
        this.$store.commit('setNumberAlerts', response.data.rankingData.totalNumberRows)
      })
    },

    onRequestData: function (dataRequestDTO) {
      let filterTerm = dataRequestDTO.filter
      if(!filterTerm) {
        filterTerm = this.getFilterTerm
      }

//      this.tableOptions.page = 1+(dataRequestDTO.rowFrom/50)
      this.tableOptions.page = this.pageNumber
      this.tableOptions.sortBy = [dataRequestDTO.sortVar]
      this.tableOptions.sortDesc = [ dataRequestDTO.sortDesc ]

      AlertsService.fetchAlertListDataNew(this.getAuthToken, this.activeSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, dataRequestDTO.sortVar, dataRequestDTO.sortDesc, filterTerm ).then((response) => {
        this.alertList = response.data.rankingData
      })


    },

  },


  watch: {

    $route: {
      handler() {

        let surveyExtName = this.$route.params.surveyExtName
        if(surveyExtName !== this.activeSurvey.extName) {
          this.$store.commit('selectSurvey', surveyExtName)
        }

        let drillPath = this.$route.params.drillPath
        this.$store.commit('setFilteredDrillPath', drillPath)

        this.$store.commit('setFilterTerm', "")

        const pageNumber = (this.$route.query.page) ? this.$route.query.page : 1
        const sortBy = (this.$route.query.sortBy) ? this.$route.query.sortBy : this.componentData.config.sortBy
        const sortDesc = (this.$route.query.sortDesc) ? (this.$route.query.sortDesc === 'true') : this.componentData.config.sortDesc

        this.tableOptions.page = pageNumber
        this.tableOptions.sortBy = [sortBy]
        this.tableOptions.sortDesc = [sortDesc]

        this.initialFetchData();
      },
      immediate: true
    },

    getFilteredDrillPath: function (newPath, oldPath) {
      if (newPath && (newPath !== oldPath)) {
        this.$router.push({ name: 'alerts', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
      }
    },

    filterTerm: function (newTerm, oldTerm) {
      if (newTerm !== oldTerm) {

        AlertsService.fetchAlertListData(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath, "lsegs=1", "none", "none", this.getFilterTerm ).then((response) => {
          this.alertList = response.data.rankingData
          this.$store.commit('setSegmentedDrillPath', response.data.segmentedDrillPath)
        })

      }
    },


    searchTerm: function (newTerm, oldTerm) {
      if (newTerm && (newTerm !== oldTerm)) {

        AlertsService.fetchAlertSuggestions(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath, newTerm).then((response) => {
          const suggestionsDTO = response.data
          this.$store.commit("setSearchSuggestions", suggestionsDTO.suggestions)
        })
      }
    }



  }

}
</script>
