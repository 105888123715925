<template>

  <v-container fluid>
    <v-row style="background-color: #aaaaaa">
      <v-col cols="1">
        <v-text-field disabled v-model="qdata.type" label="questiontype"/>
      </v-col>

      <v-col cols="2">
        <v-text-field v-model="qdata.variable" label="variable"/>
      </v-col>

      <v-col cols="2">
        <v-text-field v-model="qdata.foreignName" label="foreign"/>
      </v-col>

      <v-col cols="3">
        <v-text-field v-model="qdata.features" label="features"/>
      </v-col>

      <v-col cols="3" class="d-flex">
        <v-checkbox v-model="qdata.filterable" label="filter" class="ml-2"></v-checkbox>
        <v-checkbox v-model="qdata.weighted" label="weight" class="ml-2"></v-checkbox>
        <v-checkbox v-model="qdata.anonymizable" label="anon" class="ml-2"></v-checkbox>
        <v-checkbox v-model="qdata.diffmode" label="diff" class="ml-2"></v-checkbox>
      </v-col>

      <v-col cols="1" align-self="center">
        <v-btn @click="onSave()" icon>
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <MetaTextComponent :text-data="qdata.title"/>
    </v-row>
    <v-row>
      <MetaTextComponent :text-data="qdata.question"/>
    </v-row>
    <v-row>
      <MetaTextComponent :text-data="qdata.format"/>
    </v-row>

    <v-row v-for="(cdata,idx) in qdata.choices" :key="idx">
      <MetaChoiceComponent :cdata="cdata"/>
    </v-row>

  </v-container>

</template>

<script>

import {mapGetters, mapState} from "vuex";
import MetadataService from "@/services/MetadataService";
import MetaTextComponent from "@/views/backend/MetaTextComponent";
import MetaChoiceComponent from "@/views/backend/MetaChoiceComponent";

export default {
  name: 'MetaQuestionComponent',

  components: {MetaChoiceComponent, MetaTextComponent },

  props: {
    qdata: Object
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),
  },

  methods: {

    onSave() {
      MetadataService.saveQuestion(this.getAuthToken, this.activeSurvey.extName, this.qdata).then(() => {
        // console.log(response.data)
      })

    }


  }

}
</script>

<style scoped>
</style>