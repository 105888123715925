<template>
    <v-icon small :color="ballColor" :style="{ visibility: ballVisibility }">mdi-checkbox-blank-circle</v-icon>
</template>

<script>

export default {
    name: 'TargetBall',

    props: {
        target: Number
    },

    computed: {

        ballColor: function () {
            if (this.target === 0) { //
                return "#be0000"
            } else if (this.target === 1) {
                return "#be0000"
            } else if (this.target === 2) {
                return "#FFDE1F"
            } else if (this.target === 3) {
                return "#00c896"
            }
            return "#FFFFFF"
        },

        ballVisibility: function() {
            return this.target!=null ? 'visible' : 'hidden'
        }

    },

}

</script>

<style scoped>
</style>
