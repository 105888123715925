<template>
  <v-container fluid pa-3>
    <ConfirmDialog ref="confirm" />

    <v-alert type="error" v-if="errorMessage">
      {{errorMessage}}
    </v-alert>

    <v-form v-if="templateDetails!==null"  ref="form" >
      <v-row class="pa-3">
        <v-col cols>
          <h4>{{headlineLabel}}</h4>
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col cols="4">
          <v-text-field ref="nameField" v-model="templateDetails.name" :label="$t('NAME')" :rules="nameRules" mandatory :disabled="isReadonlyMode"/>
        </v-col>
        <v-col  cols="1" v-if="isUserRoot">
          <v-checkbox v-model="templateDetails.system" label="system"/>
        </v-col>
        <v-col cols="1">
          <v-select
              v-model="templateDetails.locale"
              :items="availableLocales"
              label="locale"
              :disabled="isReadonlyMode"
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-combobox
              v-model="templateDetails.tags"
              :items="tags"
              label="tags"
              multiple
              :disabled="isReadonlyMode"
          ></v-combobox>
        </v-col>
        <v-col v-if="isUserRoot">
          <v-text-field v-model="templateDetails.extname" label="extname"/>
        </v-col>

      </v-row>


      <v-row class="pa-3" v-if="isEmailMode">
        <v-col cols="4">
          <v-text-field ref="subjectField" v-model="templateDetails.subject" :label="$t('MAIL_SUBJECT')" :disabled="isReadonlyMode"/>
        </v-col>
        <v-col cols="1" align-self="center">
          <SubMenu :name="$t('INSERT_VARIABLE')" :menu-items='mergeTagMenuItems(emailEditorOptions.mergeTags)' @sub-menu-click='insertInSubject' v-if="emailEditorOptions && !isReadonlyMode"/>
        </v-col>

      </v-row>

      <v-row class="pa-3">
        <v-col>
          <div class="mt-4">
            <v-btn class="mr-4" v-if="!isReadonlyMode" @click="onSubmitClicked">{{ buttonLabel }}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode && !isNew && !isReadonlyMode" @click="onDeleteClicked">{{'DELETE' | i18n}}</v-btn>
            <v-btn class="mr-4" v-if="(!embeddedMode /*|| enableDuplicate*/) && !isNew && this.templateDetails.design" @click="onDuplicateClicked">{{'DUPLICATE' | i18n}}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode" @click="onCancelClicked">{{'CANCEL' | i18n}}</v-btn>

            <v-dialog
                v-model="dialog"
                width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">Test mail</v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Send test mail
                </v-card-title>

                <v-card-text>
                  <v-text-field v-model.lazy.trim="testmail" label="email" type="text" required/>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="onSendTestMail();dialog = false" :disabled="!testmail || testmail.indexOf('@')===-1">{{'SEND' | i18n }}</v-btn>
                  <v-btn color="primary" v-if="!embeddedMode" text @click="dialog = false">{{'CANCEL' | i18n}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </div>
        </v-col>
      </v-row>

      <v-row v-if="!isEmailMode">
        <v-col cols="12">
          <SMSEditor @change-content="setSMSContents" editorId="myEditor" content="" :merge-tags="emailEditorOptions.mergeTags"/>
        </v-col>
      </v-row>

      <v-row v-if="isEmailMode && emailEditorOptions">
        <v-col cols="12">

          <div style="height: 100%; position: relative" >

            <EmailEditor class="unlayer"
                         :project-id="emailEditorOptions.projectId"
                         ref="emailEditor"
                         min-height="700px"
                         :options="emailEditorOptions"
                         v-on:load="editorLoaded" />

            <div v-if="isReadonlyMode" class="overlay"></div>
          </div>

        </v-col>
      </v-row>

    </v-form>

  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import PageNavigator from "../../pagenavigator";
import MailsService from "@/services/MailsService";
import EmailEditor from "@/components/EmailEditor";
import ConfirmDialog from "@/components/ConfirmDialog";
import SMSEditor from "@/views/mailtemplates/SMSEditor";
import SubMenu from "./SubMenu";

export default {
  name: 'MailTemplateDetails',

  components: {
    SubMenu,
    SMSEditor,
    ConfirmDialog, EmailEditor
  },

  props: {
    embedded: Boolean,
    duplicate: Boolean,
    templateId: null,
    mailAction: null
  },

  data: () => ({
    templateDetails: null,
    repeatPassword: '',

    rolesList: [],

    isNew: false,

    nameRules: [
      v => !!v || 'Name is required'
    ],

    tags: [],
    availableLocales: [],

    emailRules: [ v => /.+@.+/.test(v) || 'Invalid Email address' ],

    emailEditorOptions: null,

    dialog: false,
    testmail: "",

    subjectContents: "",

    errorMessage: null,

    smsContents: ""
  }),

  created: function () {
    if (this.calculateTemplateId !== 0) {
      this.isNew = false;

      MailsService.fetchMailTemplateData(this.getAuthToken, this.activeSurvey.extName, this.calculateTemplateId, this.mailAction).then((response) => {
        this.templateDetails = response.data
      })

    } else {
      this.templateDetails = {
        sendingType: "EMAIL",
        name: "",
        extname: "",
        subject: "",
        labels: "",
        locale: this.getLocale,
        code: "",
        fromName: "",
        fromEmail: ""
      }
      this.isNew = true
    }

    MailsService.fetchUnlayerEditorOptions(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      // console.log(response.data)
      this.emailEditorOptions = response.data
      this.emailEditorOptions.mergeTags = this.mergeTagMenuItems(response.data)
    })

    MailsService.fetchTemplateTags(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      this.tags = response.data
    })

    MailsService.fetchAvailableLocales(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      this.availableLocales = response.data
    })

  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot', 'getLocale']),

    isReadonlyMode() {
      return !this.isUserRoot && this.templateDetails.system
    },

    requiredForEmailRules() {
      return [v => (!this.isEmailMode || (!!v && this.isEmailMode)) || 'Name is required']
    },


    calculateTemplateId() {
      return this.templateId ? this.templateId : this.$route.params.templateId
    },


    isEmailMode() {
      return this.templateDetails.sendingType!=='SMS'
    },

    sendingTypes() {
      return [{ text: "Email", value: "EMAIL" }, {text: "SMS", value: "SMS" }]
    },

    buttonLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    headlineLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    embeddedMode() {
      return this.embedded
    },

    enableDuplicate() {
      return this.duplicate
    }

  },


  methods: {

    mergeTagMenuItems(mergeTags) {
      // console.log("merge tags")
      // console.log(mergeTags)


      let result = []

      if(!mergeTags) {
        return result
      }

      for (const [key, value] of Object.entries(mergeTags)) {
        // console.log("key:" + key)
        // console.log(value)
        // console.log("----")
        // console.log( value.mergeTags )


        let submenu = []

        for (const [, v] of Object.entries(value.mergeTags)) {
          // console.log("v:"+v)
          // console.log(v)


          let tag = v.value.match("\\*\\|(.*?)\\|\\*");         // *|varname|* => {{varname}}
          const insertString = tag ? '{{'+tag[1]+'}}' : v.value
          submenu.push( { name:  v.name, value: insertString} )
        }

        result.push({name: key, menu: submenu})
      }

      return result
    },




    insertInSubject(text) {

      // Get the input element by tag name, using the ref as a base for the search
      // This is more vue-friendly and plays nicer when you duplicate components
      const el = this.$refs.subjectField.$el.querySelector('input')

      // Insert text into current position
      let cursorPos = el.selectionEnd; // Get current Position
      this.templateDetails.subject =
          this.templateDetails.subject.substring(0, cursorPos) + text.value + this.templateDetails.subject.substring(cursorPos);

      // Get new cursor position
      cursorPos += text.length;

      // Wait until vue finishes rendering the new text and set the cursor position.
      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));

    },



    setSubjectContents(subjectContents) {
      this.subjectContents = subjectContents
    },

    setSMSContents(smsContents) {
      this.smsContents = smsContents
    },

    onSendTestMail() {
      this.$refs.emailEditor.editor.exportHtml( (data) => {
        this.templateDetails.code = data.html;
        this.templateDetails.design = JSON.stringify(data.design);

        if(this.isNew) {
          MailsService.createMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails, this.testmail).then((response) => {
            this.templateDetails = response.data
            this.isNew = false
          })
        } else {
          MailsService.updateMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails, this.testmail).then(() => {
          })
        }
      })
    },

    goToList() {
      const fromPage = this.$route.query.fromPage

      if(fromPage) {
        PageNavigator.navigateToPage(this, fromPage, this.activeSurvey.extName,  this.getFilteredDrillPath)
      } else {
        PageNavigator.navigateToDefaultPage(this, this.activeSurvey)
      }
    },

    onCancelClicked() {
      this.goToList()
    },

    onSubmitClicked() {
      if (this.$refs.form.validate()) {

        if(this.isEmailMode) {
          this.$refs.emailEditor.editor.exportHtml( (data) => {
            this.templateDetails.code = data.html;
            this.templateDetails.design = JSON.stringify(data.design);

            if (this.isNew) {
              MailsService.createMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then(() => {
                this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
                if(!this.embedded) {
                  this.goToList()
                }
              }).catch((error) => {
                this.errorMessage = error //"Failed creating Mandrill template - name already in use"
              })

            } else {
              MailsService.updateMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then(() => {
                this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
                if(!this.embedded) {
                  this.goToList()
                }
              }).catch(() => {
                this.errorMessage = "Name already in use?"
              })
            }
          })
        } else { // SMS mode
          this.templateDetails.design = this.smsContents

          if (this.isNew) {
            MailsService.createMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then(() => {
              this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
              if(!this.embedded) {
                this.goToList()
              }
            }).catch((/*error*/) => {
              this.errorMessage = "Failed creating Mandrill template - name already in use"
            })

          } else {
            MailsService.updateMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then(() => {
              this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
              if(!this.embedded) {
                this.goToList()
              }
            })
          }

        }

      }
    },

    async onDuplicateClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Duplicate template?")) {
        this.isNew = true
        this.templateDetails.system=false
        this.templateDetails.name = ""
        this.templateDetails.extname = ""
      }
    },

    async onDeleteClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Delete template?")) {
        MailsService.deleteMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.calculateTemplateId).then(() => {
          this.goToList()
        })
      }
    },


    // https://docs.unlayer.com/reference/authentication
    editorLoaded() {

      const authToken = this.getAuthToken
      const survey = this.activeSurvey.extName

      const editor = this.$refs.emailEditor.editor

      editor.registerCallback('image', function(file, done) {
        MailsService.uploadUnlayerFile(authToken, survey, file).then((response) => {
          done({ progress: 100, url: response.data })
        })
      });



      editor.registerCallback('image:removed', function (image, done) {
        // image will include id, userId and projectId
        MailsService.deleteUnlayerFile(authToken, survey, image.id).then(() => {
          // call this when image has been deleted
          done();
        });
      });



      MailsService.fetchUnlayerImages(this.getAuthToken, this.activeSurvey.extName).then((response) => {
        const images = response.data;

        editor.registerProvider('userUploads', function (params, done) {
          // console.log("images")
          // console.log(images)
          done(images);
        });
      });


      if(this.isNew) {
//        this.$refs.emailEditor.editor.loadDesign( {} );
      } else {
        if(this.templateDetails.design) {
          this.$refs.emailEditor.editor.loadDesign( JSON.parse( this.templateDetails.design ) );
        } else {
          this.$refs.emailEditor.editor.loadDesign( { html: this.templateDetails.code, classic: true });
        }
      }

    },

    updateModelWithUnlayerEditor() {

      this.$refs.emailEditor.editor.exportHtml(
          (data) => {
            this.templateDetails.code = data.html;
            this.templateDetails.design = JSON.stringify(data.design);
          }
      )


    },



    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign(
          (design) => {
            this.templateDetails.design = JSON.stringify(design);

          }
      )
    }

  },


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.unlayer {
  height: 100%;
}

div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128,128,128,0.2);
}

</style>
