<template>

  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table v-if="tableData!==null"
                      :headers="headers"
                      :items="tableData.rows"
                      :hide-default-footer="!tableData.paging"
                      :disable-pagination="!tableData.paging"
                      class="elevation-1">

          <template v-slot:top>
            <v-toolbar flat  v-if="editable">
              <v-btn text color="primary" @click.stop="onNewRowClicked">{{'ADD_ROW' | i18n}}</v-btn>

              <v-dialog v-model="dialog" max-width="60%">
                <DataTableRowEditor  :column-headers="tableData.headers" :row-data="{ ...editedRow }" @save-row="onSaveEditor" @cancel-row="onCancelEditor"/>
              </v-dialog>
            </v-toolbar>
          </template>



          <template v-slot:item.actions="{ item }">
            <v-btn icon @click.stop="onEditItem(item)" class="mr-2">  <v-icon small>mdi-pencil</v-icon>  </v-btn>
            <v-btn icon :disabled="!deleteAllowed" @click.stop="onDeleteItem(item)">  <v-icon small>mdi-delete</v-icon></v-btn>
          </template>



        </v-data-table>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import _ from 'lodash'
import ResultsService from "../../../services/ResultsService";
import {mapGetters, mapState} from "vuex";
import DataTableRowEditor from "@/views/pages/pagecomponents/DataTableRowEditor.vue";

export default {
  name: 'DataTableServerComponent',

  components: {DataTableRowEditor},

  props: {
    componentData: Object,
  },

  data () {
    return {
      tableData: null,
      editedId: null,
      editedRow: null,
      dialog: null
    }
  },

  computed: {
    ...mapState(['activeView']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    editable() {
      return this.tableData.editable
    },

    deleteAllowed() {
      return this.tableData.rows.length>1
    },

    headers() {
      return this.editable ? [ ...this.tableData.headers, { text: this.$t('ACTIONS'), value: 'actions', sortable: false, align: 'center' } ] : this.tableData.headers
    }

  },

  methods: {
    onSaveEditor(rowdata) {
      this.dialog = null
        ResultsService.updateDataTableRow(this.getAuthToken, this.getActiveSurvey.extName, this.tableData.tableId, rowdata).then((/*response*/) => {
          this.loadTableData()
        })

    },

    onCancelEditor() {
      this.dialog = null
    },

    loadTableData() {
      ResultsService.fetchDataTableData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id).then((response) => {
        this.tableData = response.data
      })
    },

    onNewRowClicked() {
      this.editedId = 0
      this.editedRow = { 'id': null, 'col0': '' }
      this.dialog = true
    },

    onEditItem(item) {
      this.editedId = item.id
      this.editedRow = { ... item }
      this.dialog = true
    },

    onDeleteItem(item) {
      const idx =  _.findIndex(this.tableData.rows, r => r.id===item.id );

      if(idx!==-1) {
        ResultsService.deleteDataTableRow(this.getAuthToken, this.getActiveSurvey.extName, this.tableData.tableId, item.id).then((/*response*/) => {
          this.loadTableData()
        })
      }

    },

  },

  watch: {
    $route: {
      handler() {
        this.loadTableData()
      },
      immediate: true
    }
  },


}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
