<template>
  <div>
    <template v-if="dataLoaded">
      <highcharts :options="chartOptions"></highcharts>
    </template>
    <template v-else>
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'
import timeLine from 'highcharts/modules/timeline'

timeLine(Highcharts)

import ResultsService from "../../services/ResultsService";
import ColorManager from "../../colormanager";
import ChartUtil from "../../chartutil";

export default {
  name: 'ChoiceLineChartComponent',

  components: {
    highcharts: Chart
  },

  data () {
    return {
      dataLoaded: false,
      chartOptions: {
        lang: ChartUtil.getLangTranslations(this),
        exporting: ChartUtil.getExportingSettings(),

        chart: {
          type: 'timeline',

          events: {
            render() {
              const chart = this;

              chart.series[0].points.forEach(point => {
                const x = point.plotX;
                const y = point.plotY + chart.plotTop + point.graphic.height / 5;

                if (point.customLabel) {
                  point.customLabel.destroy()
                }

                point.customLabel = chart.renderer
                    .text(point.barLabel, x, y)
                    .css({ color: 'white', fontSize: 20, fontWeight: 'bold' })
                    .add();

                point.customLabel.toFront()

              })
            }
          }
        },


        accessibility: {
          screenReaderSection: {
            beforeChartFormat: '<h5>{chartTitle}</h5>' +
                '<div>{typeDescription}</div>' +
                '<div>{chartSubtitle}</div>' +
                '<div>{chartLongdesc}</div>' +
                '<div>{viewTableButton}</div>'
          },
          point: {
            valueDescriptionFormat: '{index}. {point.label}. {point.description}.',
            height: '50px'
          }
        },
        xAxis: {
          visible: false,
          height: 50
        },
        yAxis: {
          visible: false
        },

        title: null,

        colors: [
          '#ADE8F4',
          '#00B4D8',
          '#0096C7',
          '#0077B6',
          '#023E8A',
          '#03045E'
          // '#4185F3',
          // '#427CDD',
          // '#406AB2',
          // '#3E5A8E',
          // '#3B4A68',
          // '#363C46'
        ],

        plotOptions: {
          series: {
            // general options for all series
          },
          timeline: {
            marker: {
              enabled: true,
              height: 40
            }
          }
        },


        series: [{}],

        tooltip: {
          enabled: false
        },

        credits: {
          enabled: false
        }

      }

    }
  },

  props: {
    componentData: Object
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
  },

  methods: {
    loadChartData() {
      this.dataLoaded = false
      const id = (this.componentData.config) ? this.componentData.config.id : this.componentData.id

      ResultsService.fetchChoiceLineData(this.getAuthToken, this.getActiveSurvey.extName, id, this.getFilteredDrillPath).then((response) => {
        this.updateChart(response.data)
      })
    },

    updateChart(chartdata) {
      this.dataLoaded = true
      let colorManager = new ColorManager()
      colorManager.getColor("ole")

      this.chartOptions.series = [ { data: chartdata.dataPoints  }  ]
      this.chartOptions.colors = chartdata.colors
    }

  },

}
</script>
<style scoped>
</style>
