<template>

    <v-card>
      <v-card-title>
        <span class="text-h5">{{"Edit row"}}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-for="(column) in columnHeaders" :key="column.value" sm="12" md="6">
              <v-text-field v-model="rowData[column.value]" :label="column.text"></v-text-field>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('cancel-row')">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="$emit('save-row', rowData)">Save</v-btn>
      </v-card-actions>
    </v-card>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'DataTableRowEditor',

  components: {},
  data () {
    return {
      rdata: null
    }
  },


  props: {
    columnHeaders: Array,
    rowData: Object
  },

  created() {
    // this.rdata = { ...this.rowData }
  },

  computed: {
    ...mapState(['activeView']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    editable() {
      return false
    },

    // headers() {
    //   return this.columnHeaders.filter(e => e.value!=='actions')
    // }

  },

  methods: {
  },



}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  color: white;
  height: 100%;
  border-radius: 5px;
}

div.title {
  /*font-size: 24px !important;*/
  font-size: calc((24/16)*1rem) !important;
  font-weight: normal;
  line-height: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

div.score {
  font-size: 48px;
  font-size: calc((48/16)*1rem);
  font-weight: 500;
}
</style>
