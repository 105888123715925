<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel>

      <v-expansion-panel-header>{{ 'COMMENTS' | i18n }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container class="pa-2" fluid>




          <v-simple-table>
            <thead>
            <tr>
              <th class="text-left">{{'ALERT_STATE' | i18n}}</th>
              <th class="text-left">{{'USER' | i18n}}</th>
              <th class="text-left">{{'COMMENTS' | i18n}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="comments in allComments" :key="comments.id">
              <td>
                <div>{{comments.created}}</div>
              </td>
              <td>{{comments.username}}</td>
              <td>{{comments.message}}</td>
            </tr>

            <tr v-if="allComments.length===0">
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>

            </tbody>
          </v-simple-table>

          <v-form>
            <v-row>
              <v-col>
                <div>{{'ADD_NEW_COMMENT' | i18n}}</div>
                <v-textarea solo v-model="message"></v-textarea>
              </v-col>
            </v-row>

            <v-row class="pt-0">
              <v-col col="1">
                <div class="text-right">
                  <v-btn @click="onNewComment" :disabled="message.length===0">{{'SAVE' | i18n}}</v-btn>
                </div>
              </v-col>
            </v-row>

          </v-form>


        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import InterviewsService from "@/services/InterviewsService";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'InterviewCommentsComponent',

  components: {
  },

  props: {
    interviewId: Number,
    commentsList: Array
  },

  data () {
    return {
      panel: 0,
      message: "",
      newComments: []
    }
  },


  computed: {
    ...mapState(['activeSurvey', 'interviewData']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey']),

    allComments() {
      return [ ...this.newComments, ...this.commentsList ]
    }

  },

  methods: {
    onNewComment() {
      InterviewsService.addInterviewComment(this.getAuthToken, this.activeSurvey.extName, this.interviewId, this.message).then((response) => {
        this.newComments.unshift( response.data )
        this.message = ""
      })
    }

  }


}
</script>
<style scoped>
/*div.icons {*/
/*  display: flex;*/
/*  justify-content: flex-end;*/
/*}*/
</style>,