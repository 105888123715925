<template>
  <div v-if="filterSet">
    <div v-for="group in filterSet.filterGroups" :key="group.key">
      <FilterGroupComponent :filter-group="group"/>
    </div>
  </div>
</template>

<script>
import FilterGroupComponent from "./FilterGroupComponent";

export default {
  name: 'FilterSetComponent',

  components: {FilterGroupComponent},

  data () {
    return {
      radioGroup: null,
    }
  },

  props: {
    filterSet: Object
  },

}
</script>

<style>

</style>
