<template>
  <v-menu transparent offset-y class="myvmenu">
    <template v-slot:activator="{ on }">
      <v-btn :text="textButton" :color="buttonColor" v-on="on" >
        <div v-if="title != null">{{title}}
        </div>
        <div>{{selectedItem.title}}          <v-icon right dark>mdi-chevron-down</v-icon>

        </div>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in notSelectedMenuItems" :key="index" @click="menuItemSelected(item)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import _ from 'lodash'
import {mapGetters} from "vuex";

export default {
  name: 'SelectionMenuComponent',
  data () {
    return {
      on: false,
      selectedItem: null
    }
  },

  props: {
    title: String,
    selectedName: String,
    menuItems: Array,
    simple: Boolean
  },

  created() {
    this.selectedItem = _.find(this.menuItems, 'name', this.selectedName)
  },


  computed: {
    ...mapGetters(['isUserRoot']),

    notSelectedMenuItems() {
      return this.menuItems.filter(item => (item.name !== this.selectedItem.name ) )
    },

    textButton() {
      return this.simple
    },

    buttonColor() {
//      return "#fff";
      return (this.simple) ? "black" : "primary"
    }

  },

  methods: {
    menuItemSelected(item) {
      // console.log("Menu selected: " + item.title)
      this.selectedItem = item
      this.$emit('item-selected', item.name)
    }

  },

}
</script>

<style scoped>

>>>.v-btn__content {
  flex-direction: column;
  text-transform: none;
}

div.myvmenu {
  display: inline !important;
}

</style>
