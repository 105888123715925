<template>
  <v-expansion-panels expand v-model="panel">
    <v-expansion-panel>

      <v-expansion-panel-header>{{questionGroup.title}}
        <div class="icons">
          <!--          <QuestionAnswerIconComponent v-for="qa in questionGroup.questionAnswers" :qa-data="qa" :use-number-icons="useNumberIcons" :key="qa.extname"/>-->
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-container class="pa-2" fluid>
          <v-row>

            <v-simple-table style="width: 100%">
              <template v-slot:default>
                <thead>
                <tr>
                  <th v-for="column in questionGroup.columns" :key="column.extname" :class="column.rightAlign ? 'text-right' : 'text-left'">
                    {{ column.title }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row,rowidx) in questionGroup.rows" :key="'row'+rowidx">
                  <td v-for="(cell, cellidx) in row.cells" :key="'row'+rowidx+'cell'+cellidx" :class="questionGroup.columns[cellidx].rightAlign ? 'text-right' : 'text-left'">
                    {{cell}}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

// import QuestionAnswerComponent from "./QuestionAnswerComponent";
// import QuestionAnswerIconComponent from "./QuestionAnswerIconComponent";

export default {
  name: 'RecordGroupComponent',

  props: {
    questionGroup: Object,
    useNumberIcons: Boolean
  },

  data () {
    return {
      panel: 0
    }
  },

  components: {
    // QuestionAnswerComponent,
    // QuestionAnswerIconComponent
  },

}
</script>
<style scoped>
div.icons {
  display: flex;
  justify-content: flex-end;
}
</style>