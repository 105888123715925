<template>
  <v-form>
    <v-container fluid>

      <v-checkbox v-for="filter in campaignDetails.campaignFilters" :key="filter.title" :label="filter.title" v-model="filter.selected">
      </v-checkbox>


    </v-container>
  </v-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'DataValidatingCard',

  components: {},

  props: {
    campaignDetails: Object
  },

  data: () => ({
  }),

  computed: {

    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),
  },

}
</script>

<style scoped>

</style>
