<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent @path-selected="onDrillToNamedPath"  :show-filter="true" :show-download="true" :leaf="true"/>
      </v-col>
    </v-row>

    <v-row class="elevation-2">
      <v-col class="pt-0 pb-0">
        <FilterBarComponent v-if="activeSurvey && activeSurvey.timeFilterSet && activeSurvey.standardFilterSet"  :filter-sets="[activeSurvey.standardFilterSet, activeSurvey.timeFilterSet]" :show-info="true" :show-alerts="true"/>
      </v-col>
    </v-row>

    <CampaignDialog v-if="getCampaigns.length>0"/>

    <v-row v-if="page && page.components">
      <v-col v-for="(comp, index) in page.components" :key="index" :cols="comp.config.size">
        <PageComponent :component-data="comp"/>
      </v-col>
    </v-row>

    <v-row v-if="!hasAlertComponent" class="pa-3">
      <AlertTable :component-data="defaultAlertTableComponentData"/>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import _ from 'lodash'
import PageComponent from "../pages/PageComponent";
import DrillPathComponent from "../../components/DrillPathComponent";
import FilterBarComponent from "../../components/FilterBarComponent";
import ResultsService from "../../services/ResultsService";
import CampaignDialog from "@/components/CampaignDialog";
import AlertTable from "@/views/pages/pagecomponents/alerttable/AlertTable";

export default {
  name: 'alertlist',

  components: {
    AlertTable,
    CampaignDialog,
    PageComponent, DrillPathComponent, FilterBarComponent
  },

  data () {
    return {
      defaultAlertTableComponentData: {
        config: {
          id: "defaultAlertTableConfig",
          sortBy: "none",
          sortDesc: false
        }
      },

      pageId: null,
      dialog: true
    }
  },

  methods: {
    onDrillToNamedPath: function (namedPath) {
      if(!namedPath.entityId) {
        this.$store.commit('setDrillPath', namedPath.path)
      }
    },

    loadSegmentedPath() {
      ResultsService.fetchSegmentedDrillPath(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath).then((response) => {
        this.$store.commit('setSegmentedDrillPath', response.data)
//        console.log("#alerts=" + response.data.nOpenAlerts)
        this.$store.commit('setNumberOpenAlerts', response.data.nOpenAlerts)
      })
    },

  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getCampaigns']),

    enableRankingListReport: function() {
      return this.activeSurvey.enableRankingListReport /*&& this.tableData && !this.tableData.leafRows */
    },

    page: function () {
      return _.find(this.activeSurvey.pages, { extname: 'alerts' })
    },

    hasAlertComponent() {
      return this.page!==null && this.page.components.some(comp => comp.type === 'AlertList');
    }

  },

  watch: {
    $route: {
      handler() {
        let drillPath = this.$route.params.drillPath
        this.$store.commit('setFilteredDrillPath', drillPath)
        this.loadSegmentedPath();
      },
      immediate: true
    },
    getFilteredDrillPath: function (newPath, oldPath) {
      if (this.isWatchingFilteredDrillPath && newPath && (newPath !== oldPath)) {
        const pageParams = { pageId: this.$route.params.pageId, surveyExtName: this.activeSurvey.extName, drillPath: newPath }
        this.$router.push({ name: 'page', params: pageParams })
      }
    }
  },


}
</script>

<style scoped>
</style>
