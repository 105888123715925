<template>
  <div class="linkdiv">
    {{ linkData.rating }}
    <v-btn icon @click="interviewLinkClicked(linkData.interviewId)"><v-icon>mdi-chevron-right</v-icon></v-btn>
  </div>
</template>

<script>


import {mapState} from "vuex";
import PageNavigator from "../../pagenavigator";

export default {
  name: 'SideInterviewLink',

  data: () => ({}),

  props: {
    linkData: Object
  },

  components: {
  },

  computed: {
    ...mapState(['activeSurvey'])
  },

  methods: {
    interviewLinkClicked(id) {
      if(this.linkData.isCompleted) {
        PageNavigator.navigateToInterviewPage(this, this.activeSurvey.extName, id)
      } else {
        PageNavigator.navigateToSamplePage(this, this.activeSurvey.extName, this.$route.params.fromPage, id)
      }
    }
  }

}
</script>
<style scoped>
.linkdiv {
  text-align: right;
}

.selectedLink {
  background-color: grey;
}

</style>