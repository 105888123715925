<template>
    <v-btn v-if="pathSegment.children.length===0" text @click="$emit('path-selected', pathSegment.path)">
        <span class="segmentText">{{ name }}</span>
    </v-btn>

    <div v-else >
        <v-menu offset-y v-model="menuOpened">
            <template v-slot:activator="{ on }">
                <v-btn text :color="segmentColor" v-on="on">
                    <span class="segmentText">{{name}}</span>
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </template>

            <v-list class="childlist">
                <v-list-item v-for="item in pathSegment.children" :key="item.path.path" @click="$emit('path-selected', item)">
                    <v-list-item-title>{{ item.name}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </div>

</template>

<script>

    export default {
        name: 'DrillPathSelector',

        data () {
            return {
                on: {},
                menuOpened: false
            }
        },

        computed: {
            name() {
                return this.pathSegment.helpText ?  this.pathSegment.helpText : this.pathSegment.path.name
            },

            segmentColor() {
                return this.pathSegment.helpText ?  "primary" : "#000"
            }

        },

        props: {
            pathSegment: Object
        }

    }
</script>

<style scoped>
    .segmentText {
        letter-spacing: 1.25px;
        text-transform:none !important;
        /*font-size: 14px;*/
        font-size: calc((14/16)*1rem);

        font-weight: normal;
        /*color: #000;*/
    }

    i.helperchevron {
        /*font-size: 16px;*/
        font-size: calc((16/16)*1rem);
        margin-left: 16px;
        color: #000 !important;
    }

    .childlist {
        min-width: 24em;
    }

    li.v-breadcrumbs__divider {
        padding-left: 0;
        padding-right: 0;
    }
</style>
