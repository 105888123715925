<template>
  <v-menu class="menuwrapper" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn class="timebutton" color="primary" dark v-on="on">
        <div>{{selectedTimespan.title}}</div>
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="timelist">
      <v-list-item v-for="(item, index) in initialTimespans" :key="index" @click="menuItemSelected(item)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'CrossTimeSelector',

  data () {
    return {
      initialTimespans: [],
      selectedTimespan: null
    }
  },

  props: {
    timespans: Array
  },

  computed: {
    ...mapState(['activeSurvey'])
  },

  methods: {
    menuItemSelected(item) {
      this.selectedTimespan = item
      this.$emit('change', item.extName)
    },

  },

  created () {
    this.initialTimespans = [...this.timespans];
    this.selectedTimespan = this.timespans[0]
  }

}
</script>
<style scoped>
.timebutton {
  margin-top: 3px;
}

div.filterbar {
  float: right;
}
div.menuwrapper {
  display: inline !important;
  margin-left: 6px;
}

.timelist {
  min-width: 16em;
}

</style>
