<template>
  <v-container fluid>
    <v-row>
      <v-col>

        <v-card class="sentimentCard" outlined>

          <v-container fluid>
            <v-row>
              <v-col>
                <span class="title">{{'SEARCH_WORDS' | i18n}}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <!-- https://github.com/vuetifyjs/vuetify/issues/867 -->
                <v-combobox @change="onSearchClicked" :search-input.sync="searchValue" class="combobox" outlined>
                  <template v-slot:append>
                    <v-btn @click="onSearchClicked" class="searchBtn" height="auto" text>
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="1">
                <IconTextButtonComponent
                    v-if="enableDownloadButton"
                    class="mr-0 pr-0 pt-2"
                    icon="mdi-tray-arrow-down" :label="$t('DOWNLOAD')"
                    color="#0000c8"
                    @click="downloadExcel">
                </IconTextButtonComponent>
              </v-col>
            </v-row>

            <v-row><v-col>
              <v-data-table :headers="headers" :items="rows" :items-per-page="25" class="elevation-2">

                <template v-slot:item.comments="{ item }">
                  <span v-html="item.comments"></span>
                </template>

              </v-data-table>
            </v-col></v-row>

          </v-container>

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import ResultsService from "../../../../services/ResultsService";
import IconTextButtonComponent from "../../../../components/IconTextButtonComponent"

export default {
  name: 'QuestionsSearchComponent',

  components: {
    IconTextButtonComponent
  },

  props: {
    componentData: Object,
    vardto: Object
  },

  created: function () {
    this.headers[0].text = this.$t('COMMENT')
  },


  data () {
    return {
      analyticsData: null,
      searchValue: "",

      headers: [
        { text: 'Kommentar' , value: 'comment' },
      ],

      rows: [],
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    enableDownloadButton () {
      return this.rows && this.rows.length > 0
    }
  },

  methods: {
    onSearchClicked() {
      ResultsService.searchTextQuestions(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, this.searchValue).then((response) => {
        this.headers = response.data.headers;
        this.rows = response.data.rows;
      })
    },

    downloadExcel() {
      let url = ResultsService.searchTextQuestionDownloadUrl(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, this.searchValue);
      window.open(url, "_blank")
    }


  },

}
</script>

<style>
.sentimentCard {
  padding: 30px;
}

span.title {
  /*font-size: 20px;*/
  font-size: calc((20/16)*1rem);
}

.w-80 {
  width: 80%;
}
.searchBtn {
  background-color: blue;
  border-radius: 0 4px 4px 0;
  width:100%;
}
.v-input__append-inner{
  margin:0 !important;
  padding:0 !important;
  width:5%;
  height:100%;
}
.v-input__slot {
  padding-right:0 !important;
}
.theme--light.v-btn.searchBtn {
  color: white;
}

</style>
