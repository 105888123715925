<template>

  <v-card outlined>
    <v-container fluid>
      <v-row>
        <v-col cols="1">
          {{scriptEventData.eventType}}
        </v-col>
        <v-col cols="2">
          {{scriptEventData.updated}}
        </v-col>
        <v-col cols="9">
          {{scriptEventData.message}}
        </v-col>
      </v-row>
    </v-container>
  </v-card>

</template>

<script>

export default {
  name: 'ScriptEventDetails',

  components: {
  },

  props: {
    scriptEventData: Object
  },

  computed: {
  },

}


</script>

<style scoped>
</style>