<template>
  <div class="counter">
        <span v-for="(digit, idx) in digits" :class="digitClass(digit)" :key="idx">
          {{digit}}
        </span>
  </div>

</template>

<script>

export default {
  name: 'SlidingGamificationCounterComponent',
  components: {},

  props: {
    endValue: String
  },

  data: () => ({}),

  computed: {
    digits() {
      return this.endValue.split("");
    }
  },

  methods: {
    digitClass(digit) {
      return 'digit-'+digit;
    }
  }

}


</script>

<style scoped>

.counter {
  float:left;
  margin-top: 4px;
}
.counter span {
  float:left;
  width:35px;
  height:75px;
  margin:0;
  padding:0;
  text-indent:-9999px;
  font-size:0.0001em;
  background-image: url('~@/assets/counter-sprite-gamification.png');
}

/* Set all the background positions, and hook up all the animations */
.counter .digit-0 {
  background-position:0 -650px;
  animation-name: digitSpin0;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin0;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin0;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}
.counter .digit-1 {
  background-position:0 -65px;
  animation-name: digitSpin1;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin1;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin0;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}
.counter .digit-2 {
  background-position:0 -130px;
  animation-name: digitSpin2;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin2;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin2;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}
.counter .digit-3 {
  background-position:0 -195px;
  animation-name: digitSpin3;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin3;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin3;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count:1;
}
.counter .digit-4 {
  background-position:0 -260px;
  animation-name: digitSpin4;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin4;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin4;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}
.counter .digit-5 {
  background-position:0 -325px;
  animation-name: digitSpin5;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin5;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin5;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}
.counter .digit-6 {
  background-position:0 -390px;
  animation-name: digitSpin6;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin6;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin6;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}
.counter .digit-7 {
  background-position:0 -455px;
  animation-name: digitSpin7;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin7;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin7;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}
.counter .digit-8 {
  background-position:0 -520px;
  animation-name: digitSpin8;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin8;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin8;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}
.counter .digit-9 {
  background-position:0 -585px;

  animation-name: digitSpin9;
  animation-duration: 3s;
  animation-iteration-count: 1;

  -webkit-animation-name: digitSpin9;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;

  -moz-animation-name: digitSpin9;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
}

/* Animation */
@keyframes digitSpin0 {
  from { background-position:0 0; }
  to { background-position:0 -650px; }
}
@keyframes digitSpin1 {
  from { background-position:0 0; }
  to { background-position:0 -65px; }
}
@keyframes digitSpin2 {
  from { background-position:0 0; }
  to { background-position:0 -130px; }
}
@keyframes digitSpin3 {
  from { background-position:0 0; }
  to { background-position:0 -195px; }
}
@keyframes digitSpin4 {
  from { background-position:0 0; }
  to { background-position:0 -260px; }
}
@keyframes digitSpin5 {
  from { background-position:0 0; }
  to { background-position:0 -325px; }
}
@keyframes digitSpin6 {
  from { background-position:0 0; }
  to { background-position:0 -390px; }
}
@keyframes digitSpin7 {
  from { background-position:0 0; }
  to { background-position:0 -455px; }
}
@keyframes digitSpin8 {
  from { background-position:0 0; }
  to { background-position:0 -520px; }
}
@keyframes digitSpin9 {
  from { background-position:0 0; }
  to { background-position:0 -585px; }
}

/* Animation for Webkit */
@-webkit-keyframes digitSpin0 {
  from { background-position:0 0; }
  to { background-position:0 -650px; }
}
@-webkit-keyframes digitSpin1 {
  from { background-position:0 0; }
  to { background-position:0 -65px; }
}
@-webkit-keyframes digitSpin2 {
  from { background-position:0 0; }
  to { background-position:0 -130px; }
}
@-webkit-keyframes digitSpin3 {
  from { background-position:0 0; }
  to { background-position:0 -195px; }
}
@-webkit-keyframes digitSpin4 {
  from { background-position:0 0; }
  to { background-position:0 -260px; }
}
@-webkit-keyframes digitSpin5 {
  from { background-position:0 0; }
  to { background-position:0 -325px; }
}
@-webkit-keyframes digitSpin6 {
  from { background-position:0 0; }
  to { background-position:0 -390px; }
}
@-webkit-keyframes digitSpin7 {
  from { background-position:0 0; }
  to { background-position:0 -455px; }
}
@-webkit-keyframes digitSpin8 {
  from { background-position:0 0; }
  to { background-position:0 -520px; }
}
@-webkit-keyframes digitSpin9 {
  from { background-position:0 0; }
  to { background-position:0 -585px; }
}

/* Animation for Gecko (Firefox) */
@-moz-keyframes digitSpin0 {
  from { background-position:0 0; }
  to { background-position:0 -300px; }
}
@-moz-keyframes digitSpin1 {
  from { background-position:0 0; }
  to { background-position:0 -30px; }
}
@-moz-keyframes digitSpin2 {
  from { background-position:0 0; }
  to { background-position:0 -60px; }
}
@-moz-keyframes digitSpin3 {
  from { background-position:0 0; }
  to { background-position:0 -90px; }
}
@-moz-keyframes digitSpin4 {
  from { background-position:0 0; }
  to { background-position:0 -120px; }
}
@-moz-keyframes digitSpin5 {
  from { background-position:0 0; }
  to { background-position:0 -150px; }
}
@-moz-keyframes digitSpin6 {
  from { background-position:0 0; }
  to { background-position:0 -180px; }
}
@-moz-keyframes digitSpin7 {
  from { background-position:0 0; }
  to { background-position:0 -210px; }
}
@-moz-keyframes digitSpin8 {
  from { background-position:0 0; }
  to { background-position:0 -240px; }
}
@-moz-keyframes digitSpin9 {
  from { background-position:0 0; }
  to { background-position:0 -270px; }
}

</style>