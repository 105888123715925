<template>
  <LegacyBigScreen v-if="bigScreenData && bigScreenData.legacy" :big-screen-data="bigScreenData"/>
  <ModernBigScreen v-else-if="bigScreenData" :big-screen-data="bigScreenData"/>
</template>

<script>
import LegacyBigScreen from "@/views/bigscreen/LegacyBigScreen.vue";
import ModernBigScreen from "@/views/bigscreen/ModernBigScreen.vue";
import ResultsService from "@/services/ResultsService";

export default {
  name: 'bigscreen',
  components: {ModernBigScreen, LegacyBigScreen},

  computed: {
    legacyMode() {
      return false
    }
  },

  data: function () {
    return {
      bigScreenData: null,
    }
  },


  methods: {
    loadData: function () {
      const token = this.$route.params.apiKeyToken

      ResultsService.fetchBigScreenData(token).then((response) => {
        this.bigScreenData = response.data
      })

    }

  },


  watch: {
    $route: {
      handler() {
        this.loadData();
      },
      immediate: true
    },

  }



}



</script>


