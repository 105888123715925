<template>
  <v-container fluid v-if="roleData!==null">
    <ConfirmDialog ref="confirm" />

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-container fluid>

              <v-row>
                <v-col cols="2" >
                  <v-text-field v-model="roleData.name" label="name"/>
                </v-col>
                <v-col cols="2" >
                  <v-select item-text="name" item-value="id" label="parent" :items="roleData.roles" v-model="roleData.parentId"></v-select>
                </v-col>

                <v-col cols="1" align-self="center">
                  <v-btn @click="onSave" icon>
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn @click="onDelete" icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" >
                  <v-checkbox v-model="roleData.canaddusers" label="add users"/>
                </v-col>
                <v-col cols="2" >
                  <v-checkbox v-model="roleData.canseealldata" label="see all data"/>
                </v-col>
                <v-col cols="2" >
                  <v-checkbox v-model="roleData.cancreateroles" label="create roles"/>
                </v-col>
                <v-col cols="2" >
                  <v-checkbox v-model="roleData.canviewcompletedsamples" label="view completed samples"/>
                </v-col>
              </v-row>

              <v-row v-for="surveyAccess in roleData.surveyAccessList" :key="surveyAccess.surveyExtName">
                <SurveyAccessComponent :survey-access-data="surveyAccess"/>
              </v-row>

            </v-container>
          </v-card-text>

        </v-card>

      </v-col>
    </v-row>

  </v-container>

</template>

<script>

import {mapGetters} from "vuex";
import RolesService from "@/services/RolesService";
import SurveyAccessComponent from "@/views/backend/SurveyAccessComponent";
import PageNavigator from "@/pagenavigator";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: 'RoleDetails',

  components: {
    ConfirmDialog,
    SurveyAccessComponent
  },

  data: () => ({
    roleData: null
  }),

  props: {
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey']),
  },

  created() {
    RolesService.fetchRoleData(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.roleId).then((response) => {
      this.roleData = response.data;
    })
  },

  methods: {

    onSave() {
      RolesService.saveRole(this.getAuthToken, this.getActiveSurvey.extName, this.roleData).then(() => {
        PageNavigator.navigateToSurveyListPage(this, this.getActiveSurvey.extName)
      })
    },

    async onDelete() {
      if ( await this.$refs.confirm.open("Confirm", "Delete role?")) {
        RolesService.deleteRole(this.getAuthToken, this.getActiveSurvey.extName, this.roleData.id).then(() => {
          PageNavigator.navigateToSurveyListPage(this, this.getActiveSurvey.extName)
        })
      }
    },

  }
}

</script>

<style scoped>
</style>