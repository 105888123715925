<template>
  <div class="filterbar">

    <div class="leftBarPart">
      <div class="logoWrapper">
        <LogoComponent square/>
      </div>
      <div class="ml-1">
        <div class="nodename">{{getLastDrillPathSegmentName}}</div>
        <div v-if="showInfo" style="font-size: 13px">{{ (showAlerts ? 'ALERTLIST' : 'INTERVIEWS') | i18n}}: {{countToShow}}</div>
      </div>
    </div>

    <div class="rightBarPart">
      <v-chip-group class="chipscontainer">

        <template v-for="(item) of filterItems">

          <v-tooltip v-if="item.filter.multipleSelections" bottom :key="item.filter.extName+item.option.extName">
            <template v-slot:activator="{ on }">

              <v-chip
                  v-on="on"
                  color="#EBEBEB"
                  text-color="black"
                  class="singleChip"
                  :close="item.filter.multipleSelections"
                  v-model="chips[0]"
                  @click:close="onCloseClicked(item)">{{buildChipName(item, item.option)}}
              </v-chip>

            </template>
            <span>{{buildChipTooltipText(item, item.option)}}</span>
          </v-tooltip>

        </template>


      </v-chip-group>

      <div class="controlsContainer" v-if="isFiltersLoaded">
        <TrendTimeComponent/>
        <template v-for="(item) of filterItems">
          <QuickTimeSelector v-if="!item.filter.multipleSelections" :filter-item="item" :filter-sets="filterSets" :key="item.filter.extName"/>
        </template>
        <IconTextButtonComponent v-on:click="onFilterButtonClicked" icon="mdi-filter-outline" :color="filterButtonColor" :label="$t('DEFAULT_FILTERGROUP_NAME')"/>
      </div>
    </div>
  </div>


</template>

<script>
import {mapGetters, mapState} from "vuex";
import QuickTimeSelector from "./QuickTimeComponent";
import ReportsService from "../services/ReportsService";
import TrendTimeComponent from "./TrendTimeComponent";
import LogoComponent from "./LogoComponent";
import IconTextButtonComponent from "./IconTextButtonComponent";

export default {
  name: 'FilterBarComponent',
  components: {IconTextButtonComponent, LogoComponent, TrendTimeComponent, QuickTimeSelector},
  data() {
    return {
      on: true,
      chips: [true],
      filterItems: []
    }
  },

  props: {
    filterSets: Array,
    showInfo: Boolean,
    showAlerts: Boolean
  },

  computed: {
    ...mapState(['activeSurvey', 'combinedFilters', 'filterSideBar', 'numberAlerts']),
    ...mapGetters(['getLastDrillPathSegmentName', 'getInterviewCount', 'getCampaignName']),

    countToShow() {
      return this.showAlerts ? this.numberAlerts : this.getInterviewCount
    },

    filterButtonColor() {
      return this.filterSideBar ? '#0000c8' : '#757575'
    },

    isFiltersLoaded() {
      return this.filterSets.every(e => e!==null)
    }

  },

  methods: {


    buildChipName(item, option) {
      if(option.title.endsWith("~")) {
        return option.title.startsWith("_") ?
            this.$t("FILTER_NOT_CONTAINS") + " " + option.title.slice(1, -1) :
            this.$t("FILTER_CONTAINS") + " " + option.title.slice(0, -1)
      } else if(option.title.startsWith("_")) {
        return this.$t("FILTER_NO_MATCH") + " " + option.title.substring(1)
      } else if(item.filter.extName==='cf') {
        const campaignId = parseInt(option.extName.substring(2))
        const campaignName = this.getCampaignName(campaignId)
        if(!campaignName) {
          this.$store.dispatch('fetchCampaignName', { surveyName: this.activeSurvey.extName, campaignId: campaignId})
        }
        return campaignName ? campaignName : "?"

      } else {
        if(option.extName.startsWith("_")) {
          return this.$t("FILTER_NO_MATCH") + " " + option.title
        } else {
          return option.title
        }
      }

    },

    buildChipTooltipText(item, option) {
      if(item.filter.extName==='cf') {
        return option.title
      } else {
        return item.filter.title
      }

    },


    onFilterButtonClicked() {
      this.$store.commit('toggleFilterSideBarState')
    },

    onCloseClicked(item) {
      // console.log("Clear filter: " + item.filter.extName + " option: " + option)
      // console.log(item)
      // console.log(option)

      if(item.filter.extName==='cf') {
        this.$store.commit('clearFilter', {filterName: item.option.extName, filterValue: null})
      } else {
        this.$store.commit('clearFilter', {filterName: item.filter.extName, filterValue: item.option.extName})
      }

    },

    onPDFDownload() {
      window.open(ReportsService.indicatorsReportURL(this.$store.getters.getAuthToken, this.activeSurvey.extName, this.$store.getters.getFilteredDrillPath), '_blank')
    },

    onExcelDownload() {
      this.$emit('excel-report')
    }

  },


  watch: {
    combinedFilters: {
      handler() {
        let items = []

          for (let filterSet of this.filterSets) {
            if(filterSet) {
              items.push(...this.combinedFilters.getFilterItems(filterSet))
            }
          }

        this.filterItems = items
      },
      immediate: true,
      deep: true
    },

    filterSets: {
      handler() {
        let items = []

          for (let filterSet of this.filterSets) {
            if(filterSet) {
              items.push(...this.combinedFilters.getFilterItems(filterSet))
            }
          }
        this.filterItems = items
      },
      immediate: true,
      deep: true
    }





  },


}
</script>
<style scoped>
div.filterbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-top: 7px;
  padding-bottom: 7px;
}

div.leftBarPart {
  padding-left: 13px !important;
  display: flex;
  align-items: center;
}

div.logoWrapper {
  display: flex;
}

div.nodename {
  justify-content: flex-start;
  flex: 1;
  font-size: 140%;
  font-weight: bold;
  width: 15em
}

div.rightBarPart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
}

div.chipGroupContainer {
  max-width: 60%;
}

div.filters {
  display: flex;
  align-items: center;
}

div.controlsContainer {
  display: flex;
  /*flex: 1 1 auto;*/
}

.chipscontainer {
  max-width: 70%;
  /*flex: 2 1 auto;*/
}

.singleChip {
  margin-right: 1em;
}

div.filterButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.filterButton span {
  font-size: calc((13/16)*1rem);
  /*font-size: 13px;*/
  color: rgba(0,0,0,.87);
}



</style>
