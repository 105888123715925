<template>
  <v-menu ref="quickmenu" class="menuwrapper" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn class="timebutton" color="primary" dark v-on="on">
        <div>{{filterItem.option.title}}</div>
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="timelist">
      <v-list-item v-for="(item, index) in items" :key="index" @click="menuItemSelected(item)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="userSelectableRange" @click.stop="onUserSelectableRangeClicked">

        <v-list-item-title>{{'SELECT_RANGE' | i18n}}</v-list-item-title>

        <v-dialog v-model="showDialog" :max-width="400" style="{ z-index: 200 }">
          <v-card>
            <v-container class="wrapper" fluid>
              <v-row>
                <v-col cols="12">
                  <v-date-picker v-model="dateRange" range :locale="locale" value="dd-mm-YYYY" full-width></v-date-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn text :disabled="dateRange.length===0" @click="onDateRangeSelected">{{'SELECT' | i18n}}  </v-btn>
                  <v-btn text @click="onDateRangeSelected">{{'CANCEL' | i18n}}  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

      </v-list-item>

      <v-list-item @click="onOtherPeriodsClicked">
        <v-list-item-title>{{'OTHER_PERIODS' | i18n}}</v-list-item-title>
      </v-list-item>

    </v-list>
  </v-menu>

</template>

<script>
import {mapGetters, mapState} from "vuex";
import _ from 'lodash'

export default {
  name: 'QuickTimeSelector',

  data () {
    return {
      items: [],
      showDialog: false,
      dateRange: []
    }
  },

  props: {
    filterItem: Object,
    filterSets: Array
  },


  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    userSelectableRange() {
      const timeFilterSet = _.find(this.filterSets, {'type': 'time'})

      if (timeFilterSet) {
        const quickFilterGroup = _.find(timeFilterSet.filterGroups, {'quickSelect': true})
        return quickFilterGroup ? quickFilterGroup.userSelectableRange : false;
      }

      return false;
    },

    locale() {
      return this.getLocale.replace('_', '-')
    },

  },

  methods: {
    menuItemSelected(item) {
      this.$store.commit('setFilter', {filterName: 'ftime', filterValue: [item.extName]})
    },

    onOtherPeriodsClicked() {
      this.$store.commit('setFilterSideBarState', true )
      this.$store.commit('setTimeFilterTabActive')
    },

    onUserSelectableRangeClicked() {
      this.$refs.quickmenu.isActive = false
      this.showDialog = true
    },

    onDateRangeSelected() {
      this.showDialog=false

      const isSingleDay = this.dateRange.length===1

      if(isSingleDay) {
        this.dateRange.push( this.dateRange[0] )
      }

      let fromDate = new Date(this.dateRange[0])
      let toDate = new Date(this.dateRange[1])

      if(fromDate.getTime()>toDate.getTime()) {
        fromDate = new Date(this.dateRange[1])
        toDate = new Date(this.dateRange[0])
      }

      const title = isSingleDay ?
          fromDate.toLocaleDateString(this.locale) :
          fromDate.toLocaleDateString(this.locale) + " - " + toDate.toLocaleDateString(this.locale);

      toDate.setDate(toDate.getDate() + 1);
      const from = fromDate.toISOString().substring(8, 10) + fromDate.toISOString().substring(5, 7) + fromDate.toISOString().substring(0, 4);
      const to = toDate.toISOString().substring(8, 10) + toDate.toISOString().substring(5, 7) + toDate.toISOString().substring(0, 4);

      const timefilter = "Ige" + from + ":" + to

      this.$store.commit('addCustomTimeFilter', {extName: timefilter, title: title})
      this.$store.commit('setFilter', {filterName: 'ftime', filterValue: [timefilter]})
    },

  },

  created () {
    let timeFilterSet = _.find(this.filterSets, { 'type': 'time'})

    if(timeFilterSet) {
      let quickFilterGroup = _.find(timeFilterSet.filterGroups, {'quickSelect': true} )
      if(quickFilterGroup) {
        for(let filter of quickFilterGroup.filters) {
          for(let option of filter.options) {
            this.items.push( option )
          }
        }
      }

    }

  }

}
</script>
<style scoped>
.timebutton {
  margin-top: 3px;
}

div.menuwrapper {
  display: inline !important;
  margin-left: 6px;
}

.timelist {
  min-width: 16em;
}

</style>
