import backend from '@/services/backend'

export default {

  fetchAlertListData (authToken, surveyName, drillpath, filters, sortVar, sortDesc, filterTerm) {
    const safeFilterTerm = (filterTerm && filterTerm.length>0) ? filterTerm : "none";

    if(!sortVar) {
      sortVar = "none"
    }

//    console.log('fetchAlertListData: ' + authToken + ' survey:' + surveyName + " path:" + drillpath + " filters:" + filters +  " sortVar:" + sortVar + " sortDesc:" + sortDesc+ " filterTerm:" + filterTerm)
    return backend.get('vuejs/alert/list/' + surveyName + '/' + drillpath + '/' + filters + '/' + sortVar + '/' + sortDesc + '/' + safeFilterTerm, { headers: { Authorization: authToken } })
  },

  fetchAlertListDataNew(authToken, surveyName, configId, drillpath, sortVar, sortDesc, filterTerm) {
    const safeFilterTerm = (filterTerm && filterTerm.length>0) ? filterTerm : "none";
    return backend.get('vuejs/alert/listnew/' + surveyName + '/' + configId + '/' + drillpath + '/' + sortVar + '/' + sortDesc + '/' + safeFilterTerm, { headers: { Authorization: authToken } })
  },

  fetchAlertData (authToken, surveyName, alertId) {
//    console.log('fetchAlertData: ' + authToken + ' survey:' + surveyName + " alertId:" + alertId)
    return backend.get('vuejs/alert/details/' + surveyName + '/' + alertId, { headers: { Authorization: authToken } })
  },

  updateAlert (authToken, surveyName, alertId, alertUpdate) {
//    console.log('updateAlert: ' + alertId)
    return backend({ method: 'post', url: 'vuejs/alert/update/' + surveyName + '/' + alertId, data: alertUpdate, headers: { Authorization: authToken }})
  },

  fetchAlertSuggestions(authToken, surveyName, drillPath, searchTerm) {
//    console.log('fetchAlertSuggestions: ' + authToken + ' dp:' + drillPath + " searchterm: " + searchTerm)
    return backend.get('vuejs/alert/suggestions/' + surveyName + '/' + drillPath + '/' + searchTerm, { headers: { Authorization: authToken } })
  },


}
