<template>
  <div class="d-flex justify-center pa-10 elevation-1">
    <div class="actionText"><div><v-icon class="mr-2">{{iconName}}</v-icon></div><div>{{ labelText }}</div></div>
  </div>
</template>

<script>

export default {
  name: 'MessageActionComponent',

  computed: {
    labelText() {
      if(this.noSuggestions) {
        return this.$t('NO_ACTION_SUGGESTIONS')
      }
      if(this.noActives) {
        return this.$t('NO_OPEN_ACTIONS')
      }

      return "?"

    },

    iconName() {
      if(this.noSuggestions) {
        return "mdi-party-popper"
      }
      if(this.noActives) {
        return "mdi-alert-circle-outline"
      }

      return "?"
    }
  },

  props: {
    noSuggestions: Boolean,
    noActives: Boolean
  },

}
</script>

<style scoped>

div.actionText {
  /*font-size: 16px;*/
  font-size: calc((16/16)*1rem) !important;
  color: #000;
  display: flex;
  align-items: flex-end;}

</style>
