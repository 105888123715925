<template>
  <v-chip class="statechip">
    <v-icon class="stateicon">{{iconName}}</v-icon>{{title}}
  </v-chip>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SampleStateCell',

  props: {
    state: Number,
    title: String
  },

  computed: {
    ...mapGetters(['getActiveSurvey']),

    isOpen() {
      return this.state.value === 2
    },

    iconName() {
      switch (this.state) {
        case 0: // STATE_COMPLETED
          return 'mdi-check-circle'
        case 1: // STATE_SAMPLE_NEW
          return 'mdi-plus-circle'
        case 2: // STATE_SAMPLE_APPROVED
          return 'mdi-check-circle-outline'
        case 3: // STATE_SAMPLE_UNAPPROVED / not ready yet
          return 'mdi-clock'
        case 4: // STATE_SAMPLE_CANCELLED / not approved
          return 'mdi-cancel'
      }

      return ''
    },



  },

  methods: {
  },

}

</script>


<style scoped>
span.statechip {
  white-space:nowrap
}

.stateicon {
  margin-right: 4px;
}

span.open {
  background: #C00000 !important;
  color: white;
}
</style>

