<template>
  <v-container fluid pa-0>

    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent @path-selected="onDrillToNamedPath" :leaf="true" :show-download="showDownload" :interview-id="$route.params.interviewId"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathBackComponent/>
      </v-col>
    </v-row>

    <v-row class="pt-3 px-3 pb-0">
      <v-col cols="12">
        <InterviewHeader :interviewData="interviewData" v-if="interviewData" />
      </v-col>
    </v-row>

    <v-row  class="pa-3">
      <v-col cols="3">
        <SideCardComponent v-if="interviewData!=null" :interview-data="interviewData"/>
      </v-col>

      <v-col cols="9">
        <InterviewComponent v-if="interviewData!=null" :interview-data="interviewData"></InterviewComponent>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

  import {mapGetters, mapState} from "vuex";
  import SideCardComponent from "./SideCardComponent";
  import InterviewComponent from "./InterviewComponent";
  import InterviewHeader from "./InterviewHeader";
  import DrillPathComponent from "../../components/DrillPathComponent";
  import DrillPathBackComponent from "../../components/DrillPathBackComponent";

  export default {
    name: 'interview',

    components: {
      InterviewHeader,
      DrillPathComponent, DrillPathBackComponent, SideCardComponent, InterviewComponent
    },

    methods: {

      onDrillToNamedPath: function (namedPath) {
        if(!namedPath.entityId) {
          this.$store.commit('setDrillPath', namedPath.path)
          this.$router.push({ name: 'rankings', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
        }
      }


    },

    computed: {
      ...mapState(['activeSurvey', 'interviewData']),
      ...mapGetters(['getFilteredDrillPath', 'getEnableSingleInterviewReport']),

      showDownload() {
        return this.getEnableSingleInterviewReport;
      }

    },

    watch: {
      $route: {
        handler(/*$route*/) {
          let surveyExtName = this.$route.params.surveyExtName
          if(surveyExtName !== this.activeSurvey.extName) {
            this.$store.commit('selectSurvey', surveyExtName)
          }

          let interviewId = this.$route.params.interviewId
          this.$store.dispatch('fetchInterviewData', interviewId)
        },
        immediate: true
      },
    },

  }
</script>
<style scoped>
</style>