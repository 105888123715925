<template>
        <v-dialog v-model="dialog" width="60%">
            <template v-slot:activator="{ on }">
                <v-btn class="bgbutton"  color="grey" dark v-on="on">{{'BACKGROUND_INFO' | i18n}}</v-btn>
            </template>

            <v-card>
                <v-container fluid>
                    <v-row class="pax-2">
                        <v-col>
                            <div  class="mainHeadline">{{'BACKGROUND_INFO' | i18n}}</div>
                        </v-col>
                    </v-row>

                    <template v-for="group in backgroundGroups">
                        <v-row class="pax-2"  v-bind:key="group.extname">
                            <v-col>
                               <div  class="groupTitle">{{group.title}}</div>
                            </v-col>
                        </v-row>

                        <v-row v-for="qa in group.questionAnswers" class="pax-2" v-bind:key="qa.extname" justify="space-between">
                            <div class="labelValue">
                                <div>{{qa.title}}</div>
                                <div>{{qa.value}}</div>
                            </div>
                        </v-row>

                    </template>

                </v-container>

                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="dialog = false">{{'CLOSE' | i18n}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>

export default {
  name: 'BackgroundDetailsDialog',

  components: {},

  data: () => ({
      dialog: false
  }),


  props: {
    interviewData: Object,
    textAlign: String,
    label: String,
    value: String,
  },

    computed: {
        backgroundGroups() {
            return (this.interviewData && this.interviewData.backgroundGroups) ? this.interviewData.backgroundGroups : []
        }
    },



}

</script>

<style scoped>
    .bgbutton {
        margin:0 auto;
        display:block;
    }


    .mainHeadline {
        height: 28px;
        color: rgba(0,0,0,0.87);
        /*font-size: 24px;*/
        font-size: calc((24/16)*1rem);
        font-weight: 500;
    }

    .groupTitle {
        color: rgba(0,0,0,0.54);
        /*font-size: 14px;*/
        font-size: calc((14/16)*1rem);
        border-bottom: 1px solid rgba(0,0,0,0.12);
    }

    .labelValue {
        display: flex;
        border-bottom: 1px solid rgba(0,0,0,0.12);
        margin-left: 1em;
        margin-right: 1em;
        justify-content: space-between;
        width: 100%;
    }

</style>