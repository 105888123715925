<template>
  <div class="timelineContainer pt-0">
    <div v-for="item in items"
        :key="item[idFieldName]"
        :class="getEventClasses(item)"
    >
       <div :class="getEventContentClasses(item)" @click="selectItem(item)">
        <slot v-bind:item="item"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VerticalLineList',
  props: [ 'items', 'idFieldName', 'fnIsItemSelected', 'fnGetEventLineClass'],
  data () {
    return {

    }
  },
  methods: {
    getEventContentClasses(item) {
      return this.isSelected(item) ? 'eventContent selectedItem' : 'eventContent'
    },
    /**
     * Gets the CSS classes for the event-div.
     * If props.fnGetEventLineClass is not null it should be a method returning 'default', 'unknown', 'above', or 'below'
     * @param item The item
     * @returns {*|string} - The CSS classes to put on.
     */
    getEventClasses (item) {
      return 'event ' + (this.fnGetEventLineClass ? this.fnGetEventLineClass(item)  : '')
    },
    isSelected (item) {
      return this.fnIsItemSelected == null ? false : this.fnIsItemSelected(item)
    },
    selectItem (item) {
      const payload = {
        item: item
      }
      // console.log('before emit', payload)
      this.$emit('itemSelected', payload)
    }
  },
  computed: {
  }
}
</script>
<style scoped>

.timelineContainer {
  width: 100%;
/*  max-width: 500px; */
  margin: 0 0 0 10px;
  /*border-left: 2px solid #222; !* move this to the events *!*/
  padding-left: -3px;
  padding-right: 10px;
}

.timelineContainer .event {
  /* box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.2); */
  line-height: 2;
  cursor: pointer;
  position: relative;
  transition: all 500ms;
}

.timelineContainer div.above.event {
  border-left: 2px solid rgb(0, 200, 150);
  /*border-left: 2px solid olivedrab; !* move this to the events *!*/
}

.timelineContainer div.below.event {
  border-left: 2px solid rgb(185, 19, 62); /* move this to the events */
  /*border-left: 2px solid indianred; !* move this to the events *!*/
}

.timelineContainer div.unknown.event {
  border-left: 2px solid darkgrey; /* move this to the events */
}

.timelineContainer .event::before {
  content: "";
  width: 14px;
  height: 14px;
  border: 2px solid #000;
  position: absolute;
  left: -8px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: black;
  transition: all 300ms;
}

.timelineContainer .event:hover {
  /*box-shadow: 1px 6px 16px -1px rgba(0, 0, 0, 0.1);*/
}

.timelineContainer .event:hover::before {
  background: #fff;
}

.timelineContainer .event .eventContent {
  padding: 6px 10px 6px 24px;
  /*margin: 48px 0;*/
}

.timelineContainer .event .eventContent.selectedItem {

  background-color: rgba(0,0,0,0.12);
}
</style>