<template>
  <DevChartComponent :selection-block-bus="selectionBlockBus"  v-if="componentData.type==='DevelopmentChartConfig'" :component-data="componentData" />
  <DataTableComponent v-else-if="componentData.type==='datatableconfig'" :component-data="componentData" />
  <BenchmarkChartComponentDiscriminator v-else-if="componentData.type==='BenchmarkChartConfig'" :component-data="componentData" />
  <PriorityChartComponent v-else-if="componentData.type==='PriorityChartConfig'" :component-data="componentData" />
  <EffectChartComponent v-else-if="componentData.type==='EffectChartConfig'" :component-data="componentData" />
  <ChoiceLineChartComponent v-else-if="componentData.type==='ChoiceLineChartConfig'" :component-data="componentData" />
  <GroupedChartComponent  v-else-if="componentData.type==='GroupedChartConfig'" :component-data="componentData" />
  <DeltaChartComponent  v-else-if="componentData.type==='DeltaChartConfig'" :component-data="componentData" />
  <VariableBlockComponent v-else-if="componentData.type==='VariableBlockComponent' || componentData.type==='VariableBlockConfig'" :component-data="componentData" />
  <VariableActionBlockComponent v-else-if="componentData.type==='VariableActionBlockComponent'" :component-data="componentData" />
  <MenuBlockComponent v-else-if="componentData.type==='MenuBlockComponent'" :component-data="componentData" />
  <DataUploaderComponent v-else-if="componentData.type==='DataUploaderConfig'" :component-data="componentData" />
  <ImportSetListComponent v-else-if="componentData.type==='ImportSetListConfig'" :component-data="componentData" />
  <SelectionBlockComponent v-else-if="componentData.type==='SelectionBlockConfig'" :component-data="componentData" />
  <ActionOverviewComponent v-else-if="componentData.type==='ActionOverviewConfig'" :component-data="componentData" />
  <ActionPlanComponent v-else-if="componentData.type==='ActionPlanConfig'" :component-data="componentData" />
  <ActionManagerComponent v-else-if="componentData.type==='ActionManagerConfig'" :component-data="componentData" />
  <ResultsBlockComponent v-else-if="componentData.type==='ResultsBlockConfig'" :component-data="componentData" />
  <TextAnalyticsComponent v-else-if="componentData.type==='TextAnalyticsComponent'" :component-data="componentData" />
  <FeedbackOverviewComponent v-else-if="componentData.type==='FeedbackOverviewComponent'" :component-data="componentData" />
  <FeedbackSentimentComponent v-else-if="componentData.type==='FeedbackSentimentComponent'" :component-data="componentData" />
  <FeedbackSearchComponent v-else-if="componentData.type==='FeedbackSearchComponent'" :component-data="componentData" />
  <QuestionSearchComponent v-else-if="componentData.type==='QuestionSearchComponent'" :component-data="componentData" />
  <GamificationOverviewComponent v-else-if="componentData.type==='GamificationOverviewConfig'" :component-data="componentData" />

  <AlertTable v-else-if="componentData.type==='AlertList'" :component-data="componentData" />
  <SampleTable v-else-if="componentData.type==='SampleTable'" :component-data="componentData" />
  <RankingTableComponent v-else-if="componentData.type==='RankingTableComponent'" :component-data="componentData" />

  <GlobalOverviewComponent v-else-if="componentData.type==='GlobalOverviewComponent'" :component-data="componentData" />
  <IndicatorBlockComponent v-else-if="componentData.type==='IndicatorBlockComponent'" :component-data="componentData" />

  <MailTemplatesListComponent v-else-if="componentData.type==='MailTemplatesListComponent'" :component-data="componentData" />
  <MailCampaignsListComponent v-else-if="componentData.type==='MailCampaignsListComponent'" :component-data="componentData" />
  <MailCampaignDetails v-else-if="componentData.type==='MailCampaignDetailsComponent'" :component-data="componentData" />

  <GlobalCampaignsListComponent v-else-if="componentData.type==='GlobalCampaignsListComponent'" :component-data="componentData" />

  <GlobalValuesTableComponent v-else-if="componentData.type==='GlobalValuesTableComponent'" :component-data="componentData" />

  <DiscriminatorComponent v-else-if="componentData.type==='DiscriminatorComponent'" :component-data="componentData" />


  <div v-else>*Unknown component: {{componentData.type}}</div>
</template>

<script>

import DevChartComponent from "./DevChartComponent";
import VariableBlockComponent from "./pagecomponents/VariableBlockComponent";
import MenuBlockComponent from "./pagecomponents/MenuBlockComponent";
import SelectionBlockComponent from "./pagecomponents/SelectionBlockComponent";
import ActionPlanComponent from "./pagecomponents/ActionPlanComponent";
import PriorityChartComponent from "./PriorityChartComponent";
import EffectChartComponent from "./EffectChartComponent";
import ActionManagerComponent from "./pagecomponents/ActionManagerComponent";
import ResultsBlockComponent from "./pagecomponents/ResultsBlockComponent";
import DataTableComponent from "./pagecomponents/DataTableComponent";
import TextAnalyticsComponent from "./pagecomponents/textanalytics/TextAnalyticsComponent";
import FeedbackOverviewComponent from "./pagecomponents/textanalytics/FeedbackOverviewComponent";
import FeedbackSentimentComponent from "./pagecomponents/textanalytics/FeedbackSentimentComponent";
import FeedbackSearchComponent from "./pagecomponents/textanalytics/FeedbackSearchComponent";
import QuestionSearchComponent from "./pagecomponents/textanalytics/QuestionSearchComponent";
import GamificationOverviewComponent from "./pagecomponents/gamification/GamificationOverviewComponent";
import SampleTable from "@/views/pages/pagecomponents/sampletable/SampleTable";
import RankingTableComponent from "@/views/pages/pagecomponents/rankingtable/RankingTableComponent";
import ChoiceLineChartComponent from "@/views/pages/ChoiceLineChartComponent";
import GroupedChartComponent from "@/views/pages/GroupedChartComponent";
import VariableActionBlockComponent from "@/views/pages/pagecomponents/VariableActionBlockComponent";
import GlobalOverviewComponent from "@/views/login/GlobalOverviewComponent";
import IndicatorBlockComponent from "@/views/login/IndicatorBlockComponent";
import AlertTable from "@/views/pages/pagecomponents/alerttable/AlertTable";
import MailTemplatesListComponent from "@/views/pages/pagecomponents/MailTemplatesListComponent";
import MailCampaignsListComponent from "@/views/pages/pagecomponents/MailCampaignsListComponent";
import MailCampaignDetails from "@/views/mailcampaigns/MailCampaignDetails";
import DataUploaderComponent from "@/views/pages/pagecomponents/datauploader/DataUploaderComponent";
import ImportSetListComponent from "@/views/pages/pagecomponents/datauploader/ImportSetListComponent";
import DeltaChartComponent from "@/views/pages/DeltaChartComponent.vue";
import GlobalCampaignsListComponent from "@/views/pages/pagecomponents/GlobalCampaignsListComponent.vue";
import ActionOverviewComponent from "@/views/pages/pagecomponents/ActionOverviewComponent.vue";
import DiscriminatorComponent from "@/views/pages/pagecomponents/DiscriminatorComponent.vue";
import BenchmarkChartComponentDiscriminator from "@/views/pages/BenchmarkChartComponentDiscriminator.vue";
import GlobalValuesTableComponent from "@/views/pages/pagecomponents/GlobalValuesTableComponent.vue";


export default {
  name: 'PageComponent',
  components: {
    GlobalValuesTableComponent,
    BenchmarkChartComponentDiscriminator,
    DiscriminatorComponent,
    ActionOverviewComponent,
    GlobalCampaignsListComponent,
    DeltaChartComponent,
    ImportSetListComponent,
    DataUploaderComponent,
    MailCampaignDetails,
    MailCampaignsListComponent,
    MailTemplatesListComponent,
    AlertTable,
    IndicatorBlockComponent,
    GlobalOverviewComponent,
    VariableActionBlockComponent,
    GroupedChartComponent,
    ChoiceLineChartComponent,
    SampleTable,
    TextAnalyticsComponent,
    ActionManagerComponent, ActionPlanComponent, /*BenchmarkChartComponent,*/ DataTableComponent, DevChartComponent, EffectChartComponent,
    FeedbackSentimentComponent, FeedbackOverviewComponent, FeedbackSearchComponent,
    GamificationOverviewComponent,
    PriorityChartComponent, RankingTableComponent, ResultsBlockComponent, MenuBlockComponent, SelectionBlockComponent, VariableBlockComponent,
    QuestionSearchComponent
  },

  data () {
    return {}
  },

  props: {
    componentData: Object,
    selectionBlockBus: Object
  },

  computed: {

    classObject: function () {
      return {
        xs2:  this.componentData.config.size===2,
        xs4:  this.componentData.config.size===4,
        xs6:  this.componentData.config.size===6,
        xs8:  this.componentData.config.size===8,
        xs12: this.componentData.config.size===12
      }
    }

  },

}
</script>
<style scoped>
  div.componentWrapper {
    border: 1px solid black;
    /*background-color: #00acc1;*/
  }
</style>
