<template>
  <v-container fluid fill-height>

    <v-row align-center justify-center>
      <v-col offset=4 cols="4" class="logocol">
        <img width="220px" src="../assets/login_logo.png">
      </v-col>

      <v-col offset=4 cols="4">
        <v-card class="elevation-12 pa-10">
          <v-card-title class="mainTitle"><v-icon class="mr-2">mdi-alert-circle-outline</v-icon>Kirjautumisosoite on muuttunut.</v-card-title>
          <v-card-subtitle class="subTitle">Kirjautumisosoite on muuttunut .dk-päätteisestä osoitteesta .fi-päätteiseen osoitteeseen: <a href="https://dashboard.aganalytics.fi">dashboard.aganalytics.fi</a></v-card-subtitle>
<!--          <v-card-subtitle class="subTitle">Data från 2020 och tidigare finns fortfarande <a @click="onOldDataClicked">här</a></v-card-subtitle>-->
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters class="footerRow">
      ag analytics &middot; +45 7020 1075 &middot; <a class="frontLink" href="mailto://support@aganalytics.dk">support@aganalytics.dk</a>
    </v-row>


  </v-container>
</template>

<script>

import PageNavigator from "@/pagenavigator";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'forwardVGS',

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getFilteredDrillPath']),
  },

  methods: {
    onOldDataClicked() {
      PageNavigator.navigateToDefaultPage(this, this.activeSurvey, this.getFilteredDrillPath)
    }
  }


}
</script>

<style scoped>
div.loginButtonPanel {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 1em;
}

div.forgotSignupPanel {
  display: flex;
  justify-content: space-between;
}

div.forgotSignupPanel a {
  color: #888888;
}

div.logocol {
  display: flex;
  justify-content: center;
}

div.mainTitle {
  justify-content: center;
  font-size: calc((30/16)*1rem);
  margin-bottom: 1em;
  word-break: normal;
}

div.subTitle {
  text-align: center;
  font-size: calc((18/16)*1rem);
}

div.footerRow {
  align-self: flex-end;
  justify-content: center;
  color: #888888
}

a.frontLink {
  margin-left: 6px;
  color: #888888;
  text-decoration: none;
}

</style>

