<template>
  <v-card>
    <div>These fields can be used in the templates</div>

    <v-data-table
        :headers="headers"
        :items="rows"
        class="elevation-2"
      :loading="loading"
      :search="search">

      <template #top>
        <v-text-field
            v-model="search"
            label="Filter"
            class="mx-4"
        />
      </template>


      <!--        :items-per-page="50"-->
<!--        must-sort-->
<!--        :sort-by.sync="groupName"-->

    </v-data-table>


  </v-card>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";

export default {
  name: 'LetterTemplateHelp',

  components: {},

  props: {

  },

  data: () => ({
    loading: false,
    search: '',
    rows: []
  }),

  created: function () {
    this.loading = true
    MailsService.fetchLetterTemplateHelpData(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath).then((response) => {
      const helpData = response.data
      this.rows = helpData.variableRows
      this.loading = false
    })

  },


  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    headers() {
      return [
        { text: this.$t('GROUP'), align: 'left', value: 'groupName' },
        { text: this.$t('NAME'), align: 'left', value: 'questionTitle' },
        { text: this.$t('VARIABLE'), align: 'left', value: 'questionName' },
        { text: this.$t('VALUE'), align: 'left', value: 'value' }
      ];
    },

  },

}
</script>

<style scoped>

.waitingDiv {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.renderDiv {
  padding: 1em;
  background-color: white;
}
</style>
