<template>
  <v-container class="wrapper" fluid >
    <v-row no-gutters>
      <v-col>
        <div class="title justify-space-between">{{'ACTIONS' | i18n}}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="sectionHeader">
          <div class="sectionLabel">{{'OPEN_ACTIONS' | i18n}} <template v-if="hasManyOpenActions">({{openActions.length}})</template> </div>
          <v-btn class="text-none" v-if="hasManyOpenActions" text @click="viewAllOpenActions = !viewAllOpenActions">{{'VIEW_ALL' | i18n}} <v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
      </v-col>
    </v-row>

    <template v-if="openActionsView.length>0">
      <v-row v-for="(suggest, idx) in openActionsView" :key="2000+idx">
        <v-col cols="12">
          <SingleActionComponent :suggestion="suggest" :is-suggestion="false" @actionchanged="onActionChanged"/>
        </v-col>
      </v-row>
    </template>
    <v-row v-else>
      <v-col cols="12">
        <MessageActionComponent no-actives/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="sectionHeader">
          <div class="sectionLabel">{{'SUGGESTED_ACTIONS' | i18n}} <template v-if="hasManySuggestedActions">({{suggestedActions.length}})</template> </div>
          <v-btn v-if="hasManySuggestedActions" text @click="viewAllSuggestedActions = !viewAllSuggestedActions">{{'VIEW_ALL' | i18n}} <v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
      </v-col>
    </v-row>

    <template v-if="suggestedActionsView.length>0">
      <v-row v-for="(suggest, idx) in suggestedActionsView" :key="1000+idx">
        <v-col cols="12">
          <SingleActionComponent :suggestion="suggest" :is-suggestion="true" @actionchanged="onActionChanged"/>
        </v-col>
      </v-row>
    </template>
    <v-row v-else>
      <v-col>
        <MessageActionComponent no-suggestions/>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>

import SingleActionComponent from "./SingleActionComponent";
import MessageActionComponent from "./MessageActionComponent";
import {mapGetters} from "vuex";

export default {
  name: 'CompactActionsComponent',

  components: {
    SingleActionComponent, MessageActionComponent
  },

  data () {
    return {
      viewAllOpenActions: false,
      viewAllSuggestedActions: false,
    }
  },

  props: {
    suggestions: Array,
    writeable: Boolean,
    openActions: Array,
    closedActions: Array,
    suggestedActions: Array
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale', 'isUserRoot']),

    openActionsView() {
      return this.viewAllOpenActions ? this.openActions : this.openActions.slice(0, 2)
    },

    hasManyOpenActions() {
      return this.openActions.length>2
    },

    suggestedActionsView() {
      return this.viewAllSuggestedActions ? this.suggestedActions : this.suggestedActions.slice(0, 2)
    },

    hasManySuggestedActions() {
      return this.suggestedActions.length>2
    }


  },


  methods: {
    onActionChanged() {
      this.$emit('actionchanged')
    },


  }

}
</script>
<style scoped>
.wrapper {
  background-color: white;
  margin-left: 4px;
  border-radius: 5px;
}

div.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.sectionLabel {
  /*opacity: 0.5;*/
  margin-top: 12px;
  /*margin-bottom: 8px;*/
  /*font-size: 20px;*/
  font-size: calc((20/16)*1rem);
  color: #666666;
}

</style>
