<template>
  <v-container
    fluid
    pa-0
  >
    <v-row class="pa-3">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="rows"
          :loading="loading"
          class="elevation-2"
          :multi-sort="false"
          :custom-sort="customSort"
          :hide-default-footer="true"
          :disable-pagination="true"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <template v-if="item.error">
                  <v-icon small color="error">mdi-alert</v-icon>
                </template>
                {{item.surveyname}}
                {{item.error}}
              </td>

              <template v-for="(header, index) in headers.slice(1, headers.length)">
                <td :class="cellClass(header)" :key="index+header.value">
                  {{ (item.values && item.values[header.value]) ? item.values[header.value].text : '' }}
                </td>
              </template>
            </tr>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import {mapGetters, mapState} from 'vuex'
import backend from '@/services/backend'
import _ from 'lodash'

let sseClient;

export default {
  name: 'GlobalValuesTableComponent',

  components: {},

  props: {
    componentData: Object
  },

  data () {
    return {
      search: '',

      headers: [],
      rows: [],

      loading: false,

      sortBy: 'title',
    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getMailCampaignIds']),
  },

  created: function () {
    // this.initialFetchData();
  },

  mounted() {

    this.loading = true

    const url = backend.defaults.baseURL + '/vuejs/globalevaluator/globalvaluestableevents/'
        + this.activeSurvey.extName
        + '/' + this.componentData.config.id
        + '/' + this.getFilteredDrillPath
        + '?authtoken=' + this.getAuthToken

    sseClient = this.$sse.create({
      url: url,
      format: 'json',
      withCredentials: false,
      polyfill: true,
    });

    // Catch any errors (ie. lost connections, etc.)
    sseClient.on('error', (e) => {
      // console.error('lost connection or failed to parse!', e);

      // If this error is due to an unexpected disconnection, EventSource will
      // automatically attempt to reconnect indefinitely. You will _not_ need to
      // re-add your handlers.
    });

    // Handle messages without a specific event
    sseClient.on('globalTableInitialData', this.handleInitialData);
    sseClient.on('globalSurveyResult', this.handleSurveyResult);
    sseClient.on('globalTableDataEnd', this.handleDataEnd);



    sseClient.connect()
        .then(sse => {
          // console.log('We\'re connected!');

          // Unsubscribes from event-less messages after 7 seconds
          // setTimeout(() => {
          //   sseClient.off('message', this.handleMessage);
          //   console.log('Stopped listening to event-less messages!');
          // }, 7000);
          //
          // // Unsubscribes from chat messages after 14 seconds
          // setTimeout(() => {
          //   sse.off('chat', this.handleChat);
          //   console.log('Stopped listening to chat messages!');
          // }, 14000);
        })
        .catch((err) => {
          // When this error is caught, it means the initial connection to the
          // events server failed.  No automatic attempts to reconnect will be made.
          console.error('Failed to connect to server', err);
        });
  },

  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },

  methods: {

    cellClass(header) {
      let classes = "valueCell "

      if(header.align==='left') {
        classes += "text-left "
      } else if(header.align==='center') {
        classes += "text-center "
      } else if(header.align==='right') {
        classes += "text-right "
      }

      return classes
    },


    handleInitialData(globalTableInitialData /*, lastEventId*/) {
      // console.warn('Received initial data', globalTableInitialData /*, lastEventId*/);

      this.headers = globalTableInitialData.headers
      this.rows = globalTableInitialData.rows
    },


    handleDataEnd(/*globalTableInitialData, lastEventId*/) {
      this.loading = false;
    },

    handleSurveyResult(surveyResult/*, lastEventId*/) {
      const targetIndex = this.rows.findIndex(entry => entry.surveyname===surveyResult.surveyname)

      if(targetIndex!==-1) {
        this.$set(this.rows, targetIndex, surveyResult)
      } else {
        this.rows.push( surveyResult )
      }
    },


    customSort(items, varnames, sortDirections) {
      // console.log("customSort var: " + varnames[0] + " desc:" + sortDirections[0])

      if(!varnames || varnames.length===0) {
        return items
      }

      const sortVar = varnames[0]
      const sortDesc= sortDirections[0]

      let header = _.find(this.headers, { 'value': sortVar })

      if(header==null) {
        return items
      }

      items.sort((a, b) => {
        const cellA = a[sortVar]
        const cellB = b[sortVar]

        if(typeof cellA === 'undefined' || cellA.value===null) {
          return sortDesc ? -1 : 1
        } else if(typeof cellB === 'undefined' || cellB.value===null) {
          return sortDesc ? 1 : -1
        }

        var sortResult = (cellA.value < cellB.value)

        if(sortDesc===true) {
          return sortResult ? 1 : -1;
        } else {
          return sortResult ? -1 : 1;
        }

      });

      return items;
    }

  }

}
</script>

<style scoped>
</style>

