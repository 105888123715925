<template>
  <div>
    <v-btn color="#000000DE" text @click.stop="dialog = true"><v-icon class="mr-1">mdi-plus-circle-outline</v-icon><span class="dialogButton">{{'ADD_ACTION' | i18n}}</span></v-btn>
    <v-dialog v-model="dialog" width="60%">
      <ActionEditForm :dialog="dialog" @close="onClose" id="0" />
    </v-dialog>
  </div>


</template>

<script>
import ActionEditForm from "./ActionEditForm";

export default {
  name: 'ActionEditDialog',

  components: {ActionEditForm},

  props: {
    interviewData: Object,
    textAlign: String,
    label: String,
    value: String,
  },

  data: () => ({
    dialog: false,
  }),


  methods: {
    onClose(value) {
      this.dialog = false
      if(value) {
        this.$emit('actionchanged')
      }

    }
  }

}

</script>


<style scoped>
.dialogButton {
  /*font-size: 16px;*/
  font-size: calc((16/16)*1rem);
}

</style>
