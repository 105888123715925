/**
 * Get the languge object to translate hightcharts.
 * @param src
 */
function getLangTranslations(src) {
    return {
        decimalPoint: src.$t('CHART_DECIMAL_SEPARATOR'),
        thousandsSep: src.$t('CHART_THOUSAND_SEPARATOR'),
        contextButtonTitle: src.$t('CHART_MENU_TOOLTIP'),
        printChart: src.$t('CHART_MENU_PRINT'),
        downloadPNG: src.$t('CHART_MENU_EXPORT_PNG'),
        downloadJPEG: src.$t('CHART_MENU_EXPORT_JPEG'),
        downloadPDF: src.$t('CHART_MENU_EXPORT_PDF'),
        downloadSVG: src.$t('CHART_MENU_EXPORT_SVG'),
    }
}

function getExportingSettings() {
    return {
        buttons: {
            contextButton: {
                menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
            }
        }
    }
}

export default {
    getLangTranslations,
    getExportingSettings
}