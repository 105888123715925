<template>

  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table v-if="this.tableData!==null"
                      :headers="this.tableHeaders"
                      :items="this.tableRows"
                      :hide-default-footer="true"
                      :disable-pagination="true"
                      class="elevation-1">

        </v-data-table>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'DataTableStaticComponent',

  components: {},

  props: {
    componentData: Object,
  },

  data() {
    return {
      tableData: Object,
      tableHeaders: Array,
      tableRows: Array,
    }
  },

  created() {

    this.tableData = this.componentData.config;
    console.log(this.tableData)
    this.tableHeaders = this.componentData.config.rows.slice(0,1);
    this.tableHeaders.forEach(row => {
      row.cells.forEach((cell,index) => {
          cell["text"] = cell[this.getLocale],
          cell["value"] = "col"+index
          if (cell.align !== undefined) {
            cell["align"] = cell.align
          }
          if (cell.sortable !== undefined) {
            cell["sortable"] = cell.sortable
          }
      })
    });
    this.tableHeaders = this.tableHeaders[0].cells;
    this.tableRows = this.componentData.config.rows.slice(1);
    this.tableRows.forEach((row,index) => {
        row.id = index

        row.cells.forEach((cell,index) => {
          return row["col"+index] = cell[this.getLocale]
        })
    });

    console.log(this.tableHeaders)
    console.log(this.tableRows)
  },

  computed: {
    ...mapState(['activeView']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
