import backend from '@/services/backend'

export default {

  fetchBugs (authToken, surveyName) {
    return backend.get('vuejs/logs/bugs/' + surveyName, { headers: { Authorization: authToken } })
  },

  clearBugs (authToken, surveyName) {
    return backend.delete('vuejs/logs/bugs/' + surveyName, { headers: { Authorization: authToken } })
  }
}
