<template>
    <v-snackbar v-model="show">
        {{message}}
        <v-btn text color="accent" @click.native="show = false">Close</v-btn>
    </v-snackbar>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: 'SnackWatcher',

        data () {
            return {
                show: false,
                message: ''
            }
        },

        computed: {
            ...mapState(['snackText'])
        },

        watch: {
            // whenever question changes, this function will run
            snackText: function (newText) {
                if(newText!==null) {
                    this.message = newText;
                    this.$store.commit('setSnackText', null)
                    this.show = true
                }
            }
        },

    }
</script>