<template>
  <div>
    <MailTrackingsComponent :mail-trackings-data="mailsTrackingsData" v-if="mailsTrackingsData"/>
  </div>
</template>

<script>

import SamplesService from "@/services/SamplesService";
import {mapGetters, mapState} from "vuex";
import MailTrackingsComponent from "@/views/interview/MailTrackingsComponent.vue";

export default {
  name: 'MailsPanelComponent',

  components: {MailTrackingsComponent},

  data: () => ({
    mailsTrackingsData: null,
  }),

  props: {
    componentData: Object,
    sampleId: Number
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'rankingList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),
  },

  created () {
    this.fetchMailTrackingData();
  },

  methods: {

    requestSave() {
      return true
    },

    fetchMailTrackingData() {
      SamplesService.fetchMailTrackingData(this.getAuthToken, this.getActiveSurvey.extName, this.sampleId, this.componentData.relatedMailTracking).then((response) => {
        this.mailsTrackingsData = response.data
      })
    },

  },

}
</script>

<style scoped>
</style>