<template>
  <v-container class="planWrapper elevation-2" fluid>

    <v-row no-gutters>
      <v-col cols="12">
        <span class="title">{{'ACTION_PLAN' | i18n}}</span>
      </v-col>
    </v-row>

    <v-row no-gutters v-for="(compoundAction, idx) in actionPlanData.actions" :key="idx">
      <v-col cols="12" >
        <ActionSectionComponent :compound-action="compoundAction"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import ActionSectionComponent from "./ActionSectionComponent";

export default {
  name: 'ActionPlanPartComponent',

  components: {
    ActionSectionComponent
  },

  data () {
    return {
    }
  },

  props: {
    actionPlanData: Object,
  },

}
</script>

<style scoped>
.planWrapper {
  background-color: white;
  border-radius:5px;
}

span.title {
  /*font-size: 24px;*/
  font-size: calc((24/16)*1rem);
}
</style>
