import backend from '@/services/backend'

export default {

  fetchFilterData (authToken, surveyName, filtersType) {
    return backend.get('vuejs/filters/list/' + surveyName + "/" + filtersType, { headers: { Authorization: authToken } })
  },

  fetchFilterQuery (authToken, surveyName, questionName, query) {
    return backend.get('vuejs/filters/query/' + surveyName + "/" + questionName + "?query="+query, { headers: { Authorization: authToken } })
  }

}
