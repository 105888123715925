<template>
        <v-dialog v-model="dialog" width="60%">
            <template v-slot:activator="{ on }">
                <v-btn @click.stop="onOpen" class="bgbutton"  color="primary" dark v-on="on">{{copyAction.title[getLocale]}}</v-btn>
            </template>

            <v-card>
                <v-container fluid v-if="copyActionData!==null">
                  <v-form v-model="isValid">


                    <v-row class="pax-2">
                        <v-col>
                            <div  class="mainHeadline">{{copyAction.title[getLocale]}}</div>
                        </v-col>

                      <v-col class="d-flex justify-end">
                        <v-btn color="primary" text :disabled="!isValid" @click="onCopyClicked">COPY</v-btn>
                        <v-btn color="primary" text @click="dialog = false">{{'CLOSE' | i18n}}</v-btn>

                      </v-col>

                    </v-row>

                  <v-row class="pax-2">
                    <v-col cols="6">

                      <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              v-model="copyActionData.eventdate"
                              :label="$t('DEADLINE')"
                              prepend-icon="mdi-calendar-clock"
                              required
                              readonly
                              :rules="mandatoryRules"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker :locale="locale" v-model="copyActionData.eventdate" @input="menu = false" :min="new Date().toISOString()"></v-date-picker>
                      </v-menu>


                    </v-col>

                    <v-col cols="6">
                      <v-checkbox color="grey" label="Fjern dubletter"></v-checkbox>
                    </v-col>


                  </v-row>

                  <v-row>
                    <v-col v-for="field in copyActionData.fields" :key="field.questionId">
                      <QuestionComponent  :question-data="field" :validation-message-map="{}" :can-edit-state="true" />
                    </v-col>
                  </v-row>


                    <v-row>
                      <v-col>
                        <div class="commentsBar">{{'COMMENT' | i18n}}</div>
                          <v-textarea solo v-model="copyActionData.comments" rows="6"> </v-textarea>
                      </v-col>
                    </v-row>

                  </v-form>
                </v-container>

                <v-card-actions>
                    <div class="flex-grow-1"></div>
                </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import SamplesService from "../../../../services/SamplesService";
import QuestionComponent from "@/views/samples/QuestionComponent";


export default {
  name: 'CopyActionDialog',

  components: {QuestionComponent},

  props: {
    configId: String,
    copyAction: Object,
  },

  data: () => ({
    dialog: false,
    copyActionData: null,
    menu: null,
    isValid: false
  }),


  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot', 'getLocale']),

    locale() {
      return this.getLocale.replace('_', '-')
    },

    mandatoryRules() {
      return  [
        value => !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
      ]
    },

  },

  methods: {
    onOpen() {

      this.isValid = false
      this.copyActionData = null;

      SamplesService.fetchCopyActionData( this.getAuthToken, this.activeSurvey.extName, this.configId, this.copyAction.extname, this.getFilteredDrillPath) .then((response) => {
        this.copyActionData = response.data
        // console.log("acdi")
        // console.log()
        // this.inputFields = response.data.fields
      })
    },

    onCopyClicked() {
      this.dialog = false;

      SamplesService.executeCopyAction( this.getAuthToken, this.activeSurvey.extName, this.configId, this.copyAction.extname, this.getFilteredDrillPath, this.copyActionData) .then((/*response*/) => {
      })
    },


  }



}

</script>

<style scoped>
div.commentsBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*font-size: 24px;*/
  font-size: calc((24/16)*1rem);
}


    .bgbutton {
        margin-right: 1em;
        /*display:block;*/
    }

    .mainHeadline {
        height: 28px;
        color: rgba(0,0,0,0.87);
        /*font-size: 24px;*/
        font-size: calc((24/16)*1rem);
        font-weight: 500;
    }

    .groupTitle {
        color: rgba(0,0,0,0.54);
        /*font-size: 14px;*/
        font-size: calc((14/16)*1rem);
        border-bottom: 1px solid rgba(0,0,0,0.12);
    }

    .labelValue {
        display: flex;
        border-bottom: 1px solid rgba(0,0,0,0.12);
        margin-left: 1em;
        margin-right: 1em;
        justify-content: space-between;
        width: 100%;
    }

</style>