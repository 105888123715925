<template>
  <v-card outlined v-if="metaInterviewData">
    <v-card-title >MetaInterview: {{metaInterviewData.extName}}</v-card-title>

    <v-container fluid>

      <v-row>
        <v-col cols="2" >
          <v-text-field v-model="metaInterviewData.organisationName" label="organisation"/>
        </v-col>
        <v-col cols="2" >
          <v-text-field v-model="metaInterviewData.tag" label="tag"/>
        </v-col>
        <v-col cols="2" >
          <v-checkbox v-model="metaInterviewData.disabled" label="disabled"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <MetaTextComponent :text-data="metaInterviewData.nameText"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" >
          <v-btn @click="onSaveClicked()">Save</v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-card>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import SurveysService from "@/services/SurveysService";
import MetaTextComponent from "@/views/backend/MetaTextComponent";

export default {
  name: 'MetaInterviewComponent',
  components: {MetaTextComponent},

  data: () => ({
    metaInterviewData: null,
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey']),
  },

  created() {
    SurveysService.fetchMetaInterviewData(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId).then((response) => {
      this.metaInterviewData = response.data;
    })
  },

  methods: {
    onSaveClicked() {
      SurveysService.saveMetaInterviewData(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId, this.metaInterviewData).then((/*response*/) => {
        this.$store.commit('showSnackbar', 'Metainterview saved')
      })
    }

  }


}
</script>

<style scoped>
div.viewwrapper {
  display: flex;
  width: 25%;
}
</style>