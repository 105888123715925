<template>
  <div class="sidebarWrapper">

    <v-tabs v-model="activeFilterTab">
      <v-tab>{{'DEFAULT_FILTERGROUP_NAME' | i18n}}</v-tab>
      <v-tab>{{ 'TIME' | i18n }}</v-tab>

      <v-tab-item>
        <FilterSetComponent v-if="activeSurvey!==null && activeSurvey.standardFilterSet!==null"  :filter-set="activeSurvey.standardFilterSet"/>
      </v-tab-item>

      <v-tab-item>
        <FilterSetComponent v-if="activeSurvey!==null && activeSurvey.timeFilterSet!==null"  :filter-set="activeSurvey.timeFilterSet"/>
      </v-tab-item>

    </v-tabs>

    <div class="clearWrapper"><v-btn @click="resetFilters" class="white--text" color="#9e9e9e">clear all</v-btn></div>
  </div>


</template>

<script>
import {mapState} from "vuex";
import FilterSetComponent from "./FilterSetComponent";

export default {
  name: 'FiltersSidebar',

  components: {FilterSetComponent},

  data () {
    return {
      filterSet: null,
      activeFilterTab: null
    }
  },

  props: {
    pathItems: Array
  },

  computed: {
    ...mapState(['activeSurvey', 'timeFilterTab'])
  },

  methods: {
    resetFilters() {
      this.$store.commit('resetFilters')
    }
  },

  watch: {
    activeSurvey: {
      handler(survey) {
        if (survey !== null && !survey.standardFilterSet) {
          // console.log('calling fetchFilterData from sidebar - on: ' + survey)

          this.$store.dispatch('fetchFilterData', { surveyName: survey.extName, filtersType:  'standard'})
          this.$store.dispatch('fetchFilterData', { surveyName: survey.extName, filtersType:  'time'})
        }
      },
      immediate: true
    },

    timeFilterTab: function () {
      this.activeFilterTab = 1
    }


  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
div.sidebarWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.clearWrapper {
  padding: 6px;
  align-self: flex-end;
  margin-right: 20px;
  margin-bottom: 8px;
}

</style>
