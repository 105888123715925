import backend from '@/services/backend'

export default {

  fetchSurveyListData (authToken, surveyName) {
    return backend.get('vuejs/surveys/list/' + surveyName, { headers: { Authorization: authToken } })
  },

  saveApiKey(authToken, surveyName, apiKeyDTO) {
    return backend({ method: 'post', url: 'vuejs/surveys/apikey/' + surveyName, data: apiKeyDTO,  headers: { Authorization: authToken } })
  },

  createApiKey(authToken, surveyName) {
    return backend.get('vuejs/surveys/apikey/create/' + surveyName, { headers: { Authorization: authToken } })
  },

  deleteApiKey(authToken, surveyName, apiKeyId) {
    return backend.get('vuejs/surveys/apikey/delete/' + surveyName + '/' + apiKeyId, { headers: { Authorization: authToken } })
  },

  fetchUniverseData(authToken, surveyName) {
    return backend.get('vuejs/surveys/universedata/'+ surveyName, { headers: { Authorization: authToken } })
  },

  saveUniverseData(authToken, surveyName, universeData) {
    return backend({ method: 'put', url: 'vuejs/surveys/universedata/' + surveyName, data: universeData,  headers: { Authorization: authToken } })
  },

  fetchMetaInterviewData(authToken, surveyName, surveyId) {
    return backend.get('vuejs/surveys/metainterviewdata/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  },

  saveMetaInterviewData(authToken, surveyName, surveyId, metaInterviewData) {
    return backend({ method: 'post', url: 'vuejs/surveys/metainterviewdata/' + surveyName + '/' + surveyId, data: metaInterviewData,  headers: { Authorization: authToken } })
  },

  fetchMetaData(authToken, surveyName, surveyId) {
    return backend.get('vuejs/surveys/metadata/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  },

  fetchConfigBlocks(authToken, surveyName, surveyId) {
    return backend.get('vuejs/surveys/configblocks/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  },

  createConfigBlock(authToken, surveyName, surveyId) {
    return backend.get('vuejs/surveys/configblocks/create/'+ surveyName + '/' + surveyId, { headers: { Authorization: authToken } })
  },

  saveMetaProperty(authToken, surveyName, mdpdata) {
    return backend({ method: 'post', url: 'vuejs/surveys/metaproperty/' + surveyName, data: mdpdata,  headers: { Authorization: authToken } })
  },


  saveConfigBlock(authToken, surveyName, cblock) {
    return backend({ method: 'post', url: 'vuejs/surveys/configblocks/' + surveyName, data: cblock,  headers: { Authorization: authToken } })
  },

  //////////// effectblocks ////////////
  fetchEffectBlocks(authToken, surveyName, surveyId) {
    return backend.get('vuejs/surveys/effectblocks/'+ surveyName + '/'+ surveyId, { headers: { Authorization: authToken } })
  },


  saveEffectBlock(authToken, surveyName, surveyId, blockdata) {
    return backend({ method: 'post', url: 'vuejs/surveys/effectblocks/' + surveyName, data: blockdata,  headers: { Authorization: authToken } })
  },

  createEffectBlock(authToken, surveyName, surveyId) {
    return backend.get('vuejs/surveys/effectblocks/create/'+ surveyName + '/'+ surveyId, { headers: { Authorization: authToken } })
  },

  deleteEffectBlock(authToken, surveyName, surveyId, blockId) {
    return backend.delete('vuejs/surveys/effectblocks/'+ surveyName + '/'+ surveyId + '/' + blockId, { headers: { Authorization: authToken } })
  },

  fetchTasks (authToken, surveyName, surveyId, taskState, taskType) {
    return backend.get('vuejs/surveys/tasks/' + surveyName + '/' + surveyId + '?state=' + taskState + '&type=' + taskType , { headers: { Authorization: authToken } })
  },

  processTask(authToken, surveyName, taskId) {
    return backend.get('vuejs/surveys/tasks/process/' + surveyName + '/' + taskId, { headers: { Authorization: authToken } })
  },

  deleteTask(authToken, surveyName, taskId) {
    return backend.get('vuejs/surveys/tasks/deletesingle/' + surveyName + '/' + taskId, { headers: { Authorization: authToken } })
  },

  assignState (authToken, surveyName, surveyId, taskState, taskType, newState) {
    return backend.get('vuejs/surveys/tasks/assignstate/' + surveyName + '/' + surveyId + '?state=' + taskState + '&type=' + taskType + '&newstate='+newState, { headers: { Authorization: authToken } })
  },

  deleteTasks(authToken, surveyName, surveyId, taskState, taskType) {
    return backend.get('vuejs/surveys/tasks/delete/' + surveyName + '/' + surveyId + '?state=' + taskState + '&type=' + taskType, { headers: { Authorization: authToken } })
  },

  fetchLoadCount(authToken, surveyName) {
    return backend.get('vuejs/surveys/loadcount/'+ surveyName, { headers: { Authorization: authToken } })
  },

}
