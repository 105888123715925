<template>
  <v-container fluid pa-0>

    <v-form v-if="userDetails!==null"  ref="form" >
      <v-row class="pa-3">
        <v-col>
          <h4>{{headlineLabel}}</h4>
        </v-col>
       </v-row>

      <v-row v-if="isKeyCloakMode"  class="pa-3">
        <v-col cols="3">
          <v-text-field :disabled="!isUserRoot" v-model="userDetails.userDTO.email" :label="$t('EMAIL')" required/>
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col cols="3">

          <template v-if="!isKeyCloakMode">
            <v-text-field :disabled="!isNew" v-model="userDetails.userDTO.name"  :label="$t('NAME')" required :rules="nameRules"/>
            <v-text-field :disabled="!isNew" v-model="userDetails.userDTO.email" :label="$t('EMAIL')" required/>
          </template>

          <template v-if="showPasswordField">
            <v-text-field type="password" v-model="userDetails.userDTO.password" :label="$t('PASSWORD')"/>
            <v-text-field type="password" v-model="repeatPassword" :label="$t('PASSWORD_REPEAT')" :rules="passwordRules"/>
          </template>

          <v-select v-if="showPasswordField" v-model="userDetails.userDTO.locale" :items="userDetails.locales" label="Locales"/>

          <v-select :disabled="restricted" v-model="userDetails.userDTO.selectedRoleId" :items="rolesList" :rules="roleRules" :label="$t('ROLE')"/>

          <v-text-field v-if="isUserRoot" v-model="userDetails.userDTO.actAsAccess" label="actas access"></v-text-field>

          <v-checkbox v-model="userDetails.userDTO.showIndicators" label="Indicator start menu"/>

          <v-text-field v-if="!restricted" disabled v-model="userDetails.lastLogin" label="Last login'" />
          <v-checkbox v-if="!restricted" v-model="userDetails.userDTO.disabled" :label="$t('DISABLED_USER')" />
          <v-checkbox v-if="!restricted" v-model="sendNotifications" :label="$t('NOTIFICATIONS')"/>
          <v-checkbox v-if="!restricted" v-model="userDetails.userDTO.sendEmail" :label="$t('RESEND_MAIL_INVITATION')"/>

          <template v-for="(ncc,idx) in userDetails.channelDTOs">
            <template v-for="(channel,channelName) in ncc">
              <NotificationChannelComponent
                  @update-channeldata="updateChannelData(idx, channelName, $event)"
                  :key="channel.title" :channel-data="channel"/>
            </template>

          </template>


        </v-col>
      </v-row>

      <v-row class="pa-3" v-if="userDetails.userQuestionFilterMap['global']">
        <v-col cols="3">
          <UserSurveyComponent v-if="!restricted"
                               v-model="userDetails.userQuestionFilterMap['global']"
                               @update-surveydata="updateSurveyData('global', $event)"
                               :survey-name="$t('GLOBAL_UQF_TITLE')" :show-globals="true"/>

        </v-col>
      </v-row>

      <v-row class="pa-3">
        <template v-for="(uqfm,idx) in userDetails.userQuestionFilterMap">
          <v-col cols="3"  :key="idx" v-if="isUserRoot && idx!=='global'">
            <UserSurveyComponent
                v-model="userDetails.userQuestionFilterMap[idx]"
                @update-surveydata="updateSurveyData(idx, $event)"
                :survey-name="idx"
                :show-globals="false"/>
          </v-col>
        </template>
      </v-row>



    </v-form>

    <v-row class="pa-3">
      <v-col>
        <v-alert type="error" v-if="userDetails && userDetails.userDTO && userDetails.userDTO.errorMessage">
          {{userDetails.userDTO.errorMessage}}
        </v-alert>

        <v-btn class="mr-4" @click="onSubmitClicked">{{ buttonLabel }}</v-btn>
        <v-btn class="mr-4" @click="onCancelClicked">{{'CANCEL' | i18n}}</v-btn>
      </v-col>


    </v-row>


  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import UsersService from "../../services/UsersService";
import UserSurveyComponent from "./UserSurveyComponent";
import NotificationChannelComponent from "./NotificationChannelComponent";
import PageNavigator from "../../pagenavigator";
import Clientconfig from "@/clientconfig";


export default {
  name: 'UserDetails',

  components: {
    NotificationChannelComponent,
    UserSurveyComponent
  },

  data: () => ({
    userDetails: null,

    repeatPassword: '',
    sendNotifications: null,

    rolesList: [],


    nameRules: [
      v => !!v || 'Name is required'
    ],

    roleRules: [
      v => !!v || 'Role is required'
    ],


  }),

  created: function () {
    this.initialFetchData();
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    buttonLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    headlineLabel() {
      return this.isNew ? this.$t('CREATE_USER') : this.$t('UPDATE_USER')
    },

    isNew() {
      return this.userDetails && this.userDetails.userDTO.id===0
    },

    showPasswordField() {
      return this.userDetails.canEditPassword && !this.isKeyCloakMode;
    },

    restricted() {
      return this.userDetails.restricted
    },

    passwordRules() {
      return  [
        (v) => ( this.userDetails.userDTO.password===null  ||  (v === this.userDetails.userDTO.password) ) || 'Passwords must match'
      ]},

    isKeyCloakMode() {
      return Clientconfig.isKeyCloakMode()
    },

    isKeyCloakExternIdPMode() {
      return Clientconfig.isKeyCloakExternIdPMode()
    },


    keyCloakAccountURL() {
      return Clientconfig.keyCloakAccountURL()
    }


  },


  methods: {

    initialFetchData() {
      UsersService.fetchUserDetailsData(this.getAuthToken, this.activeSurvey.extName, this.$route.params.userId).then((response) => {
        this.userDetails = response.data

        this.sendNotifications = this.userDetails.userDTO.notifyFlags===0

        this.rolesList = []

        for( const [k,v] of this.userDetails.roleNames.entries()) {
          this.rolesList.push( { text: v, value: this.userDetails.roleIDs[k] }  )
        }

      })
    },

    onCancelClicked() {
      this.$router.push({name: 'users', params: {surveyExtName: this.activeSurvey.extName} })
    },

      onSubmitClicked() {
      if (this.$refs.form.validate()) {

        this.userDetails.userDTO.notifyFlags = this.sendNotifications ? 0 : 1

        UsersService.updateUserDetailsData(this.getAuthToken, this.activeSurvey.extName, this.$route.params.userId, this.userDetails).then((response) => {
          const userDTO = response.data;

          if(userDTO.errorMessage) {
            this.userDetails.userDTO = userDTO
          } else {
            if(this.restricted) {
              PageNavigator.navigateToDefaultPage(this, this.activeSurvey)
            } else {
              this.$router.push({name: 'users', params: {surveyExtName: this.activeSurvey.extName} })
            }

          }

        })

      }

    },

    updateSurveyData(idx, surveyData) {
      this.userDetails.userQuestionFilterMap[idx] = surveyData;

      // console.log("**")
      // console.log(surveyData)

      // for(const uqf of surveyData) {
      //   if(uqf.globalName) {
      //     this.assignGlobalVariables(uqf);
      //   }
      // }

      // const uqf = this.userDetails.userQuestionFilterMap[idx];


    },

    assignGlobalVariables(assigningUQF) {
      // console.log("assignGlobalVariables");
      // console.log(assigningUQF)
      // console.log(this.userDetails.userQuestionFilterMap)

      for (let property in this.userDetails.userQuestionFilterMap) {
        const uqfMap = this.userDetails.userQuestionFilterMap[property]
        // console.log(uqfMap)

        for(const uqf of uqfMap) {
          // console.log(uqf)
          if(assigningUQF.globalName === uqf.globalName) {
            // console.log("Assigning to " + property + " " + assigningUQF.globalName + " value: " + assigningUQF.value)
            uqf.value = assigningUQF.value
          }

        }


      }

      // for(let idx uqfMap of this.userDetails.userQuestionFilterMap) {
      //   console.log(uqfMap)
      // }

    },

    updateChannelData(idx, channelName, channelData) {
      const surveyChannels = this.userDetails.channelDTOs[idx]
      surveyChannels[channelName] = channelData
    }

  },


}
</script>
