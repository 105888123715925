<template>
  <v-container class="compwrapper elevation-2" fluid >
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-space-between">
        <div class="title">{{'ACTIONS' | i18n}}</div>
        <ActionEditDialog @actionchanged="onActionChanged"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="sectionLabel">{{'OPEN_ACTIONS' | i18n}} ({{openActions.length}})</div>
      </v-col>
    </v-row>

    <v-row v-for="(suggest, idx) in openActions" :key="2000+idx">
      <v-col cols="12">
        <SingleActionComponent :suggestion="suggest" :is-suggestion="false" @actionchanged="onActionChanged"/>
      </v-col>
    </v-row>

    <v-row v-if="openActions.length===0">
      <v-col cols="12">
        <MessageActionComponent no-actives/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="sectionLabel">{{'SUGGESTED_ACTIONS' | i18n}} ({{suggestedActions.length}})</div>
      </v-col>
    </v-row>

    <v-row v-for="(suggest, idx) in suggestedActions" :key="1000+idx">
      <v-col cols="12">
        <SingleActionComponent :suggestion="suggest" :is-suggestion="true" @actionchanged="onActionChanged"/>
      </v-col>
    </v-row>

    <v-row v-if="suggestedActions.length===0">
      <v-col cols="12">
        <MessageActionComponent no-suggestions/>
      </v-col>
    </v-row>





    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="closedActionPanelHeader">
          <div class="sectionLabel">{{'CLOSED_ACTIONS' | i18n}} ({{closedActions.length}})</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="closedActionPanelContent">
          <v-row v-for="(suggest, idx) in closedActions" :key="3000+idx">
            <v-col cols="12">
              <SingleActionComponent :suggestion="suggest" :is-suggestion="false" @actionchanged="onActionChanged"/>
            </v-col>
          </v-row>


        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row v-if="isUserRoot">
      <v-col>
        <v-btn @click="onEvaluateClicked">evaluate</v-btn>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import SingleActionComponent from "./SingleActionComponent";
import ActionEditDialog from "./ActionEditDialog";
import ResultsService from "../../../services/ResultsService";
import {mapGetters} from "vuex";
import MessageActionComponent from "./MessageActionComponent";

export default {
  name: 'ActionsPartComponent',

  components: {
    SingleActionComponent, ActionEditDialog, MessageActionComponent
  },

  data () {
    return {
    }
  },

  props: {
    suggestions: Array,
    writeable: Boolean,
    openActions: Array,
    closedActions: Array,
    suggestedActions: Array
  },

  computed: {
    // ...mapState(['activeView']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale', 'isUserRoot'])
  },


  methods: {
    onActionChanged() {
      this.$emit('actionchanged')
    },

    onEvaluateClicked() {
      // console.log("Evaluating")

      ResultsService.evaluateActions(this.getAuthToken, this.getActiveSurvey.extName).then((/*response*/) => {
        // console.log("Evaluated!")
        this.$emit('actionchanged')
      })

    }

  }

}
</script>

<style scoped>
.compwrapper {
  background-color: white;
  margin-left: 4px;
  border-radius: 5px
}

div.sectionLabel {
  /*opacity: 0.5;*/
  color: #666666;
  /*font-size: 20px;*/
  font-size: calc((20/16)*1rem);
  flex: 0 1 auto;
}

button.closedActionPanelHeader {
  /*background-color: red !important;*/
  padding-left: 0 !important;
}



button.closedActionPanelHeader >>> .v-expansion-panel-header__icon {
  /*background-color: red !important;*/
  margin-left: 1em !important;
}

div.closedActionPanelContent >>> .v-expansion-panel-content__wrap {
  /*background-color: red !important;*/
  padding-left: 0 !important;
}


span.title {
  /*font-size: 24px;*/
  font-size: calc((24/16)*1rem);
}
</style>
