<template>
  <v-container fluid pa-0>
    <ConfirmDialog ref="confirm" />

    <v-row class="pa-3">
      <v-col>

    <v-data-table
        :search = "search"
        :headers="headers"
        :items="rows"
        :loading="loading"
        item-key="id"
        class="elevation-2"
        must-sort
        :sort-by.sync="sortBy"
        @click:row="onRowClicked">

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="onClickDelete(item)">
          <v-icon class="mr-2">mdi-delete</v-icon>
        </v-btn>
      </template>


      <template v-slot:top>
        <v-text-field v-model="search" label="Filter" class="mx-4"></v-text-field>
      </template>

    </v-data-table>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import UploadService from "@/services/UploadService";
import ConfirmDialog from "@/components/ConfirmDialog";

  export default {
    name: 'ImportSetListComponent',

    components: {
      ConfirmDialog
    },

    props: {
      componentData: {
        type: Object,
        required: true
      }
    },


    data () {
      return {
        search: '',
        headers: [
          { text: this.$t('DATE'), align: 'left', value: 'created' },
          { text: this.$t('USER'), align: 'left', value: 'user' },
          { text: this.$t('DATA_UPLOADER_FILE'), align: 'left', value: 'filename' },
          { text: this.$t('INTERVIEWS'), align: 'left', value: 'ninterviews' },
          { text: this.$t('ACTIONS'), align: 'center', value: 'actions',  },
        ],

        loading: false,

        rows: [],

        sortBy: 'created'
      }
    },

    computed: {
      ...mapState(['activeSurvey']),
      ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),
    },

    created: function () {
      this.initialFetchData();
    },

    methods: {
      initialFetchData() {
        this.loading = true
        UploadService.fetchImportSets(this.getAuthToken, this.activeSurvey.extName,  this.componentData.config.id).then((response) => {
          this.rows = response.data
          this.loading = false
        })

      },

      async onClickDelete(row) {
        if ( await this.$refs.confirm.open("Confirm", this.$t('DATA_UPLOADER_DELETE_IMPORTSET'))) {
          UploadService.deleteImportSet(this.getAuthToken, this.activeSurvey.extName,  row.id).then((/*response*/) => {
            this.$store.commit('showSnackbar', this.$t('DATA_DELETED'))
            this.initialFetchData()
            this.$store.dispatch('fetchFilterData', { surveyName: this.getActiveSurvey.extName, filtersType:  'standard'})
          })

        }
      },



    },


  }
</script>

