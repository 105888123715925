<template>
  <v-card class="pa-8">
    <v-container class="wrapper" fluid>
      <v-row>
        <v-col>
          <h2>{{'MAIL_CAMPAIGN_INFO' | i18n}}</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field flat readonly disabled :label="$t('MAIL_CAMPAIGN_NAME_OF')" v-model="campaignDetails.title" ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field flat readonly disabled :label="$t('TYPE')" v-model="campaignDetails.sendingType" ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field flat disabled :label="$t('MAIL_SUBJECT')" v-model="mailSubject" ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field flat disabled :label="$t('MAIL_TEMPLATE')" v-model="campaignDetails.templateDetails.name" ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="campaignDetails.campaignFilters.length>0">
        <v-col>
          <div><b>{{'MAIL_CAMPAIGN_SELECTED_FILTERS' | i18n}}</b></div>
          <div v-for="(filter,idx) in campaignDetails.campaignFilters" :key="idx">{{filter.title}}</div>
        </v-col>
      </v-row>


    </v-container>
  </v-card>
</template>


<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'MailCampaignInfo',

  components: {},

  props: {
    campaignDetails: Object
  },

  data: () => ({
    mailTemplate: null,
//    mailSubject: "abc"
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    templateDetails() {
      return this.campaignInformation.templateDetails
    },

    mailSubject() {
      return this.mailTemplate!==null ? this.mailTemplate.subject : ""
    }
  },


}
</script>

<style scoped>

</style>
