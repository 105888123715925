<template>
    <v-container fluid fill-height>

        <v-row align-center justify-center>
          <v-col offset=4 cols="4" class="logocol">
            <img width="220px" src="../assets/login_logo.png">
          </v-col>

          <v-col offset=4 cols="4">
            <v-card class="elevation-12 pa-10">
              <v-card-title class="mainTitle">Upgrade in progress</v-card-title>
              <v-card-subtitle class="subTitle">Dashboard reopens June 14th 17:00 CET</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>

        <v-row no-gutters class="footerRow">
          ag analytics &middot; +45 7020 1075 &middot; <a class="frontLink" href="mailto://support@aganalytics.dk">support@aganalytics.dk</a>
        </v-row>


    </v-container>
</template>

<script>

export default {
  name: 'upgrade'

}
</script>

<style scoped>
  div.loginButtonPanel {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 1em;
  }

  div.forgotSignupPanel {
    display: flex;
    justify-content: space-between;
  }

  div.forgotSignupPanel a {
    color: #888888;
  }

  div.logocol {
    display: flex;
    justify-content: center;
  }

  div.mainTitle {
    justify-content: center;
    /*font-size: 30px;*/
    font-size: calc((30/16)*1rem);
  }

  div.subTitle {
    text-align: center;
    /*font-size: 18px;*/
    font-size: calc((18/16)*1rem);
  }

  div.footerRow {
    align-self: flex-end;
    justify-content: center;
    color: #888888
  }

  a.frontLink {
    margin-left: 6px;
    color: #888888;
    text-decoration: none;
  }

  div.waitContainer {
      display: flex;
      justify-content: center;
  }

</style>

