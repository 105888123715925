<template>
  <v-form v-model="isValid" ref="sendingForm">
    <v-container fluid>
      <v-row>
        <v-col cols="2">
          <v-text-field v-model="campaignDetails.title" :label="$t('MAIL_CAMPAIGN_NAME_OF')" :rules="[mandatoryRules]" mandatory></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select :items="campaignTypes" v-model="campaignDetails.campaignType" :label="$t('MAIL_CAMPAIGN_TYPE')" @change="onCampaignTypeChanged"></v-select>
        </v-col>

        <v-col cols="2" v-if="isUserRoot">
          <v-text-field v-model="campaignDetails.extname" label="extname"></v-text-field>
        </v-col>


      </v-row>

      <v-row>
        <v-col cols="2">
          <v-text-field :disabled="!autoAdding" v-model="scheduleParams.fromDays" :label="$t('MAIL_CAMPAIGN_RELATIVE_FROM_TODAY')" type="number" persistent-hint :hint="fromDaysHint"
                        :rules="[mandatoryNumberRules, validateFirstTextField]" mandatory> </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field :disabled="!autoAdding" v-model="scheduleParams.toDays" :label="$t('MAIL_CAMPAIGN_RELATIVE_FROM_TODAY')"  type="number" persistent-hint :hint="toDaysHint"
                        :rules="[mandatoryNumberRules, validateSecondTextField]" mandatory> </v-text-field>
        </v-col>
      </v-row>
      <v-row>
      </v-row>

      <v-row>

        <v-col cols="2">

          <v-text-field v-model="campaignDetails.scheduledStartDateTime"
                        :hint="$t('MAIL_CAMPAIGN_SCHEDULE_HINT')"
                        persistent-hint
                        :label="$t('MAIL_CAMPAIGN_SCHEDULE')"
                        type="datetime-local"
                        :disabled="isPushCampaign"

          />
        </v-col>
        <v-col cols="2">
          <v-select :disabled="!autoAdding" :items="schedulePeriodItems" v-model="campaignDetails.scheduledPeriod" :label="$t('MAIL_CAMPAIGN_SCHEDULE')"></v-select>
        </v-col>

        <v-col>
          <div style="display: flex">
            <v-checkbox v-model="scheduleParams.weekdays" value="mon" class="pr-4" :label="$t('MONDAY')" :disabled="!autoAdding"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="tue" class="pr-4" :label="$t('TUESDAY')" :disabled="!autoAdding"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="wed" class="pr-4" :label="$t('WEDNESDAY')" :disabled="!autoAdding"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="thu" class="pr-4" :label="$t('THURSDAY')" :disabled="!autoAdding"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="fri" class="pr-4" :label="$t('FRIDAY')" :disabled="!autoAdding"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="sat" class="pr-4" :label="$t('SATURDAY')" :disabled="!autoAdding"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="sun" class="pr-4" :label="$t('SUNDAY')" :disabled="!autoAdding"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="hol" class="pr-4" :label="$t('HOLIDAYS')" :disabled="!autoAdding"></v-checkbox>
          </div>
        </v-col>

      </v-row>



<!--      <SchedulingComponent v-if="campaignDetails.autoAdd"  :schedule-params="campaignDetails.scheduleParams" @component-valid="e => schedulingComponentValid=e"/>-->
    </v-container>
  </v-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'SendingCard',

  components: {},

  props: {
    campaignDetails: Object
  },

  data: () => ({
    isValid: true,
    schedulingComponentValid: true
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    autoAdding() {
      return this.campaignDetails.campaignType==='PULL'
    },

    isPushCampaign() {
      return this.campaignDetails.campaignType==='PUSH'
    },

    campaignTypes() {
      return [
        { text: this.$t('MAIL_CAMPAIGN_TYPE_ONE_SHOT'), value: 'PULL_ONCE' },
        { text: this.$t('MAIL_CAMPAIGN_TYPE_AUTO_ADD'), value: 'PULL' },
        { text: this.$t('MAIL_CAMPAIGN_TYPE_PUSH'), value: 'PUSH' },
      ];
    },

    schedulePeriodItems() {
      return [
        { text: this.$t('MAIL_CAMPAIGN_DAILY'), value: 'DAILY' },
        { text: this.$t('MAIL_CAMPAIGN_WEEKLY'), value: 'WEEKLY' },
        { text: this.$t('MAIL_CAMPAIGN_MONTHLY'), value: 'MONTHLY' },
      ];
    },

    scheduleParams() {
      return this.campaignDetails.scheduleParams
    },


    validateFirstTextField() {
      return v=> isNaN(v) || !this.autoAdding || Number(v)<Number(this.scheduleParams.toDays) || this.$t('MAIL_CAMPAIGN_MUST_BE_LESS_THAN') + ' ' + this.scheduleParams.toDays
    },

    validateSecondTextField() {
      return v=> isNaN(v) || !this.autoAdding || Number(v)>Number(this.scheduleParams.fromDays) || this.$t('MAIL_CAMPAIGN_MUST_BE_LARGER_THAN')+ ' ' + this.scheduleParams.fromDays
    },

    fromDaysHint() {
      if(this.scheduleParams.fromDays) {
        const days = this.scheduleParams.fromDays>0 ? '+'+this.scheduleParams.fromDays : this.scheduleParams.fromDays;
        return this.$t('TODAY') + ' ' + days + ' ' + this.$t('DAYS')
      }
      return ''
    },

    toDaysHint() {
      if(this.scheduleParams.toDays) {
        const days = this.scheduleParams.toDays>0 ? '+'+this.scheduleParams.toDays : this.scheduleParams.toDays;
        return this.$t('TODAY') + ' ' + days + ' ' + this.$t('DAYS')
      }
      return ''
    },

    mandatoryRules() {
      return value => !!value || this.$t('VALIDATE_ERROR_MANDATORY')
    },

    mandatoryNumberRules() {
      return v => (!this.autoAdding || (v!==null && v!=='' && !isNaN(v))) ? true : this.$t('VALIDATE_ERROR_MANDATORY')
    },

  },

  methods: {
    onCampaignTypeChanged() {
      this.$refs.sendingForm.validate()
    }
  },

  watch: {
    isValid: function (newValue) {
      this.$emit('tab-valid', newValue)
    }
  }


}
</script>

<style scoped>

</style>
