<template>

  <v-card>
    <v-container fluid>
      <v-row class="pax-2">
        <v-col>
          <div class="mainHeadline">{{title}}</div>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn v-if="showSave" color="primary" text @click="onSaveClicked" :disabled="!isValid">{{'SAVE' | i18n}}</v-btn>
          <v-btn color="primary" text @click="onCloseClicked">{{'CANCEL' | i18n}}</v-btn>
          <v-btn v-if="showDelete" color="primary" text @click="onDeleteClicked">{{'DELETE' | i18n}}</v-btn>
        </v-col>

      </v-row>

      <v-form v-model="isValid" ref="form">

        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="selectedItem"
                              :disabled="isReadonly || suggestion"
                              :items="actionableItems"
                              item-text="title"
                              return-object
                              :label="$t('ACTION_QUESTION')"
                              :rules="mandatoryRules">
              </v-autocomplete>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field :label="$t('ACTION_VALUE')" v-model="initialValue" :disabled="true"/>
            </v-col>

            <v-col cols="6">

              <v-text-field v-model="goal" :label="goalLabel" type="number" :hint="goalHint"
                            required persistent-hint
                            :disabled="!selectedItem || isReadonly"
                            :rules="goalRules"
                            ref="goalField"
              >
              </v-text-field>


            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                  v-model="startMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  :disabled="isReadonly"
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="periodStartDate"
                      :label="$t('FROM')"
                      prepend-icon="mdi-calendar-clock"
                      required
                      :rules="mandatoryRules"
                      :disabled="isReadonly"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker :locale="locale" v-model="periodStartDate" @input="startMenu = false"></v-date-picker>
              </v-menu>

            </v-col>




            <v-col cols="12" md="6">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  :disabled="isReadonly"
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="deadline"
                      :label="$t('DEADLINE')"
                      prepend-icon="mdi-calendar-clock"
                      required
                      :rules="mandatoryRules"
                      :disabled="isReadonly"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker :locale="locale" v-model="deadline" @input="menu = false"></v-date-picker>
              </v-menu>

            </v-col>

          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                  :disabled="isReadonly"
                  :label="$t('RESPONSIBLE')"
                  v-model="responsible"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="commentsBar">{{'COMMENT' | i18n}}
                <div><v-btn text :disabled="addNewComment"  @click="addNewComment=true"><v-icon  class="commentIcons">mdi-plus-circle-outline</v-icon>{{'ADD_NEW_COMMENT' | i18n}}</v-btn>
                  <v-btn v-if="!historyExpanded" text @click="historyExpanded = !historyExpanded"><v-icon  class="commentIcons">mdi-history</v-icon>{{'COMMENTS_HISTORY' | i18n}}</v-btn>
                  <v-btn v-else text @click="historyExpanded = !historyExpanded"><v-icon class="commentIcons">mdi-chevron-left</v-icon>{{'BACK' | i18n}}</v-btn>
                </div>
              </div>

              <div class="newCommentWrapper" v-if="addNewComment">
                <v-textarea  solo v-model="comments" rows="6"> </v-textarea>
                <div class="newCommentButtons">
                  <v-btn v-if="showSave" color="primary" text @click="onSaveClicked" :disabled="!isValid">{{'SAVE' | i18n}}</v-btn>
                  <v-btn color="primary" text @click="onCloseClicked">{{'CANCEL' | i18n}}</v-btn>
                </div>
              </div>

              <v-simple-table v-if="historyExpanded">
                <thead>
                <tr>
                  <th class="text-left">{{'DATE' | i18n}}</th>
                  <th class="text-left">{{'SCORE' | i18n}}</th>
                  <th class="text-left">{{'GOAL' | i18n}}</th>
                  <th class="text-left">{{'USER' | i18n}}</th>
                  <th class="text-left">{{'COMMENTS' | i18n}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(c,idx) in oldCommentsView" :key="idx">
                  <td>{{c.updated}}</td>
                  <td>{{c.formattedScore}}</td>
                  <td>{{c.formattedGoal}}</td>
                  <td>{{c.username}}</td>
                  <td>{{c.message}}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>


          <v-row v-if="devChartData">
            <v-col>
              <ActionDevChartComponent :chart-data="devChartData"/>
            </v-col>
          </v-row>


        </v-container>
      </v-form>

    </v-container>
  </v-card>
</template>

<script>

import ResultsService from "../../../services/ResultsService";
import {mapGetters} from "vuex";
import * as _ from "lodash";
import ActionDevChartComponent from "../ActionDevChartComponent";

export default {
  name: 'ActionEditForm',

  components: {ActionDevChartComponent},

  data: () => ({
    initialValue: "",
    selectedItem: null,
    state: 0,
    question: "",
    goal: "",
    responsible: "",
    oldComments: [],
    comments: "",
    startMenu: null,
    menu: null,
    isValid: false,
    periodStartDate: null,
    deadline: null,
    actionableItems: [],
    devChartData: null,
    suggestion: false,
    canEdit: false,
    historyExpanded: false,
    addNewComment: false,
    effectiveActionPeriod: ''
  }),

  props: {
    dialog: Boolean,
    id: String
  },

  created: function () {
    this.loadActionData()
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isUserRoot', 'getTimeFilter', 'getLocale']),


    isReadonly() {
      return this.state!==0 || !this.canEdit
    },

    title() {
      return this.id==="0" ? this.$t('ADD_ACTION') : this.$t('EDIT_ACTION')
    },

    locale() {
      return this.getLocale.replace('_', '-')
    },

    showSave() {
      return this.state===0
    },

    showDelete() {
      return (this.canEdit) && this.id!=="0"
    },

    oldCommentsView() {
      if(this.oldComments) {
        return this.historyExpanded ? this.oldComments : this.oldComments.slice(0, 1)
      }

      return []
    },

    goalLabel() {
      if(this.selectedItem) {
        return this.$t("SET_MIN_GOAL")
      }

      return this.$t("GOAL_ICON_TOOLTIP")
    },

    goalHint() {
      if(this.selectedItem && this.selectedItem.goalMin!==null && this.selectedItem.goalMax!==null) {
        return this.selectedItem.goalMin + " - " + this.selectedItem.goalMax
      }

      return ""
    },

    timePeriods() {
      let items = []

      for(let filterGroup of this.getActiveSurvey.timeFilterSet.filterGroups) {
        items.push( { header: filterGroup.title }  )

        for(let timeOption of filterGroup.filters[0].options) {
          items.push( { name: timeOption.title, value: timeOption.extName } )
        }

      }

      return items
    },

    mandatoryRules() {
      return  [
        value => !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
      ]
    },


    goalRules() {
      return  [
        value => !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
        value =>  {
          if(this.selectedItem && this.selectedItem.goalMin!==null && this.selectedItem.goalMax!==null) {
            if(Number(value) < this.selectedItem.goalMin || Number(value)>this.selectedItem.goalMax) {
              return "Value must be between " + this.selectedItem.goalMin + " and " + this.selectedItem.goalMax
            }
          }
          return true
        }
      ]
    },


  },



  methods: {

    updateEffectivePeriod() {
      if(this.period && this.deadline) {
        ResultsService.calculateEffectiveActionPeriod(this.getAuthToken, this.getActiveSurvey.extName, this.period, this.deadline).then((response) => {
          this.effectiveActionPeriod = response.data
        })
      } else {
        this.effectiveActionPeriod = null
      }

    },

    loadActionData() {

      this.initialValue = ""
      this.selectedItem = null
      this.state = 0;
      this.question = ""
      this.goal = ""
      this.responsible = ""
      this.comments = ""
      this.oldComments = []
      this.menu = null
      this.isValid = false
      this.deadline = null
      this.period = null
      this.actionableItems = []
      this.devChartData= null
      this.suggestion = false;

      ResultsService.getActionData(this.getAuthToken, this.getActiveSurvey.extName, this.id, this.getFilteredDrillPath).then((response) => {
        const updateData = response.data.updateData

        this.actionableItems = response.data.actionableItems

        if(updateData) {
          this.suggestion = updateData.suggestion;

          this.canEdit = updateData.canEdit

          console.log("Expression")
          console.log(updateData)

          if(updateData.expression) {
            this.selectedItem = _.find(this.actionableItems, {'expression': updateData.expression, });
          }

          this.responsible = updateData.responsible
          this.comments = updateData.comments

          this.oldComments = updateData.commentsList

          this.historyExpanded = false

          this.addNewComment = true; //(this.id===null && this.oldComments===null)

          this.state = updateData.state
          this.goal = updateData.goal
          this.periodStartDate = updateData.periodStartDate
          this.deadline = updateData.deadline
          this.period = (updateData.period) ? updateData.period : this.getTimeFilter
          this.$refs.form.validate()

          if(updateData.expression) {
            this.loadDevChartData(this.selectedItem)
            this.evaluateAction(this.selectedItem)
          }

        } else {
          // this.id = null
          this.period = this.getTimeFilter
          this.$refs.form.validate()

          this.canEdit = true;
          this.suggestion = false;
          this.addNewComment = true

        }

      })
    },

    loadDevChartData(item) {
      ResultsService.fetchActionDevChartData(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, item.expression).then((response) => {
        this.devChartData = response.data
      })
    },

    evaluateAction(item) {
      ResultsService.getActionValue(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, item.expression, this.period).then((response) => {
        this.initialValue = response.data
      })
    },

    onPeriodChanged() {
      this.evaluateAction(this.selectedItem);
    },

    onCloseClicked() {
      this.$emit('close', false)
    },

    onSaveClicked() {

      const updateDTO = {
        id: this.id,
        extname: this.selectedItem.extname,
        state: this.state,
        expression: this.selectedItem.expression,
        goal: this.goal,
        periodStartDate: this.periodStartDate,
        deadline: this.deadline,
        responsible: this.responsible,
        comments: this.comments
      }

      ResultsService.updateActionData(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, updateDTO).then(() => {
        this.$store.commit('setSnackText', this.$t('ACTION_SAVED'))
        this.$emit('close', true)
      })

    },

    onDeleteClicked() {
      ResultsService.deleteActionData(this.getAuthToken, this.getActiveSurvey.extName, this.id).then(() => {
        this.$emit('close', true)
      })
    }
  },


  watch: {
    deadline: function (value) {
      this.updateEffectivePeriod()
    },

    period: function (value) {
      this.updateEffectivePeriod()
    },

    dialog: function (value) {
      if(value) { // create new action shown again
        this.loadActionData()
      }
    },

    selectedItem: function (item) {
      if(item) {
        const inputElement = this.$refs.goalField.$el.querySelector('input')

        inputElement.min = item.goalMin
        inputElement.max = item.goalMax

        this.evaluateAction(this.selectedItem);
        this.loadDevChartData(item);
      }
    },
  }

}

</script>

<style scoped>
.bgbutton {
  margin:0 auto;
  display:block;
}

.mainHeadline {
  height: 28px;
  color: rgba(0,0,0,0.87);
  /*font-size: 24px;*/
  font-size: calc((24/16)*1rem);
  font-weight: 500;
  margin-left: 10px;
}

div.commentsBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*font-size: 24px;*/
  font-size: calc((24/16)*1rem);

}

div.newCommentWrapper {
  display: flex;
  flex-direction: column;
}

div.newCommentButtons {
  align-self: flex-end;
}


.groupTitle {
  color: rgba(0,0,0,0.54);
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
  border-bottom: 1px solid rgba(0,0,0,0.12);
}

.labelValue {
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  margin-left: 1em;
  margin-right: 1em;
  justify-content: space-between;
  width: 100%;
}

.commentDiv {
  display: flex;
  flex-direction: column;
}

.commentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*font-size: 13px;*/
  font-size: calc((13/16)*1rem);
  color: rgba(0,0,0,.54);
}

.commentBody {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
}

.commentIcons {
  margin-left: 4px;
}

</style>