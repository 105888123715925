<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent @path-selected="onDrillToNamedPath" :leaf="true" :show-download="true" :interview-id="interviewId"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathBackComponent/>
      </v-col>
    </v-row>

    <v-row class="pl-3 pr-3">
      <v-col cols="12">
        <AlertTopComponent @save="updateAlert" :alert-data="alertData" v-if="alertData!==null" v-on:reload="loadAlertData"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <InterviewHeader v-if="alertData!==null && alertData.interviewData!==null" :interview-data="alertData.interviewData" />
      </v-col>
    </v-row>

    <v-row class="pa-3">
      <v-col cols="3">
        <SideCardComponent v-if="alertData!==null && alertData.interviewData!==null" :interview-data="alertData.interviewData"/>
      </v-col>

      <v-col cols="9">
        <InterviewComponent v-if="alertData!==null && alertData.interviewData!==null" :interview-data="alertData.interviewData"></InterviewComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import AlertsService from "../../services/AlertsService";
import AlertTopComponent from "./AlertTopComponent";
import InterviewComponent from "../interview/InterviewComponent";
import SideCardComponent from "../interview/SideCardComponent";
import DrillPathComponent from "../../components/DrillPathComponent";
import DrillPathBackComponent from "../../components/DrillPathBackComponent";
import InterviewHeader from "@/views/interview/InterviewHeader";

export default {
  name: 'AlertDetails',

  components: {
    InterviewHeader,
    DrillPathComponent, DrillPathBackComponent, AlertTopComponent, SideCardComponent, InterviewComponent
  },

  data: () => ({
    alertData: null,
    showSuccessAlert: false
  }),

  methods: {
    onDrillToNamedPath: function (namedPath) {
      if(!namedPath.entityId) {
        this.$store.commit('setDrillPath', namedPath.path)
        this.$router.push({ name: 'alerts', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
      }
    },

    updateAlert(dto) {
      AlertsService.updateAlert(this.getAuthToken, this.getActiveSurvey.extName, this.alertData.id, dto).then((/*response*/) => {
        this.$store.commit('setSnackText', dto.isClosed ? this.$t('ALERT_CLOSED') : this.$t('ALERT_PROCESSED'))
        this.$router.push({ name: 'alerts', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
      })


    },

    loadAlertData() {
      let surveyExtName = this.$route.params.surveyExtName
      let alertId = this.$route.params.alertId

      AlertsService.fetchAlertData(this.getAuthToken, surveyExtName, alertId).then((response) => {
        // console.log("Alert result: ")
        // console.log( response.data)
        this.alertData = response.data
      })
    }
  },

  computed: {
    ...mapState(['interviewData', 'activeSurvey']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    interviewId() {
      return this.alertData ? this.alertData.interviewId : null
    },

  },

  watch: {
    $route: {
      handler() {
        let surveyExtName = this.$route.params.surveyExtName
        if(surveyExtName !== this.activeSurvey.extName) {
          this.$store.commit('selectSurvey', surveyExtName)
        }
        this.loadAlertData()
      },
      immediate: true
    },
  },

}
</script>
<style scoped>
</style>