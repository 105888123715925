<template>
    <v-btn :disabled="isDisabled"  :block="componentData.block" @click.once="onButtonClick" :color="componentData.color">{{componentData.title}}</v-btn>
</template>

<script>

import {mapGetters, mapState} from "vuex";

export default {
  name: 'PushCampaignButtonComponent',

  components: {},

  data: () => ({
    isClicked: false
  }),

  props: {
    componentData: Object,
    sampleId: Number
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'rankingList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),

    isDisabled() {
      return !this.componentData.active || this.isClicked
    }

  },

  methods: {
    requestSave() {
      return true
    },

    onButtonClick() {
      this.isClicked = true
      this.$root.$emit('pushcampaign', this.componentData)
    }
  },

}
</script>
<style scoped>
</style>