<template>

  <v-container fluid pa-0>
    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent @path-selected="onDrillToNamedPath" :show-filter="true" :show-download="true" :ranking-list-report="enableRankingListReport"/>
      </v-col>
    </v-row>

    <v-row class="elevation-2 filterRow">
      <v-col class="pt-0 pb-0">
        <FilterBarComponent v-if="activeSurvey!==null && activeSurvey.timeFilterSet && activeSurvey.standardFilterSet"  :filter-sets="[activeSurvey.standardFilterSet, activeSurvey.timeFilterSet]" :show-info="true"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <PageComponent v-for="(comp, index) in page.components" :component-data="comp" :key="index"/>
      </v-col>
    </v-row>

    <v-row class="pa-3" v-if="showOldRankingTable">
      <v-col>
        <RankingTable v-if='tableData.headers'
                      v-on:view-interview="onViewInterview"
                      v-on:view-sample="onViewSample"
                      v-on:drill-to="onDrillTo"
                      v-on:request-data="onRequestData"
                      :tableheaders="tableData.headers"
                      :tabledata="tableData.rows"
                      :tablefooter="tableData.footer"
                      :rankingVar="tableData.rankingVar"
                      :leaf-rows="tableData.leafRows"
                      :total-rows="tableData.totalNumberRows"
                      :loading="tableData.loading"
                      :table-options="tableData.tableOptions"/>

        <div v-else class="waitContainer">
          <v-progress-circular
              :size="100"
              :width="4"
              color="#0000c8"
              indeterminate
          />
        </div>

      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
import RankingTable from "./RankingTable";
import DrillPathComponent from "../../components/DrillPathComponent";
import FilterBarComponent from "../../components/FilterBarComponent";
import PageComponent from "../pages/PageComponent";
import ResultsService from "../../services/ResultsService";
import PageNavigator from "../../pagenavigator";

export default {
  name: 'ranking',

  components: {
    FilterBarComponent,
    DrillPathComponent,
    RankingTable,
    PageComponent
  },

  data: () => ({
    tableData: {
      headers: null,
      rows: null,
      footer: null,
      rankingVar: null,
      leafRows: null,
      totalNumberRows: null,
      loading: false,
      tableOptions: {
        page: 1,
        sortBy: [],
        sortDesc: []
      }
    },
    canViewCompletedSamples: false
  }),


  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'rankingList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),

    // isAudi () {
    //   return this.activeSurvey && this.activeSurvey.extName.startsWith('Audi')
    // },

    page: function () {
      return _.find(this.activeSurvey.pages, { extname: 'rankings' })
    },

    showOldRankingTable() {
      return !this.page.components.some(comp => comp.type==='RankingTableComponent')
    },

    enableRankingListReport: function() {
      return this.activeSurvey.enableRankingListReport /*&& this.tableData && !this.tableData.leafRows*/
    }

  },


  methods: {
    initialFetchData() {

      this.tableData.loading = true

      ResultsService.fetchRankingData(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, this.getFilterTerm).then((response) => {
        const rankingData = response.data.rankingData
        this.tableData.headers = rankingData.headers
        this.tableData.rows = rankingData.rows
        this.tableData.footer = rankingData.footer
        this.tableData.rankingVar = rankingData.rankingVar
        this.tableData.leafRows = rankingData.leafRows
        this.tableData.totalNumberRows = rankingData.totalNumberRows

        this.tableData.loading = false

        this.canViewCompletedSamples = response.data.viewCompletedSamples
        this.$store.commit('setSegmentedDrillPath', response.data.segmentedDrillPath)
      })

    },

    onRequestData: function (dataRequestDTO) {
      this.tableData.loading = true

      let filterTerm = dataRequestDTO.filter

      if(!filterTerm) {
        filterTerm = this.getFilterTerm
      }

      this.tableData.tableOptions.page = 1+(dataRequestDTO.rowFrom/50)
      this.tableData.tableOptions.sortBy = [dataRequestDTO.sortVar]
      this.tableData.tableOptions.sortDesc = [ dataRequestDTO.sortDesc ]

      ResultsService.fetchRankingDataPaged(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, dataRequestDTO.rowFrom, dataRequestDTO.rowTo, dataRequestDTO.sortVar, dataRequestDTO.sortDesc, filterTerm ).then((response) => {
        const rankingData = response.data.rankingData
        this.tableData.rows = rankingData.rows
        this.tableData.totalNumberRows = rankingData.totalNumberRows
        this.tableData.loading = false
      })

    },

    replaceCurrentRoute() {
      this.$router.replace( {
        name: 'rankings',
        params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath },
        query: { page: this.tableData.tableOptions.page, sortBy: this.tableData.tableOptions.sortBy, sortDesc: this.tableData.tableOptions.sortDesc }
      }).catch(() => {})
    },

    onDrillToNamedPath: function (namedPath) {
      if(namedPath.entityId) {
        if (namedPath.isCompleted) {
          this.replaceCurrentRoute()
          PageNavigator.navigateToInterviewPage(this.$router, this.activeSurvey.extName, namedPath.entityId)
        } else {
          this.replaceCurrentRoute()
          PageNavigator.navigateToSamplePage(this.$router, this.activeSurvey.extName, namedPath.entityId)
        }

      } else {
        this.$store.commit('setDrillPath', namedPath.path)
      }
    },

    loadSegmentedPath() {
      ResultsService.fetchSegmentedDrillPath(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath).then((response) => {
        this.$store.commit('setSegmentedDrillPath', response.data)
//        console.log("#alerts=" + response.data.nOpenAlerts)
        this.$store.commit('setNumberOpenAlerts', response.data.nOpenAlerts)
      })
    },
    onDrillTo: function (drillPath) {
      // console.log("Drillpath: " + drillPath)

      this.$store.commit('setDrillPath', drillPath)
    },


    onViewInterview: function (interviewId) {
      // if( this.canViewCompletedSamples) {
      //   this.$router.push({ name: 'sample', params: { surveyExtName: this.activeSurvey.extName, sampleId: interviewId } })
      // } else {
      this.replaceCurrentRoute()
      this.$router.push({ name: 'interview', params: { surveyExtName: this.activeSurvey.extName, interviewId: interviewId } })
//      }
    },

    onViewSample: function (sampleId) {
      this.replaceCurrentRoute()
      this.$router.push({ name: 'sample', params: { surveyExtName: this.activeSurvey.extName, fromPage: 'rankings', sampleId: sampleId } })
    }

  },

  updated: function () {
    this.$nextTick(function () {
      // this.onResize()
      // console.log("Parent updated method!")
      // Code that will run only after the
      // entire view has been re-rendered
    })
  },

  loadSegmentedPath() {
    ResultsService.fetchSegmentedDrillPath(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath).then((response) => {
      this.$store.commit('setSegmentedDrillPath', response.data)
      this.$store.commit('setNumberOpenAlerts', response.data.nOpenAlerts)
    })
  },



  watch: {
    $route: {
      handler() {
        let drillPath = this.$route.params.drillPath
        this.$store.commit('setFilteredDrillPath', drillPath)
        this.$store.commit('setFilterTerm', "")
        this.$store.commit("setSearchSuggestions", [])
        this.loadSegmentedPath();

        const pageNumber = (this.$route.query.page) ? this.$route.query.page : 1
        const sortBy = (this.$route.query.sortBy) ? this.$route.query.sortBy : "none"
        const sortDesc = (this.$route.query.sortDesc) ? (this.$route.query.sortDesc === 'true') : true

        this.tableData.tableOptions.page = pageNumber
        this.tableData.tableOptions.sortBy = [sortBy]
        this.tableData.tableOptions.sortDesc = [sortDesc]

        if(this.showOldRankingTable) {
          this.initialFetchData();
        } else {

          ResultsService.fetchSegmentedDrillPath(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath).then((response) => {
            this.$store.commit('setSegmentedDrillPath', response.data)
            this.$store.commit('setNumberOpenAlerts', response.data.nOpenAlerts)
          })

        }
      },
      immediate: true
    },

    getFilteredDrillPath: function (newPath, oldPath) {
      if (newPath && (newPath !== oldPath)) {
        this.$router.push({ name: 'rankings', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
        this.$store.commit('setFilterTerm', "")
        this.$store.commit("setSearchSuggestions", [])
      }
    },

    filterTerm: function (newTerm, oldTerm) {
      if (newTerm !== oldTerm) {
        this.initialFetchData();
      }
    },

    searchTerm: function (newTerm, oldTerm) {
      if (newTerm && (newTerm !== oldTerm)) {

        ResultsService.fetchRankingSuggestions(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, newTerm).then((response) => {
          const suggestionsDTO = response.data
          this.$store.commit("setSearchSuggestions", suggestionsDTO.suggestions)
        })
      }
    }

  },

}
</script>
<style scoped>

div.waitContainer {
  display: flex;
  justify-content: center;
}


</style>