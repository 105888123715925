<template>
    <v-container fluid v-if="campaignDetails && campaignConfiguration">
      <v-row>
        <v-col>
          <v-tabs v-model="activeTab">
            <v-tab href="#basic">{{'MAIL_CAMPAIGN_TAB_SENDINGS' | i18n}}<v-icon v-if="!sendingCardValid">mdi-information</v-icon></v-tab>
            <v-tab href="#template" append>{{'MAIL_CAMPAIGN_TAB_TEMPLATE' | i18n}}<v-icon v-if="!templateCardValid">mdi-information</v-icon></v-tab>
            <v-tab href="#datavalidation">{{'MAIL_CAMPAIGN_TAB_VALIDATION' | i18n}}</v-tab>
            <v-tab href="#filters"  @click="filtersTabSelected()">{{'MAIL_CAMPAIGN_TAB_FILTERS' | i18n}}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab">
            <v-tab-item value="basic">
              <SendingCard :campaign-details="campaignDetails" @tab-valid="e=> sendingCardValid=e"/>
            </v-tab-item>
            <v-tab-item value="template" eager>
              <TemplateCard :campaign-details="campaignDetails" :campaign-configuration="campaignConfiguration" @tab-valid="e=> templateCardValid=e" />
            </v-tab-item>
            <v-tab-item value="datavalidation">
              <DataValidatingCard :campaign-details="campaignDetails"/>
            </v-tab-item>
            <v-tab-item value="filters">
              <FiltersCard :campaign-details="campaignDetails"/>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn class="mr-4" :disabled="!sendingCardValid || !templateCardValid" @click="onUpdateClicked">{{'UPDATE' | i18n}}</v-btn>
          <v-btn @click="onCancelClicked">{{'CANCEL' | i18n}}</v-btn>
        </v-col>
      </v-row>

    </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import SendingCard from "@/views/mailcampaigns/SendingCard.vue";
import DataValidatingCard from "@/views/mailcampaigns/DataValidatingCard.vue";
import TemplateCard from "@/views/mailcampaigns/TemplateCard.vue";
import MailsService from "@/services/MailsService";
import FiltersCard from "@/views/mailcampaigns/FiltersCard.vue";

export default {
  name: 'CampaignEditorComponent',

  components: {FiltersCard, TemplateCard, DataValidatingCard, SendingCard},

  props: {
    campaignId: Number
  },

  data: () => ({
    activeTab: null,

    campaignDetails: null,
    campaignConfiguration: null,

    sendingCardValid: true,
    templateCardValid: true
  }),

  created() {
    this.$store.commit('setWatchingFilteredDrillPath', false)
    this.fetchCampaignConfiguration()
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

  },

  beforeDestroy: function () {
    this.$store.commit('setWatchingFilteredDrillPath', true)
  },

  methods: {
    onCancelClicked() {
      this.$emit('editor-cancel')
    },

    onUpdateClicked() {
      this.campaignDetails.link = this.getFilteredDrillPath

      MailsService.updateMailCampaignData(this.getAuthToken, this.activeSurvey.extName, this.campaignDetails.id, this.campaignDetails).then((/*response*/) => {
        this.$emit('editor-update')
      })

    },

    fetchCampaignConfiguration() {
      MailsService.fetchMailCampaignData(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((response) => {
        this.campaignDetails = response.data
        this.$store.commit('setFilteredDrillPath', this.campaignDetails.link.replaceAll('?', ';'))
      }),

      MailsService.fetchCampaignConfiguration(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((response) => {
        this.campaignConfiguration = response.data
      })

    },

    filtersTabSelected() {
      this.$store.commit('setFilterSideBarState', true)
    }

  }


}
</script>

<style scoped>

</style>
