<template>
  <div class="wrapper elevation-2">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>

import {Chart} from "highcharts-vue";

export default {
  name: 'PerformanceChart',

  props: {
    chartData: Object
  },

  components: {
    highcharts: Chart
  },

  data () {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          alignTicks: false,
          style: {
            fontFamily: 'Roboto',
          }
        },

        plotOptions: {
          // pointStart: 2010,
          series: {
            enableMouseTracking: false,
            color: '#00C896'
          }
        },

        xAxis: {
          categories: this.chartData.categories,
        },

        yAxis: {
          lineWidth: 1,
          tickInterval: 5,
          max: this.chartData.record+2,
          title: null,

          plotLines: [{
            value: this.chartData.record,
            color: '#0000C8',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: this.$t('GAMIFICATION_PERSONAL_RECORD') + ": " + this.chartData.record
            }
          }]

        },

        series: [
          {
            data: this.chartData.points,
          }],

        tooltip: {
          enabled: true,
          shared: false
        },

        title: {
          text: this.$t('GAMIFICATION_PERFORMANCE').replace('{0}', this.chartData.points.length),
          align: "left",
          margin: 55
        },

        legend: {
          enabled: false
        },

        credits: {
          enabled: false
        },

      },

      mytitle: "Peter"
    }

  },

  computed: {
    chartTitle() {
      return this.$t('GAMIFICATION_PERFORMANCE') + " " + this.chartData.points.length
    }
  },



}
</script>
<style scoped>

.wrapper {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}

</style>