<template>

  <v-card>
    <v-card-title>KeyCloak</v-card-title>
      <v-container fluid>
        <v-row align="center">

          <v-col cols="2">
            <v-text-field :value="email" label="email" :disabled="true"/>
          </v-col>

          <v-col cols="1">
            <v-text-field :value="firstName" label="firstName" :disabled="true"/>
          </v-col>

          <v-col cols="1">
            <v-text-field :value="lastName" label="lastName" :disabled="true"/>
          </v-col>

          <v-col cols="1">
            <v-checkbox v-model="enabled" label="enabled" :disabled="true"/>
          </v-col>

          <v-col cols="2">
            <v-text-field :value="userId" label="id" :disabled="true"/>
          </v-col>

          <v-col cols="1">
            <v-btn @click="$emit('updatekeycloakdata')">fetch</v-btn>
          </v-col>

        </v-row>

        <v-row v-if="error">
          <v-col>
            <div>{{error}}</div>
          </v-col>
        </v-row>

      </v-container>

  </v-card>


</template>


<script>
import {mapGetters, mapState} from 'vuex'
// import UserSurveyComponent from "@/views/users/UserSurveyComponent.vue";
// import UserQuestionFilterComponent from "./UserQuestionFilterComponent";

export default {
  name: 'KeyCloakUserComponent',

  components: {
  },

  props: {
    userId: String,
    email : String,
    firstName : String,
    lastName: String,
    enabled: Boolean,
    error: String
  },

  data: () => ({
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    // isUserExisting() {
    //   return this.userId!==0
    // },

  },

  methods: {
    // onCreateClicked(doCreate) {
    //   this.$emit('update:createuser', true)
    // }
  },


}
</script>
