<template>

  <v-hover v-slot="{ hover }">
    <v-container class="wrapper elevation-2" fluid @click="onContainerClicked">

      <v-row>
        <v-col >
          <div class="text-center">
            <TinyLogoComponent :universe="vardto.universeName"/>
          </div>
          <div class="title text-center">
            <template v-if="loading">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </template>

            <div :class="{ hiddenState: hover }">{{currentVariable.nodeName}}</div>

            <div v-if="hover" style="height: 30px">
              <div class="loginDiv">LOGIN <v-icon color="primary">mdi-chevron-right</v-icon> </div>
            </div>
            <div v-else style="height: 30px">
              <div v-html="title"></div>
            </div>

          </div>
        </v-col>
      </v-row>


      <v-row :class="{ hoverState: hover }" no-gutters>
        <v-col >
          <div class="score text-center" :style="{ color: titleColor }">
            <span v-if="currentVariable.formattedValue!==''">{{currentVariable.formattedValue}}</span>
            <span v-else style="visibility: hidden">?</span>
          </div>
        </v-col>
      </v-row>

      <v-row :class="{ hoverState: hover }">
        <v-col px-1 mb-3>
          <ScoreGoalBarComponent :vardto="currentVariable"/>
        </v-col>
      </v-row>

      <v-row align="center" :class="{ hoverState: hover }">
        <v-col cols="6" pl-2>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="iconnumberleft">
                <v-icon small class="bottomicon" >mdi-message-outline</v-icon>
                <span class="bottomNumbers">{{currentVariable.nInterviews}}</span>
              </div>
            </template>
            <span>{{currentVariable.interviewsTooltip}}</span>
          </v-tooltip>

        </v-col>

        <v-col cols="6" pr-2>
          <div class="text-right">

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on" class="iconnumberright">
                  <v-icon small class="bottomicon mr-3">mdi-crosshairs-gps</v-icon>
                  <span class="bottomNumbers">{{currentVariable.formattedGoal}}</span>
                </div>
              </template>
              <span>{{currentVariable.goalTooltip}}</span>
            </v-tooltip>

          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-hover>
</template>

<script>
import ScoreGoalBarComponent from '../pages/pagecomponents/ScoreGoalBarComponent'
import TinyLogoComponent from "@/components/TinyLogoComponent";

export default {
  name: 'IndicatorVariableComponent',

  components: {TinyLogoComponent, ScoreGoalBarComponent },

  props: {
    vardto: Object
  },

  data: () => ({
    selectedVariable: null,
    loading: false
  }),

  computed: {

    title() {
      return this.currentVariable.title ? this.currentVariable.title : "&nbsp"
    },

    currentVariable() {
      return this.selectedVariable ? this.selectedVariable : this.vardto
    },

    arrowIcon: function () {
      if (this.currentVariable.diffArrow === 1) {
        return 'mdi-menu-up'
      } else if (this.currentVariable.diffArrow === 2) {
        return 'mdi-menu-down'
      }

      return 'mdi-trending-neutral'
    },

    arrowColor: function () {
      if(this.currentVariable.inverse) {
        if (this.currentVariable.diffArrow === 1) {
          return '#b9133e'
        } else if (this.currentVariable.diffArrow === 2) {
          return '#00c896'
        }
      } else {
        return (this.currentVariable.diffArrow === 1) ? '#00c896' : '#b9133e';
      }

      return '#000000'
    },


    titleColor: function () {
      if(this.currentVariable.goalValue !== null) {
        if(this.currentVariable.inverse) {
          return (this.currentVariable.value >= this.currentVariable.goalValue) ? '#b9133e' : '#00c896'
        } else {
          return (this.currentVariable.value >= this.currentVariable.goalValue) ? '#00c896' : '#b9133e'
        }
      }

      return '#000000'
    }

  },

  methods: {

    onContainerClicked() {
      this.loading = true
      this.$emit('select', { universe: this.currentVariable.universeName, metainterview: this.currentVariable.metaInterviewName })
    },

  }



}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  height: 100%;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}


div.title {
  /*font-size: 14px !important;*/
  font-size: calc((14/16)*1rem) !important;

  font-weight: normal;
  line-height: 20px;
  /*height: 30px;*/
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-overflow: ellipsis;
  overflow: hidden;
}

div.score {
  font-size: 3em;
  font-weight: 500;
}

i.bottomicon {
  vertical-align: text-top;
  margin-right: 5px;
}

span.bottomNumbers {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem) !important;
}

div.iconnumberleft {
  display: flex;
  align-items: center;
}

div.iconnumberright {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
}

div.hoverState {
  opacity: 0.2;
}

div.hiddenState {
  display: inline-block;
  visibility: hidden;
}

div.loginDiv {
  font-weight: bold;
}

</style>
