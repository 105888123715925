import Vue from 'vue'
import Router from 'vue-router'
import Ranking from './views/ranking/Ranking.vue'
import Interview from './views/interview/Interview.vue'
import AlertList from './views/alerts/AlertList.vue'
import Login from "./views/Login";
// import Forgot from "./views/Forgot";
// import Reset from "./views/Reset";
// import store from './store'
import SampleList from "./views/samples/SampleList";
import GenericPage from "./views/pages/GenericPage";
import ReportDownload from "./views/download/ReportDownload";
import AlertDetails from "./views/alerts/AlertDetails";
import SampleDetails from "./views/samples/SampleDetails";
import UserList from "./views/users/UserList";
import UserDetails from "./views/users/UserDetails";
import Upgrade from "./views/Upgrade";
import Responsive from "./views/Responsive";
import ForwardVGS from "@/views/ForwardVGS";
import SurveyList from "@/views/backend/SurveyList";
import SurveyDetails from "@/views/backend/SurveyDetails";
import ScriptBlockDetails from "@/views/backend/ScriptBlockDetails";
import RoleDetails from "@/views/backend/RoleDetails";
import BigScreen from "@/views/bigscreen/BigScreen";
import MailTemplateDetails from "@/views/mailtemplates/MailTemplateDetails";
import MailCampaignDetails from "@/views/mailcampaigns/MailCampaignDetails";
import CreateCampaignPage from "@/views/mailcampaigns/CreateCampaignPage.vue";
import ForwardFinland from "@/views/ForwardFinland";
import UserEditor from "@/views/users/UserEditor.vue";
import SMSTemplateDetails from "@/views/mailtemplates/SMSTemplateDetails";
import LetterTemplateDetails from "@/views/mailtemplates/LetterTemplateDetails.vue";

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: { requiresAuth: true, hideViewTabs: true, hideFilterSidebar: true, hideMainSidebar: true }
    },

    {
      path: '/upgrade',
      name: 'upgrade',
      component: Upgrade,
      meta: { requiresAuth: false }
    },

    {
      path: '/vgs',
      name: 'vgs',
      component: ForwardVGS,
      meta: { requiresAuth: true, hideViewTabs: true, hideFilterSidebar: true, hideMainSidebar: true }
    },

    {
      path: '/finland',
      name: 'finland',
      component: ForwardFinland,
      meta: { requiresAuth: true, hideViewTabs: true, hideFilterSidebar: true, hideMainSidebar: true }
    },

    // {
    //   path: '/forgot',
    //   name: 'forgot',
    //   component: Forgot,
    //   meta: { requiresAuth: false }
    // },

    // {
    //   path: '/reset/:resetToken',
    //   name: 'reset',
    //   component: Reset,
    //   meta: { requiresAuth: false }
    // },

    {
      path: '/bigscreen/:apiKeyToken',
      name: 'bigscreen',
      component: BigScreen,
      meta: { requiresAuth: false, hideViewTabs: true, hideFilterSidebar: true, hideMainSidebar: true }
    },


    {
      path: '/ranking/:surveyExtName/:drillPath',
      name: 'rankings',
      component: Ranking,
      meta: { requiresAuth: true }
    },

    {
      path: '/interview/:surveyExtName/:interviewId',
      name: 'interview',
      component: Interview,
      meta: { requiresAuth: true, hideViewTabs: true, hideFilterSidebar: true }
    },

    {
      path: '/alertlist/:surveyExtName/:drillPath',
      name: 'alerts',
      component: AlertList,
      meta: { requiresAuth: true }
    },

    {
      path: '/alert/:surveyExtName/:alertId',
      name: 'alert',
      component: AlertDetails,
      meta: { requiresAuth: true, hideViewTabs: true, hideFilterSidebar: true }
    },

    {
      path: '/samplelist/:surveyExtName/:drillPath',
      name: 'samples',
      component: SampleList,
      meta: { requiresAuth: true }
    },

    {
      path: '/sample/:surveyExtName/:fromPage/:sampleId',
      name: 'sample',
      component: SampleDetails,
      meta: { requiresAuth: true, hideViewTabs: true, hideFilterSidebar: true }
    },

    {
      path: '/page/:pageId/:surveyExtName/:drillPath',
      name: 'page',
      component: GenericPage,
      meta: { requiresAuth: true }
    },

    {
      path: '/reportdownload/:surveyExtName/:reportUUID',
      name: 'ReportDownload',
      component: ReportDownload,
      meta: { requiresAuth: true }
    },

    {
      path: '/userlist/:surveyExtName',
      name: 'users',
      component: UserList,
      meta: { requiresAuth: true }
    },

    {
      path: '/userdetails/:surveyExtName/:userId',
      name: 'userdetails',
      component: UserDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/usereditor/:surveyExtName/:userId',
      name: 'usereditor',
      component: UserEditor,
      meta: { requiresAuth: true, hideViewTabs: true, hideFilterSidebar: true }
    },


    {
      path: '/surveylist/:surveyExtName',
      name: 'surveylist',
      component: SurveyList,
      meta: { requiresAuth: true }
    },

    {
      path: '/sl',
      name: 'shortcutsurveylist',
      component: SurveyList,
      meta: { requiresAuth: true }
    },


    {
      path: '/survey/:surveyExtName/:surveyId',
      name: 'survey',
      component: SurveyDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/script/:surveyExtName/:surveyId/:scriptId',
      name: 'script',
      component: ScriptBlockDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/script/:surveyExtName/:scriptId',
      name: 'globalscript',
      component: ScriptBlockDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/script/:surveyExtName',
      name: 'universescript',
      component: ScriptBlockDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/role/:surveyExtName/:roleId',
      name: 'role',
      component: RoleDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/mailtemplate/:surveyExtName/:templateId',
      name: 'mailtemplate',
      component: MailTemplateDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/smstemplate/:surveyExtName/:templateId',
      name: 'smstemplate',
      component: SMSTemplateDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/lettertemplate/:surveyExtName/:templateId',
      name: 'lettertemplate',
      component: LetterTemplateDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/mailcampaign/:surveyExtName/:campaignId',
      name: 'mailcampaign',
      component: MailCampaignDetails,
      meta: { requiresAuth: true }
    },

    {
      path: '/createmailcampaign/:surveyExtName/:mailAction/:drillPath',
      name: 'createmailcampaign',
      component: CreateCampaignPage,
      meta: { requiresAuth: true, hideViewTabs: true, hideFilterSidebar: true }
    },

    {
      path: '*',
      redirect: '/',
      meta: { requiresAuth: false }
    },

    {
      path: '/responsive',
      name: 'responsive',
      meta: { requiresAuth: false },
      component: Responsive

      // // route level code-splitting
      // // this generates a separate chunk (about.[hash].js) for this route
      // // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
  ]
})

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

router.beforeEach(async (to, from, next) => {
      const wasAuthenticated = router.app.$keycloak.authenticated
      if (to.matched.some(record => record.meta.requiresAuth)) {

        // We wait for Keycloak init, then we can call all methods safely
        while (router.app.$keycloak.createLoginUrl === null) {
          await sleep(100)
        }

        if (router.app.$keycloak.authenticated) {
          if(!wasAuthenticated) {
            if(to.fullPath!=='/' && to.query.linkable) {
              next({ path: '/', query: { redirect: to.fullPath, survey: to.params.surveyExtName}})
            } else {
              next('/')
            }
          } else {
            next()
          }
        } else {
          const loginUrl = router.app.$keycloak.createLoginUrl()
          window.location.replace(loginUrl)
        }
      } else {
        next()
      }
    }

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (!store.getters.isAuthenticated /*getAuthToken*/) {
//       // console.log("Not logged in - forwarding to login page")
//
//       // console.log("About to redirect. ")
//       // console.log(to)
//
//       if(to.name==='rankings' || to.name==='page') {
//         next({ path: '/'});
//       } else {
// //        console.log("Survey: " + to.params.surveyExtName)
//
// //        let surveyName = to.params.surveyExtName ?
//
//         next({ path: '/', query: { redirect: to.fullPath, survey: to.params.surveyExtName}})
//       }
//
//
//
//     } else {
//       // console.log("All good from router - we are logged in")
//       next()
//     }
//   } else {
//     next() // make sure to always call next()!
//   }
// }
//
//



)

export default router
