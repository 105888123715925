<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col>
        <template v-if="!chartOptions">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </template>
        <template v-else>
          <highcharts :options="chartOptions" :ref="'chart'"></highcharts>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'

import ResultsService from "../../services/ResultsService";
import ChartUtil from "../../chartutil";

export default {
  name: 'GroupedChartComponent',

  components: {
    highcharts: Chart
  },

  data () {
    return {
      chartOptions: null,
      selectedCategories: new Map() // categoryIndex -> NamedPath
    }
  },

  props: {
    componentData: Object
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    selectionMode() {
      return this.externalName!=null
    },

    externalName() {
      return (this.componentData.config) ? this.componentData.config.extname : this.componentData.extname
    }

  },

  methods: {
    loadChartData() {
      const id = (this.componentData.config) ? this.componentData.config.id : this.componentData.id

      ResultsService.fetchGroupedChartData(this.getAuthToken, this.getActiveSurvey.extName, id, this.getFilteredDrillPath).then((response) => {
        this.updateChart(response.data)
      })
    },

    updateChart(chartdata) {
      if (! chartdata.lang) {
        chartdata.lang = ChartUtil.getLangTranslations(this) // TODO [tho] - move this to ChartsPort
      }
      if (! chartdata.exporting) {
        chartdata.exporting = ChartUtil.getExportingSettings() // TODO [tho] - move this to ChartsPort
      }


      if(this.selectionMode) {
        const self = this

        chartdata.plotOptions.series.events = {
          click: function (event) {
            self.setSelectedCategoryIndex(event.point.index)
          }
        }
      }

      this.chartOptions = chartdata;
    },

    setSelectedCategoryIndex(categoryIndex) {
      const categoryPath = this.chartOptions.categoryPaths[categoryIndex]

      //
      if(this.selectedCategories.has(""+categoryIndex)) { // deselect
        this.selectedCategories.delete(""+categoryIndex);
        this.$refs.chart.chart.xAxis[0].removePlotBand("band"+categoryIndex)
      } else {
        this.selectedCategories.set(""+categoryIndex, categoryPath)

        this.$refs.chart.chart.xAxis[0].addPlotBand({ id: "band"+categoryIndex, color: '#dae3f3', from: categoryIndex-0.4, to: categoryIndex+0.4 })
      }

      for(const [idx, tick] of Object.entries(this.$refs.chart.chart.xAxis[0].ticks)) {
        const cssValue = this.selectedCategories.has(""+idx) ? '1000' : 'normal'
        if(tick.label) {
          tick.label.css({
            fontWeight: cssValue
          });
        }
      }

      // dispatch event
      if(this.selectionMode) {
        const paths = Array.from(this.selectedCategories.values());
        this.$store.dispatch('postGlobalEvent', { source: this.externalName, type: "filterPath", data: paths })
      }

    }

  },

}
</script>
<style scoped>
</style>
