<template>
  <v-card outlined>
    <v-card-title >Effect blocks</v-card-title>

    <v-expansion-panels>
      <v-expansion-panel v-for="block in effectBlocks" :key="block.id">
        <v-expansion-panel-header>
          <v-row>
            <v-col cols="2" >
              <v-text-field v-model="block.drillPath" label="path"/>
            </v-col>

            <v-col cols="2" >



<!--              <v-menu v-model="showPicker"-->
<!--                  :close-on-content-click="false" transition="scale-transition" offset-y>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-text-field prepend-icon="mdi-calendar" v-model="selectedDate" label="From" readonly v-on="on"></v-text-field>-->
<!--                </template>-->
<!--                <v-date-picker v-model="selectedDate" no-title @input="showPicker = false" scrollable></v-date-picker>-->
<!--              </v-menu>-->



              <v-menu ref="menu" v-model="menuFrom"  transition="scale-transition" :close-on-content-click="false" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="block.fromDate" label="From" readonly prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="block.fromDate" @input="menuFrom = false" :locale="locale" no-title scrollable></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2" >
              <v-menu ref="menu" v-model="menuTo" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="block.toDate" label="To" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="block.toDate" @input="menuTo = false" :locale="locale" no-title scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>


            <v-col cols="2" >
              <v-text-field type="number" v-model="block.fromCSI" label="csi from"/>
            </v-col>

            <v-col cols="2" >
              <v-text-field type="number" v-model="block.toCSI" label="csi to"/>
            </v-col>

            <v-col cols="1" align-self="center">
              <v-btn @click="onSave(block)" icon>
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn @click="onDelete(block)" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>

          </v-row>

        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row v-for="(row, idx) in block.rows" :key="idx">
            <v-col cols="4">
              <v-text-field :value="row.questionName" label="question" disabled></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field :value="row.questionVariable" label="variable" disabled></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="row.effectSize" label="effect"></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <div class="d-flex">
      <v-btn @click="onNewBlockClicked()" icon>
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </div>

  </v-card>
</template>

<script>

import {mapGetters} from "vuex";
import SurveysService from "@/services/SurveysService";

export default {
  name: 'EffectBlockList',

  data: () => ({
    effectBlocks: [],
    date: new Date().toISOString().substr(0, 10),
    menuFrom: null,
    menuTo: false,

    showPicker: false,
    selectedDate: null

  }),

  props: {},

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    locale() {
      return this.getLocale.replace('_', '-')
    },

  },

  created() {
    SurveysService.fetchEffectBlocks(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId).then((response) => {
      this.effectBlocks = response.data;
    })
  },

  methods: {
    onSave(block) {
      SurveysService.saveEffectBlock(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId, block).then((/*response*/) => {
      })
    },

    onNewBlockClicked() {
      SurveysService.createEffectBlock(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId).then((response) => {
        this.effectBlocks = response.data
      })
    },

    onDelete(block) {
      SurveysService.deleteEffectBlock(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId, block.id).then((response) => {
        this.effectBlocks = response.data
      })
    },



  }


}
</script>

<style scoped>
</style>