<template>
  <KeyCloakLogin v-if="showKeyCloakLogin"></KeyCloakLogin>
</template>

<script>
import KeyCloakLogin from "@/views/KeyCloakLogin.vue";
import Clientconfig from "@/clientconfig";
import backend from "@/services/backend";

export default {
  name: 'login',

  components: {KeyCloakLogin },

  data: () => ({
    showKeyCloakLogin: false
  }),

  created() {
    if (!Clientconfig.isLoaded()) {
      backend.get('/config/client').then((response) => {
        let clientConfigDTO = response.data
        Clientconfig.setConfig(clientConfigDTO)
        this.showKeyCloakLogin = true
      });
    } else {
      this.showKeyCloakLogin = true
    }
  }

}
</script>
