<template>
  <v-menu class="bugMenu" offset-y>

    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="onClick" icon v-on="on" v-bind="attrs">
        <v-badge v-if="getSurveyBugs.length>0" :content="getSurveyBugs.length" color="#BE0000" offset-x="11" offset-y="20">
          <v-icon size="26">mdi-bug-outline</v-icon>
        </v-badge>
        <v-icon v-else size="26">mdi-bug-outline</v-icon>
      </v-btn>
    </template>

    <v-list class="bugList">
      <v-list-item class="bugItem" v-for="(item, index) in getSurveyBugs" :key="index">
        <v-list-item-content>
          <v-list-item-title class="bugTitle">{{item.count}}: {{ item.message }}</v-list-item-title>
          <v-list-item-subtitle class="bugSubtitle">{{ item.user!=='?' ? item.user : '' }} {{ item.datetime }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-btn icon @click="onReloadBugs"> <v-icon>mdi-reload</v-icon> </v-btn>
    <v-btn icon @click="onClearBugs"> <v-icon>mdi-delete</v-icon> </v-btn>
  </v-menu>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'BugsNotifierComponent',

  computed: {
    ...mapState(['numberOpenAlerts']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getSurveyBugs']),
  },

  data() {
    return {
      items: ['aa', 'bb']
    }
  },

  methods: {
    onClick() {
      // let drillpath = this.isAlertFilterDefined ? this.getAlertFilteredDrillPath : this.getFilteredDrillPath
      // PageNavigator.navigateToAlertsPage(this, this.getActiveSurvey.extName, drillpath)
    },

    onReloadBugs() {
      this.$store.dispatch('fetchBugsForSurvey', this.getActiveSurvey.extName)
    },

    onClearBugs() {
      this.$store.dispatch('clearBugsForSurvey', this.getActiveSurvey.extName)
    }

  }

}
</script>

<style scoped>

.bugMenu {
  background-color: #ffffffff;
}

.bugItem {
  min-height: auto;
}

.bugTitle {
  font-size: 10px;
}
.bugSubtitle {
  font-size: 10px;
}
</style>

