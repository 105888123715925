<template>
  <div class="cellContainer" @click.stop>
<a v-if="!isEmpty" :href="linkData.url" target="_blank" >{{linkData.text}}<v-icon color="#0000c8" small>mdi-open-in-new</v-icon> </a>

<!--    <v-btn icon :href="linkData.url" target="_blank">-->
<!--      <v-icon>mdi-open-in-new</v-icon> {{linkData.text}}-->
<!--    </v-btn>-->

  </div>
</template>

<script>
export default {
  name: 'LinkCell',

  data: () => ({
    colors: [],
    visibilities: []
  }),

  props: {
    linkData: Object
  },

  computed: {
    isEmpty() {
      return this.linkData.url===null || this.linkData.url===''
    }
  }

}

</script>

<style scoped>
  div.cellContainer {
    text-align: left;
    white-space: nowrap;
  }

  div.cellContainer a {
    text-decoration: none;
    color: #0000c8;
  }
</style>
