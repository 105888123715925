import backend from '@/services/backend'

export default {

  fetchRolesListData (authToken, surveyName) {
    return backend.get('vuejs/roles/' + surveyName, { headers: { Authorization: authToken } })
  },

  fetchRoleData(authToken, surveyName, roleId) {
    return backend.get('vuejs/roles/' + surveyName + '/' +roleId, { headers: { Authorization: authToken } })
  },

  saveRole(authToken, surveyName, roleData) {
    return backend({ method: 'post', url: 'vuejs/roles/' + surveyName, data: roleData,  headers: { Authorization: authToken } })
  },

  deleteRole(authToken, surveyName, roleId) {
    return backend.delete('vuejs/roles/'+ surveyName + '/'+ roleId, { headers: { Authorization: authToken } })
  }

}
