<template>
  <v-card outlined>
    <v-card-title >Metadata</v-card-title>

    <v-card-actions>
      <v-text-field
          v-model="filterText"
          label="Filter"
          append-icon="mdi-filter-outline"/>
    </v-card-actions>

    <v-expansion-panels v-if="metadata!==null && filterText===''">
      <v-expansion-panel v-for="(qg,i) in filteredQuestionGroups" :key="i">
        <v-expansion-panel-header>
          {{ questionGroupTitle(qg) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetaQuestionGroupComponent :filter-text="filterText" :qgroupdata="qg"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <template v-if="filterText!==''">
      <MetaQuestionComponent v-for="q in filteredQuestions"  :qdata="q" :key="q.id"/>
    </template>


  </v-card>
</template>

<script>

import {mapGetters} from "vuex";
import MetadataService from "@/services/MetadataService";
import MetaQuestionGroupComponent from "@/views/backend/MetaQuestionGroupComponent";
import MetaQuestionComponent from "@/views/backend/MetaQuestionComponent";

export default {
  name: 'MetadataComponent',

  components: {
    MetaQuestionGroupComponent, MetaQuestionComponent
  },

  data: () => ({
    metadata: null,
    filterText: ""
  }),

  props: {
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    filteredQuestionGroups() {
      return this.metadata.questionGroups
    },

    filteredQuestions() {
      return this.metadata.questionGroups
          .flatMap(group => group.questions)
          .filter(question => this.questionMatch(question, this.filterText))
          .slice(0,10)
    }

  },


  created() {
    MetadataService.fetchMetadata(this.getAuthToken, this.getActiveSurvey.extName).then((response) => {
      this.metadata = response.data
    })
  },

  methods: {
    questionGroupTitle(qgroupData) {
      return qgroupData.type + ': ' +  qgroupData.name.localeTextList[0].text + '[' + qgroupData.extName + ']'
    },

    questionMatch(question, filterText) {
      const props =  Object.values(question).findIndex( elem => (typeof elem === 'string') && elem.toLowerCase().indexOf(filterText.toLowerCase())!==-1  ) !== -1
      const texts = this.textMapMatch(question.title, filterText) || this.textMapMatch(question.question, filterText)
      const choices = (question.choices!==null) && question.choices.findIndex( choice => this.choiceMatch(choice, filterText)) !== -1

      return props || texts || choices
    },

    choiceMatch(choice, filterText) {
      return this.textMapMatch(choice.title, filterText) ||
          Object.values(choice).findIndex(prop => (typeof prop === 'string') && prop.toLowerCase().indexOf(filterText.toLowerCase())!==-1) !==-1
    },


    textMapMatch(textMap, filterText) {
      return textMap.localeTextList.findIndex(elem => elem.text.toLowerCase().includes( filterText.toLowerCase() )   ) !== -1
    }

  }


}
</script>

<style scoped>
</style>