<template>
    <v-container class="wrapper" fluid>
        <v-row><v-col><span :class="[positive ? 'positiveTitle' : 'negativeTitle']" class="title">{{'CATEGORIES_TOP3' | i18n}}</span></v-col></v-row>

        <v-row v-for="(catName, idx) in categories" :key="idx"><v-col>{{catName}}</v-col></v-row>
        <v-row><v-col><v-btn color="#3f3f3f" class="white--text" @click="onMoreDetailsClicked">{{'SEE_MORE_DETAILS' | i18n}}</v-btn></v-col></v-row>
    </v-container>
</template>

<script>
    import PageNavigator from "../../../../pagenavigator"
    import {mapGetters} from "vuex";

    export default {
        name: 'TextAnalyticsCategoryComponent',
        components: {},
        data: () => ({}),

        props: {
            positive: Boolean,
            categories: Array,
            pageName: String
        },

        computed: {
            ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
        },

        methods: {
            onMoreDetailsClicked() {
                PageNavigator.navigateToPage(this, this.pageName, this.getActiveSurvey.extName, this.getFilteredDrillPath)
            }
        }

    }

</script>

<style scoped>
    span.positiveTitle {
        /*font-size: 20px;*/
        font-size: calc((20/16)*1rem);
        border-bottom: solid 2px #00C896;
    }

    span.negativeTitle {
      /*font-size: 20px;*/
      font-size: calc((20/16)*1rem);
      border-bottom: solid 2px #BE0000;
    }

    /*.wrapper {*/
    /*    margin-right: 5em;*/
    /*}*/
</style>