<template>

    <v-container fluid class="wrapper elevation-2 d-flex flex-column align-center justify-space-between">
        <div class="title ">
            {{streakdto.streakType}} {{'GAMIFICATION_TOP_MARKS_ROW' | i18n}}
        </div>

        <div class="align-center">
            <img width="130px" :src="streakdto.obtained!==0 ? streakdto.obtainedImageURL : streakdto.notObtainedImageURL">
        </div>

        <div :class="streakdto.obtained===0 ? 'unobtainedContainer' : 'obtainedContainer'"><span class="obtainedNumber">{{streakdto.obtained}}</span> {{'GAMIFICATION_OBTAINED' | i18n}}</div>
        <div class="totalScore">{{'GAMIFICATION_TOTAL_SCORE' | i18n}}: {{streakdto.score}}</div>

    </v-container>

</template>

<script>
export default {
    name: 'StreakMarksComponent',

    props: {
        streakdto: Object
    },

    methods: {
        getObtainedImage(streakCount) {
            if(streakCount===5) {
                return "https://dashboard.aganalytics.dk/files/awards/badge5_obtained.png"
            }
        }

    }


}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
    background-color: white;
    height: 100%;
    border-radius: 5px;
}

div.title {
    /*font-size: 18px !important;*/
    font-size: calc((18/16)*1rem) !important;
    font-weight: 400;
    color: rgba(0,0,0,0.87);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.obtainedContainer {
    /*font-size: 13px !important;*/
    font-size: calc((13/16)*1rem) !important;
    color: rgba(0,0,0,0.87);
    padding-left: 2vw;
    padding-right: 2vw;
    border-bottom: #0000C8 2px solid;
}

div.unobtainedContainer {
    /*font-size: 13px !important;*/
    font-size: calc((13/16)*1rem) !important;
    color: rgba(0,0,0,0.87);
    padding-left: 2vw;
    padding-right: 2vw;
    border-bottom: rgb(144, 155, 167) 2px solid;
}

span.obtainedNumber {
    /*font-size: 24px !important;*/
    font-size: calc((24/16)*1rem) !important;
    font-weight: 500;
    margin-right: 5px;
}

div.totalScore {
    /*font-size: 13px !important;*/
    font-size: calc((13/16)*1rem) !important;
    color: rgba(0,0,0,0.87);
    margin-bottom: 10px;
    margin-top: 4px;
}

</style>
