<template>
  <div class="wrapper fill-height-or-more">

    <div class="row_one">
      <div class="headerDiv">
        <img class="topLogo" :src="bigScreenData.logoURL" alt="logo"/>
        <div class="topTitle">{{bigScreenData.pathName}}</div>
      </div>
    </div>
    <div class="row_two">

      <div class="ratingColumn">

        <v-container fluid class="mainColumn" >
          <v-row align="center" justify="center">
            <v-col>
              <div class="questionTitle">{{bigScreenData.ratingTitle}}</div>
            </v-col>
          </v-row>

          <v-divider class="vdivider"/>

          <v-row align="center" justify="center">
            <v-col>
              <div class="mainQuestionScore">{{bigScreenData.avgRatingText}}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-rating v-model="bigScreenData.avgRating"  :color="starsColor" size="5vw" half-increments readonly></v-rating>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex" style="align-items: center; justify-content: center">
              <img src="../../assets/animating_target.gif" width="30vw" height="30vw" alt="target">
              <div class="evaluations"> {{bigScreenData.evaluationsText}}</div>
            </v-col>
          </v-row>


          <v-divider class="vdivider"/>

          <v-row  >
            <v-col align="center" justify="center">
              <div class="providedBy">{{ bigScreenData.deliveredByText }}</div>
              <img src="../../assets/ag_logo.png" alt="target">
            </v-col>
          </v-row>


        </v-container>

      </div>
      <div class="commentColumn">
        <v-container fluid class="mainColumn" style="display: flex; align-items: center">
          <v-carousel cycle hide-delimiter-background show-arrows-on-hover max="20" style="height:60vh">
            <v-carousel-item v-for="(slide, i) in bigScreenData.slides" :key="i">
              <v-sheet :color="slideColor">
                <v-container fluid>
                  <v-row>
                    <v-col offset="2">
                      <v-rating v-model="slide.starRating" :color="slideStarsColor" size="4vw" half-increments readonly></v-rating>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="8" offset="2">
                      <div class="questionAnswer">{{slide.comment}}</div>
                    </v-col>
                  </v-row>

                  <v-row >
                    <v-col cols="8" offset="2">
                      <div class="interviewDate">{{slide.evaluation}}</div>
                    </v-col>
                  </v-row>

                </v-container>

              </v-sheet>

            </v-carousel-item>
          </v-carousel>

        </v-container>


      </div>

    </div>
  </div>

</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'LegacyBigScreen',

  props: {
    bigScreenData: Object
  },

  computed: {
    ...mapState(['userIdentity', 'activeSurvey', 'activeDrillPath']),
    ...mapGetters(['getFilteredDrillPath']),

    colors() {
      return this.bigScreenData.colors
    },

    wrapperColor() {
      return this.colors.wrapperColor ? this.colors.wrapperColor : '#1d2633'
    },

    mainColumnColor() {
      return this.colors.mainColumnColor ? this.colors.mainColumnColor : '#242d3c'
    },

    topTitleColor() {
      return this.colors.topTitleColor ? this.colors.topTitleColor : "#ffffff"
    },

    questionTitleColor() {
      return this.colors.questionTitleColor ? this.colors.questionTitleColor : '#ffffff'
    },

    mainQuestionScoreColor() {
      return this.colors.mainQuestionScoreColor ? this.colors.mainQuestionScoreColor : '#ffffff'
    },

    interviewDateColor() {
      return this.colors.interviewDateColor ? this.colors.interviewDateColor : '#646a74'
    },

    ratingColumnColor() {
      return this.colors.ratingColumnColor ? this.colors.ratingColumnColor : '#802420'
    },

    questionAnswerColor() {
      return this.colors.questionAnswerColor ? this.colors.questionAnswerColor : '#ffffff'
    },

    evaluationsColor() {
      return this.colors.evaluationsColor ? this.colors.evaluationsColor : "#646a74"
    },

    dividerColor() {
      return this.colors.dividerColor ? this.colors.dividerColor : "#1d2633"
    },

    providedByColor() {
      return this.colors.providedByColor ? this.colors.providedByColor : "#646a74"
    },

    starsColor() {
      return this.colors.starsColor ? this.colors.starsColor : "yellow"
    },

    slideStarsColor() {
      return this.colors.slideStarsColor ? this.colors.slideStarsColor : "white"
    },

    slideColor() {
      return this.colors.slideColor ? this.colors.slideColor : "#242d3c"
    },

}


}
</script>

<style scoped>

div.wrapper {
  //background-color: #1d2633;
  background-color: v-bind(wrapperColor);
}


.row_one {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

div.headerDiv {
  display: flex;
  align-items: center;
}


div.row_two {
  display: flex;
  flex: 8;
  flex-direction: row;
}

.ratingColumn {
  flex: 3;
  //background-color: #802420;
  background-color: v-bind(ratingColumnColor);
  margin: 10px;
}

.commentColumn {
  flex: 9;
  //background-color: #8fbde7;
  //background-color: red;
  margin: 10px;
}


.fill-height-or-more {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}


.mainColumn {
  //background-color: #242d3c;
  background-color: v-bind(mainColumnColor);
  height: 100%;
}

.topLogo {
  width: 5vw;
  height: 5vw;
}

.topTitle {
  //color: #ffffff;
  color: v-bind(topTitleColor);
  font-size: 5vw;
  margin-left: 10px;
}

.questionTitle {
  //color: #ffffff;
  color: v-bind(questionTitleColor);
  font-size: 2.5vw;
  text-align: center;
}

.mainQuestionScore {
  //color: #ffffff;
  color: v-bind(mainQuestionScoreColor);
  font-size: 10vw;
  text-align: center;
}

.questionAnswer {
  color: v-bind(questionAnswerColor);
  font-size: 2vw;
}

.interviewDate {
  //color: #646a74;
  color: v-bind(interviewDateColor);
  font-size: 2vw;
}

.evaluations {
  //color: #646a74;
  color: v-bind(evaluationsColor);
  text-align: center;
  font-size: 1.5vw;
}

.vdivider {
  border-width: medium;
  //border-color: #1d2633;
  border-color: v-bind(dividerColor);
}

.providedBy {
  //color: #646a74;
  color: v-bind(providedByColor);
  font-size: 1vw;
}


</style>

