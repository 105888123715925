<template>
  <v-container class="wrapper" fluid pa-0>
    <template v-for="(branch, idx) in componentData.config.branches">
      <v-row no-gutters  :key="idx" v-if="branch===selectedBranch">
        <v-col cols="12">
          <page-component v-for="componentConfig in branch.components" :component-data="componentConfig" :key="componentConfig.id"/>
        </v-col>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import PageComponent from "@/views/pages/PageComponent.vue";

export default {
  name: 'DiscriminatorComponent',
  components: {
    'page-component': () => import('../PageComponent.vue')
  },

  data () {
    return {
      model: 0,
      selectedMenuItem: null
    }
  },

  props: {
    componentData: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['activeView']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale', 'getUserRole']),

    selectedBranch() {
      let selected = null;
      let selectedScore = 0;

      for(const branch of this.componentData.config.branches ) {
        const score = this.branchScore(branch)

        if(score>selectedScore) {
          selected = branch
          selectedScore = score
        }

      }

      return selected
    },

  },

  created: function () {
  },

  methods: {
    branchScore(branch) {
      let score = 1;

      if(branch.roles.length) {
        if(branch.roles.includes(this.getUserRole)) {
          score *= 2
        } else {
          return 0
        }
      }

      if(branch.views.length) {
        if(branch.views.includes(this.activeView.extName)) {
          score *= 2
        } else {
          return 0
        }
      }

      return score;
    },
  }

}
</script>

<style scoped>
.wrapper {
  border-radius: 5px;
  color: #2E4F5C
}

.singletab {
  text-transform: capitalize;
  color: #000 !important;
}

.activetab {
  color: #0000c8 !important;
}



</style>
