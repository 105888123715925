<template>
  <div>
    <v-select v-model="filterMode" :items="filterModes" @change="onFilterModeChange"></v-select>
  </div>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'FilterComponentToday',

  data () {
    return {
      filterMode: null,
   }
  },

  props: {
    filterSet: Object,
    filter: Object
  },

  created() {
    this.filterMode = null
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getFilterSelectedOptions', 'getActiveCombinedFilters']),

    filterModes() {
      return [{value: 'today_ymd', text: 'today'}, {value: 'today_md', text: 'annually'} ]
    },

  },


  methods: {
    onFilterModeChange() {
      const extname = this.filterMode

      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: [extname]})

      // this.checkedOptions = this.getActiveCombinedFilters.getFilterItems(this.activeSurvey.standardFilterSet)
      //     .filter(e => e.filter.extName===this.filter.extName)
      //     .map(e => e.option);
    },

    // onCheckClicked(optionExtName) {
    //   this.$store.commit('clearFilter', {filterName: this.filter.extName, filterValue: optionExtName})
    // }

  },


}
</script>

<style>
.filterCheckBox {
  /*    color: red; */
}

div.filterCheckBox div.v-input__control div.v-input__slot {
  margin: 0 !important;
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

}

div.filterCheckBox label {
  left: 6px !important;
}

div.filterCheckBox label {
  /*font-size: 13.5px !important;*/
  font-size: calc((13.5/16)*1rem) !important;

}

.v-select-list .v-list .v-input__slot { margin-bottom: 0 !important; }

/*.v-autocomplete-content .v-select-list .v-subheader {*/
/*  color: blue;*/
/*  font-size: 13.5px !important;*/
/*}*/

</style>
