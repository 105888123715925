import _ from 'lodash'


/**
 * @typedef {Object} FilterOption
 * @property {string} extName
 * @property {string} title
 */

/**
 * @typedef {Object} Filter
 * @property {string} extName
 * @property {string} title
 * @property {Array<FilterOption>} options
 * @property {boolean} multipleSelections
 */

/**
 * @typedef {Object} FilterItem
 * @property {Filter} filter
 * @property {FilterOption} option
 */

/**
 * @typedef {Object} FilterGroup
 * @property {string} key
 * @property {string} title
 * @property {boolean} expanded
 * @property {boolean} ungrouped
 * @property {boolean} flatten
 * @property {Array<Filter>} filters
 */

/**
 * @typedef {Object} FilterSet
 * @property {string} surveyExtName
 * @property {string} type
 * @property {Array<FilterGroup>} filterGroups
 */





function CombinedFilters (initialFilters) {
  this.filterMap = new Map()
  // this.timeSpan = []
  // this.loyaltySegments = []
  this.setFilters(initialFilters)
  // console.log("Filter init: " + initialFilters)
}

// CombinedFilters.prototype.timeFilter = function () {
//   const v = (this.timeSpan.length > 0) ? this.timeSpan[0] : '?'
//   return 'ftime=' + v
// }

CombinedFilters.prototype.asTokenString = function () {
  let result = []

  for (const [key, value] of this.filterMap.entries()) {
    if(value && 0 !== value.length) {
      // console.log(key, value);

      result.push( key+"=" +   value.join('*'))
    }
  }

  return result.join(';').replace("/", "¤")
}

CombinedFilters.prototype.getFilterValue = function (filterName) {
  if (filterName === 'ftime') {
    return this.timeSpan
  } else if (filterName === 'lsegs') {
    return this.loyaltySegments
  }
  return 'Unknown filter:' + filterName
}

CombinedFilters.prototype.parseIntegerListString = function (inputString) {
  return inputString.split(',')
}

// array! [filterValue]
CombinedFilters.prototype.setFilter = function (filterName, filterValue) {
  this.filterMap.set(filterName, [ ...filterValue ])
  this.filterMap = new Map(this.filterMap)
}


CombinedFilters.prototype.clearFilterAllOptions = function (filterName) {
  if(this.filterMap.has(filterName)) {
    this.filterMap.delete(filterName)
    this.filterMap = new Map(this.filterMap)
  }
},

CombinedFilters.prototype.clearFilter = function (filterName, filterValue) {
  if(this.filterMap.has(filterName)) {
    let values = this.filterMap.get(filterName)

    _.remove(values, (n) => n === filterValue )

    if(values.length === 0) {
      this.filterMap.delete(filterName)
    }

    this.filterMap = new Map(this.filterMap)
  }
}





// ftime=Iyr01012018:01012019;states=0,1;
CombinedFilters.prototype.setFilters = function (filtersTokenString) {
  let parts = filtersTokenString.trim().split(';')

  for (let f of parts) {
    if (f !== '') {
      let keyvalue = f.split('=')
      let value = keyvalue[1]
      if (value !== '') {
        this.setFilter(keyvalue[0], value.split('*'))
      }
    }
  }
}

// let combinedFilters = new CombinedFilters()



CombinedFilters.prototype.getFilterValuesForFilter = function (filterName) {
  let values = this.filterMap.get(filterName)

  return values ? values : []
}



// CombinedFilters.prototype.getFilterItemsForFilter = function (filterSet, filterName) {
//   this.getFilterItems(filterSet)
// }

/**

 */

CombinedFilters.prototype.appendFilterOptions = function (filterSet, filterName, filterOptions) {
  for (const filterGroup of filterSet.filterGroups) {
    for (const filter of filterGroup.filters) {

      if (filter.extName === filterName) {
        // console.log("Ok found: " + filterName)
        // console.log(filter.options)
        // console.log("appending")
        // console.log(filterOptions)

        const optionNamesSet = new Set( filter.options.map(o => o.extName.toLowerCase()) );

        for (const option of filterOptions) {
          if(!optionNamesSet.has(option.extName.toLowerCase())) {
            filter.options.push( option )
            optionNamesSet.add(option.extName)
          }
        }

        return
        // console.log(filter.options)
      }
    }
  }

}



CombinedFilters.prototype.getFilterItems = function (filterSet) {
  let result = []

  nextfilter:
  for(const [key, value] of this.filterMap.entries() ) {

    if (value && 0 !== value.length && typeof(value) === 'object') {

      // console.log("Val: " + value)

      nextvalue:
          for (var property of value) {

            for (const filterGroup of filterSet.filterGroups) {
              for (const filter of filterGroup.filters) {

                if(filter.extName === key) {

                  if(filter.range) {
                    result.push( { filter: filter, option: { title: value[0].replace('_', '-'), extName: value[0] } } )
                    continue nextvalue

                  } else if(filter.dateRef) {
                    const regex = "rdate([eba])(\\d+)([dmy])([bf])"
                    const found = property.match(regex);

                    let title = "?"

                    if(found) {
                      const filterDirection = found[1]

                      let filterDirectionTitle = ""

                      if(filterDirection==='e') {
                        filterDirectionTitle = "exact"
                      } else if(filterDirection==='b') {
                        filterDirectionTitle = "before"
                      } else if(filterDirection==='a') {
                        filterDirectionTitle = "after"
                      }

                      const deltaDirectionTitle = (found[4]==='b') ? 'before' : 'after';

                      const deltaValue = found[2]

                      let deltaUnit = found[3]

                      let deltaUnitTitle = null

                      if(deltaUnit==='d') {
                        deltaUnitTitle = 'days'
                      } else if(deltaUnit==='m') {
                        deltaUnitTitle = 'months'
                      } else if(deltaUnit==='y') {
                        deltaUnitTitle = 'years'
                      }

                      title = filterDirectionTitle + " " + deltaValue + " " + deltaUnitTitle + " " + deltaDirectionTitle + " today";
                    }

                    result.push( { filter: filter, option: { title: title, extName: property } } )
                    continue nextvalue

                  } else if(filter.today) {
                    let title = (property==='today_md') ? 'annually' : 'today';
                    result.push( { filter: filter, option: { title: title, extName: property } } )
                    continue nextvalue
                  } else if(filter.dateInterval) {
                    const rdays_regex = "rdays(-?\\d+),(-?\\d+)"
                    const rmonths_regex = "rmonths(-?\\d+),(-?\\d+)"
                    const ryears_regex = "ryears(-?\\d+),(-?\\d+)"

                    const rdays_result = property.match(rdays_regex);
                    const rmonths_result = property.match(rmonths_regex);
                    const ryears_result = property.match(ryears_regex);

                    let title = "?"

                    if(rdays_result) {
                      const from = Number( rdays_result[1] )
                      const to = Number( rdays_result[2] )

                      const fromTitle = (from>0) ? "+"+from+ " days" : ((from < 0) ? ""+from+" days" : "")
                      const toTitle = (to>0) ? "+"+to+ " days" : ((to < 0) ? ""+to+" days" : "")
                      title = "from today " + fromTitle + " until " + "today " + toTitle
                    } else if(rmonths_result) {
                      const from = Number( rmonths_result[1] )
                      const to = Number( rmonths_result[2] )

                      const fromTitle = (from>0) ? "+"+from+ " months" : ((from < 0) ? ""+from+" months" : "")
                      const toTitle = (to>0) ? "+"+to+ " months" : ((to < 0) ? ""+to+" months" : "")
                      title = "from this month " + fromTitle + " until " + "this month " + toTitle
                    } else if(ryears_result) {
                      const from = Number( ryears_result[1] )
                      const to = Number( ryears_result[2] )

                      const fromTitle = (from>0) ? "+"+from+ " years" : ((from < 0) ? ""+from+" years" : "")
                      const toTitle = (to>0) ? "+"+to+ " years" : ((to < 0) ? ""+to+" years" : "")
                      title = "from this year " + fromTitle + " until " + "this year " + toTitle
                    }

                    result.push( { filter: filter, option: { title: title, extName: property } } )
                    continue nextvalue
                  }

                  else {

                    for(const filterOption of filter.options) {
                      if (filterOption.extName === property) {        // property is the extname of the selected filter
                        result.push({filter: filter, option: filterOption})
                        continue nextvalue
                      } else if (property.startsWith("_") && filterOption.extName === property.slice(1)) {
                        result.push({filter: filter, option: {extName: property, title: filterOption.title}})
                        continue nextvalue
                      }
                    }

                    if(property && (property.startsWith("_") || property.endsWith("~") )) {
                      result.push( { filter: filter, option: { extName: property, title: property} })
                      continue nextvalue
                    } else if(filter.extName!=='ftime') {
                      result.push( { filter: filter, option: { extName: property, title: property} })
                      continue nextvalue
                    }


                  }

                } else if(filter.extName==="cf" && key.startsWith("cf")) {
                  let title = "";

                  if(value[0]!=="any") {
                    title+=" send out " + value[0] + " at least days ago"
                  }

                  if(value[1]!=="any") {
                    title+=" state=" + value[1]
                  }

                  if(value[2]!=="any") {
                    title+=" opened=" + value[2]
                  }

                  if(value[3]!=="any") {
                    title+=" clicked=" + value[3]
                  }

                  result.push({filter: filter, option: {extName: key, campaignId: parseInt(key.substring(2)), title: title}})
                  continue nextfilter
                }

              }
            }



          }
    }




  }

  return result

}


CombinedFilters.prototype.searchFilterOptionDTO = function (filter, optionName) {
//  console.log("searchFilterOptionDTO " + filter.extName + " " + optionName)

  for(const filterOption of filter.options) {
    // console.log("Checking: " + filterOption.extName)
    if( filterOption.extName === optionName) {
      return filterOption
    }
  }

  return null
}


CombinedFilters.prototype.searchFilterDTO = function (filterSet, name) {

  for(const filterGroup of filterSet.filterGroups) {
    for(const filter of filterGroup.filters) {
      // console.log("Looking at " + filter.extName + " for " + name);
      if(filter.extName === name) {
        // console.log("Got it!");
        return filter
      }
    }
  }

  return null
}



export default CombinedFilters
