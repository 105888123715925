<template>
  <v-dialog v-model="dialog" :max-width="600" style="{ z-index: 200 }" @keydown.esc="onCancel" @click:outside="onCancel">

    <v-container class="wrapper" fluid>
      <v-row>
        <v-col cols="8">
          {{bodyText}}
        </v-col>
        <v-col>
          <v-btn color="warning" class="body-2 font-weight-bold" @click.native="onAction">{{ actionText }}</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="waiting">
        <v-col>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
      </v-row>

    </v-container>

  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import CampaignService from "@/services/CampaignService";

export default {
  name: "CampaignDialog",

  computed: {
    ...mapGetters(['getLocale', 'getCampaigns', 'getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),

    campaign() {
      return this.getCampaigns[0]
    },

    actionText() {
      return this.campaign.actionText[this.getLocale]
    },

    bodyText() {
      return this.campaign.bodyText[this.getLocale]
    }

  },


  data() {
    return {
      dialog: true,
      waiting: false
    };
  },

  methods: {
    onAction() {

      this.waiting = true

      CampaignService.fetchCampaignLink(this.getAuthToken, this.getActiveSurvey.extName, this.campaign.extName).then((response) => {
        const linkResponse = response.data;

        window.open(linkResponse.url, '_blank');
        this.dialog = false;
      })
      this.dialog = true;


      this.$store.commit('removeCampaign')
    },

    onCancel() {
      this.dialog = false;
      this.$store.commit('removeCampaign')
    }

  },
};
</script>

<style scoped>
.wrapper {
  background-color: #1d2633;
  color: #eeeeee;
}
</style>
