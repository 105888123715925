<template>
  <v-radio-group class="radioGroup" v-model="radioGroup" @change="radioClicked">
    <v-list-item v-for="option in filter.options" :key="option.extName">
      <v-list-item-content class="listitem">
        <v-radio class="filterRadioButton ma-0 pa-0" :value="option.extName" :label="option.title"/>
      </v-list-item-content>
    </v-list-item>
  </v-radio-group>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'FilterComponentRadios',

  data () {
    return {
      radioGroup: null
    }
  },

  props: {
    filter: Object
  },

  computed: {
    ...mapGetters(['getActiveCombinedFilters'])
  },

  methods: {
    radioClicked() {
      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: [this.radioGroup]})
    }
  },


  watch: {
    combinedFilters: {
      handler() {
        let filterValues = this.getActiveCombinedFilters.getFilterValuesForFilter(this.filter.extName);

        if(this.radioGroup !== filterValues[0]) {
          this.radioGroup = filterValues[0]
        }
      },
      deep: true,
      immediate: true
    },

  },


}
</script>

<style >
  .listitem {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  div.filterRadioButton label {
    margin: 0 !important;
    /*font-size: 13.5px !important;*/
    font-size: calc((13.5/16)*1rem) !important;

  }

  .radioGroup div div {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }


</style>
