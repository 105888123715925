<template>
    <div style="height: 50px">
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {Chart} from 'highcharts-vue'
    // import ColorManager from "colormanager";


    export default {
        name: 'CategoryFeedbackChartComponent',

        components: {
            highcharts: Chart
        },

        data () {
            return {
                chartOptions: {
                    chart: {
                        type: 'bar',
                        height: 45,
                        marginTop: 0,
                        marginBottom: 0
                    },
                    title: null,

                    credits: {
                        enabled: false
                    },

                    legend: {
                        enabled: false,
                    },

                    xAxis: {
                        visible: false,
                    },

                    yAxis: {
                        visible: false
                    },

                    plotOptions: {
                        series: {
                            stacking: 'percentage',
                            stackingOrder: 'normal',
                        }
                    },

                    tooltip: {
                        formatter: function() {
                            return this.series.name +': ' + this.y.toFixed(2) +'%';
                        }
                    },

                    series: [{
                        color: "#00C896",
                        index: 2,
                        data: [this.categoryData.percentagePositives],
                        name: 'Positiv feedback'
                    }, {
                        color: "#BE0000",
                        index: 1,
                        data: [this.categoryData.percentageNegatives],
                        name: 'Negativ feedback'
                    }, {
                        color: "#BFBFBF",
                        index: 0,
                        data: [this.categoryData.percentageNeutrals],
                        name: 'Neutral feedback'
                    }]



                }
            }
        },

        props: {
            categoryData: Object
        },

        created: function () {
            this.chartOptions.series[0].name = this.$t('POSITIVE_FEEDBACK')
            this.chartOptions.series[1].name = this.$t('NEGATIVE_FEEDBACK')
            this.chartOptions.series[2].name = this.$t('NEUTRAL_FEEDBACK')
        },


        computed: {
            ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
        },

        methods: {

        },

    }
</script>
<style scoped>
</style>
