<template>
  <v-card>

  <v-carousel v-model="model" hide-delimiters height="auto" class="scrollableCarousel">
    <v-carousel-item v-for="id in trackingIds" :key="id">
      <RenderedMail :campaign-id="0" :template="templateRef" :tracking-id="id" />
    </v-carousel-item>
  </v-carousel>

  </v-card>
</template>


<script>
import {mapGetters, mapState} from 'vuex'
// import MailsService from "@/services/MailsService";
import RenderedMail from "@/views/mailcampaigns/RenderedMail";

export default {
  name: 'RenderedMailCarousel',

  components: {RenderedMail},

  props: {
    campaignId: Number,
    templateRef: String,
    trackingIds: Array
  },

  data: () => ({
    model: 0,
    loading: false,
    // renderedMail: null
  }),

  // created: function () {
  //   MailsService.fetchRenderedMailData(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.trackingId, this.templateRef, "maillive").then((response) => {
  //     this.renderedMail = response.data
  //   })
  // },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),
  },

}
</script>

<style>

.scrollableCarousel {
  overflow: auto !important;
}

.scrollableCarousel .v-window__prev, .v-window__next {
  top: 1em;
}

.waitingDiv {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

</style>
