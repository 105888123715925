<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
import {Chart} from "highcharts-vue";

export default {
  name: 'SideDevChart',

  data () {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          alignTicks: false,
        },

        plotOptions: {
          pointStart: 2010
        },

        xAxis: {
          categories: "this.chartData.categories"
        },

        yAxis: [{
          lineWidth: 1,
          title: {
            text: "this.chartData.title"
          }
        }
        ],

        series: [ { data: this.chartData.data } ],

        tooltip: {
          enabled: true,
          shared: false
        },

        title: {
          text: null
        },

        legend: {
          enabled: false
        },

        credits: {
          enabled: false
        },

      }
    }

  },

  props: {
    chartData: Object
  },

  components: {
    highcharts: Chart
  },

  watch: {
    chartData: {
      handler() {
        this.chartOptions.xAxis.categories = this.chartData.categories
        this.chartOptions.yAxis.title = { text: this.chartData.title }
        this.chartOptions.series = [ { data: this.chartData.data } ]
      },
      immediate: true
    },
  },

}
</script>
