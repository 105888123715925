e<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on }">
                <v-btn :color="color" dark v-on="on">{{title | i18n}}</v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{ headline | i18n }}
                </v-card-title>

                <v-card-text>
                    <v-textarea name="input-7-1" :label="$t('COMMENTS')" v-model="updatedComment"/>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="onOkClicked">{{title | i18n}}</v-btn>
                    <v-btn color="primary" @click="dialog = false">{{'CANCEL' | i18n}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    // import _ from 'lodash'

    export default {
        name: 'CommentDialog',

        components: {},

        data: () => ({
            dialog: false,
            updatedComment: null,
        }),

        props: {
            color: String,
            headline: String,
            title: String,
            value: String
        },

        created: function () {
            this.updatedComment = this.value
        },

        computed: {},

        methods: {

            onOkClicked() {
                // console.log("Returning: " + this.updatedComment)
                this.$emit("do-action", { comment: this.updatedComment})
                this.dialog = false;
            }

        },

    }
</script>
<style scoped>
</style>